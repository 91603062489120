import React, { useCallback, useEffect, useState } from "react";
import { ProtagonistDetails, RoundImage, SubText } from "./style";
import PropTypes from "prop-types";
import { apiHandler } from "../../services/axios";
import UserIcon from "../../assets/icons/UserIcon";
import { getUser } from "../../services/userServices";

const Types = {
  Capsule: "capsule",
  Expanded: "expanded",
  normal: "normal",
};

const ProtagonistChip = (props) => {
  const [createrdata, setCreaterData] = useState();

  const { className: _className = "", id, type = Types.Capsule } = props;

  const defaultClass = "protagnistChip";
  const [className, setClassName] = useState(defaultClass);

  const getCreaterDetails = useCallback(async () => {
    if (id) {
      apiHandler(() => getUser(id), {
        onSuccess: (data) => {
          setCreaterData(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      getCreaterDetails();
    }
    let classText = [defaultClass, type, ..._className.split(" ")].join(" ");
    setClassName(classText);
  }, [_className, id, type, getCreaterDetails]);

  return (
    <ProtagonistDetails className={className} $imgURL={createrdata?.profile_image_url}>
      <div className="avatar">
        <RoundImage>
          {createrdata?.profile_image_url ? (
            <img
              className="asset-img"
              src={createrdata?.profile_image_url}
              alt="Card cap"
            />
          ) : (
            <UserIcon className="svg" />
          )}
        </RoundImage>
      </div>
      <div className="details">
        {createrdata?.username ? (
          <SubText className=" label">{createrdata?.username}</SubText>
        ) : (
          <SubText className=" label">@anonymous</SubText>
        )}
        {/* <p className="text2 protagonist-name">{name}</p> */}
      </div>
      {/* {description && type === Types.Expanded ? (
        <div className="description text-weak">Description:</div>
      ) : null} */}
    </ProtagonistDetails>
  );
};

export default ProtagonistChip;

// ProtagonistChip.propTypes = {
//   name: PropTypes.string,
//   className: PropTypes.string,
//   image: PropTypes.string,
//   description: PropTypes.string,
//   type: PropTypes.oneOf([Types.Capsule, Types.Expanded, Types.normal]),
// };
