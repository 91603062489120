import React from "react";

import EtherScanLogo from "../../assets/icons/etherScanLogo";
// import defaultConfig from "../../configuration/config";
import { useTheme } from "styled-components";

import Button from "../Button/Button";

import PropTypes from "prop-types";
import {
  getEtherscanLink,
  getWethioscanLink,
} from "../../common/helpers/helper";
import { StyledEtherScanLink } from "./style";
import store from "../../store/store";
import { useSelector } from "react-redux";

const LinkTypes = {
  Etherscan: "etherscan",
};

const EtherScanLink = ({
  icon,
  borderLess = false,
  mintTxHash = "",
  className = "",
  text = "View on Etherscan",
  link = LinkTypes.Etherscan,
}) => {
  const theme = useTheme();
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const blockchain = useSelector((state) => state.global.blockchain);
  return (
    <StyledEtherScanLink
      className={`link-wrap ${className}`}
      $borderLess={borderLess}
    >
      <Button
        as={"a"}
        id={`${link}-link`}
        outline="true"
        color="secondary"
        className="custom-link"
        target="_blank"
        href={
          blockchain === "eth"
            ? getEtherscanLink(defaultConfig?.chainInfo.name, mintTxHash)
            : getWethioscanLink(defaultConfig?.chainInfo.name, mintTxHash)
        }
      >
        <div className="etherscan-wrapper">
          {icon ? (
            <img
              style={{ height: "30px", width: "30px" }}
              src={icon}
              alt="wethio"
            />
          ) : (
            <EtherScanLogo fillColor={theme?.palette?.secondary?.main} />
          )}
          <span className="etherscan-text">{text}</span>
        </div>
      </Button>
    </StyledEtherScanLink>
  );
};

export default EtherScanLink;

EtherScanLink.propTypes = {
  mintTxHash: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  borderLess: PropTypes.bool,
  icon: PropTypes.element,
  link: PropTypes.oneOf(Object.values(LinkTypes)),
};
