import styled, { css } from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const TableContainer = styled.div`
  ${({ variant }) =>
    variant === 'dark' &&
    css`
      background-color: ${({ theme }) => theme.palette.secondary.main};
    `}


  .table {
    ${({ variant }) =>
      variant === 'dark' &&
      css`
        background-color: transparent;
        color: ${({ theme }) => theme.palette.text.primary};
      `}
    font-size: ${({ theme }) => theme.typography.text};
    position: relative;

    tr > td {
      vertical-align: middle;
    }
    thead > tr > th {
      vertical-align: middle;
      height: 5rem;
    }

    tbody > tr > td > .td-item {
      width: 100%;
      display: block;
    }

    @media ${deviceQuery.tabletM} {
      font-size: 1.2rem;
    }
  }

  .no-data-message {
    text-align: center;
    margin: 1rem 0;
    font-size: ${({ theme }) => theme.typography.text2};
  }
`;
