import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  data: {
    assets: [],
    users: [],
  },
};

const SearchReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case actionTypes.GET_SEARCH_DATA:
      return {
        ...state,
        data: {
          assets: actions.assets,
          users: actions.users,
        },
      };

    default:
      return state;
  }
};

export default SearchReducer;
