import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  convertEthToNumber,
  getWethioscanLink,
} from "../../common/helpers/helper";
import { getEtherscanLink } from "../../common/helpers/helper";
// import defaultConfig from "../../configuration/config";
import { useLocaleDateTime } from "../../hooks/useLocalDateTime";

import ActivityListLinkIcon from "../../assets/icons/activityListLinkIcon";
import CustomTable from "../CustomTable/CustomTable";
import store from "../../store/store";
import { useSelector } from "react-redux";
import moment from "moment";

const ActivityList = ({ listData, listingInfo = [], finaliseInfo = [] }) => {
  // const state = store.getState();
  // const defaultConfig = state.global.activeConfig;

  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const blockchain = localStorage.getItem("blockchain");
  const formatDateTime = useLocaleDateTime({
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    locale: "en-GB",
  });
  const columns = [
    {
      label: "Event",
      key: "event",
      render: function EventName(row) {
        return <span>{row?.name}</span>;
      },
    },
    {
      label: "Amount",
      key: "null",
      render: function Amount(row) {
        return (
          <span>
            {row?.amount
              ? `${convertEthToNumber(row?.amount, 18)} ${
                  blockchain === "eth" ? "ETH" : "ZYN"
                }`
              : "-"}
          </span>
        );
      },
    },
    {
      label: "From",
      key: "wallet_address",
      render: function FromAddress(row) {
        return (
          <span>
            {row?.from?.wallet_address ? row?.from?.wallet_address : row?.from}
          </span>
        );
      },
    },
    {
      label: "Time",
      key: "createdAt",
      render: function LocalTime(row) {
        return (
          <span>
            {new Date(row?.createdAt)?.toLocaleString()}
            {/* {row.createdAt
              ? formatDateTime(row.time)[3]
              : formatDateTime(row?.bidder_id?.updatedAt)[3]} */}
          </span>
        );
      },
    },
    {
      label: "",
      key: "",
      render: function Action(row) {
        return (
          <a
            target="_blank"
            href={
              defaultConfig?.chainInfo?.name === "wethio"
                ? getWethioscanLink(
                    defaultConfig?.chainInfo.name,
                    row?.transactionHash
                  )
                : getEtherscanLink(
                    defaultConfig?.chainInfo.name,
                    row?.transactionHash
                  )
            }
            rel="noreferrer"
            className="etherscan-link"
          >
            <ActivityListLinkIcon />
          </a>
        );
      },
    },
  ];

  // const reverseListData = useMemo(
  //   () =>
  //     listData?.sort(function (a, b) {
  //       return b.amount - a.amount;
  //     }),
  //   [listData]
  // );

  return (
    <CustomTable
      columns={columns}
      hidePagination
      // rows={[...finaliseInfo, ...reverseListData, ...listingInfo]}
      rows={listingInfo}
      variant="light"
    />
  );
};

ActivityList.propTypes = { listData: PropTypes.array };

export default ActivityList;
