import React from 'react';
import PropTypes from 'prop-types'
import { Container, Loader } from "./fullSizeLoaderStyle"

export const FullSizeLoader = (props) => {
    return (
          <Container>
              <Loader color={props.color} lightColor={props.lightColor} size={props.size} borderWidth={props.borderWidth} />
          </Container>
    )
}

FullSizeLoader.propTypes = {
    color: PropTypes.string,
    lightColor: PropTypes.string,
    size: PropTypes.number,
    borderWidth: PropTypes.number
}