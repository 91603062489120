import styled from "styled-components";

export const FooterC = styled.footer`
  .footer-social-media {
    margin-right: auto;
  }
`;
export const Heading = styled.div`
  margin-bottom: 25px;
  max-width: 200px;
  svg path {
    fill: linear-gradient(180deg, #ffb11f 0%, #ffc861 100%);
  }
`;
export const FooterSectionHeading = styled.h1`
  font-family: Unifont;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  color: #fff;
  margin-bottom: 25px;
`;
export const Content = styled.div`
  font-family: "Kanit", sans-serif;
  color: white;
`;
export const IconList = styled.ul`
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    margin: 0 10px;
  }
  a {
    display: inline-block;
    width: 30px;
  }
  img {
    width: 100%;
  }
`;
