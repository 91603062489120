import { useCallback, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useDetectClickOutside } from "react-detect-click-outside";
import { debounce } from "lodash";

import { getSearchData } from "../../store/actions/searchAction";
import { useDispatch, useSelector } from "react-redux";
import SearchItemRow from "../SearchItemRow/SearchItemRow";
import { useNavigate } from "react-router";
import styled from "styled-components";

export const SearchDropdown = styled.div`
width: 88%;
  position: absolute;
  top: 40px;
  left: 15px;
  padding-bottom: ${(props) =>
    props?.users?.length > 0 || props?.nfts?.length > 0 ? "10px" : "0px"};
  overflow-y: auto;
  border-radius: 10px;
  border: 1px solid #cccccc;
  max-height: 300px;
  background-color: #ffffff;
`;

const SearchBox = ({isAdminUser}) => {
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ref = useDetectClickOutside({ onTriggered: handleClose });
  const nfts = useSelector((state) => state.search.data.assets);
  const users = useSelector((state) => state.search.data.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getSearch = () => {
    if (searchValue) {
      dispatch(getSearchData(searchValue));
    }
    // A search query api call.
  };
  const delayedQuery = useCallback(debounce(getSearch, 500), [searchValue]);

  useEffect(() => {
    delayedQuery();

    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [searchValue, delayedQuery]);
  return (
    <div style={{ position: "relative",display: !isAdminUser ? "block" :"block" }}>
      <input
        type="text"
        style={{
          minWidth: "230px",
          width:"88%",
          outline: "none",
          border: "1px solid #ced4da",
          borderRadius: "12px",
          paddingLeft: "10px",
          marginLeft: "15px",
          fontSize: "14px",
          height: "40px",
        }}
        value={searchValue}
        placeholder="Search Nft's & Users"
        onChange={(e) => {
          handleShow();
          setSearchValue(e?.target?.value);
        }}
      />
      {/* <AiOutlineSearch
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translate(0,-50%)",
          color: "black",
        }}
      /> */}
      {show && searchValue && (
        <SearchDropdown users={users} nfts={nfts} ref={ref}>
          {nfts?.length === 0 && users?.length === 0 && (
            <p
              style={{
                padding: "10px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
              }}
            >
              No Items Found
            </p>
          )}
          {nfts?.length > 0 && (
            <h6 style={{ padding: "10px 0 10px 10px", color: "black" }}>
              NFTs
            </h6>
          )}
          {nfts?.length > 0 &&
            nfts?.map((singlenft, i) => {
              return (
                <SearchItemRow
                  key={i}
                  onClick={() => {
                    navigate(`/collectionDetails/${singlenft?._id}`);
                    handleClose();
                    setSearchValue("");
                  }}
                  profile_image_url={singlenft?.metadata_fields?.image_url}
                  username={singlenft?.name}
                />
              );
            })}
          {users?.length > 0 && (
            <h6
              style={{
                marginTop: "10px",
                padding: "10px 0 10px 10px",
                color: "black",
              }}
            >
              Users
            </h6>
          )}
          {users?.length > 0 &&
            users?.map((singleuser, i) => {
              return (
                <SearchItemRow
                  key={i}
                  onClick={() => {
                    navigate(`/artistDetails/${singleuser?._id}`);
                    handleClose();
                    setSearchValue("");
                  }}
                  profile_image_url={singleuser?.profile_image_url}
                  username={singleuser?.username}
                />
              );
            })}
        </SearchDropdown>
      )}
    </div>
  );
};

export default SearchBox;
