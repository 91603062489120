import React from "react";
import PropTypes from "prop-types";

import "./metamaskIcon.css";

const MetaMaskIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59.249"
      height="55"
      viewBox="0 0 59.249 55"
      {...props}
    >
      <defs>
        <style></style>
      </defs>
      <g id="MetaMask_Fox" transform="translate(-31.3 -35)">
        <path
          id="Tracé_26"
          data-name="Tracé 26"
          d="M197.328 35.5 174.6 52.381l4.2-9.959z"
          transform="translate(-110.181)"
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            fill: "#e2761b",
            stroke: "#e2761b",
          }}
        />
        <g
          id="Groupe_1571"
          data-name="Groupe 1571"
          transform="translate(32.28 35.5)"
        >
          <path
            id="Tracé_27"
            data-name="Tracé 27"
            className="cls-2"
            d="m44.4 35.5 22.546 17.041-4-10.119z"
            transform="translate(-42.002 -35.5)"
          />
          <path
            id="Tracé_28"
            data-name="Tracé 28"
            className="cls-2"
            d="m217.853 206.8-6.053 9.274 12.952 3.563 3.723-12.632z"
            transform="translate(-171.163 -167.67)"
          />
          <path
            id="Tracé_29"
            data-name="Tracé 29"
            className="cls-2"
            d="m33.9 207.006 3.7 12.632 12.952-3.563L44.5 206.8z"
            transform="translate(-33.9 -167.67)"
          />
          <path
            id="Tracé_30"
            data-name="Tracé 30"
            className="cls-2"
            d="m91.409 111.889-3.609 5.46 12.86.571-.46-13.82z"
            transform="translate(-75.488 -88.43)"
          />
          <path
            id="Tracé_31"
            data-name="Tracé 31"
            className="cls-2"
            d="M183.806 111.349 174.9 103.4l-.3 13.98 12.838-.571z"
            transform="translate(-142.46 -87.89)"
          />
          <path
            id="Tracé_32"
            data-name="Tracé 32"
            className="cls-2"
            d="m106.8 217.077 7.721-3.769-6.67-5.208z"
            transform="translate(-90.148 -168.674)"
          />
          <path
            id="Tracé_33"
            data-name="Tracé 33"
            className="cls-2"
            d="m177.9 213.308 7.744 3.769-1.074-8.977z"
            transform="translate(-145.007 -168.674)"
          />
        </g>
        <g
          id="Groupe_1572"
          data-name="Groupe 1572"
          transform="translate(48.932 80.135)"
        >
          <path
            id="Tracé_34"
            data-name="Tracé 34"
            className="cls-3"
            d="M185.644 234.669 177.9 230.9l.617 5.048-.069 2.124z"
            transform="translate(-161.659 -230.9)"
          />
          <path
            id="Tracé_35"
            data-name="Tracé 35"
            className="cls-3"
            d="m106.8 234.669 7.2 3.4-.046-2.124.571-5.048z"
            transform="translate(-106.8 -230.9)"
          />
        </g>
        <path
          id="Tracé_36"
          data-name="Tracé 36"
          className="cls-4"
          d="m117.042 180.075-6.442-1.9 4.546-2.079z"
          transform="translate(-60.8 -108.483)"
        />
        <path
          id="Tracé_37"
          data-name="Tracé 37"
          className="cls-4"
          d="m179.7 180.075 1.9-3.975 4.569 2.079z"
          transform="translate(-114.116 -108.483)"
        />
        <g
          id="Groupe_1573"
          data-name="Groupe 1573"
          transform="translate(42.879 64.419)"
        >
          <path
            id="Tracé_38"
            data-name="Tracé 38"
            className="cls-5"
            d="m86.353 216.074 1.1-9.274-7.15.206z"
            transform="translate(-80.3 -196.589)"
          />
          <path
            id="Tracé_39"
            data-name="Tracé 39"
            className="cls-5"
            d="m207 206.8 1.1 9.274 6.053-9.069z"
            transform="translate(-178.058 -196.589)"
          />
          <path
            id="Tracé_40"
            data-name="Tracé 40"
            className="cls-5"
            d="m187.438 162.1-12.838.571 1.188 6.6 1.9-3.975 4.569 2.079z"
            transform="translate(-153.059 -162.1)"
          />
          <path
            id="Tracé_41"
            data-name="Tracé 41"
            className="cls-5"
            d="m93.008 167.377 4.569-2.079 1.873 3.975 1.211-6.6L87.8 162.1z"
            transform="translate(-86.087 -162.1)"
          />
        </g>
        <g
          id="Groupe_1574"
          data-name="Groupe 1574"
          transform="translate(44.592 64.419)"
        >
          <path
            id="Tracé_42"
            data-name="Tracé 42"
            className="cls-6"
            d="m87.8 162.1 5.391 10.508-.183-5.231z"
            transform="translate(-87.8 -162.1)"
          />
          <path
            id="Tracé_43"
            data-name="Tracé 43"
            className="cls-6"
            d="m207.328 167.377-.228 5.231 5.414-10.508z"
            transform="translate(-179.849 -162.1)"
          />
          <path
            id="Tracé_44"
            data-name="Tracé 44"
            className="cls-6"
            d="m140.011 164.6-1.211 6.6 1.508 7.789.343-10.256z"
            transform="translate(-127.15 -164.029)"
          />
          <path
            id="Tracé_45"
            data-name="Tracé 45"
            className="cls-6"
            d="m172.517 164.6-.617 4.112.274 10.279 1.53-7.789z"
            transform="translate(-152.689 -164.029)"
          />
        </g>
        <path
          id="Tracé_46"
          data-name="Tracé 46"
          className="cls-7"
          d="m174.63 187.1-1.53 7.789 1.1.754 6.67-5.208.228-5.231z"
          transform="translate(-109.023 -115.505)"
        />
        <path
          id="Tracé_47"
          data-name="Tracé 47"
          className="cls-7"
          d="m110.6 185.2.183 5.231 6.67 5.208 1.1-.754-1.508-7.789z"
          transform="translate(-60.8 -115.505)"
        />
        <path
          id="Tracé_48"
          data-name="Tracé 48"
          d="m123.589 250.8.069-2.124-.571-.5h-8.612l-.525.5.05 2.124-7.2-3.4 2.513 2.056 5.094 3.544h8.749l5.117-3.541 2.513-2.056z"
          transform="translate(-57.868 -163.496)"
          style={{
            fill: "#c0ad9e",
            stroke: "#c0ad9e",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <path
          id="Tracé_49"
          data-name="Tracé 49"
          d="m147.191 228.354-1.1-.754h-6.327l-1.1.754-.564 5.046.525-.5h8.612l.571.5z"
          transform="translate(-82.018 -148.219)"
          style={{
            fill: "#161616",
            stroke: "#161616",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
        <g
          id="Groupe_1575"
          data-name="Groupe 1575"
          transform="translate(31.8 35.5)"
        >
          <path
            id="Tracé_50"
            data-name="Tracé 50"
            className="cls-10"
            d="m200.834 53.477 1.942-9.32-2.9-8.657L177.9 51.81l8.452 7.15 11.948 3.494 2.65-3.084-1.142-.822 1.827-1.668-1.416-1.1 1.827-1.393z"
            transform="translate(-144.527 -35.5)"
          />
          <path
            id="Tracé_51"
            data-name="Tracé 51"
            className="cls-10"
            d="m31.8 44.157 1.942 9.32-1.234.914 1.827 1.393-1.393 1.1 1.827 1.668-1.142.822 2.627 3.084L48.2 58.959l8.452-7.15L34.678 35.5z"
            transform="translate(-31.8 -35.5)"
          />
        </g>
        <path
          id="Tracé_52"
          data-name="Tracé 52"
          className="cls-7"
          d="m220.828 141.695-11.946-3.495 3.632 5.459-5.414 10.508 7.127-.091h10.622z"
          transform="translate(-135.257 -79.241)"
        />
        <path
          id="Tracé_53"
          data-name="Tracé 53"
          className="cls-7"
          d="m49.821 138.2-11.947 3.495-3.974 12.381h10.6l7.1.091-5.391-10.508z"
          transform="translate(-1.62 -79.241)"
        />
        <path
          id="Tracé_54"
          data-name="Tracé 54"
          className="cls-7"
          d="m136.793 88.369.754-13.18 3.472-9.388H125.6l3.426 9.388.8 13.18.274 4.157.023 10.234h6.327l.046-10.234z"
          transform="translate(-72.374 -23.379)"
        />
      </g>
    </svg>
  );
};

MetaMaskIcon.propTypes = {};

export default MetaMaskIcon;
