import * as actionTypes from "../../constants/actionTypes";
import {
  getAccount,
  getBalance,
  getChainId,
  hexToNumber,
} from "../../common/common";
// import defaultConfig from '../../configuration/config';

export const setLoading = (loading) => {
  return {
    type: actionTypes.SET_LOADING,
    payload: {
      loading,
    },
  };
};

export const setHasMetamask = (hasMetamask) => ({
  type: actionTypes.SET_HAS_METAMASK,
  payload: { hasMetamask },
});

export const setAccountDetails = (account, signature, balance) => ({
  type: actionTypes.SET_ACCOUNT_DETAILS,
  payload: {
    account,
    signature,
    balance,
  },
});

export const setError = (
  error,
  { errorTitle, errorMsg, errorBtnLabel, errorCallback = null }
) => ({
  type: actionTypes.SET_ERROR,
  payload: {
    error,
    errorMsg,
    errorTitle,
    errorBtnLabel,
    errorCallback,
  },
});

export const setChainId = (currentChainId) => ({
  type: actionTypes.SET_CHAIN_ID,
  payload: { currentChainId },
});

export const setEtherCurPrice = (etherCurPrice) => ({
  type: actionTypes.SET_ETHER_CUR_AMOUNT,
  payload: { etherCurPrice },
});

export const updateAccountDetailsOnChainChange = (chainId) => {
  return async (dispatch, getState) => {
    const isLogin = getState().login.isLogin;
    const web3Instance = getState().global.web3Instance;
    dispatch(setChainId(hexToNumber(web3Instance, chainId)));
    if (!isLogin) return;
    try {
      const acc = await getAccount(web3Instance);
      const balances = await getBalance(web3Instance, acc);
      const sign = getState().login.signature;
      // dispatch(setAccountDetails(acc, sign, balances?.eth));
      dispatch(setAccountDetails(acc, sign, balances));
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateChainId = () => {
  return async (dispatch, getState) => {
    const web3Instance = getState().global.web3Instance;
    try {
      const chainId = await getChainId(web3Instance);
      dispatch(setChainId(chainId));
    } catch (error) {
      console.error(error);
    }
  };
};

export const setWeb3Instance = (web3Instance) => ({
  type: actionTypes.SET_WEB3_INSTANCE,
  payload: { web3Instance },
});

export const updateProvider = (provider) => ({
  type: actionTypes.UPDATE_PROVIDER,
  payload: { provider },
});
export const showWalletModal = () => ({
  type: actionTypes.SHOW_WALLET_MODAL,
  payload: {},
});

export const hideWalletModal = () => ({
  type: actionTypes.HIDE_WALLET_MODAL,
  payload: {},
});

export const toggleWalletModal = () => ({
  type: actionTypes.TOGGLE_WALLET_MODAL,
  payload: {},
});

export const setWalletType = (walletType) => ({
  type: actionTypes.SET_WALLET_TYPE,
  payload: { walletType },
});

export const setIpfsInstance = (ipfsInstance) => ({
  type: actionTypes.SET_IPFS_INSTANCE,
  payload: { ipfsInstance },
});
