import React from 'react';
import PropTypes from 'prop-types';

import Countdown from 'react-countdown';
import { Container } from 'react-bootstrap';
import { StyledTimer } from './style';
import { useLocaleDateTime } from '../../hooks/useLocalDateTime';

const RenderedElement = ({
  days,
  hours,
  minutes,
  seconds,
  vertical,
  className,
  unitLabels,
}) => {
  const parseNumber = (value) => {
    const v = Number.parseInt(value);
    if (v < 10) return '0' + value;
    else return value;
  };



  return (
    <Container
      fluid
      as={StyledTimer}
      $vertical={vertical}
      className={className}
    >
      <div className="remaing-time">
        {!!days && (
          <>
            <div className="day">
              <p className="title4 value">{parseNumber(days)}</p>
              <p className="text2 unit">{unitLabels?.days || 'Days'}</p>
            </div>
            <div className="colon">
              <p className="title4 value">:</p>
            </div>
          </>
        )}

        <div className="hour">
          <p className="title4 value">{parseNumber(hours)}</p>
          <p className="text2 unit">{unitLabels?.hours || 'Hours'}</p>
        </div>
        <div className="colon">
          <p className="title4 value">:</p>
        </div>
        <div className="min">
          <p className="title4 value">{parseNumber(minutes)}</p>
          <p className="text2 unit">{unitLabels?.minutes || 'minutes'}</p>
        </div>
        {!days && (
          <>
            <div className="colon">
              <p className="title4 value">:</p>
            </div>
            <div className="sec">
              <p className="title4 value">{parseNumber(seconds)}</p>
              <p className="text2 unit">{unitLabels?.seconds || 'seconds'}</p>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

RenderedElement.propTypes = {
  days: PropTypes.any,
  hours: PropTypes.any,
  seconds: PropTypes.any,
  minutes: PropTypes.any,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  unitLabels: PropTypes.object,
};

const Timer = ({
  timeStamp,
  unitLabels,
  onComplete,
  skipConversion = false,
  vertical = false,
  className = '',
}) => {



  const formatDate = useLocaleDateTime({
    date: new Date(timeStamp) ?? '28 oct 2021, 00:00:00 PST',
  });
  const [targetDate, localTimeString] = formatDate();

   

  return (
    <Countdown
      date={true ? timeStamp : `${targetDate} ${localTimeString}`}
      // date={timeStamp}
      onComplete={onComplete}
      renderer={(props) => (
        <RenderedElement
          {...props}
          vertical={vertical}
          className={className}
          unitLabels={unitLabels}
        />
      )}
    />
  );
};

Timer.propTypes = {
  timeStamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  vertical: PropTypes.bool,
  className: PropTypes.string,
  unitLabels: PropTypes.object,
  onComplete: PropTypes.func,
  skipConversion: PropTypes.bool,
};

export default React.memo(Timer);
