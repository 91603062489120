import React, { useState, useEffect, useRef } from "react";
import {
  CreateCollectionStyled,
  CommonButton,
  SubText,
  HeadingText,
  Heading,
  Text,
  CustomCard,
} from "../ApproveAssets/style";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { apiHandler } from "../../services/axios";
import { getAllCollection } from "../../services/collectionServices";
import AssetCard from "../../Components/AssetCardApprove";
import { getAllAssets } from "../../services/assetServices";
import MainTopSection from "../../Components/MainTopSection";
import { getAllAuctions } from "../../services/auctionServices";

function ApproveAssets() {
  const [allCollection, setAllCollection] = useState();

  const getCollectionDetails = async () => {
    apiHandler(() => getAllAuctions(1, 100), {
      onSuccess: (data) => {
        setAllCollection(data.results);
      },
      onError: (error) => console.error(error),
    });
  };

  useEffect(() => {
    getCollectionDetails();
  }, []);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="Auctions Pending For Approval"
        descriptionText={""}
        routePath="/"
      />
      <CreateCollectionStyled>
        {allCollection && allCollection.length > 0 ? (
          <AssetCard allCollection={allCollection} status={"PENDING"} />
        ) : (
          <div className="text-center mt-3 mb-3">
            <Text>
              <HeadingText>No items found</HeadingText>
            </Text>
          </div>
        )}
      </CreateCollectionStyled>
    </>
  );
}

export default ApproveAssets;
