import Axios from "axios";
import { getLocalStorageItem } from "../common/helpers/localStorage";
import defaultConfig from "../configuration/config";

// import store from "../store/store";

const CancelToken = Axios.CancelToken;

export default function getAxiosInst({ withAuth }) {
  const token = getLocalStorageItem("token");
  if (withAuth) {
    const axiosInst = Axios.create({
      baseURL: defaultConfig?.apiBaseUrl,
      headers: { Authorization: `Bearer ${token}` },
    });
    return axiosInst;
  } else {
    return Axios.create({
      baseURL: defaultConfig?.apiBaseUrl,
    });
  }
}

export const apiHandler = async (
  apiCall,
  { onSuccess, onError, final } = {},
  options = { sync: false }
) => {
  let response;
  try {
    const source = CancelToken.source();
    response = await apiCall(source);

    const data = response?.data;

    if (response?.status === 200) {
      if (onSuccess && options.sync) onSuccess(data);
      else if (onSuccess) await onSuccess(data);
    } else {
      const error = new Error("Some Error");
      error.code = response?.status;
      throw error;
    }
  } catch (error) {
    if (Axios.isCancel(error)) {
      console.error("Request canceled", error.message);
    } else {
      response = error.response;
      onError && onError(error, response);
    }
  } finally {
    final && final();
    // eslint-disable-next-line no-unsafe-finally
    return response;
  }
};
