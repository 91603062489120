import React, { useCallback, useEffect, useState } from "react";

import { useLocation, Routes, Route } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import MintingStepOne from "../../assets/icons/mintingStepOne";
import MintingStepTwo from "../../assets/icons/mintingStepTwo";
import { Row, Col } from "react-bootstrap";
import CollectionStepOne from "./CollectionStepOne";
import CollectionStepTwo from "./CollectionStepTwo";
import CollectionStepThree from "./CollectionStepThree";
import { VerticalLine, RouteStyled } from "./style";

function CollectionRoute() {
  const [step, setSteps] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const [assetData, setAssetData] = useState(null);
  const [metadataUrl, setMetadataUrl] = useState("");

  const query = useQuery();

  const { pathname } = useLocation();
  const toggleIsMinting = useCallback(() => setIsMinting((prev) => !prev), []);

  const incrementStep = () => {
    setSteps((prev) => prev + 1);
  };

  const onAssetCreate = (data) => {
    setIsMinting(true);
    setAssetData(data);
    incrementStep();
  };
  useEffect(() => {
    if (pathname.includes("/mint")) {
      setSteps(2);
    } else if (pathname.includes("/collectionStepfinal")) {
      setSteps(3);
    } else {
      setSteps(1);
    }
  }, [pathname]);

  return (
    <RouteStyled>
      <Row>
        <Col md={2} sm={12} xs={12} className="MobileView">
          <div className="Horizantalline">
            <MintingStepOne />
            <VerticalLine />
          </div>
        
          <div className="Horizantalline">
            {step < 2 ? (
              <MintingStepTwo />
            ) : step === 2 ? (
              <MintingStepOne />
            ) : (
              <MintingStepOne />
            )}
            <VerticalLine />
          </div>
          <div>
            {step < 3 ? (
              <MintingStepTwo />
            ) : step === 3 ? (
              <MintingStepOne />
            ) : (
              <MintingStepOne />
            )}
          </div>
        </Col>
        <Col md={10} sm={12} xs={12}>
          <Routes>
            <Route
              path="/"
              element={
                <CollectionStepOne
                  onAssetCreateSuccess={onAssetCreate}
                  type={query?.get("type")}
                  setMetadataUrl={setMetadataUrl}
                />
              }
            />
            <Route
              path="/mint/:assetId"
              element={
                <CollectionStepTwo
                  isMinting={isMinting}
                  assetData={assetData}
                  toggleIsMinting={toggleIsMinting}
                  metadataUrl={metadataUrl}
                  setAssetData={setAssetData}
                />
              }
            />
            <Route
              path="/collectionStepfinal/:assetId"
              element={
                <CollectionStepThree
                  assetData={assetData}
                  metadataUrl={metadataUrl}
                  setAssetData={setAssetData}
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </RouteStyled>
  );
}

export default CollectionRoute;
