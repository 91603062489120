import styled from "styled-components"
import { flexCenter, rotate } from "../../styles/mixins";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${flexCenter};
`;
export const Loader = styled.div`
  width: ${p => p.size || 30}px;
  height: ${p => p.size || 30}px;
  border-radius: 50%;
  border: ${p => p.borderWidth || 5}px solid ${p => p.color || "black"};
  border-top-color: ${p => p.lightColor || "transparent"};
  animation: ${rotate} 0.5s infinite;
`;