import React from "react";

import animationData from "../../assets/lottie/lottieRequestLoadingAnimation.json";

import Modal from "../Modal/Modal";
import { ModalBody } from "react-bootstrap";
import { Player } from "@lottiefiles/react-lottie-player";

import PropTypes from "prop-types";
import { StyledTitle, AnimationWrapper } from "./style";

const LoadingModal = ({
  show,
  onHide,
  title,
  body,
  block,
  walletType,
  requestStatus,
}) => {
  return (
    <Modal show={show} onHide={block ? () => {} : onHide} centered>
      <ModalBody>
        <StyledTitle className="title1">{title}</StyledTitle>
        <p className="text1 my-5">{body}</p>
        <AnimationWrapper>
          <Player
            src={animationData}
            loop
            autoplay
            style={{ height: "10rem", width: "10rem" }}
          />
        </AnimationWrapper>
      </ModalBody>
    </Modal>
  );
};

LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  block: PropTypes.bool,
  walletType: PropTypes.string,
  requestStatus: PropTypes.string,
};

export default React.memo(LoadingModal);
