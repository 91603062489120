import { Card } from "react-bootstrap";
import styled from "styled-components";
import { AUCTION_STATUS } from "../../constants/appConstants";
import { deviceQuery } from "../../styles/mediaSizes";

export const ApproveButton = styled.button`
  padding: 15px 45px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 20px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const CommonButton = styled.button`
  width: 100%;
  border-radius: 14px;
  z-index: 1000;
  padding: 10px 20px;
  bottom: 25px;
  left: 2rem;
  background-color: ${(props) => props.theme.palette.newTheme.darkYellow};
  margin: 0px 15px 8px 15px;
  border: none;
  color: #fff;
  font-weight: 500;
  &:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    border: none;
  }
`;
export const StyledCard = styled.div`
  max-width: 100%;
  margin: auto;
  margin-bottom: 5rem;

  .card {
    min-height: 420px;
    cursor: pointer;
    margin-top: 22px;
    border-radius: 32px;
    border: none;
  }
  .card-title {
    color: ${(props) => props.theme.palette.text.primary} !important;
    min-height: 74px;
  }
  border-radius: 2.4rem;
  .card {
    width: 100%;
    text-align: left;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.text.pink};

    img {
      padding: 0px 2px;
      height: 270px;
      border: none;
      border-radius: 32px;
      width: 100%;
    }

    .body {
      padding: 2rem 1.7rem;
      .card-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 112.1%;
        margin-bottom: 1.5rem;
      }
      .protagonist-wrap {
        .avatar {
          width: 4rem;
          height: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
          .protagonist-name {
            font-size: 1.6rem;
          }
        }
      }
    }

    .footer {
      color: ${(props) => props.theme.palette.text.light};
      background-color: ${(props) => props.theme.palette.secondary.light};
      padding: 2rem 1.7rem;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 162.1%;
        margin-bottom: 0.5rem;
      }

      .remaining-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 162.1%;
      }

      .value,
      .bid-amount {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
`;

export const CardSection = styled(Card)`
  border-radius: 16px;
  cursor: pointer;
  height: 90%;
  display: block;
  margin-top: 60px;
  text-align: left;
  color: ${(props) => props.theme.palette.text.primary};
  background: ${(props) => props.theme.palette.text.light};
  border: 2px solid ${(props) => props.theme.palette.newTheme.darkYellow};
  .asset-container {
    padding-top: 99% !important;
  }
`;

export const CardMoreDetails = styled.div`
  h4 {
    color: ${(props) => props.theme.palette.newTheme.cardGray} !important;
    font-size: 16px;
    font-weight: 500;
  }
  p {
    color: ${(props) => props.theme.palette.newTheme.darkYellow}; !important;
    font-size: 16px;
    font-weight: 500;
  }
`;
export const CountDownTimerStyled = styled.div`
  h4 {
    color: ${(props) => props.theme.palette.newTheme.cardGray};
    font-size: 16px;
    font-weight: 500;
  }
`;
export const ToolTipValues = styled.div`
  position: absolute;
  // background: ${(props) => props.theme.palette.newTheme.cardToasterDarkGray};
  border-radius: 8px;
  right: 40px;
  top: 145px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  @media screen and (min-device-width: 767px) and (max-device-width: 991px) {
    position: initial;
    margin: 24px;
  }
  @media ${deviceQuery.tabletM} {
    position: initial;
    margin: 24px;
  }
  p {
    color: ${(props) => props.theme.palette.text.white};
  }
  h4 {
    font-size: 16px;
    font-weight: 800;
    color: ${(props) => props.theme.palette.text.white};
  }
`;
export const Footer = styled.div`
  @media ${deviceQuery.tabletM} {
    display: inline-block !important;
  }

  @media ${deviceQuery.mobileSM} {
    display: flex !important;
  }
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 20px 20px 20px;
  background-color: ${({ status, approvalStatus, ...p }) =>
    p.theme.palette[
      status === AUCTION_STATUS.created && approvalStatus === "PENDING"
        ? "newTheme"
        : "white"
    ].main};

  h4,
  p {
    font-family: "Kanit", sans-serif;
  }

  h4 {
    color: white;
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fw.medium};
  }

  p {
    font-size: 1.1rem;
    font-weight: ${(p) => p.theme.fw.bold};
    color: ${(p) => p.theme.palette.common.yellow};
  }
  .remaing-time {
    .hour,
    .min,
    .sec,
    .colon,
    .day {
      width: 30px !important;
      .value {
        font-size: ${(props) => props.theme.typography.subText4} !important;
      }
    }
    .colon {
      display: none !important;
    }
  }
`;
export const TimingDiv = styled.div``;

export const CardTitle = styled(Card.Title)`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const ProfileUser = styled.p`
  margin-left: 15px;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const ProfileText = styled.h4`
  margin-left: 15px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.newTheme.black};
`;
export const CustomImage = styled.div`
  margin: 24px;
  background-color: ${(props) => props.theme.palette.newTheme.cardGray};
  box-shadow: 0 16px 16px 0 rgb(0 0 0 / 8%);
  border-radius: 12px;
  margin-top: -40px;
  // height: 300px;
`;
export const ImageLayout = styled.div`
  width: 48px;
  align-items: center;
  height: 48px;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.newTheme.darkYellow};
  img {
    width: 21px;
    height: 21px;
  }
`;
