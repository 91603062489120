import React from 'react';

// const style={fill:'#41416e'}
const tickIcon = ({ fillColor = '#41416e' }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="126" height="126" viewBox="0 0 126 126">
    <path d="M489.374 398.889a3.318 3.318 0 0 1 0 4.689l-33.158 33.158a3.33 3.33 0 0 1-4.7 0l-19.895-19.895a3.315 3.315 0 0 1 4.688-4.688l17.553 17.547 30.81-30.81a3.334 3.334 0 0 1 4.702-.001zM523.5 414.5a63 63 0 1 1-63-63 63.074 63.074 0 0 1 63 63zm-6.632 0a56.369 56.369 0 1 0-56.368 56.368 56.431 56.431 0 0 0 56.368-56.368z" transform="translate(-397.5 -351.5)" style={{ fill: fillColor }}/>
</svg>
  );
};

export default tickIcon;
