// for global reducer

export const SET_LOADING = "SET_LOADING";
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const SET_HAS_METAMASK = "SET_HAS_METAMASK";
export const SET_ERROR = "SET_ERROR";
export const SET_CHAIN_ID = "SET_CHAIN_ID";
export const SET_ETHER_CUR_AMOUNT = "SET_ETHER_CUR_AMOUNT";
export const SET_WEB3_INSTANCE = "SET_WEB3_INSTANCE";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const SHOW_WALLET_MODAL = "SHOW_WALLET_MODAL";
export const HIDE_WALLET_MODAL = "HIDE_WALLET_MODAL";
export const TOGGLE_WALLET_MODAL = "TOGGLE_WALLET_MODAL";
export const SET_WALLET_TYPE = "SET_WALLET_TYPE";
export const SET_IPFS_INSTANCE = "SET_IPFS_INSTANCE";
export const ADD_ASSETS_DETAILS="ADD_ASSETS_DETAILS";
export const DELETE_ASSETS_DETAILS="DELETE_ASSETS_DETAILS";

// for login reducer

export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const UPDATE_MSG = "UPDATE_MSG";
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";

// for user reducer

export const SET_USER = "SET_USER";
export const UPDATE_PROFILE_IMG = "UPDATE_PROFILE_IMG";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_IMG_LOADING = "SET_IMG_LOADING";

// for user reducer

export const ADD_METADATA_URL = "ADD_METADATA_URL";
export const ADD_TOKEN_ID = "ADD_TOKEN_ID";
export const DELETE_TOKEN_ID = "DELETE_TOKEN_ID";
// for contract reducer
export const ADD_COLLECTION_ID = "ADD_COLLECTION_ID";
export const DELETE_COLLECTION_ID = "DELETE_COLLECTION_ID";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const DELETE_COLLECTION= "DELETE_COLLECTION";
export const SET_CONTRACT = "SET_CONTRACT";

export const GET_SEARCH_DATA = "GET_SEARCH_DATA";

export const SET_ACTIVE_CONFIG = "SET_ACTIVE_CONFIG";

export const UPDATE_BLOCKCHAIN = "UPDATE_BLOCKCHAIN";
export const SET_CREATOR = "SET_CREATOR";
export const SET_BLOCKED = "SET_BLOCKED";
