import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin: 0;
        padding: 0;
    }
    // p{
    //     font-family: 'Kanitlight';
    // }
    h1, h2, h3{
        font-family: 'UniKanit';
    }
    .h5,h4{
        font-family: 'Kanit';  
    }
    h1,.h5::first-letter {
        text-transform:capitalize;
    }
`;

export default GlobalStyle;