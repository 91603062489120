import styled from "styled-components";

import { AUCTION_STATUS } from "../../constants/appConstants";

import { Card } from "react-bootstrap";
import { deviceQuery } from "../../styles/mediaSizes";

export const CardSection = styled(Card)`
  border-radius: 16px;
  cursor: pointer;
  height: 100%;
  text-align: left;
  color: ${(props) => props.theme.palette.text.primary};
  background: ${(props) => props.theme.palette.text.light};

  border: 2px solid ${(props) => props.theme.palette.newTheme.darkYellow};
  .asset-container {
    padding-top: 99% !important;
  }
`;

export const CardMoreDetails = styled.div`
  h4 {
    color: ${(props) => props.theme.palette.newTheme.cardGray} !important;
    font-size: 16px;
    font-weight: 500;
  }
  p {
    color: ${(props) => props.theme.palette.newTheme.darkYellow} !important;
    font-size: 16px;
    font-weight: 500;
  }
`;
export const CountDownTimerStyled = styled.div`
  h4 {
    color: ${(props) => props.theme.palette.newTheme.cardGray};
    font-size: 16px;
    font-weight: 500;
  }
`;
export const ToolTipValues = styled.div`
  position: absolute;
  // background: ${(props) => props.theme.palette.newTheme.cardToasterDarkGray};
  border-radius: 8px;
  right: 40px;
  top: 185px;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  p {
    color: ${(props) => props.theme.palette.text.white};
  }
  h4 {
    font-size: 16px;
    font-weight: 800;
    color: ${(props) => props.theme.palette.text.white};
  }
  @media screen and (min-device-width: 767px) and (max-device-width: 991px) {
    position: initial;
    margin: 24px;
  }

  @media ${deviceQuery.tabletM} {
    position: initial;
    margin: 24px;
  }
`;
export const Footer = styled.div`
  @media ${deviceQuery.tabletM} {
    display: inline-block !important;
  }

  @media ${deviceQuery.mobileSM} {
    display: flex !important;
  }
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 20px 20px 20px;
  background-color: ${({ status, approvalStatus, ...p }) =>
    p.theme.palette[
      status === AUCTION_STATUS.created && approvalStatus === "PENDING"
        ? "newTheme"
        : "white"
    ].main};

  h4,
  p {
    font-family: "Kanit", sans-serif;
  }

  h4 {
    color: white;
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fw.medium};
  }

  p {
    font-size: 1.1rem;
    font-weight: ${(p) => p.theme.fw.bold};
    color: ${(p) => p.theme.palette.common.yellow};
  }
  .remaing-time {
    .hour,
    .min,
    .sec,
    .colon,
    .day {
      width: 30px !important;
      .value {
        font-size: ${(props) => props.theme.typography.subText4} !important;
      }
    }
    .colon {
      display: none !important;
    }
  }
`;

export const CardFooterSectionLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
    display: inline-block !important;
  }
`;
export const TimingDiv = styled.div``;

export const CardTitle = styled(Card.Title)`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const ProfileUser = styled.p`
  margin-left: 15px;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const ProfileText = styled.h4`
  margin-left: 15px;
  font-size: 16px;
  color: ${(props) => props.theme.palette.newTheme.black};
`;
export const CustomImage = styled.div`
  margin: 24px;
  background-color: ${(props) => props.theme.palette.newTheme.cardGray};
  box-shadow: 0 16px 16px 0 rgb(0 0 0 / 8%);
  border-radius: 12px;
  margin-top: -40px;
  // height: 300px;
  .asset-wrap:hover {
    filter: brightness(0.5);
  }
`;
export const AniMationStyled = styled.div`
  // width: 100%;
  height: 100%;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 10px rgb(255 255 255);
  animation-name: spin;
  animation-duration: 6s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 50%;
  @keyframes spin {
    from {
      opacity: 1;
    }
    to {
      opacity: 2;
      transform: scale3d(1.5, 1.5, 1.5);
    }
  }
  .solidDot {
    width: 8px;
    height: 8px;
    background: white;
    box-shadow: 0px 0px 10px rgb(255 255 255);
    border-radius: 50%;
  }
`;
export const ImageLayout = styled.div`
  width: 48px;
  align-items: center;
  height: 48px;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.newTheme.darkYellow};
  img {
    width: 21px;
    height: 21px;
  }
`;

export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  p {
    font-size: 28px;
    letter-spacing: 1.5px;
    display: inline-block;
    padding: 5px;
    box-shadow: 1px 1px 42px 2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 1px 42px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 42px 2px rgba(0, 0, 0, 0.75);
    font-weight: 800;
    color: black;
  }
`;
