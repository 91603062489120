import React, { useState, useEffect } from "react";
import {
  CreateCollectionDiv,
  Heading,
  CardProfile,
  SubText,
  ListButton,
  CardTitle,

} from "./style";

import ProtagonistChip from "../../Components/Protagonist";
import { apiHandler } from "../../services/axios";
import {
  getOneAsset,
  updateAssetWithTxData,
} from "../../services/assetServices";

import {

  useParams,
  useNavigate,
} from "react-router-dom";

import { Card, Col, Row, Spinner } from "react-bootstrap";
import AssetComponent from "../../Components/AssetComponent";

function ItemListingStepThree() {

  const [isSubmiting, setIsSubmiting] = useState({ editUser: false });
  const [collectionDetail, setCollectionDetails] = useState();
  const [assetData, setAssetData] = useState(null);
  const { assetId } = useParams();


  const navigate = useNavigate();

  const onMintClickHandler = () => {
    navigate("/marketPlacePage");
  };

  const getCollectionDetails = async () => {
    apiHandler(() => getOneAsset(assetId), {
      onSuccess: (data) => {
        setCollectionDetails(data);
      },
      onError: (error) => console.error(error),
      final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
    });
  };

  useEffect(() => {
    getCollectionDetails();
  }, []);

  useEffect(() => {
    if (!assetData && assetId) {
      apiHandler(() => getOneAsset(assetId), {
        onSuccess: (data) => {
          setAssetData(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [assetData, assetId]);

  return (
    <CreateCollectionDiv>
      <Row>
        <Col md={5}>
          <CardProfile>
            {assetData ? (
              <AssetComponent
                assetType="image"
                freeSize
                src={assetData?.metadata_fields?.image_url}
              />
            ) : (
              <Spinner animation="border" />
            )}
            <Card.Body>
              <CardTitle>{assetData?.name}</CardTitle>
              <ProtagonistChip
                id={assetData?.creator_id}
                type="normal"
                className="protagonist-wrap"
              />
            </Card.Body>
          </CardProfile>
        </Col>
        <Col md={5}>
          <Heading>Your NFT is being listed</Heading>
          <SubText>
            Your NFT has been submitted to our marketplace and will be live as
            soon as the transaction is processed. You are free to leave the page
            if you like.
          </SubText>
          {assetData?.auctions?.[0]?.approvalStatus === "PENDING" ? (
            <ListButton variant="primary" type="submit">
              Pending for approval
            </ListButton>
          ) : (
            <ListButton
              variant="primary"
              type="submit"
              onClick={onMintClickHandler}
            >
              Go For Bidding
            </ListButton>
          )}
        </Col>
      </Row>
    </CreateCollectionDiv>
  );
}

export default ItemListingStepThree;
