import styled from "styled-components";
import { deviceQuery } from "../../styles/mediaSizes";

export const ApproveButton = styled.button`
  padding: 15px 45px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 20px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const ImageCard = styled.img`
  border-radius: 12px;
  height: 220px;
  object-fit: cover;
  @media ${deviceQuery.tabletM} {
    height: 100%;
  }
  @media ${deviceQuery.mobileSM} {
    height: 100%;
  }
`;
export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
  width: 100%;
`;
export const CommonButton = styled.button`
  padding: 0px 12px;
  height: 40px;
  margin-left: 6px;
  margin-top: -4px;
  border-radius: 42px;
  align-items: center;
  background-color: #fff;
  border: 2px solid ${(props) => props.theme.palette.newTheme.darkYellow};
  color: ${(props) => props.theme.palette.newTheme.darkYellow};
`;
export const StyledCard = styled.div`
  max-width: 100%;
  margin: auto;
  /* margin-bottom: 5rem; */
  .card {
    cursor: pointer;
    margin-top: 22px;
    border-radius: 16px;
    border: none;
  }

  .card-title {
    color: ${(props) => props.theme.palette.text.primary} !important;
    min-height: 74px;
  }
  border-radius: 2.4rem;
  .card {
    min-height: 340px;
    width: 100%;
    text-align: left;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.text.white};

    .body {
      .card-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 112.1%;
      }
      .protagonist-wrap {
        .avatar {
          width: 4rem;
          height: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
          .protagonist-name {
            font-size: 1.6rem;
          }
        }
      }
    }

    .footer {
      color: ${(props) => props.theme.palette.text.light};
      background-color: ${(props) => props.theme.palette.secondary.light};
      padding: 2rem 1.7rem;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 162.1%;
        margin-bottom: 0.5rem;
      }

      .remaining-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 162.1%;
      }

      .value,
      .bid-amount {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
`;
export const ImageSection = styled.div`
  img {
    width: 56px;
    height: 58px;
    padding: 8px;
    margin: 10px;
  }
  border-radius: 22px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  justify-content: center;
  display: flex;

  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
`;
export const AvtarImg = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  text-align: center;
  svg {
    fill: white;
    height: 60px;
    width: 30px;
  }
`;
export const CarouselCaption = styled.div`
  position: relative;
  left: 0px;
  align-items: center;
  .verifiedIcon {
    position: absolute;
    bottom: -2px;
    left: -14px;
    width: 16px;
    height: 16px;
  }
  h3 {
    font-size: 16px;
    color: ${(props) => props.theme.palette.newTheme.textColor};
    margin: 8px;
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    width: 100%;
    margin-left: 8px;
    color: ${(props) => props.theme.palette.newTheme.cardGray};
  }
`;
