import styled from "styled-components";

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px 25px;
  margin-top: 25px;
  margin-bottom: 30px;
  .card {
  
    background-color: ${(props) => props.theme.palette.text.light};

    border: 2px solid ${(props) => props.theme.palette.newTheme.blueIcon};
  }
`;
export const LoadMoreButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  margin: 20px 0px 4rem 0px;
  color:${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
`;
export const StyledMarketPlacePageWrapper = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
`;
