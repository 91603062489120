import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import config from "../../configuration/config";

import {
  toggleWalletModal,
  updateProvider,
  setChainId,
  setError,
} from "../../store/actions/globalActions";
import {
  getSignInMsg,
  checkIfLoginAndUpdateState,
  setLoginLoading,
  logout,
} from "../../store/actions/loginActions";
import { WALLET_TYPE, CURRENCY_SYMBOLS } from "../../constants/appConstants";
import { createAndSwitchProvider } from "../../common/helpers/providerHelper";
import { getChainId } from "../../common/common";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { embedMessageString, scrollTopTop } from "../../common/helpers/helper";
import {
  WALLET_MESSAGE,
  SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE,
} from "../../constants/messages";
import { useNavigate } from "react-router";
import ConnectButton from "./components/ConnectButton";
import {
  Navbar,
  Container,
  Dropdown,
  Nav,
  Button,
  Tooltip,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import WalletModal from "./components/WalletModal";

import { createContractInstance } from "../../common/common";
import {
  HeaderStyled,
  AccountDiv,
  NavLinkStyled,
  HeadingSubText,
  TextContent,
  CustomDropDownStyled,
  CustomConnectButton,
  Logo,
  FormSelect,
  SwitchContainer,
} from "./style";
import SearchField from "../SearchField";
import LoadingModal from "../LoadingModal/LoadingModal";
import Drawer from "./components/Drawer";
import { ROUTES } from "../../constants/routes";
import WethioLogo from "../../assets/icons/wethioftLogo";
import { toast } from "react-toastify";
import NotificationsBox from "../NotificationsBox/NotificationsBox";
import FilterDropdown from "../Dropdown/Dropdown";
import getAxiosInst from "../../services/axios";
import store from "../../store/store";
import Web3 from "web3";
import {
  setActiveConfig,
  setBlockchain,
} from "../../store/actions/configAction";
import { setCreator, setBlocked } from "../../store/actions/userActions";
import { MenuItem, Select } from "@mui/material";
import SearchBox from "../SearchBox/SearchBox";
import WalletConnect from "../../assets/icons/wallets/walletConnect";

const values = ["delhi", "mumbai", "delhi56"];

function Header() {
  const [wallets] = useState([
    {
      walletType: WALLET_TYPE.metamask,
    },
  ]);

  const [modalLoading, setModalLoading] = useState(wallets.map(() => false));
  const loginState = useSelector((state) => state.login);
  const currentBlockchain = useSelector((state) => state.global.blockchain);
  const isCreator = useSelector((state) => state.global.isCreator);
  const isBlocked = useSelector((state) => state.global.isBlocked);

  const [block, setBlock] = useState(currentBlockchain);
  const options = useSelector((state) => state.search.data);
  const { isLogin, requestStatus, loading } = loginState;
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const [filter, setFilter] = useState("nft");
  const [inputValue, setInputValue] = useState("");
  const userState = useSelector((store) => store.user);
  const [toggleNavBar, setToggleNavBar] = useState(false); // show and hide navbar mobile
  const globalState = useSelector((store) => store.global);
  const [isAdminUser, setIsAdminUser] = useState();
  const { account } = globalState;
  const { web3Instance, hasMetamask, error, currentChainId, walletModal } =
    globalState;
  const { userData } = userState;
  const balance = globalState["balance"];

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const state = useSelector((state) => state);

  useEffect(() => {
    setBlock(currentBlockchain);
  }, [currentBlockchain]);

  const OpenAssetPage = () => {
    if (isAdminUser) {
      navigate("/createCollection");
    } else if (isBlocked) {
      navigate("/userProfile", {
        state: { msg: "You are blocked by admin" },
      });
    } else if (isCreator) {
      navigate("/createCollection");
    } else if (
      (!isCreator && userData?.instagramUsername) ||
      userData?.twitterUsername
    ) {
      navigate("/userProfile", {
        state: { msg: "Please wait for approval from admin" },
      });
    } else if (
      !isCreator &&
      (!userData?.instagramUsername || !userData?.twitterUsername)
    ) {
      navigate("/userProfile", {
        state: { msg: "Please complete your profile" },
      });
    }
  };
  const importAssetPage = () => {
    navigate("/importCollection");
  };
  const toggleWalletList = useCallback(
    () => dispatch(toggleWalletModal()),
    [dispatch]
  );

  const toggleModalLoading = (...index) =>
    setModalLoading((prevState) =>
      prevState.map((el, i) => (index.includes(i) ? !el : el))
    );
  const toggleNavbarHandler = () => {
    setToggleNavBar((prevState) => !prevState);
    document.querySelector("body").style = `${
      toggleNavBar ? "" : "overflow:hidden"
    }`;
  };

  const handleChangeBlockchain = async (e) => {
    localStorage.setItem("blockchain", e?.target?.value);
    setBlock(e.target.value);
    const configKey = e?.target?.value === "eth" ? "ethConfig" : "wethConfig";
    dispatch(setActiveConfig(config[configKey]));
    dispatch(setBlockchain(e?.target?.value));
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(logout(navigate));
    }
  };
  useEffect(() => {
    if (localStorage.getItem("blockchain")) {
      setBlock(localStorage.getItem("blockchain"));
    } else {
      localStorage.setItem("blockchain", "weth");
    }
  }, []);

  const checkUserIsAdmin = async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  };

  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account, isLogin]);

  useEffect(() => {
    if (isLogin && account && userData?._id) {
      const blockchain = localStorage.getItem("blockchain");
      getAxiosInst({ withAuth: true })
        .get(`/users/creators/${userData?._id}?network=${blockchain}`)
        .then((data) => {
          localStorage.setItem("isCreator", data?.data?.isCreator);
          dispatch(setCreator(data?.data?.isCreator));
          dispatch(setBlocked(data?.data?.isBlocked));
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [account, isLogin, userData?._id, dispatch]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {account}
    </Tooltip>
  );
  return (
    <>
      <HeaderStyled $showDrawer={toggleNavBar}>
        <Navbar
          className="HeaderView"
          collapseOnSelect
          expand="lg"
          variant="light"
        >
          <Container
            style={{
              maxWidth: "1600px",
              justifyContent: "space-around",
              padding: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: "10px",
                marginRight: "-15px",
                alignItems: "center",
              }}
            >
              <Logo to="/" className="logo-text" onClick={scrollTopTop}>
                <WethioLogo />
              </Logo>
              <SearchBox isAdminUser={isAdminUser} />
            </div>
            <Nav className="align-items-center">
              <NavLinkStyled
                className="nav-link text-center"
                to="/marketPlacePage"
              >
                <p>Marketplace</p>
              </NavLinkStyled>
              <NavLinkStyled className="nav-link text-center" to="/creators">
                <p>Creators</p>
              </NavLinkStyled>
              <NavLinkStyled
                exact="true"
                to={ROUTES.allCollection}
                className="nav-link text-center"
              >
                <p>Assets</p>
              </NavLinkStyled>
              {isAdminUser && isLogin ? (
                <CustomDropDownStyled>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Admin Approval
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => navigate(ROUTES.approveAssetsCreator)}
                      >
                        Approve Assets
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(ROUTES.approveAssets)}
                      >
                        Approve Auction
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => navigate(ROUTES.approveCreators)}
                      >
                        Approve Creators
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </CustomDropDownStyled>
              ) : null}

              <CustomDropDownStyled>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    Wethio Gold
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate(ROUTES.zynecoin)}>
                      Wethio Gold ZYN
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate(ROUTES.bitcoin)}>
                      Wethio Gold BTC
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate(ROUTES.zynecoin18k)}>
                      Wethio Gold 18K
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </CustomDropDownStyled>
              {/* // <FormSelect
                //   onChange={(e) => navigate(e?.target?.value)}
                //   aria-label="Default select example"
                //   style={{ minWidth: "140px" ,fontSize:"14px"}}
                // >
                //    <option value={ROUTES.wethioGold}>Wethio Gold</option>
                //   <option value={ROUTES.zynecoin}>Zynecoin</option>
                //   <option value={ROUTES.bitcoin}>Bullish BTC</option>
                // </FormSelect> */}
              <FormSelect
                onChange={(e) => {
                  handleChangeBlockchain(e);
                }}
                style={{ minWidth: "128px", fontSize: "14px" }}
                aria-label="Default select example"
                className="mx-1"
                value={block}
              >
                <option value="weth">Wethio</option>
                <option value="eth">Ethereum</option>
              </FormSelect>
              {isLogin ? (
                <AccountDiv>
                  <HeadingSubText>
                    {balance.eth}
                    {defaultConfig?.chainInfo?.name === "wethio"
                      ? CURRENCY_SYMBOLS.wrappedEther
                      : CURRENCY_SYMBOLS.ether}
                  </HeadingSubText>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 300 }}
                    overlay={renderTooltip}
                  >
                    <TextContent
                      onClick={() => {
                        navigator.clipboard.writeText(account);
                        toast("Account address copied successfully");
                      }}
                    >
                      {account}
                    </TextContent>
                  </OverlayTrigger>
                </AccountDiv>
              ) : null}
              <div className="text-end">
                <div
                  variant="light"
                  className="AvtarButton "
                  onClick={userData ? undefined : toggleWalletList}
                >
                  {isLogin ? (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {/* <NotificationsBox>
                        <img
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          src="/bellIcon.svg"
                          alt=""
                        />
                      </NotificationsBox> */}

                      <ConnectButton
                        isLogin={isLogin}
                        toggleWalletList={toggleWalletList}
                        currentChainId={currentChainId}
                      />
                    </div>
                  ) : (
                    <CustomConnectButton>
                      <WalletConnect />

                      <Button
                        className="ConnectButon"
                        style={{
                          background:
                            "linear-gradient(180deg, #ffb11f 0%, #ffc861 100%)",
                        }}
                        onClick={() => navigate("/login")}
                      >
                        Connect Wallet
                      </Button>
                    </CustomConnectButton>
                  )}
                </div>
              </div>
            </Nav>
          </Container>
        </Navbar>
        <Drawer show={toggleNavBar} closeDrawer={toggleNavbarHandler} />
      </HeaderStyled>
    </>
  );
}

export default Header;
