import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import wethioExplorer from "../../assets/icons/wethio-explorer.png";
import { useDispatch, useSelector } from "react-redux";
import { apiHandler } from "../../services/axios";
import {
  getOneAsset,
  updateAssetWithTxData,
} from "../../services/assetServices";
import { useWarnForUnsavedChanges } from "../../hooks/useWarnForUnsavedChanges";
import {
  useLocation,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";

import {
  CreateCollectionDiv,
  InputStyled,
  CustomCard,
  Heading,
  CardProfile,
  CardTitle,
  SubText,
  ListButton,
} from "./style";
import ProfileImage from "./../../Components/Profile/ProfileImage";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import EtherScanLink from "../../Components/EtherScanLink/EtherScanLink";
import AssetComponent from "../../Components/AssetComponent";
import Avatar from "../../Components/Avatar/Avatar";
import {
  getCollection,
  getAllCollection,
} from "../../services/collectionServices";
import { getSearchData } from "../../store/actions/searchAction";
import store from "../../store/store";

function CollectionStepTwo({ isMinting, toggleIsMinting, metadataUrl }) {
  const [loading, setLoading] = useState(false);
  const [assetData, setAssetData] = useState(null);
  const { id } = useParams();
  const contractState = useSelector((store) => store.contractState);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const userState = useSelector((store) => store.user);
  const globalState = useSelector((store) => store.global);

  const { account } = globalState;
  const {
    contract: { minting: mintingContract },
  } = contractState;
  const { userData } = userState;

  useWarnForUnsavedChanges({
    check: loading,
    msg: "Asset Minting Underway",
  });
  const blockchain = useSelector((state) => state.global.blockchain);
  const { state } = useLocation();
  const { assetId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const getTokenUriPath = (uri) => uri.replace(defaultConfig?.ipfsBaseUrl, "");

  const onMintClickHandler = () => {
    if (assetData?.mint_tx_hash) {
      navigate("/collectionSteps?type=image");
    } else {
    }
  };



  useEffect(() => {
    if (!assetData) {
      apiHandler(() => getOneAsset(assetId), {
        onSuccess: (data) => {
          setAssetData(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [assetData, assetId]);


   
  // if (state?.from === 'assetCreate') {
  return (
    <CreateCollectionDiv>
      <Row>
        <Col md={5}>
          <CardProfile>
            {assetData ? (
              <AssetComponent
                assetType="image"
                className="createNftImage"
                freeSize
                src={assetData?.metadata_fields?.image_url}
              />
            ) : (
              <Spinner animation="border" />
            )}
            <Card.Body>
              <CardTitle>{assetData?.name}</CardTitle>
              <div className="d-flex align-items-center pt-3 pb-3">
                <Avatar
                  $imgURL={userData?.profile_image_url}
                  $height="48px"
                  $width="60px"
                  $size="small"
                />
                <SubText className="mb-0 pl-3" stye={{marginLeft:"12px",textAlign:"left"}}>
                  {userData?.username ? userData?.username : "@anonymous"}
                </SubText>
              </div>
            </Card.Body>
          </CardProfile>
        </Col>
        <Col md={5}>
          <Heading>Your NFT has been minted !</Heading>
          <SubText>
            Your artwork has officially been minted as an NFT on the{" "}
            {defaultConfig?.chainInfo?.name === "wethio"
              ? "Wethio"
              : "Ethereum"}&nbsp;
            blockchain.
          </SubText>
   
          {assetData?.mint_tx_hash && blockchain === "eth" ? (
            <EtherScanLink
              mintTxHash={assetData?.mint_tx_hash}
              className="mt-5 mb-5 etherscan-link"
              borderLess
              text="View on Etherscan"
            />
          ) : (
            <EtherScanLink
              mintTxHash={assetData?.mint_tx_hash}
              className="mt-5 mb-5 etherscan-link"
              borderLess
              icon={wethioExplorer}
              text="View on Wethio"
            />
          )}

          <div className="mt-3 d-flex">
          {assetData?.status === "PENDING" ? (
            <ListButton variant="primary" type="submit">
              Pending for Approval
            </ListButton>
          ) : (
            <>
             <Button
            color="secondary"
            className="action-btn m-3"
            onClick={onMintClickHandler}
          >
            {"Add More"}
          </Button>
            <Button  color="secondary"
                  className="action-btn m-3"
                  onClick={()=>navigate(`/collectionDetails/${assetData?._id}`)}
                   >
             List NFT
            </Button> 
            </>

          )}
          </div>
        </Col>
      </Row>
    </CreateCollectionDiv>
  );
}

CollectionStepTwo.propTypes = {
  isMinting: PropTypes.bool,
  assetdata: PropTypes.object,
  metadataUrl: PropTypes.string,
  toggleIsMinting: PropTypes.func,
  setAssetData: PropTypes.func,
};

export default CollectionStepTwo;
