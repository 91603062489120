import { ROUTES } from "../../constants/routes";

import * as actionTypes from "../../constants/actionTypes";
import {
  getAccount,
  getBalance,
  getChainId,
  createContractInstance,
  reqAccountAndSignature,
} from "../../common/common";

import {
  checkIfLogin,
  setLocalStorageForLogout,
  setLocalStorageForLogin,
} from "../../common/helpers/helper";
import { initProviderAndWeb3Instance } from "../../common/helpers/providerHelper";
import config from "../../configuration/config";
// import mintingAbi from "../../abi/MINTING_ABI.json";
// import auctionAbi from "../../abi/AUCTION_ABI.json";

import {
  setLoading,
  setAccountDetails,
  setError,
  setWeb3Instance,
  updateProvider,
  setChainId,
  setWalletType,
  updateChainId,
} from "./globalActions";
import { setUser } from "./userActions";
import { setContract } from "./contractActions";
import { apiHandler } from "../../services/axios";
import {
  getSigningMsg,
  loginApi,
  logoutApi,
} from "../../services/loginServices";
import { LOGIN_MESSAGE } from "../../constants/messages";
import { STORAGES, CONTRACT_NAME } from "../../constants/appConstants";
import { getUser } from "../../services/userServices";
import { setActiveConfig } from "./configAction";
import Web3 from "web3";

const setLogin = (isLogin, token, msg) => ({
  type: actionTypes.UPDATE_LOGIN,
  payload: {
    isLogin,
    token,
    msg,
  },
});

export const setRequestStatus = (requestStatus) => ({
  type: actionTypes.SET_REQUEST_STATUS,
  payload: {
    requestStatus,
  },
});

export const setLoginLoading = (loading) => ({
  type: actionTypes.SET_LOGIN_LOADING,
  payload: { loading },
});

export const login = (account, signature, msg, walletType) => {
  return async (dispatch, getState) => {
    const web3Instance = getState().global.web3Instance;

    await apiHandler(() => loginApi(signature, msg), {
      onSuccess: async (data) => {
        if (data.success) {
          setLocalStorageForLogin([
            {
              key: STORAGES.token,
              value: data.jwt_token,
            },
            {
              key: STORAGES.id,
              value: data.user._id,
            },
            {
              key: STORAGES.walletType,
              value: data.user.wallet_type,
            },
          ]);
          dispatch(setLogin(true, data.jwt_token, msg));
          localStorage.setItem("account", account);
          const blockchain = localStorage.getItem("blockchain");

          const configKey = blockchain === "eth" ? "ethConfig" : "wethConfig";

          const defaultConfig = config[configKey];
          const web3Instance = new Web3(
            Web3.givenProvider || defaultConfig?.web3ProviderFallbackUrl
          );
          const ContractInstance = await createContractInstance(
            web3Instance,
            defaultConfig?.treasuryABI,
            defaultConfig?.treasuryContractAddress
          );

          const isAdmin = await ContractInstance.methods
            .isAdmin(account)
            .call();

          localStorage.setItem("isAdmin", isAdmin);

          let apiArr;
          apiArr = [() => getChainId(web3Instance)];
          const all = await Promise.all(apiArr.map((fn) => fn()));
          all.forEach((res, i) => {
            if (i === 0) {
              dispatch(setChainId(res));
            }
          });

          getBalance(web3Instance, account).then((res) =>
            dispatch(setAccountDetails(account, signature, res))
          );

          dispatch(setUser(data.user));
          apiHandler(() => getUser(data.user._id), {
            onSuccess: (data) => {
              dispatch(setUser(data));
            },
          });
        }
      },
      onError: (error) => {
        console.error(error);
      },
      final: () => {
        dispatch(setLoginLoading(false));
        dispatch(setRequestStatus(false));
      },
    });
  };
};

export const logout = (navigate, { onSuccess, onError, final } = {}) => {
  return async (dispatch, getState) => {
    const isLogin = getState().login.isLogin;
    if (!isLogin) return;

    dispatch(setLoading(true));

    await apiHandler(
      logoutApi,
      {
        onSuccess: () => {
          onSuccess?.();
          setLocalStorageForLogout();
          dispatch(setAccountDetails("", "", 0, 0));
          dispatch(setLogin(false, ""));
          dispatch(setUser(null));
          navigate(ROUTES.home);
        },
        onError: (error) => {
          console.error(error);
        },
        final: () => {
          dispatch(setLoading(false));
        },
      },
      { sync: true }
    );
  };
};

export const getSignInMsg = (walletType) => {
  return async (dispatch) => {
    dispatch(setWalletType(walletType));
    dispatch(setLoginLoading(true));
    dispatch(setRequestStatus(true));
    dispatch(sendSignatureRequest(walletType));
  };
};

export const sendSignatureRequest = (walletType) => {
  return async (dispatch, getState) => {
    const web3Instance = getState().global.web3Instance;
    await apiHandler(getSigningMsg, {
      onSuccess: async (data) => {
        dispatch(setLoginLoading(true));
        dispatch(setRequestStatus(true));
        const [account, signature] = await reqAccountAndSignature(
          web3Instance,
          data.message,
          walletType
        );
        dispatch(login(account, signature, data.message, walletType));
      },
      onError: (error) => {
        console.error(error);
        dispatch(setLoginLoading(false));
        dispatch(setRequestStatus(false));
        if (error?.code?.toString() === "4001") {
          dispatch(
            setError(error, {
              errorTitle: "Signature rejected",
              errorMsg: LOGIN_MESSAGE.invalidSignature.message,
              errorBtnLabel: "Retry",
            })
          );
        }
      },
    });
  };
};

export const checkIfLoginAndUpdateState = (callback) => {
  return async (dispatch, getState) => {
    const token = JSON.parse(localStorage.getItem(STORAGES.token));
    const walletType = JSON.parse(localStorage.getItem(STORAGES.walletType));
    const blockchain = localStorage.getItem("blockchain");
    if (blockchain) {
      const configKey = blockchain === "eth" ? "ethConfig" : "wethConfig";
      dispatch(setActiveConfig(config[configKey]));
    }
    dispatch(setWalletType(walletType));
    const [web3Instance] = initProviderAndWeb3Instance({
      walletType,
      dispatch,
      setWeb3Instance,
      updateProvider,
    });
    dispatch(updateChainId());
    const activeConfig = getState().global.activeConfig;
    const auctionContractInstance = await createContractInstance(
      web3Instance,
      activeConfig?.auctionABI,
      activeConfig?.auctionContractAddress
    );
    dispatch(setContract(CONTRACT_NAME.auction, auctionContractInstance));
    if (token) {
      try {
        if (checkIfLogin() && (await getAccount(web3Instance))) {
          const account = await getAccount(web3Instance);
          const balance = await getBalance(web3Instance, account);
          const id = JSON.parse(localStorage.getItem(STORAGES.id));
          apiHandler(() => getUser(id), {
            onSuccess: (data) => {
              dispatch(setUser(data));
            },
          });
          dispatch(setLogin(true, token, ""));
          dispatch(setAccountDetails(account, "", balance));
        } else {
          setLocalStorageForLogout();
        }
        const mintingContractInstance = await createContractInstance(
          web3Instance,
          activeConfig?.mintingABI,
          activeConfig?.mintingContractAddressBitCoinNft
        );
        dispatch(setContract(CONTRACT_NAME.minting, mintingContractInstance));
        const auctionContractInstance = await createContractInstance(
          web3Instance,
          activeConfig?.auctionABI,
          activeConfig?.auctionContractAddress
        );
        dispatch(setContract(CONTRACT_NAME.auction, auctionContractInstance));
      } catch (error) {
        setLocalStorageForLogout();
        console.error(error);
      } finally {
        callback && callback(false);
      }
    }
  };
};
