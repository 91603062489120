import styled from 'styled-components';
import { Container ,Popover,FormControl,FormLabel,FormCheck } from 'react-bootstrap';




export const UserProfileStyled = styled.div`

`;

export const Text1 = styled.p`
color: ${(props) => props.theme.palette.text.secondary};
margin:0px 0px 0px 8px;

`;