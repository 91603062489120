import React, { useState, useEffect } from "react";

import { Card, Row, Col } from "react-bootstrap";

// import EthAmount from '../EthAmount';
import UserIcon from "../../assets/icons/UserIcon";
import PropTypes from "prop-types";
import ProtagonistChip from "../Protagonist";
// import { useLocaleNumber } from "../../hooks/useLocaleNumber";
import { useNavigate } from "react-router-dom";

import getAxiosInst, { apiHandler } from "../../services/axios";
// import THREASURY_ABI from "../../abi/THREASURY_ABI.json";

import {
  // createWeb3Instance,
  createContractInstance,
  // createProvider,
} from "../../common/common";
import { useSelector } from "react-redux";
// import defaultConfig from "../../configuration/config";
import { getUser } from "../../services/userServices";
import { toast } from "react-toastify";
import store from "../../store/store";
import { BsInstagram } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";

import {
  ImageSection,
  ImageCard,
  CommonButton,
  CarouselCaption,
  AvtarImg,
  SubText,
} from "./style";

const { Body: CardBody, Title: CardTitle } = Card;

const CommonCreatorCard = ({
  creatorId,
  coverImage,
  allAssets,
  username,
  totalFollowCount,
  loginUserId,
  twitterUsername,
  instagramUsername,
}) => {
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.user);
  const [isCreator, setIsCreator] = useState(false);
  const [isCreatorApproved, setisApprovedCreator] = useState("");
  const [isFollowed, setIsFollowed] = useState([]);
  const getDetailsPage = (creatorId) => {
    navigate(`/artistDetails/${creatorId}`);
  };

  const onFollowHandler = () => {
    const blockchain = localStorage.getItem("blockchain");
    if (loginUser?.userData) {
      getAxiosInst({ withAuth: true })
        .put(`/users/creators/${creatorId}/toggle-follow?network=${blockchain}`)
        .then((data) => {
          toast.success(data?.data?.message);

          setIsFollowed(data?.data?.creatorInfo?.followingCreators);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    } else {
      toast.error("Please Connect to Wallet");
    }
  };

  const getAllFollowingCreatorList = () => {
    if (isFollowed.includes(creatorId)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (loginUser?.userData?.id) {
      const blockchain = localStorage.getItem("blockchain");

      getAxiosInst({ withAuth: true })
        .get(`/users/creators/${loginUser?.userData?.id}?network=${blockchain}`)
        .then((data) => {
          setIsCreator(data?.data?.isCreator);
          setisApprovedCreator(data?.data?.creatorApprovalStatus);
          const follow = data?.data?.followingCreators.includes(creatorId);

          setIsFollowed(data?.data?.followingCreators);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [loginUser]);

  return (
    <Col md={4} sm={12} xs={12} className="mb-3">
      {/* {status=="APPROVED" ? ( */}
      <Card style={{ height: "340px" }}>
        <CardBody>
          <div className="d-flex creator-mobile-view-card-style">
            <ImageSection onClick={() => getDetailsPage(creatorId)}>
              {coverImage ? (
                <img className="d-block m-0 p-0" src={coverImage} alt="First slide" />
              ) : (
                <AvtarImg>
                  <UserIcon />
                </AvtarImg>
              )}
            </ImageSection>
            <CarouselCaption onClick={() => getDetailsPage(creatorId)}>
              <img
                className="d-block verifiedIcon "
                src="./verifiedIcon.png"
                alt="First slide"
              />
              <h3 className="title1 ">{username || "anonymous"}</h3>

              <p>{allAssets?.length} item Products</p>
            </CarouselCaption>
            <div>
           {loginUserId !==creatorId &&  <CommonButton
              className="action-btn"
              onClick={() => onFollowHandler()}
            >
              {!getAllFollowingCreatorList() ? "Follow" : "Unfollow"}
            </CommonButton>}
            <div className="d-flex">
             
              <SubText style={{ width: "10px" }}>{totalFollowCount}</SubText>&nbsp;
              <SubText>Followers</SubText> 
            </div>
            </div>
           
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "30px",
            }}
            onClick={() => getDetailsPage(creatorId)}
          >
            <Row>
              {allAssets && allAssets?.length > 0 ? (
                <Col md={allAssets?.length === 1 ? 12 : 6}>
                  <ImageCard
                    className="asset-img-collection w-100"
                    src={allAssets[0]?.metadata_fields?.image_url}
                    alt="Card cap"
                  />
                </Col>
              ) : null}
              <Col md={6}>
                <Row>
                  {allAssets && allAssets?.length >= 1
                    ? allAssets?.slice(1, 3).map((item, i) => {
                        return (
                          <Col md={allAssets?.length === 2 ? 12 : 6}>
                            <ImageCard
                              className="asset-img-collection w-100"
                              src={item?.metadata_fields?.image_url}
                              style={{ height: "80px" }}
                              alt="Card cap"
                            />
                          </Col>
                        );
                      })
                    : null}
                </Row>

                {allAssets && allAssets?.length >= 4 ? (
                  <Col md={12} style={{ marginTop: "14px" }}>
                    <ImageCard
                      className="asset-img-collection w-100"
                      src={allAssets[3]?.metadata_fields?.image_url}
                      style={{ height: "124px" }}
                      alt="Card cap"
                    />
                  </Col>
                ) : null}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CommonCreatorCard;

CommonCreatorCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  soldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  remainingTime: PropTypes.string,
  detailsPageType: PropTypes.oneOf(["auction"]),
};
