import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { apiHandler } from "../../services/axios";
import {
  getOneAsset,
} from "../../services/assetServices";
import {
  addCollectionID,

} from "../../store/actions/userActions";
import {
  getAuctionData,

} from "../../services/collectionServices";
import { useWarnForUnsavedChanges } from "../../hooks/useWarnForUnsavedChanges";
import {

  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";

import {
  CreateCollectionDiv,
  CardProfile,
  TextHeading,
  SubText,
  HeadingEth,
  StyleInput,
  HeadingItem,
  CardTitle,
  SwitchContainDiv,
} from "./style";

import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import AssetComponent from "../../Components/AssetComponent";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import TextInput from "../../Components/TextInput/TextInput";
import * as Yup from "yup";
import {
  createContractInstance,
} from "../../common/common";
import {
  convertToEthAmount,
} from "../../common/helpers/helper";
import ProtagonistChip from "../../Components/Protagonist";

const validationScema = Yup.object().shape({

  amountEth: Yup.mixed().test(
    "isRequred",
    "Enter valid amount",
    (value) => {
      if (Number(value)) {
        if (Number(value) > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  ),

});
function ItemListingStepOne({ stateChange, setIsMinted }) {

  const [loading, setLoadingagain] = useState(false);

  const [collectionDetail, setCollectionDetails] = useState();
  const [assetData, setAssetsData] = useState();
  const [auctionId, setAuctionId] = useState({});
  const [auctionDetails, setAuctionDetails] = useState();
  const [timeDuration, setTimeDuration] = useState();
  const [assetTokenId, setAssetTokenId] = useState();
  const contractState = useSelector((store) => store.contractState);
  const userState = useSelector((store) => store.user);
  const [primaryFee, setPrimaryFee] = useState();
  const [secondaryFee, setSecondaryFee] = useState();
  const globalState = useSelector((store) => store.global);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const [confirmationRejected, setConfirmationRejected] = useState({
    code: "",
    message: "",
  });
  const { account, web3Instance } = globalState;
  const {
    contract: { auction: auctionContractInstance },
  } = contractState;


  useWarnForUnsavedChanges({
    check: loading,
    msg: "Asset Minting Underway",
  });
  const { assetId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAuctionTime = async (value) => {
    try {
      const auctionContractInstance = await createContractInstance(
        web3Instance,
        defaultConfig?.auctionABI,
        defaultConfig?.auctionContractAddress
      );
      const getTime = await auctionContractInstance.methods
        .getReserveAuctionConfig()
        .call();
      let timeSecond = getTime.duration;
      const sec = parseInt(timeSecond, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      let TotalTime = hours + ":" + minutes + ":" + seconds;
 

      setTimeDuration(TotalTime);
    } catch (error) {}
  };
  const getCreateReserveAuction = async (dataElem, amountEth) => {
    setIsMinted(true);
    dispatch(addCollectionID(dataElem?.asset_id));
    const auctionContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.auctionABI,
      defaultConfig?.auctionContractAddress
    );
    let txHashData = "";
    try {
      txHashData = await auctionContractInstance.methods
        .createReserveAuction(
          assetData?.contract_address,
          assetData?.token_id,
          amountEth
        )
        .send({
          from: account,
    
        });
      dispatch(
        addCollectionID(
          dataElem?.asset_id,
          assetData?.token_id,
          amountEth,
          dataElem?._id,
          txHashData
        )
      );
      setAuctionDetails(txHashData);
      setIsMinted(false);
      stateChange(false);
    } catch (error) {
      console.log(error);
      stateChange(false);
      dispatch(
        addCollectionID(
          dataElem?.asset_id,
          assetData?.token_id,
          amountEth,
          dataElem?._id,
          txHashData
        )
      );

      setIsMinted(false);
    }
  };

  const onAuctionClickHandler = async (value) => {

    stateChange(true);
    setIsMinted(true);
    try {
      const reqData = {
        asset_id: assetId,
      };

      const amountEth = convertToEthAmount(value.amountEth);

      const getauctionId = await apiHandler(() => getAuctionData(reqData), {
        onSuccess: (data) => {
          setAuctionId(data.auction);
          getCreateReserveAuction(data.auction, amountEth);

          navigate(`/itemListingSteps/${assetId}/listing`);
        },
        onError: (error) => console.error(error),
      });
    } catch (error) {
      stateChange(false);
      setIsMinted(false);
    }
  };
  const getAssetDetails = async () => {
    apiHandler(() => getOneAsset(assetId), {
      onSuccess: (data) => {
        setAssetsData(data);
        setAssetTokenId(data.token_id);

      },
      onError: (error) => console.error(error),
      final: () => {},
    });
  };



  useEffect(() => {
    async function fetchData() {
      const value = await auctionContractInstance?.methods.getFeeConfig().call();
    let primary = value?.primaryWethioFeeBasisPoints / 100;
    let secondary = value?.secondaryWethioFeeBasisPoints / 100;
    setPrimaryFee(primary);
    setSecondaryFee(secondary);
    }
    fetchData();
    
  }, []);

  useEffect(() => {

    if (assetData) getAuctionTime();
  }, [assetData]);

  useEffect(() => {
    if (assetId) getAssetDetails();
  }, [assetId]);

  if (true) {
    return (
      <CreateCollectionDiv>
        <Row>
          <Col md={5}>
            <CardProfile>
              {assetData ? (
                <AssetComponent
                  assetType="image"
                  freeSize
                  src={assetData?.metadata_fields?.image_url}
                />
              ) : (
                <Spinner animation="border" />
              )}
              <Card.Body>
                <CardTitle>{assetData?.name}</CardTitle>
                <ProtagonistChip
                  id={assetData?.creator_id}
                  type="normal"
                  className="protagonist-wrap"
                />
              </Card.Body>
            </CardProfile>
          </Col>
          <Col md={5}>
            <SwitchContainDiv>
              <TextHeading>Put on sale</TextHeading>

              <SubText>You will receive bids on this item.</SubText>
            </SwitchContainDiv>
            <SwitchContainDiv>
              <TextHeading>Instant sale price</TextHeading>
              <SubText>
                Enter the price for which the Collection will be instantly sold.
              </SubText>
            </SwitchContainDiv>
            <Formik
              initialValues={{
                amountEth: "",
              }}
              validationSchema={validationScema}
              onSubmit={onAuctionClickHandler}
            >
              {(formik) => (
                <FormikForm>
                  <Col md={12} sm={12}>
                    <Form.Group className="mb-3">
                      <HeadingItem>Set a reserve price</HeadingItem>
                      <StyleInput>
                        <TextInput
                          name="amountEth"
                          id="amountEth"
                          type="text"
                          placeholder="0.00"
                          outline="true"
                          color="secondary"
                          autoComplete="off"
                        />
                        <HeadingEth>
                          {defaultConfig?.chainInfo?.name === "wethio"
                            ? "ZYN"
                            : "ETH"}
                        </HeadingEth>
                      </StyleInput>
                    </Form.Group>
                    <SubText>
                      This price will be made public. Bidders will not be able
                      to bid below this price.
                    </SubText>
                    <SubText>
                      A {primaryFee}% service fee on Primary sale.{" "}
                    </SubText>
                    <SubText>
                      A {secondaryFee}% service fee on Secondary sale.
                    </SubText>
                    <Col md={12} sm={12}>
                      <Form.Group className="mb-3 mt-3">
                        <TextHeading>Duration</TextHeading>

                        <TextInput
                          name="duration"
                          id="duration"
                          type="text"
                          placeholder="7 days"
                          value={timeDuration}
                          readOnly
                          outline="true"
                          color="white"
                        />
                      </Form.Group>
                    </Col>
              
                    <div className="mt-3">
                      <Button
                        color="secondary"
                        className="action-btn w-100"
                        type="submit"
                      >
                        List your NFT
                      </Button>
                    </div>
                    {/* )} */}
                  </Col>
                </FormikForm>
              )}
            </Formik>
          </Col>
        </Row>
      </CreateCollectionDiv>
    );
  } else {
    return <Navigate to="/" />;
  }
}

ItemListingStepOne.propTypes = {
  isMinting: PropTypes.bool,
  assetdata: PropTypes.object,
  metadataUrl: PropTypes.string,
  toggleIsMinting: PropTypes.func,
  setAssetData: PropTypes.func,
};

export default ItemListingStepOne;
