import * as actionTypes from "../../constants/actionTypes";

const initState = {
  userData: null,
  tempURL: "",
};

export const userReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_USER:
      return { ...state, userData: payload.userData };
    case actionTypes.UPDATE_PROFILE_IMG:
      return {
        ...state,
        tempURL: payload.imageUrl,
      };
    case actionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        userData: { ...state.userData, ...payload.data },
      };
    default:
      return state;
  }
};

const initStateAdded = {
  id: "",
  imagesUrl: null,
};

export const userAssetsReducer = (state = initStateAdded, action) => {
  const { type, payload } = action;
  switch (type) {
    // case actionTypes.ADD_METADATA_URL:
    //   return { ...state, userData: payload.userData };
    case actionTypes.ADD_TOKEN_ID:
      return {
        ...state,
        id: payload.id,
      };
    // case actionTypes.UPDATE_USER_DATA:
    //   return {
    //     ...state,
    //     userData: { ...state.userData, ...payload.data },
    //   };
    default:
      return state;
  }
};

const initCollectionState = {
  id: "",
  assetTokenId: "",
  amountEth: "",
  auctionId: "",
  txHashData: "",
};

export const userAssetsCollectionReducer = (
  state = initCollectionState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    // case actionTypes.ADD_METADATA_URL:
    //   return { ...state, userData: payload.userData };
    case actionTypes.ADD_COLLECTION_ID:
      return {
        ...state,
        id: payload.id,
        assetTokenId: payload.assetTokenId,
        amountEth: payload.amountEth,
        auctionId: payload.auctionId,
        txHashData: payload.txHashData,
      };
    case actionTypes.DELETE_COLLECTION_ID:
      return {
        ...state,
        id: "",
        assetTokenId: "",
        amountEth: "",
      };
    // case actionTypes.UPDATE_USER_DATA:
    //   return {
    //     ...state,
    //     userData: { ...state.userData, ...payload.data },
    //   };
    default:
      return state;
  }
};

