import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as Yup from "yup";
// import { apiHandler } from "../../services/axios";
// import { createCollection } from "../../services/collectionServices";
// import { css } from "glamor";
// import { useParams } from "react-router-dom";
import { StyledCollectionCreateModal } from "./style";
import ApproveCreateForm from "./ApproveCreateForm";
// import minting_abi from "../../abi/MINTING_ABI.json";
// import auction_abi from '../../abi/AUCTION_ABI.json'
// import TextInput from "../../Components/TextInput/TextInput";
// import Checkbox from "../../Components/Checkbox/Checkbox";
// import defaultConfig from "../../configuration/config";
// import { useSelector } from "react-redux";
// import { getCollectionApproval } from "../../services/collectionServices";
import { useNavigate } from "react-router";
// import {
//   createWeb3Instance,
//   createContractInstance,
//   createProvider,
// } from "../../common/common";
import { Formik } from "formik";
toast.configure();

const validationScemaApprove = Yup.object().shape({
  auctionId: Yup.string().required("Name is required"),
  status: Yup.string().required("status is required"),
});

const validationScemaReject = Yup.object().shape({
  auctionId: Yup.string().required("Name is required"),
  status: Yup.string().required("status is required"),
  reason: Yup.string().required("Description is required"),
});
const ApproveCreateModal = ({ show, onHide, onSubmit, auctionId, status }) => {
  const [finishStatus, setfinishStatus] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  // const globalState = useSelector((store) => store.global);
  // const { account, web3Instance } = globalState;
  // const [isMinted, setIsSubmiting] = useState(false);

  // const { id } = useParams();
  const navigate = useNavigate();

  const validationSchema = useMemo(() => {
    if (status) {
      return validationScemaApprove;
    } else {
      return validationScemaReject;
    }
  }, [status]);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!finishStatus) {
        if (window.confirm("Do you want to go back ?")) {
          setfinishStatus(true);
          // your logic
          navigate("/");
        } else {
          window.history.pushState(null, null, window.location.pathname);
          setfinishStatus(false);
        }
      }
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [finishStatus, navigate]);

  return (
    <StyledCollectionCreateModal
      show={show}
      onHide={onHide}
      centered
      showCloseButton
    >
      <Formik
        initialValues={{
          auctionId: auctionId,
          status: status,
          reason: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => <ApproveCreateForm isSubmiting={isSubmiting} status={status} />}
      </Formik>
    </StyledCollectionCreateModal>
  );
};

ApproveCreateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ApproveCreateModal;
