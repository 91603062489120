import React from 'react';

const copyAddress = ({ fillColor = '#080b35',onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" onClick={onClick} height="25" viewBox="0 0 34.561 34.561">
      <path data-name="COPY ADRESSE" d="m575.281 502.509-6.21-6.21a2.712 2.712 0 0 0-1.93-.8H556.98a6.374 6.374 0 0 0-6.367 6.367v.909h-2.728a6.374 6.374 0 0 0-6.367 6.367v14.552a6.374 6.374 0 0 0 6.367 6.367h12.733a6.374 6.374 0 0 0 6.367-6.367v-.909h2.728a6.374 6.374 0 0 0 6.367-6.367v-11.98a2.7 2.7 0 0 0-.799-1.929zm-6.51-3.938 4.238 4.238h-1.837a2.4 2.4 0 0 1-2.4-2.4zm-3.6 25.124a4.554 4.554 0 0 1-4.547 4.547h-12.738a4.554 4.554 0 0 1-4.548-4.547v-14.553a4.554 4.554 0 0 1 4.548-4.547h2.729v11.824a6.374 6.374 0 0 0 6.367 6.367h8.185zm4.548-2.729h-12.738a4.554 4.554 0 0 1-4.548-4.547v-14.553a4.554 4.554 0 0 1 4.548-4.548h9.97v3.089a4.225 4.225 0 0 0 4.22 4.22h3.089v11.791a4.551 4.551 0 0 1-4.546 4.548z" transform="translate(-541.518 -495.5)"  style={{ fill: fillColor }}  />
    </svg>
  );
};

export default copyAddress;
