import styled from "styled-components";
import { deviceQuery } from "../../../styles/mediaSizes";
import { Container } from "react-bootstrap";
export const StyledUl = styled.ul`
@media ${deviceQuery.tabletM} {
  display: inline-block !important;

}
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex: 1;
  overflow-x: auto;
  padding-right: 1rem;

  > li {
    margin-left: 0px;
    color: ${(p) => p.theme.palette.newTheme.cardGray};
  }
  li {
    margin-right: 10px;
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const CreateCollectionStyled = styled.div`
  .card:hover {
    background-color: #fff;
  }
  .infinite-scroll-component__outerdiv {
    display: flex;
  }

  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }

  .nav-link {
    color: #b5b6c7;
  }
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px;
    display: inline-block;
  }
`;

export const Heading = styled.h3`
  font-family: Unifont;
  margin-right: 20px;
  font-size: 2.5rem;
  color: ${(p) => p.theme.palette.secondary.main};

  @media ${deviceQuery.tabletS} {
    font-size: 1rem;
    margin-right: 10px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 30px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: 'Kanit', sans-serif;
    color: ${(p) => p.theme.palette.text.gray};
  }

  @media ${deviceQuery.tabletS} {
    .left {
      img {
        width: 20px;
      }
    }
    button {
      font-size: 1rem;
    }
  }
`;

// export const CreateCollectionStyled = styled(Container)`
//   .card:hover {
//     background-color: #fff0cc;
//   }
//   .cards-grid{
//     display:flex;
//   }
//   .nav-tabs .nav-link.active {
//     color: #080b35;
//     border-bottom: 2px solid #080b35 !important;
// }
// .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
//     border:none;
// }
// .nav-link{
//     color: #b5b6c7;
// }
// .silderImage{
//   text-align: center;
//   height: 400px;
// width:400px;
//   display: inline-block;

// }
// `;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px 25px;
  margin-top: 60px;
  margin-bottom: 30px;
  width: 100%;
   
`;
export const StuledExploreContainer = styled.div`
  .ExploreContainer {
    height: 400px;
    border-radius: 20px;
    background-color: #fff5de;
    margin: 10% 5% 10% 5%;
    .upper {
      height: 50%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
    .below {
      height: 50%;
      display: flex;
      flex-direction: column;
      .name {
        display: flex;
        flex-direction: column;
        height: 50%;
        > p {
          margin: 15px 15px 10px 15px;
          font-weight: 600;
          font-size: 1rem;
        }
        .iconContainer {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0px 5px 0 20px;
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        height: 50%;
        background-color: #feefa4;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        p {
          margin: 15px 15px 10px 15px;
          font-size: 0.9rem;
        }
        .number {
          margin: 5px 15px 10px 15px;
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
  @media ${deviceQuery.mobileSM} {
    grid-template-columns: repeat(1, 1fr);
  }
  @media ${deviceQuery.tabletL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const HeadingText = styled.h3`

  color: ${(props) => props.theme.palette.newTheme.mainGray};
  font-size: 18px;
  font-weight: 400;
`;
export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
`;
