import getAxiosInst from "./axios";

export const getAllBids = () => {
  const blockchain = localStorage.getItem("blockchain");
  return getAxiosInst({ withAuth: false }).get(`/bids?network=${blockchain}`);
};

export const getAllAuctionBids = (auctionId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/bids?auctionId=${auctionId}&limit=100&network=${blockchain}`
  );
};

export const getBid = (bidId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/bids/${bidId}?network=${blockchain}`
  );
};

/**
 *     "auction_id": "61a0e6a8d12628386aa5ee42",
    "amount": "100",
    "tx_data": {
        "transactionHash":"sdcscs",
    }
 */

export const createBid = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/bids?network=${blockchain}`,
    reqData
  );
};
