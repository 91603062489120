import React from "react";
import {
  CreateCollectionStyled,
  Header,
  CustomCard,
  TextContent,
  HeadingText,
} from "./style";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";


function CreateCollection() {
  const navigate = useNavigate();
  const cardDetails = [
    {
      title: "Gold Token",
      ImageUrl: "goldToken",
      type: "image",
      disabled: false,
    },
    {
      title: "Image",
      ImageUrl: "mountain",
      type: "3d",
      disabled: true,
    },

    {
      title: "Video",
      ImageUrl: "Vedio",
      type: "video",
      disabled: true,
    },
    {
      title: "Audio",
      ImageUrl: "Audio",
      type: "audio",
      disabled: true,
    },

  ];
  const RoutingPage = (type) => {
    if(type==="3d"){
      navigate(`/goldToken`)
    }else{
      navigate(`/collectionSteps?type=${type}`, {
        state: {
          assetType: type,
        },
      });
    }

  };
  const RoutingDashboard = () => {
    navigate(`/`);
  };

  return (
    <CreateCollectionStyled>
      <Header>
        <div className="left">
          <HeadingText>Create new item</HeadingText>
          <TextContent>
            Image, Video, Audio, or 3D Model
            <br />
            File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG,
            GLB, GLTF.
            <br />
            Max size: 5 MB
          </TextContent>
        </div>
        <div className="right">
          <button onClick={RoutingDashboard}>View your dashboard</button>
        </div>
      </Header>
      <Row xs={1} md={4} className="g-5 mt-5 mb-5">
        {cardDetails?.map((item, index) => (
          <Col key={index}>
            <CustomCard
              disabled={item.disabled}
              onClick={() => RoutingPage(item.type)}
            >
              <Card.Img variant="top" src={`./${item.ImageUrl}.png`} />
              <Card.Body>
                <Card.Title>{item.title}</Card.Title>
              </Card.Body>
            </CustomCard>
          </Col>
        ))}
      </Row>
    </CreateCollectionStyled>
  );
}

export default CreateCollection;
