import React from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router-dom';

import { checkIfLogin } from '../common/helpers/helper';

function PrivateRoute({ children }) {
  return checkIfLogin() ? (
    children
  ) : (
    <>
      <Navigate to="/" />
    </>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
