import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { apiHandler } from "../../services/axios";
import { getUser } from "../../services/userServices";

import {
  CardSection,
  CardTitle,
  Footer,
  ToolTipValues,
  CountDownTimerStyled,
  TimingDiv,
  CustomImage,
  CardMoreDetails,
  SubText,
  ProfileText,
  HeadingText,
  AniMationStyled,
  ProfileUser,
  ImageLayout,
  CardFooterSectionLayout,
} from "./style";
import { Card, Spinner, Col } from "react-bootstrap";
import Timer from "../Timer/Timer";
import Avatar from "../Avatar/Avatar";
import AssetComponent from "../AssetComponent";
import { convertEthToNumber } from "../../common/helpers/helper";
import { AUCTION_STATUS } from "../../constants/appConstants";
import { useSelector } from "react-redux";
import ShareIcon from "../../assets/icons/shareIcon";
import ShareModal from "../ShareModal/ShareModal";
import TransferIcon from "../../assets/icons/transferIcon";

const CollectionAuctionCard = ({
  creator_id,
  collectionImage,
  status,
  reservePrice,
  // contractAuctionId,
  time,
  auctionPage,
  title,
  highestBid,
  followCount,
  onClick,
  approvalStatus,
}) => {
  const [creatorData, setCreatorData] = useState(null);
  const [show, setShow] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  useEffect(() => {
    if (creator_id) {
      apiHandler(() => getUser(creator_id), {
        onSuccess: (data) => {
          setCreatorData(data);
        },
      });
    }
  }, [creator_id]);

  const getFixedPriceValue = (amount, decimal) => {
    const result = convertEthToNumber(amount, decimal);
    return Number(result).toFixed(4);
  };

  const handleShareModaClose = () => setShow(false);
  const handleShareModalShow = () => setShow(true);

  return (
    <Col md={auctionPage ? 12 : 4} style={{ margin: "45px 0px" }}>
      <CardSection>
        <CustomImage onClick={onClick}>
          <AssetComponent assetsType="image" src={collectionImage} />
        </CustomImage>
        {approvalStatus !== "PENDING" ? (
          <ToolTipValues>
            <p>Current Bid</p>
            <div className="d-flex align-items-center">
              {status === AUCTION_STATUS.open ? (
                <AniMationStyled>
                  <div className="solidDot"></div>
                </AniMationStyled>
              ) : null}
              <h4>
                {highestBid !== null
                  ? getFixedPriceValue(highestBid?.amount, 18)
                  : reservePrice
                  ? getFixedPriceValue(reservePrice, 10)
                  : 0}
                &nbsp;
                {defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"}
              </h4>
            </div>
          </ToolTipValues>
        ) : null}

        <Card.Body className="d-flex flex-column " onClick={onClick}>
          <CardTitle>{title}</CardTitle>
          <CardFooterSectionLayout>
            <div className="d-flex">
              <Avatar
                $size="small"
                $width="40px"
                $height="40px"
                $imgURL={creatorData?.profile_image_url}
                alt="images"
              />
              <div>
                <ProfileUser>User</ProfileUser>
                <ProfileText>
                  {creatorData?.username || "anonymous"}
                </ProfileText>
              </div>
            </div>
            {status === AUCTION_STATUS.open ? (
              <CountDownTimerStyled>
                <h4>Ending in</h4>
                <TimingDiv>
                  <Timer
                    timeStamp={time}
                    unitLabels={{ hours: "h", minutes: "m", seconds: "s" }}
                  />
                </TimingDiv>
              </CountDownTimerStyled>
            ) : null}
          </CardFooterSectionLayout>
        </Card.Body>
        <Footer
          className="d-flex justify-content-between align-items-center"
          approvalStatus={approvalStatus}
          status={status}
        >
          {approvalStatus === "PENDING" ? (
            <HeadingText style={{ margin: "20px 0px 10px 26px" }}>
              Coming Soon
            </HeadingText>
          ) : approvalStatus !== "PENDING" ? (
            <>
              <CardMoreDetails>
                <h4>Followers</h4>
                <p>{followCount}</p>
              </CardMoreDetails>

              <CardMoreDetails>
                <h4>Reserved Price</h4>
                <p>
                  {reservePrice ? getFixedPriceValue(reservePrice, 10) : 0}
                  &nbsp;
                  {defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"}
                </p>
              </CardMoreDetails>
              <CardMoreDetails
                style={{ cursor: "pointer" }}
                onClick={() => handleShareModalShow()}
                className="d-flex align-items-center"
              >
                <ImageLayout>
                  <TransferIcon />
                </ImageLayout>
              </CardMoreDetails>
              <ShareModal
                image={collectionImage}
                show={show}
                handleClose={handleShareModaClose}
              />
            </>
          ) : // </div>
          status === AUCTION_STATUS.close ||
            status === AUCTION_STATUS.completed ? (
            <div>
              <HeadingText>Highest Bid</HeadingText>
              <p className="mt-2">
                {getFixedPriceValue(highestBid?.amount, 18)}{" "}
                {defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"}
              </p>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center p2">
              <Spinner animation="border" />
            </div>
          )}
        </Footer>
      </CardSection>
    </Col>
  );
};

CollectionAuctionCard.propTypes = {
  data: PropTypes.shape({
    mainImg: PropTypes.string,
    userName: PropTypes.string,
    userImg: PropTypes.string,
    reservePrice: PropTypes.number,
  }),
};

export default CollectionAuctionCard;
