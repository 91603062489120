import styled from "styled-components";
import UserIcon from "../../assets/icons/UserIcon";

const SearchItemRowContainer = styled.div`
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
  border-top: 1px solid #ccc;
`;

const SearchItemRow = ({ profile_image_url, username, onClick }) => {
  return (
    <SearchItemRowContainer onClick={onClick}>
      {profile_image_url ? (
        <img
          style={{ height: "30px", width: "30px" }}
          src={profile_image_url}
          alt="Profile "
        />
      ) : (
        <div style={{ height: "30px", width: "30px" }}>
          <UserIcon style={{ height: "100%", width: "100%" }} />
        </div>
      )}
      <span style={{ color: "black" }}>{username || "anonymous"}</span>
    </SearchItemRowContainer>
  );
};

export default SearchItemRow;
