import React, { useState, useEffect } from "react";
import {
  CreateCollectionStyled,
  Text1,
  Text2,
  SubTextGray,
  SaveButton,
  CustomCardStyled,
  RoundImage,
  CoverImage,
  CustomCard,
  CommonButton,
  CommonReject,
  Text,
  AddressBtn,
  SubText,
  ContractAddess,
  HeadingText,
  HeadingEth,
  StyleInput,
  AuctionButton,
  SubHeading,
  FormCheckStyled,
} from "./style";
import {
  Card,
  Row,
  Tabs,
  Form,
  Tab,
  Col,
  Spinner,
  Carousel,
} from "react-bootstrap";
import {
  getCollection,
  getAllCollection,
  getfreezeCollections,
  getCollectionApproval,
} from "../../services/collectionServices";
import { useDispatch, useSelector } from "react-redux";
import EtherScanLink from "../../Components/EtherScanLink/EtherScanLink";

import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { apiHandler } from "../../services/axios";
import { getAuction } from "../../services/auctionServices";
import wethioExplorer from "../../assets/icons/wethio-explorer.png";
import UserIcon from "../../assets/icons/UserIcon";
import { getUser } from "../../services/userServices";
import ApproveCreateModal from "../../Components/ApproveCollectionModal/ApproveCreateModal";
import { postAuctionUpdateApproval } from "../../services/auctionServices";
import Button from "../../Components/Button/Button";
import CopyAddress from "../../assets/icons/copyAddress";
import { Formik, Form as FormikForm, FieldArray } from "formik";

import TextInput from "../../Components/TextInput/TextInput";
import Checkbox from "../../Components/Checkbox/Checkbox";

import { getOneAsset } from "../../services/assetServices";

import {
  createWeb3Instance,
  createContractInstance,
  createProvider,
} from "../../common/common";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ActivityList from "../../Components/ActivityList/ActivityList";
const validationScema = Yup.object().shape({
  amountEth: Yup.string().required("Is Required"),
  bidcheckbox: Yup.boolean().required("Please click on checkbox"),
});

function ApproveAssetsDetails() {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [selectIndex, setIndex] = useState(0);
  const [auctionId, setAuctionId] = useState();
  const [status, setStatus] = useState();
  const [checkBox, setCheckbox] = useState(false);
  const [collectionDetail, setCollectionDetails] = useState();
  const [isMinted, setIsMinted] = useState(false);
  const [assetData, setAssetsData] = useState();
  const [assetDetails, setAssetsDetails] = useState();
  const [loadcontent, setLoadContent] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const [showCreateCollectionModal, setShowCreateCollectionModal] =
    useState(false);
  const [auctionDetails, setAuctionDetails] = useState();
  const [createrdata, setCreaterData] = useState();
  const [ownerdata, setOwnerData] = useState();
  const [tokenIds, setTokenIds] = useState([]);
  const loginState = useSelector((state) => state.login);
  const { isLogin, requestStatus, loading } = loginState;
  const globalState = useSelector((store) => store.global);
  const userState = useSelector((store) => store.user);
  const [listingInfo, setListingInfo] = useState([]);
  const [toggleNavBar, setToggleNavBar] = useState(false); // show and hide navbar mobile
  const contractState = useSelector((store) => store.contractState);
  const blockchain = useSelector((state) => state.global.blockchain);
  const [isAdminUser, setIsAdminUser] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = userState;
  const { account, web3Instance } = globalState;

  const handleSelect = (selectedIndex, e) => {
    setAssetsDetails(assetData[selectedIndex]);

    setIndex(selectedIndex);
  };

  const onTokenIdAdded = async () => {
    assetData?.map((items) => {
      let ids = parseInt(items.token_id);

      setTokenIds((tokenIds) => [...tokenIds, ids]);
    });
  };
  const toggleColletionCreateModal = (status) => {
    setShowCreateCollectionModal((prev) => !prev);
    setStatus(status);
  };

  const onApproveClickHandler = async (value) => {
    const auctionContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.auctionABI,
      defaultConfig?.auctionContractAddress
    );

    setIsSubmiting(true);

    const reqData = {};

    try {
      // 'APPROVED', 'REJECTED'

      setShowCreateCollectionModal((prev) => !prev);

      let booleanValue;
      if (value === "APPROVED") {
        booleanValue = true;
      } else {
        booleanValue = false;
      }

      const approveAssets = await auctionContractInstance.methods
        .approveAuctionByAdmin(value.auctionId, value.status, value.reason)
        .send({
          from: account,
        });

      apiHandler(() => postAuctionUpdateApproval(id, reqData), {
        onSuccess: (data) => {
          navigate("/approveAssets");
          setIsSubmiting(false);
        },
        onError: (error) => console.error(error),
        final: setIsSubmiting(false),
      });
    } catch (error) {
      navigate("/approveAssets");
      setIsSubmiting(false);
    }
  };

  const getAuctionDetails = async () => {
    apiHandler(() => getAuction(id), {
      onSuccess: (data) => {
        setAuctionDetails(data);
        setAuctionId(data?.auctionId);
        setListingInfo(data?.events);
      },
      onError: (error) => console.error(error),
      final: console.log(),
    });
  };

  const getCollectionDetails = async () => {
    apiHandler(() => getOneAsset(auctionDetails?.asset_id?._id), {
      onSuccess: (data) => {
        // setAuctionId(data?.auctions?.auctionId);

        setCollectionDetails(data);
      },
      onError: (error) => console.error(error),
      final: console.log(),
    });
  };

  const getCreaterDetails = async (collectionData) => {
    apiHandler(() => getUser(collectionData?.creator_id), {
      onSuccess: (data) => {
        setCreaterData(data);
      },
      onError: (error) => console.error(error),
      final: console.log(),
    });
  };
  const getOwnerDetails = async (id) => {
    apiHandler(() => getUser(id), {
      onSuccess: (data) => {
        setOwnerData(data);
      },
      onError: (error) => console.error(error),
      final: console.log(),
    });
  };

  const checkUserIsAdmin = async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  };

  useEffect(() => {
    getAuctionDetails();
  }, [id]);

  useEffect(() => {
    getCollectionDetails();
  }, [auctionDetails]);
  useEffect(() => {
    onTokenIdAdded();
  }, [assetData]);

  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account]);

  useEffect(() => {
    getCreaterDetails(collectionDetail);
    getOwnerDetails(collectionDetail?.owner_id);
  }, [collectionDetail]);
  return (
    <CreateCollectionStyled>
      <Row xs={1} md={4} className="g-5 mt-5 mb-5">
        <Col md={6}>
          <Carousel
            activeIndex={selectIndex}
            variant="dark"
            className="text-center"
            pause="hover"
            interval={null}
            onSelect={handleSelect}
          >
            {/* {assetData?.map((items) => {
              return ( */}
            <Carousel.Item>
              <img
                className="silderImage"
                src={collectionDetail?.metadata_fields.image_url}
                alt="First slide"
              />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            {/* );
            })} */}
          </Carousel>
        </Col>
        <Col md={6}>
          <div>
            <div className="d-flex">
              <HeadingText>
                {collectionDetail?.metadata_fields.name}
              </HeadingText>
            </div>
            <Text className="mt-3 mb-3">
              {collectionDetail?.metadata_fields.description}
            </Text>
            {collectionDetail?.mint_tx_hash && blockchain === "eth" ? (
              <EtherScanLink
                mintTxHash={collectionDetail?.mint_tx_hash}
                className="mt-5 mb-5 etherscan-link"
                borderLess
                text="View on Etherscan"
              />
            ) : (
              <EtherScanLink
                mintTxHash={collectionDetail?.mint_tx_hash}
                className="mt-5 mb-5 etherscan-link"
                borderLess
                icon={wethioExplorer}
                text="View on Wethio"
              />
            )}
          </div>
          <div>
            {auctionDetails?.approvalStatus !==
            "PENDING" ? null : isSubmiting ? (
              <div className="spinner-wrapper d-flex text-start  mt-5">
                <Spinner
                  animation="border"
                  style={{ width: "60px", height: "60px", color: "#080b35" }}
                />
              </div>
            ) : (
              <div>
                <CommonButton
                  className="action-btn m-3"
                  onClick={() => toggleColletionCreateModal(true)}
                >
                  Accept Approval
                </CommonButton>
                <CommonReject
                  className="action-btn reject-btn m-3"
                  onClick={() => toggleColletionCreateModal(false)}
                >
                  Reject Approval
                </CommonReject>
              </div>
            )}
          </div>
        </Col>
        <Col md={12}>
          <Tabs
            defaultActiveKey="Detail"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="Information"
              title="Information"
              className="text-center p-3"
            >
              <div>
                <div className="d-flex text-start mt-3">
                  <SubTextGray style={{ width: "400px" }}>
                    Asset Name
                  </SubTextGray>
                  <SubText>{collectionDetail?.metadata_fields.name}</SubText>
                </div>
                <div className="d-flex text-start mt-3">
                  <SubTextGray style={{ width: "400px" }}>
                    Asset description
                  </SubTextGray>

                  <SubText className="asset-description">
                    {collectionDetail?.metadata_fields.description}
                  </SubText>
                </div>
                <div className="d-flex text-start mt-3">
                  <SubTextGray style={{ width: "400px" }}>Status</SubTextGray>

                  <SubText>{collectionDetail?.auctions?.status}</SubText>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Activity"
              title="Activity"
              className="text-center p-3"
            >
              <div>
                {
                  <ActivityList
                    listData={[]}
                    listingInfo={listingInfo}
                    finaliseInfo={[]}
                  />
                }
              </div>
            </Tab>
            <Tab eventKey="Detail" title="Detail">
              <div>
                <div className="d-flex mt-3">
                  <SubTextGray style={{ width: "400px" }}>
                    Contract Address
                  </SubTextGray>
                  <ContractAddess>
                    {collectionDetail?.contract_address}
                  </ContractAddess>
                </div>
                <div className="d-flex mt-3">
                  <SubTextGray style={{ width: "400px" }}>Token ID</SubTextGray>

                  <SubText>{collectionDetail?.token_id}</SubText>
                </div>
                {/* <div className="d-flex mt-3">
                  <SubTextGray style={{ width: "400px" }}>Status</SubTextGray>

                  <SubText>
                    {collectionDetail?.auctions?.approvalStatus == "PENDING"
                      ? "Need Approval"
                      : "APPROVED"}
                  </SubText>
                </div> */}

                <div className="d-flex mt-3">
                  <SubTextGray style={{ width: "400px" }}>
                    Blockchain
                  </SubTextGray>

                  <SubText>
                    {defaultConfig?.chainInfo?.name === "wethio"
                      ? "Wethio"
                      : "Ethereum"}
                  </SubText>
                </div>

                <div className="d-flex mt-3">
                  <SubTextGray style={{ width: "400px" }}>Type</SubTextGray>

                  <SubText>Physical Item</SubText>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Property" title="Property">
              <div className="d-flex">
                {collectionDetail?.metadata_fields?.attributes?.map((items) => {
                  return (
                    <CustomCardStyled>
                      <Card.Body>
                        <Card.Title>{items?.traitType}</Card.Title>
                        <Card.Text>
                          <SubText>{items?.value}</SubText>
                        </Card.Text>
                      </Card.Body>
                    </CustomCardStyled>
                  );
                })}
              </div>
            </Tab>
          </Tabs>
        </Col>
        <Col md={12}>
          <Tabs
            defaultActiveKey="Creator"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Creator" title="Creator" className="text-center p-3">
              {createrdata ? (
                <div className="row">
                  <div className="d-flex align-items-center col-md-1">
                    <RoundImage>
                      {createrdata?.profile_image_url ? (
                        <img
                          className="asset-img"
                          src={createrdata?.profile_image_url}
                          alt="Card cap"
                        />
                      ) : (
                        <UserIcon />
                      )}
                    </RoundImage>
                  </div>
                  <div className="col-md-11 text-start">
                    {createrdata?.username ? (
                      <Text1>{createrdata?.username}</Text1>
                    ) : (
                      <Text1>@anonymous</Text1>
                    )}
                    <AddressBtn>
                      <CopyAddress
                        onClick={() => {
                          navigator.clipboard.writeText(
                            createrdata?.wallet_address
                          );
                          toast("Account address copied successfully");
                        }}
                      />
                      <SubText>{createrdata?.wallet_address}</SubText>
                    </AddressBtn>
                  </div>
                </div>
              ) : (
                <div>
                  <Text>
                    <HeadingText>No items found</HeadingText>
                  </Text>
                </div>
              )}
            </Tab>
            <Tab eventKey="Owner" title="Owner">
              {ownerdata ? (
                <div className="row">
                  <div className="d-flex align-items-center col-md-1">
                    <RoundImage>
                      {ownerdata?.profile_image_url ? (
                        <img
                          className="asset-img"
                          src={ownerdata?.profile_image_url}
                          alt="Card cap"
                        />
                      ) : (
                        <UserIcon />
                      )}
                    </RoundImage>
                  </div>
                  <div className="col-md-11 text-start">
                    {ownerdata?.username ? (
                      <Text1>{ownerdata?.username}</Text1>
                    ) : (
                      <Text1>@anonymous</Text1>
                    )}
                    <AddressBtn>
                      <CopyAddress
                        onClick={() => {
                          navigator.clipboard.writeText(
                            ownerdata?.wallet_address
                          );
                          toast("Account address copied successfully");
                        }}
                      />
                      <SubText>{ownerdata?.wallet_address}</SubText>
                    </AddressBtn>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <Text>
                    <HeadingText>No items found</HeadingText>
                  </Text>
                </div>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <ApproveCreateModal
        show={showCreateCollectionModal}
        onHide={toggleColletionCreateModal}
        onSubmit={onApproveClickHandler}
        auctionId={auctionId}
        status={status}
      />
    </CreateCollectionStyled>
  );
}

export default ApproveAssetsDetails;
