export const ROUTES = {
  home: "/",
  login:"/login",
  userDetails: "userDetails",
  userProfile: "userProfile",
  createCollection: "createCollection",
  collectionDetails: "collectionDetails/:id",
  collectionStep: "collectionSteps",
  allCollection: "allAssets",
  approveAssets: "approveAuctions",
  approveAssetsCreator: "approveAssets",
  placebid: "/placeBid/*",
  itemListing: "itemListingSteps/*",
  marketplacepage: "marketPlacePage",
  bitcoin: "wethioGold/bullishBTC",
  wethioGold: "wethioGold/publicMinting",
  zynecoin: "wethioGold/zynecoin",
  zynecoin18k:"wethioGold/zynecoin18k",
  marketplacepage: "marketPlacePage",
  importCollection: "importCollection",
  approveAssetsDetails: "approveAssetsDetails/:id",
  edit: "/edit",
  auctionDetails: "/auctionDetails/:auctionId",
  approveImportedCollection: "/approveCollection/:collectionId/*",
  artistDetails: "/artistDetails/:id",
  approveCreators: "/approveCreators",
  creators: "/creators",
};
