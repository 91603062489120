import React, { useEffect, useState } from "react";

import getAxiosInst, { apiHandler } from "../../services/axios";
import { createAsset } from "../../services/assetServices";
import { getOwnedCollections } from "../../services/userServices";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { css, select } from "glamor";
import {
  getSupportedFileString,
  filterNonEmptyAttributes,
} from "../../common/helpers/helper";
import { useNavigate, useLocation } from "react-router-dom";
import { uploadAsset, uploadMetaData } from "../../services/ipfsUploadServices";

import {
  CreateCollectionStyled,
  InputStyled,
  SaveButton,
  PreviewDiv,
  MintButton,
  HeadingText,
  SubText,
  CreateButton,
  LabelStyled,
  BackButton,
  VerySmallText,
  MintRadio,
  AddButton,
} from "./style";
import ProfileImage from "../../Components/Profile/ProfileImage";
import { Form, Col, Row, Container, Spinner } from "react-bootstrap";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import TextInput from "../../Components/TextInput/TextInput";
import TextArea from "../../Components/Textarea/Textarea";
import SelectInput from "../../Components/SelectInput/SelectInput";
import {
  createWeb3Instance,
  createContractInstance,
  createProvider,
} from "../../common/common";
import * as Yup from "yup";
import CollectionCreateModal from "../../Components/CollectionCreateModal/CollectionCreateModal";
import AssetComponent from "../../Components/AssetComponent";
import Button from "../../Components/Button/Button";
import CloseIcon from "../../assets/icons/closeIcon";
import FormErrorMsg from "../../Components/FormErrorMsg/FormErrorMsg";
import { setContract } from "../../store/actions/contractActions";
import { CONTRACT_NAME } from "../../constants/appConstants";

const arrPay = ["mint_3", "mint_4"];

const validationScema = Yup.object().shape({
  
  description: Yup.string().trim().required("Is Required"),
  name: Yup.string().trim().required("Is Required"),
  payment: Yup.mixed().when("sel_mint", {
    is: (value) => arrPay.includes(value),
    then: Yup.string()
      .test(
        "checkNumber",
        "Invalid Address",
        (value, context) => value?.length === 42
      )
      .required("Is Required"),
  }),
  contract_address: Yup.string().required("Is  Required"),
  sel_mint: Yup.string().required("Is Required"),
  image_url: Yup.string().required("Is Required"),
  attributes: Yup.mixed().test(
    "isRequred",
    "Attributes are required",
    (value) => {
      if (value) {
        if (value.some((val) => Object.values(val).every((el) => !!el?.trim?.()))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  ),
});

const EMPTY_ATTRIBUTE_VALUE = { traitType: "", value: "" };
toast.configure();

function CollectionStepOne({ onAssetCreateSuccess, type, setMetadataUrl }) {

  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState({ image_url: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const loginState = useSelector((state) => state.login);
  const { isLogin } = loginState;
  const globalState = useSelector((state) => state.global);
  const { web3Instance, hasMetamask, error, currentChainId, walletModal } =
    globalState;
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const [selectAddressOptions, setSelectAddressOptions] = useState([
    {
      label: "Wethio Gold ZYN",
      value: defaultConfig?.mintingContractAddressZyneCoinGold,
    },
    {
      label: "Wethio Gold 18K",
      value: defaultConfig?.mintingContractAddressZyneCoin18k,
    },
    {
      label: "Wethio Gold BTC",
      value: defaultConfig?.mintingContractAddressBitCoinNft,
    },
   
  ]);
  const userState = useSelector((state) => state.user);
  const { userData } = userState;
  const id = userData?._id;
  const { account } = globalState;
  const { pathname } = useLocation();
  const [isAdminUser, setIsAdminUser] = useState();
  const [isCreatorVerifed, setIsCreatorVerifed] = useState();
  const navigate = useNavigate();
  const isCreator = useSelector((state) => state.global.isCreator);
  const dispatch = useDispatch();


  const onSubmitHandler = async (value) => {
    const metadata = {
      name: value.name,
      description: value.description,
      attributes: filterNonEmptyAttributes(value.attributes),
      image_url: value.image_url,
    };
    setIsSubmitting(true);
    try {
      const baseName = "/metadata.json";
      const uploadMetadataResponse = await uploadMetaData(
        metadata,
        "/temp" + baseName
      );
      const { cid } = uploadMetadataResponse?.data;
      const cidHash = cid;
      const metadataUrl = `https://wethio.infura-ipfs.io/ipfs/${cidHash}${baseName}`;
      if (isAdminUser && isLogin) {
        const mintingContractInstance = await createContractInstance(
          web3Instance,
          defaultConfig?.mintingABI,
          value.contract_address
        );
        dispatch(setContract(CONTRACT_NAME.minting, mintingContractInstance));
      } else {
        const mintingContractInstance = await createContractInstance(
          web3Instance,
          defaultConfig?.publicMintingABI,
          value.contract_address
        );

    

        dispatch(setContract(CONTRACT_NAME.minting, mintingContractInstance));
      }
      const reqData = {
        name: value.name,
        description: value.description,
        metadata_url: metadataUrl,
        contract_address: value.contract_address,
        payment_address: value.payment,
      };

      setMetadataUrl(metadataUrl);
      await apiHandler(() => createAsset(reqData), {
        onSuccess: (data) => {
          onAssetCreateSuccess && onAssetCreateSuccess(data?.asset);
          navigate(`${pathname}/mint/${data?.asset?._id}`, {
            replace: true,
            state: { from: "assetCreate", method: value.sel_mint },
          });
        },
        onError: (error) => {
          console.error(error);
          setIsSubmitting(false);
        },
      });
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const onIpfsUploadSuccess = (formik) => async (file) => {
    setIsUploading((prev) => ({ ...prev, image_url: true }));
    try {
      const formData = new FormData();
      formData.append("image", file);
      await apiHandler(() => uploadAsset(formData), {
        onSuccess: (data) => {
          formik.setFieldValue(
            "image_url",
            `${defaultConfig?.ipfsBaseUrl}${data.cid}`
          );
        },
        onError: (error, res) => {
          toast.error("File limit exceeded", {
            className: css({
              background: "red !important",
            }),
          });
        },
      });
    } catch (error) {
    } finally {
      setIsUploading((prev) => ({ ...prev, image_url: false }));
    }
  };

  const onDeleteListItemHandler = (arrayHelpers, index, formik) => {
    if (formik.values.attributes.length === 1) {
      arrayHelpers.replace(0, EMPTY_ATTRIBUTE_VALUE);
    } else {
      arrayHelpers.remove(index);
    }
  };



  const onAddListItemHandler = (arrayHelpers) => {
    arrayHelpers.push(EMPTY_ATTRIBUTE_VALUE);
  };


  const checkUserIsAdmin = async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  };
  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account]);

  useEffect(() => {
    const blockchain = localStorage.getItem("blockchain");
    if (id) {
      getAxiosInst({ withAuth: true })
        .get(
          `/users/creators/${id}?network=${blockchain}`
        )
        .then((data) => {
       
          setIsCreatorVerifed(data?.data?.isCreator)
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [id]);

  useEffect(() => {
    if (!isAdminUser) {
      setSelectAddressOptions([
        {
          label: "Wethio Public Minting",
          value: defaultConfig?.publicMintingContractAddress,
        },
      ]);
    }
    //  else if(isCreatorVerifed) {
    //   setSelectAddressOptions([
    //     {
    //       label: "Wethio Public Minting",
    //       value: defaultConfig?.publicMintingContractAddress,
    //     },
    //     {
    //       label: "Wethio Gold ZYN",
    //       value: defaultConfig?.mintingContractAddressZyneCoinGold,
    //     },
    //     {
    //       label: "Wethio Gold 18K",
    //       value: defaultConfig?.mintingContractAddressZyneCoin18k,
    //     },
    //     {
    //       label: "Wethio Gold BTC",
    //       value: defaultConfig?.mintingContractAddressBitCoinNft,
    //     },
    //   ]);
    // }
    else{
      setSelectAddressOptions([
        {
          label: "Wethio Gold ZYN",
          value: defaultConfig?.mintingContractAddressZyneCoinGold,
        },
        {
          label: "Wethio Gold 18K",
          value: defaultConfig?.mintingContractAddressZyneCoin18k,
        },
        {
          label: "Wethio Gold BTC",
          value: defaultConfig?.mintingContractAddressBitCoinNft,
        },
      ]);
    }
  }, [isAdminUser, defaultConfig]);
  
  return (
    <CreateCollectionStyled>
      <HeadingText className="m-3">Create New Asset</HeadingText>

      <Formik
        initialValues={{
          image_url: "",
          name: "",
          contract_address: "",
          description: "",
          payment: "",
          sel_mint: "mint_2",
          attributes: [{ traitType: "", value: "" }],
        }}
        validationSchema={validationScema}
        onSubmit={onSubmitHandler}
      >
        {(formik) => (
          <FormikForm>
            <Row>
              <Col md={9} sm={12}>
                <Row>
                  <Col md={5} sm={12}>
                    <ProfileImage
                      accept=".jpg,.png"
                      //accept={getSupportedFileString([type])}
                      id="image_url"
                      ipfs
                      onIpfsUploadSuccess={onIpfsUploadSuccess(formik)}
                      assetComponentProps={{
                        disableLocalLoad: true,
                        loading: isUploading.image_url,
                      }}
                    />
                    {formik.errors.image_url && formik.touched.image_url && (
                      <FormErrorMsg outSideContainer>
                        {formik.errors.image_url}
                      </FormErrorMsg>
                    )}
                  </Col>
                  <Col md={7} sm={12}>
                    <Form.Group className="mb-3">
                      <TextInput
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                        outline="true"
                        color="white"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <TextArea
                        name="description"
                        id="description"
                        placeholder="Description"
                        outline="true"
                        color="white"
                        height="170px"
                      />
                    </Form.Group>
                  </Col>
                </Row>
               
                <Form.Group className="mb-3 mt-3">
                  <LabelStyled>Contract Name</LabelStyled>
                  <SelectInput
                    name="contract_address"
                    id="contract_address"
                    options={selectAddressOptions}
                    
                    placeholder="Select a Contract Address"
                    outline="true"
                    color="white"
                    transparent="true"
                    // extraItem={<Spinner animatio="true" />}
                  />
                
                </Form.Group>
               
                <Form.Group as={Container} fluid className="mt-5">
                  <Row>
                    <Col xs={{ span: 5, offset: 2 }}>Type</Col>
                    <Col xs={5}>Value</Col>
                  </Row>
                  <FieldArray name={"attributes"}>
                    {(arrayHelpers) => (
                      <>
                        {formik.values.attributes.map((attribute, i) => (
                          <Row
                            key={i}
                            className="align-items-center mt-3 justify-content-center"
                          >
                            <Col xs={2}>
                              <CloseIcon
                                onClick={() =>
                                  onDeleteListItemHandler(
                                    arrayHelpers,
                                    i,
                                    formik
                                  )
                                }
                              />
                            </Col>
                            <Col xs={{ span: 5 }}>
                              <TextInput
                                name={`attributes.${i}.traitType`}
                                id={`attributes.${i}.traitType`}
                                type="text"
                                placeholder="Enter Trait"
                                outline="true"
                                color="white"
                              />
                            </Col>
                            <Col xs={5}>
                              <TextInput
                                name={`attributes.${i}.value`}
                                id={`attributes.${i}.value`}
                                type="text"
                                placeholder="Enter Value"
                                outline="true"
                                color="white"
                              />
                            </Col>
                          </Row>
                        ))}
                        {formik.errors.attributes && formik.touched.attributes && (
                          <FormErrorMsg
                            outSideContainer
                            className="text-center"
                          >
                            {formik.errors.attributes}
                          </FormErrorMsg>
                        )}
                        <AddButton
                          type="button"
                          onClick={() => onAddListItemHandler(arrayHelpers)}
                          className="mt-3 d-block mx-auto"
                        >
                          Add More Item
                        </AddButton>
                      </>
                    )}
                  </FieldArray>
                </Form.Group>

                <div className="d-flex justify-content-center">
                  <BackButton  type="submit"  onClick={() => navigate('/')}>
                    Back
                  </BackButton>
                </div>
              </Col>
              <Col md={3} sm={12}>
                <PreviewDiv className="d-flex align-items-center preview-img img-upload">
                  {formik.values.image_url && (
                    <AssetComponent
                      freeSize
                      assetType={type || "image"}
                      src={formik.values.image_url}
                    />
                  )}
                </PreviewDiv>
                <MintRadio className="mt-4 mb-4">
                  
                  <div className="d-flex align-items-center p-1">
                    <input
                      type="radio"
                      name="sel_mint"
                      id="mint2"
                      className="d-none"
                      value="mint_2"
                      checked={formik.values.sel_mint === "mint_2"}
                      onChange={() =>
                        formik.setFieldValue("sel_mint", "mint_2")
                      }
                    />
                    <label htmlFor="mint2">Mint</label>
                  </div>
                  {/* <div className="d-flex align-items-center p-1">
                    <input
                      type="radio"
                      name="sel_mint"
                      id="mint3"
                      className="d-none"
                      value="mint_3"
                      checked={formik.values.sel_mint === "mint_3"}
                      onChange={() =>
                        formik.setFieldValue("sel_mint", "mint_3")
                      }
                    />
                    <label htmlFor="mint3">Mint with Royalty</label>
                  </div> */}
                  <div className="d-flex align-items-center p-1">
                    <input
                      type="radio"
                      name="sel_mint"
                      id="mint4"
                      className="d-none"
                      value="mint_4"
                      checked={formik.values.sel_mint === "mint_4"}
                      onChange={() =>
                        formik.setFieldValue("sel_mint", "mint_4")
                      }
                    />
                    <label htmlFor="mint4">Mint with Royalty</label>
                  </div>
                  {formik.errors.sel_mint && formik.touched.sel_mint && (
                    <FormErrorMsg outSideContainer>
                      {formik.errors.sel_mint}
                    </FormErrorMsg>
                  )}
                </MintRadio>
                {arrPay.includes(formik.values.sel_mint) && (
                  <Form.Group className="mb-3">
                    <TextInput
                      name="payment"
                      id="payment"
                      type="text"
                      placeholder="Royalty Address"
                      outline="true"
                      color="white"
                    />
                  </Form.Group>
                )}
                {(isAdminUser && isLogin) || (isCreator && isLogin) ? (
                  <MintButton
                    variant="primary"
                    type="submit"
                    disabled={isUploading.image_url || isSubmitting}
                    className="justify-content-center"
                  >
                    {isSubmitting && (
                      <Spinner
                        animation="border"
                        className="me-2"
                        style={{ width: "20px", height: "20px" }}
                      />
                    )}
                    <span> Mint NFT</span>
                  </MintButton>
                ) : null}

                <VerySmallText>By clicking "Mint NFT" :</VerySmallText>
                <VerySmallText>
                  - You agree to our Terms of Services & Privacy Policy
                </VerySmallText>
                <VerySmallText>
                  - You declare that everything you have uploaded is original
                  artwork.
                </VerySmallText>
                <VerySmallText>
                  - Any plagrization is not allowed and will be subject to
                  removal.
                </VerySmallText>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </CreateCollectionStyled>
  );
}

export default CollectionStepOne;
