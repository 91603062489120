import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './style';
import { Button as ReactBootstrapButton } from 'react-bootstrap';
import { PalleteColorTypes } from '../../constants/appConstants';
import MiniLoader from '../MiniLoader';

const defaultClass = 'button';

const Button = (props) => {
  const {
    loading: _loading = false,
    children,
    loadingContent,
    state = 'default',
    className: _className = '',
    appendElement,
    ...restProps
  } = props;
  const [loading, setLoading] = useState(_loading);
  const [className, setClassName] = useState(defaultClass);

  useEffect(() => {
    setLoading(_loading);
  }, [_loading]);

  useEffect(() => {
    let classText = [defaultClass, state, ..._className.split(' ')].join(' ');
    setClassName(classText);
  }, [_className, state]);

  return (
    <StyledButton className={className} {...restProps}>
      {loading && <MiniLoader className="spinner" />}
      <span>{loading && loadingContent ? loadingContent : children}</span>
      {appendElement ? appendElement : null}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  ...ReactBootstrapButton.propTypes,
  children: PropTypes.node,
  loading: PropTypes.bool,
  loadingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  $width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.oneOf(PalleteColorTypes),
  transparent: PropTypes.bool,
  state: PropTypes.oneOf(['error', 'success', 'default']),
  round: PropTypes.bool,
  appendElement: PropTypes.node,
};
