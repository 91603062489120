import getAxiosInst from "./axios";

export const loginApi = (signature, msg, walletType, locale) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).post(
    `/auth/login?network=${blockchain}`,
    {
      signature: signature,
      message: msg,
      wallet_type: walletType,
      locale,
    }
  );
};

export const getSigningMsg = () => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/auth/signMessage?network=${blockchain}`
  );
};

export const logoutApi = () => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/auth/logout?network=${blockchain}`
  );
};
