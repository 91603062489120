import styled from 'styled-components';

export const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;

  .play-button-label {
    display: inline-block;
    line-height: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-left: 1.6rem;
  }
`;

export const PlayButtonWrap = styled.span`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.x-small-size {
    width: 4.4rem;
    height: 4.4rem;
  }

  &.small-size {
    width: 5rem;
    height: 5rem;
  }

  &.large-size {
    width: 8rem;
    height: 8rem;
  }

  /* .icon {
    width: 23%;
    transform: translate(10%, 0);
  } */
`;
