import React, { useState, forwardRef } from "react";

import { Dropdown } from "react-bootstrap";
import { CustomMenuStyled, SubText, MenuItemList } from "./style";
import PropTypes from "prop-types";
import { InputStyled, LabelStyled } from "../TextInput/TextInput";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { getAllCollection } from "../../services/collectionServices";

import FormErrorMsg from "../FormErrorMsg/FormErrorMsg";

const popperConfig = {
  modifiers: [
    {
      name: "offset",
      enabled: true,
      options: {
        offset: [0, 0],
      },
    },
  ],
};

const CustomToggle = forwardRef(
  ({ onClick, label, isInvalid, error, ...rest }, ref) => {
    return (
      <>
        {label && (
          <LabelStyled htmlFor={rest?.name} {...rest.labelProps}>
            {label}
          </LabelStyled>
        )}
        <InputStyled
          onClick={(e) => {
            onClick(e);
          }}
          onKeyDown={(e) => {
            if (e.which === 32) {
              e.preventDefault();
              onClick(e);
            }
          }}
          ref={ref}
          isInvalid={isInvalid}
          readOnly
          color="white"
          outline="true"
          {...rest}
        />
        {isInvalid && <FormErrorMsg>{error}</FormErrorMsg>}
      </>
    );
  }
);

CustomToggle.displayName = "CustomSelect";
CustomToggle.propTypes = {
  isInvalid: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  labelProps: PropTypes.object,
};

const CustomMenu = forwardRef(({ children, ...props }, ref) => {
  return (
    <CustomMenuStyled ref={ref} {...props}>
      {children}
    </CustomMenuStyled>
  );
});

CustomMenu.displayName = "CustomMenu";
CustomMenu.propTypes = {
  children: PropTypes.node,
};

const InputSelect = ({
  options,
  outline = false,
  extraItem = null,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [field, meta] = useField(rest.name);
  const [value, setValue] = useState("");
  const formik = useFormikContext();
  const [allCollection, setAllCollection] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    let label = "";
    for (let option of options) {
      if (option.value == meta.value) {
        label = option.label;
        break;
      }
    }
    setValue(label);
  }, [meta.value, options]);

  const toggleDropdown = (isOpen) => {
    setOpen(isOpen);
  };

  const onSelectHandler = (value) => {
    if (!meta.touched) {
      formik.setFieldTouched(field.name);
    }
    if (value) formik && formik.setFieldValue(field.name, value);
  };

  return (
    <>
      <Dropdown
        show={open}
        onToggle={toggleDropdown}
        onSelect={onSelectHandler}
        drop="down"
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="custom-toggle"
          outline={outline}
          isInvalid={meta.touched && meta.error}
          value={value}
          error={meta.error}
          {...rest}
        />
        <Dropdown.Menu
          popperConfig={popperConfig}
          $data={options}
          as={CustomMenu}
        >
          <MenuItemList>
            <div className="menu-wrapper">
              {options?.map((el) => (
                <Dropdown.Item
                  key={el.value}
                  disabled={el?.isFreezed === true ? "disabled" : ""}
                  as="div"
                  eventKey={el.value}
                >
                  <SubText>{el.label}</SubText>
                </Dropdown.Item>
              ))}
              {extraItem}
            </div>
          </MenuItemList>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

InputSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  formik: PropTypes.any,
  outline: PropTypes?.string,
  extraItem: PropTypes.node,
};

export default InputSelect;
