export const formatDateTime = ({
  locale: _locale = navigator?.language,
  date,
  timeFormatOptions,
  dateFormatOptions,
  dateTimeFormatOptions,
  commonFormatOptions,
}) => {
  let dateObj = new Date();
  const isValidDate = Date.parse(date);
 
  if (isValidDate) {
    dateObj = new Date(date);
  }
  const { timeZone } = Intl.DateTimeFormat(_locale).resolvedOptions();
 
  const GMTDate = new Date(dateObj.toUTCString());

  function getFormatedTime(date) {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const _date = date.getDate();
      const time = dateObj.toUTCString().split(" ");
      return `${_date}/${month+1}/${year} ${time[4]} ${time[5]}`;
    } else {
      return "";
    }
  }

  const formated = [
    dateObj.toLocaleDateString(_locale, {
      ...commonFormatOptions,
      ...dateFormatOptions,
    }),
    dateObj.toLocaleTimeString(_locale, {
      timeZone,
      ...commonFormatOptions,
      ...timeFormatOptions,
    }),
    dateObj.toLocaleString(_locale, {
      ...commonFormatOptions,
      ...dateTimeFormatOptions,
    }),
    getFormatedTime(GMTDate),
  ];

  return formated;
};

export const useLocaleDateTime = ({ date, ...rest } = {}) => {
  return (_date) => formatDateTime({ date: _date ?? date, ...rest });
};
