import styled, { css } from 'styled-components';
import { ColorLuminance } from '../../common/helpers/styleHelpers';

export const StyledButton = styled.button`
  color: ${({ theme, color = 'primary', transparent }) =>
    `${
      transparent || color === 'secondary'
        ? theme.palette.text.primary
        : theme.palette.text.dark
    }`};
  background-color: ${({ theme, transparent = false, color = 'primary' }) =>
    `${theme.palette[color]?.main}${transparent ? '11' : ''}`};

  border: none;
  ${(props) =>
    props.outline &&
    css`
      background-color: transparent;
      border-color: ${props.theme.palette.common.border.light};
      color: ${props.theme.palette.text.primary};
    `}
  ${(props) =>
    props.round &&
    css`
      border-radius: 2.5rem;
    `}
    border-radius: 12px;
  width: ${(props) => (props.$width ? props.$width : 'max-content')};
  padding: 1rem 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
 
  font-size: ${({ theme, $fontSize }) =>
    $fontSize ? $fontSize : theme.typography.text1};

  .spinner {
    border-width: 0.2rem;
    margin-right: 0.8rem;
  }

  &:hover {
    background-color: ${({ theme, color = 'primary' }) =>
      `${ColorLuminance(theme.palette[color]?.main, 0.1)}`};

    border-color: ${(props) => props.theme.palette.common.border.dark};
  }

  &:focus {
    box-shadow: none;
    background-color: ${(props) =>
      props.theme.palette[props.color || 'primary'].main};
    border-color: transparent;
    ${(props) =>
      props.outline &&
      css`
        background-color: ${props.theme.palette.secondary.light};
        border-color: ${props.theme.palette.common.border.light};
      `}
  }

  &.btn:not(:disabled):active,
  &:not(:hover):focus:active,
  &:not(:disabled):focus:hover:active {
    background-color: ${(props) => props.theme.palette.primary.main};
    border-color: transparent;
  }

  &:not(:disabled):focus:active:hover,
  &.btn:not(:disabled):focus:active {
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.palette.common.light};
  }
  &:focus-visible {
    outline: none;
  }

  &.success {
    background-color: ${(props) => props.theme.palette.common.success};
  }

  &.error {
    background-color: ${(props) => props.theme.palette.common.error};
  }
`;
