import React, { useState, useEffect, useRef } from "react";
import {
  CreateCollectionStyled,
  SubText,
  HeadingText,
  Heading,
  Text,
  CustomCard,
} from "../ApproveAssets/style";
import getAxiosInst from "../../services/axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MainTopSection from "../../Components/MainTopSection";
import ApproveAsssetsCard from "./ApproveAssetsCard";
import { Row } from "react-bootstrap";
toast.configure();
function ApproveAsssetsCreator() {
  const [allCollection, setAllCollection] = useState();
  const [approveAllLoader, setApproveAllLoader] = useState(false);
    const [updatedApiData, setUpdatedApiData] = useState(false);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.global);
  const user = useSelector((state) => state.user);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const { web3Instance } = globalState;

  const getUnapprovedCreators = async () => {
    const blockchain = localStorage.getItem("blockchain");

    getAxiosInst({ withAuth: true })
      .get(`/assets/unapproved?network=${blockchain}`)
      .then((data) => {
        setAllCollection(data?.data);
        setUpdatedApiData(false)
      })
      .catch((err) => console.log(err));
  };



  useEffect(() => {
    getUnapprovedCreators();
  }, [updatedApiData]);

  return (
    <>
    <MainTopSection
      backButton={false}
      headingText="Assets Pending For Approval"
      descriptionText={""}
      routePath="/"
    />
    <CreateCollectionStyled>
      {allCollection?.length > 0 ? (
        <Row>
          {allCollection?.map((items, index) => {
            return (
              <ApproveAsssetsCard
                key={index}
                items={items}
                assetTitle={items?.name}
                username={items?.creator_id?.username}
                image_url={items?.metadata_fields?.image_url}
                profile_image_url={items?.creator_id?.profile_image_url}
                assetId ={items?._id}
                approveAllLoader={approveAllLoader}
                setApproveAllLoader={setApproveAllLoader}
                setUpdatedApiData={setUpdatedApiData}
                updatedApiData={updatedApiData}
                status={"PENDING"}
              />
            );
          })}
   
        </Row>
      ) : (
        <div className="text-center mt-3 mb-3">
          <Text>
            <HeadingText>No items found</HeadingText>
          </Text>
        </div>
      )}
    </CreateCollectionStyled>
    </>
  );
}

export default ApproveAsssetsCreator;
