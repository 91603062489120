import getAxiosInst from "../../services/axios";
import * as actionTypes from "../../constants/actionTypes";

export const getSearchData = (query) => {
  const blockchain = localStorage.getItem("blockchain");
  return (dispatch) => {
    getAxiosInst({ withAuth: true })
      .get(`/search?q=${query}&network=${blockchain}`)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_SEARCH_DATA,
          assets: data?.data?.assets,
          users: data?.data?.users,
        });
      })
      .catch((err) => console.log(err));
  };
};
