import getAxiosInst from "./axios";

export const getAllUsers = () => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(`/users?network=${blockchain}`);
};

export const getUser = (userId) => {
  if (userId) {
    const blockchain = localStorage.getItem("blockchain");

    return getAxiosInst({ withAuth: false }).get(
      `/users/${userId}?network=${blockchain}`
    );
  }
};

export const updateUser = (userId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/users/${userId}?network=${blockchain}`,
    reqData
  );
};

export const getOwnedAssets = (userId, filters) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/users/${userId}/owned-assets?limit=100&network=${blockchain}`,
    {
      params: {
        contract_address: filters?.ownedContractAddress,
        q: filters?.ownedQ,
      },
    }
  );
};

export const getCreatedAssets = (userId, filters) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/users/${userId}/created-assets?limit=100&network=${blockchain}`,
    {
      params: {
        contract_address: filters?.createdContractAddress,
        q: filters?.createdQ,
      },
    }
  );
};

export const getOnSaleAssets = (userId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/users/${userId}/on-sale-assets?network=${blockchain}`
  );
};

export const getAllCreators = (limit) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/users/creators/all?limit=${limit}&network=${blockchain}`
  );
};

export const blockUser = (id) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/users/creators/${id}/toggle-block?network=${blockchain}`
  );
};
