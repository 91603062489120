import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultConfig from "../../../configuration/config";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  toggleWalletModal,
  updateProvider,
  setChainId,
  setError,
} from "../../../store/actions/globalActions";
import {
  getSignInMsg,
  checkIfLoginAndUpdateState,
  logout,
  setLoginLoading,
} from "../../../store/actions/loginActions";
import WethioLogo from "../../../assets/icons/wethioftLogo";
import { CURRENCY_SYMBOLS, WALLET_TYPE } from "../../../constants/appConstants";
import { createAndSwitchProvider } from "../../../common/helpers/providerHelper";
import { createContractInstance, getChainId } from "../../../common/common";
import { ROUTES } from "../../../constants/routes";

import { embedMessageString } from "../../../common/helpers/helper";
import { WALLET_MESSAGE } from "../../../constants/messages";
import { useNavigate } from "react-router";
import ConnectButton from "./../components/ConnectButton";
import {
  Logo,
  NavLinkStyled,
  CustomDropDownStyled,
  AccountDiv,
  HeadingSubText,
  TextContent,
} from "../style";
import SearchField from "../../SearchField";
// import THREASURY_ABI from "../../../abi/THREASURY_ABI.json";
import config from "../../../configuration/config";

import {
  Navbar,
  Nav,
  Button,
  Dropdown,
  Container,
  Offcanvas,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { DrawerWrapper } from "../style";
import { Autocomplete, TextField } from "@mui/material";
import {
  setActiveConfig,
  setBlockchain,
} from "../../../store/actions/configAction";
import SearchBox from "../../SearchBox/SearchBox";
import getAxiosInst from "../../../services/axios.js";

const Drawer = ({ show, closeDrawer }) => {
  const [wallets] = useState([
    {
      walletType: WALLET_TYPE.metamask,
    },
  ]);
  const [modalLoading, setModalLoading] = useState(wallets.map(() => false));
  const loginState = useSelector((state) => state.login);
  const { isLogin, requestStatus } = loginState;
  const globalState = useSelector((store) => store.global);
  const options = useSelector((state) => state.search.data);

  const userState = useSelector((store) => store.user);
  const [toggleNavBar, setToggleNavBar] = useState(false); // show and hide navbar mobile
  const [isAdminUser, setIsAdminUser] = useState();
  const isCreator = useSelector((state) => state.global.isCreator);
  const balance = globalState["balance"];
  const { account } = globalState;
  const [inputValue, setInputValue] = useState("");
  const currentBlockchain = useSelector((state) => state.global.blockchain);
  const [block, setBlock] = useState(currentBlockchain);
  const [creatorApprovalStatus, setCreatorApprovalStatus] = useState('');
  const isBlocked = useSelector((state) => state.global.isBlocked);
  const { web3Instance, hasMetamask, currentChainId } = globalState;
  const { userData } = userState;
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  // const balance = globalState["balance"];
  useEffect(() => {
    setBlock(currentBlockchain);
  }, [currentBlockchain]);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleChangeBlockchain = (e) => {
    localStorage.setItem("blockchain", e?.target?.value);
    setBlock(e.target.value);
    const configKey = e?.target?.value === "eth" ? "ethConfig" : "wethConfig";
    dispatch(setActiveConfig(config[configKey]));
    dispatch(setBlockchain(e?.target?.value));
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(logout(navigate));
    }
  };

  const back = () => {
    navigate("/");
  };
  // const OpenAssetPage = () => {
  //   if (isAdminUser) {
  //     navigate("/createCollection");
  //   }
  // };

  const id = useSelector(state=>state?.user?.userData?._id);

  useEffect(() => {
    const blockchain = localStorage.getItem("blockchain");
    if (id)
 {
      getAxiosInst({ withAuth: true })
        .get(
          `/users/creators/${id}?network=${blockchain}`
        )
        .then((data) => {
       
          setCreatorApprovalStatus(data?.data?.creatorApprovalStatus)
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [id]);
  const OpenAssetPage = () => {
    if (isAdminUser) {
      navigate("/collectionSteps?type=image");
    } else if (isBlocked) {
      navigate("/userProfile", {
        state: { msg: "You are blocked by admin" },
      });
    } else if (isCreator) {
      navigate("/collectionSteps?type=image");
    } else if(creatorApprovalStatus ==='REJECTED'){
      navigate("/userProfile", {
        state: { msg: "You are rejected by admin" },
      });

    }else if (
      (!isCreator && userData?.instagramUsername) ||
      userData?.twitterUsername
    ) {
      navigate("/userProfile", {
        state: { msg: "Please wait for approval from admin" },
      });
    } else if (
      !isCreator &&
      (!userData?.instagramUsername || !userData?.twitterUsername)
    ) {
      navigate("/userProfile", {
        state: { msg: "Please complete your profile" },
      });
    }
  };
  const importAssetPage = () => {
    navigate("/importCollection");
  };
  const toggleWalletList = useCallback(
    () => dispatch(toggleWalletModal()),
    [dispatch]
  );

  const logoutHandler = () => dispatch(logout(navigate));

  const toggleModalLoading = (...index) =>
    setModalLoading((prevState) =>
      prevState.map((el, i) => (index.includes(i) ? !el : el))
    );
  // const toggleNavbarHandler = () => {
  //   setToggleNavBar((prevState) => !prevState);
  //   document.querySelector("body").style = `${
  //     toggleNavBar ? "" : "overflow:hidden"
  //   }`;
  // };

  // const onWalletClickHandler = async (walletType, i) => {
  //   createAndSwitchProvider({
  //     walletType,
  //     web3Instance,
  //     dispatch,
  //     updateProvider: updateProvider,
  //   });
  //   if (walletType === WALLET_TYPE.metamask) {
  //     if (hasMetamask) {
  //       const curChainId = await getChainId(web3Instance);
  //       dispatch(setChainId(curChainId));
  //       if (curChainId === defaultConfig?.chainInfo.chainId) {
  //         toggleModalLoading(i);
  //         dispatch(getSignInMsg(walletType));
  //       } else
  //         dispatch(
  //           setError(
  //             { code: 10 },
  //             {
  //               errorTitle: "Wrong Network",
  //               errorMsg: embedMessageString(
  //                 WALLET_MESSAGE.wrongNetwork.message,
  //                 defaultConfig?.chainInfo?.network
  //               ),
  //             }
  //           )
  //         );
  //     } else {
  //       dispatch(
  //         setError(
  //           { code: 0 },
  //           {
  //             errorTitle: "Install Metamask",
  //             errorMsg: WALLET_MESSAGE.noWallet.message,
  //             errorBtnLabel: "Go to MetaMask's website",
  //           }
  //         )
  //       );
  //     }
  //   }
  //   toggleWalletList();
  // };

  const checkUserIsAdmin = async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  };

  const onConnectClikHandler = () => {
    if (requestStatus) {
      dispatch(setLoginLoading(true));
      return;
    }
    if (userData) logoutHandler();
    else toggleWalletList();
  };

  // useEffect(() => {
  //   dispatch(checkIfLoginAndUpdateState());
  // }, [dispatch]);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {account}
    </Tooltip>
  );
  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account]);

  return (
    <DrawerWrapper $show={show}>
      <Navbar bg="transparent" variant="dark" expand={false}>
        <Container fluid>
          <Logo to="/" className="logo-text" onClick={back}>
            <WethioLogo />
          </Logo>
          {/* <Navbar.Brand href="#home" className="logo-text" onClick={back}>
            <h1>Wethio </h1>NFT
          </Navbar.Brand> */}
          <Navbar.Toggle
            className="toggle-btn"
            style={{ color: "#ffffff" }}
            aria-controls="offcanvasNavbar"
          />
          <Navbar.Offcanvas
            style={{ background: "rgb(37, 37, 37)" }}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                style={{ color: "#ffffff" }}
                id="offcanvasNavbarLabel"
              >
                Wethio <span className="font-unifont">NFT</span>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="me-auto align-items-center w-100">
                {/* <SearchField
                  style={{ marginBottom: "1rem", marginLeft: "0" }}
                /> */}
                <SearchBox />
                {/* <Autocomplete
                  inputValue={inputValue}
                  onInputChange={(e) =>
                    e?.target?.value
                      ? setInputValue(e?.target?.value)
                      : setInputValue("")
                  }
                  id="combo-box-demo"
                  options={options}
                  size="small"
                  sx={{ minWidth: "200px" }}
                  onChange={(e, value) => {
                    value && navigate(value?.url);
                  }}
                  fullWidth
                  // clearOnEscape={true}
                  getOptionLabel={(option) => option?.name}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search" variant="outlined" />
                  )}
                  open={inputValue?.length > 1}
                /> */}
                <NavLinkStyled
                  className="nav-link text-center p-2"
                  to="/marketPlacePage"
                  onClick={closeDrawer}
                >
                  <h4>Marketplace</h4>
                </NavLinkStyled>
                <NavLinkStyled
                  className="nav-link text-center p-2"
                  to="/creators"
                  onClick={closeDrawer}
                >
                  <h4>Creators</h4>
                </NavLinkStyled>
                <NavLinkStyled
                  exact="true"
                  to={ROUTES.allCollection}
                  className="nav-link text-center p-2"
                  onClick={closeDrawer}
                >
                  <h4>Assets</h4>
                </NavLinkStyled>

                {isAdminUser && isLogin ? (
                  <NavLinkStyled
                    exact="true"
                    to={ROUTES.approveAssets}
                    className="nav-link text-center p-2"
                    onClick={closeDrawer}
                  >
                    <h4>Approve Auction</h4>
                  </NavLinkStyled>
                ) : null}

                {isAdminUser && isLogin ? (
                  <NavLinkStyled
                    exact="true"
                    to={ROUTES.approveAssetsCreator}
                    className="nav-link text-center p-2"
                    onClick={closeDrawer}
                  >
                    <h4>Approve Assets</h4>
                  </NavLinkStyled>
                ) : null}
                {isAdminUser && isLogin ? (
                  <NavLinkStyled
                    exact="true"
                    to={ROUTES.approveCreators}
                    className="nav-link text-center p-2"
                    onClick={closeDrawer}
                  >
                    <h4>Approve Creators</h4>
                  </NavLinkStyled>
                ) : null}

                {/* {isLogin ? ( */}
                <CustomDropDownStyled>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Wethio Gold
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate(ROUTES.zynecoin)}>
                        Wethio Gold ZYN
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(ROUTES.zynecoin18k)}
                      >
                        Wethio Gold 18K
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => navigate(ROUTES.bitcoin)}>
                        Wethio Gold BTC
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </CustomDropDownStyled>
                {/* // ) : null} */}

                <Form.Select
                  onChange={(e) => {
                    handleChangeBlockchain(e);
                  }}
                  aria-label="Default select example"
                  className="mx-1"
                  value={block}
                >
                  <option value="eth">Ethereum</option>
                  <option value="weth">Wethio</option>
                </Form.Select>
                {isLogin ? (
                  <div className="ml3 p-2">
                    {/* <CreateButton
                        variant="outline-dark"
                        onClick={OpenAssetPage}
                      >
                        Create
                      </CreateButton> */}
                    <CustomDropDownStyled>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Create
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {isLogin ? (
                            <Dropdown.Item onClick={OpenAssetPage}>
                              Create Asset
                            </Dropdown.Item>
                          ) : null}
                          <Dropdown.Item onClick={importAssetPage}>
                            Import Asset
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </CustomDropDownStyled>
                  </div>
                ) : null}
                {isLogin ? (
                  <AccountDiv>
                    <HeadingSubText>
                      {balance.eth}
                      {defaultConfig?.chainInfo?.name === "wethio"
                        ? CURRENCY_SYMBOLS.wrappedEther
                        : CURRENCY_SYMBOLS.ether}
                    </HeadingSubText>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 300 }}
                      overlay={renderTooltip}
                    >
                      <TextContent
                        onClick={() => {
                          navigator.clipboard.writeText(account);
                          toast("Account address copied successfully");
                        }}
                      >
                        {account}
                      </TextContent>
                    </OverlayTrigger>
                  </AccountDiv>
                ) : null}
                <div className="text-end p-2">
                  <div
                    // variant="light"
                    className="AvtarButton "
                    onClick={userData ? undefined : toggleWalletList}
                  >
                    {isLogin ? (
                      <ConnectButton
                        isLogin={isLogin}
                        toggleWalletList={toggleWalletList}
                        currentChainId={currentChainId}
                      />
                    ) : (
                      <Button variant="dark" className="ConnectButon">
                        Connect
                      </Button>
                    )}
                  </div>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </DrawerWrapper>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool,
  closeDrawer: PropTypes.func,
};

export default Drawer;
