import { OverlayTrigger, Popover } from "react-bootstrap";
import SingleNotification from "./SingleNotification";

const NotificationsBox = ({ children }) => {
  return (
    <>
      {["bottom"].map((placement) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          key={placement}
          placement={placement}
          overlay={
            <Popover
              style={{ minWidth: "250px" }}
              id={`popover-positioned-${placement}`}
            >
              <Popover.Header as="h3">Notifications</Popover.Header>
              <Popover.Body
                style={{
                  padding: "5px",
                  height: "200px",
                  overflowY: "scroll",
                }}
              >
                <SingleNotification title={"Hey"} description={"Nice"} />
                <SingleNotification title={"Hey"} description={"Nice"} />
                <SingleNotification title={"Hey"} description={"Nice"} />
                <SingleNotification title={"Hey"} description={"Nice"} />
                <SingleNotification title={"Hey"} description={"Nice"} />
                <SingleNotification title={"Hey"} description={"Nice"} />
                <SingleNotification title={"Hey"} description={"Nice"} />
              </Popover.Body>
            </Popover>
          }
        >
          {children}
        </OverlayTrigger>
      ))}
    </>
  );
};

export default NotificationsBox;
