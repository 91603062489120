import React from 'react';

const TransferIcon = ({ fillColor = '#fff',onClick }) => {
  return (
<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.757 5.284 10.954.32C10.446-.114 9.645.24 9.645.914v2.614C4.35 3.588.15 4.64.15 9.611c0 2.007 1.305 3.995 2.748 5.034.45.324 1.091-.083.925-.608-1.495-4.736.71-5.994 5.823-6.067v2.872c0 .676.801 1.027 1.309.593l5.803-4.964a.78.78 0 0 0 0-1.187z" fill="#F9FAFC"/>
</svg>
  );
};

export default  TransferIcon;
