import React from 'react';
import PropTypes from 'prop-types';

const EtherScanLogo = ({ fillColor = '#fff', ...props }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.81919 13.3313C5.81916 13.175 5.85002 13.0201 5.90998 12.8757C5.96995 12.7313 6.05785 12.6002 6.16862 12.4899C6.2794 12.3796 6.41088 12.2922 6.55549 12.2329C6.70011 12.1735 6.85503 12.1434 7.01134 12.1441L8.9878 12.1506C9.30294 12.1506 9.60518 12.2758 9.82801 12.4987C10.0509 12.7216 10.176 13.024 10.176 13.3392V20.8156C10.3987 20.7496 10.6842 20.6793 10.9971 20.6059C11.2142 20.5547 11.4078 20.4317 11.5464 20.2567C11.6849 20.0818 11.7603 19.8651 11.7604 19.6419V10.3681C11.7604 10.212 11.7911 10.0574 11.8508 9.91315C11.9105 9.7689 11.9981 9.63784 12.1084 9.52743C12.2188 9.41703 12.3498 9.32945 12.4939 9.26969C12.6381 9.20993 12.7927 9.17917 12.9487 9.17916H14.9291C15.2442 9.17921 15.5464 9.30446 15.7693 9.52737C15.9921 9.75027 16.1173 10.0526 16.1173 10.3678V18.9755C16.1173 18.9755 16.613 18.7747 17.0961 18.5707C17.2755 18.4948 17.4286 18.3677 17.5363 18.2053C17.6439 18.043 17.7014 17.8525 17.7016 17.6576V7.39592C17.7016 7.23983 17.7323 7.08526 17.792 6.94105C17.8517 6.79683 17.9392 6.6658 18.0496 6.55542C18.1599 6.44504 18.2909 6.35748 18.4351 6.29775C18.5792 6.23801 18.7337 6.20726 18.8898 6.20726H20.8701C21.1853 6.20726 21.4875 6.33249 21.7104 6.55541C21.9332 6.77832 22.0584 7.08066 22.0585 7.39592V15.8459C23.7754 14.6011 25.5155 13.1039 26.8964 11.3037C27.0967 11.0424 27.2293 10.7356 27.2822 10.4105C27.3352 10.0855 27.3069 9.75241 27.1999 9.44098C26.2656 6.71709 24.513 4.34838 22.1819 2.65872C19.8507 0.969069 17.0547 0.040949 14.1762 0.00126922C6.41421 -0.103038 -0.000695325 6.23539 6.71635e-05 14.0011C-0.0075542 16.4585 0.633576 18.8742 1.85863 21.0042C2.02758 21.2955 2.27612 21.5326 2.57507 21.6875C2.87402 21.8424 3.21096 21.9088 3.5463 21.8788C3.92097 21.8458 4.38742 21.7992 4.94194 21.7341C5.1833 21.7066 5.40613 21.5913 5.56798 21.4101C5.72984 21.2289 5.81942 20.9944 5.81966 20.7514V13.3313"
          fill={fillColor}
        />
        <path
          d="M5.77353 25.3224C7.86103 26.8416 10.3279 27.7534 12.9013 27.9571C15.4747 28.1608 18.0543 27.6484 20.3546 26.4765C22.6549 25.3047 24.5864 23.5191 25.9352 21.3173C27.2841 19.1156 27.9978 16.5834 27.9974 14.0011C27.9974 13.6787 27.9824 13.36 27.9609 13.043C22.8482 20.6706 13.4082 24.2365 5.77344 25.3227"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

EtherScanLogo.propTypes = {
  fillColor: PropTypes.string,
};

export default EtherScanLogo;
