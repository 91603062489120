import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiHandler } from "../../services/axios";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import PlaceBidStepOne from "./PlaceBidStepOne";
import PlaceBidStepTwo from "./PlaceBidStepTwo";
import PlaceBidStepThree from "./PlaceBidStepThree";
import { RouteStyled } from "./style";
import { getAuction } from "../../services/auctionServices";

function PlaceBidRoute() {
  const [isMinting, setIsMinted] = useState(false);
  const [auctionDetails, setAuctionDetails] = useState();
  const storecollectionId = useSelector((store) => store.assetsCollectionId);

  const { auctionId } = useParams();

  useEffect(() => {
    if (auctionId) {
      apiHandler(() => getAuction(auctionId), {
        onSuccess: (data) => {
          setAuctionDetails(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [auctionId]);

  return (
    <RouteStyled>
      <Row>
        <Col md={2} sm={12} xs={12}></Col>
        <Col md={10} sm={10} xs={10}>
          <Routes>
            <Route
              path="/:auctionId"
              element={<PlaceBidStepOne auctionDetails={auctionDetails} />}
            />
            <Route
              path="/:auctionId/loading"
              element={
                <PlaceBidStepTwo
                  isMinting={isMinting}
                  collectionStateId={storecollectionId.id}
                  auctionDetails={auctionDetails}
                />
              }
            />
            <Route
              path="/:auctionId/success"
              element={
                <PlaceBidStepThree
                  isMinting={isMinting}
                  collectionStateId={storecollectionId.id}
                  auctionDetails={auctionDetails}
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </RouteStyled>
  );
}

export default PlaceBidRoute;
