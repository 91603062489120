import styled, { css } from "styled-components";
import { Button } from "../UserProfile/style";
import { Dropdown, Form } from "react-bootstrap";
import { deviceQuery, size } from "../../styles/mediaSizes";
import { NavLink, Link } from "react-router-dom";
import { poppinsBold, poppinsLight } from "../../styles/mixins";

export const WalletAddress = styled.h4`
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const AccountDiv = styled.div`
  align-items: center;
  margin-left: 4px;
  P {
    font-size: 12px;
    max-width: 61px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const UserName = styled.p`
  ${poppinsBold};
  font-size: 1.7rem;
  /* color: #080b35 !important; */
`;
export const Option = styled.div`
  ${poppinsLight};
  color: ${(p) => p.theme.palette.secondary.main};
  font-size: 1.3rem;
`;
export const MenuLogo = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 20px;
`;
export const DropdownItem = styled(Dropdown.Item)`
  margin: 1rem 0 !important;
`;
export const Logo = styled(Link)`
  text-decoration: none;
  margin-left: 0px !important;
  width: 40%;
  img {
    width: 160px;
  }
  span {
    font-family: Unifont;
  }
`;
export const AddressInDropdown = styled.div`
  .walletAddress {
    max-width: 128px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${poppinsLight};
    font-size: 1.3rem;
    color: #080b35 !important;
  }

  img {
    width: 100%;
    object-fit: contain;
    max-width: 22px;

    &:hover {
      filter: drop-shadow(2px 2px 2px #333);
    }
  }
`;

export const DrawerWrapper = styled.div`
  display: ${(props) => (props.$show ? "flex" : "none")};
  background: #252525;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  .toggle-btn > span {
    color: #ffffff;
  }

  overflow-y: auto;
  flex-direction: column;
  padding: 15px 1rem 1rem;
  &.offcanvas-header .btn-close {
    color: white !important;
  }
  .nav-bar {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    .nav-link {
      font-size: ${(props) => props.theme.typography.subTitle1};
      font-weight: 400;
      line-height: 2.2rem;
      text-transform: uppercase;
      color: #ffffff !important;
      width: max-content;
      &:not(:last-child) {
        margin-bottom: 4rem;
      }
    }
  }

  @media ${deviceQuery.laptopM} {
    display: block !important;
  }
  .drawer-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    .nav {
      .nav-link {
        font-size: ${(props) => props.theme.typography.subText4};
        font-weight: 400;
        line-height: 2.4rem;
        color: #ffffff !important;
        opacity: 0.5;
      }
    }
    .tnc {
      font-size: ${(props) => props.theme.typography.subText4};
      font-weight: 400;
      line-height: 2.4rem;
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 0.5;
      margin-top: 1rem;
    }
  }
`;
export const WalletListStyled = styled.ul`
  list-style: none;
  margin-top: 32px;
  margin-bottom: 26px;

  li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .wallet-list-item {
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    border-radius: 21px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #252525;
    padding: 35px 20px;
    max-width: 100%;
    min-height: 54px;
    border: none;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: ${(props) => props.theme.palette.primary.main + "22"};
    }

    .iconWrap {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      .wallet-img {
        flex-shrink: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .wallet-name {
        flex-grow: 1;
        font-weight: 800;
        padding: 0 15px;
        font-size: 18px;
        line-height: 112.1%;
        span {
          width: 100%;
        }
      }
    }
  }
`;
export const StyledConnectingButton = styled.div`
  ${(props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props.theme.typography.subText4};
    pointer-events: none;
    text-transform: uppercase;

    p {
      margin: 0;
    }

    .loader-wrap {
      width: 4.4rem;
      height: 4.4rem;
      margin-left: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${props.theme.palette.primary.main};
      border-radius: 50%;
    }
  `}
`;
export const CustomDropDownStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  transition: all 0.3s linear;
  color: ${(props) => props.theme.palette.text.primary} !important;
  cursor: pointer;
  .dropdown-menu {
    /* margin-left: 0px; */
    border-radius: 22px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

    padding: 0;
    border: none;
  }
  .dropdown-menu > .dropdown-item:first-child:hover {
    border-radius: 22px 22px 0 0;
  }
  .dropdown-menu > .dropdown-item:last-child:hover {
    border-radius: 0px 0px 22px 22px;
  }
  .btn-success:focus,
  .btn-success:hover {
    border: none;
    color: ${(props) => props.theme.palette.text.primary} !important;
    box-shadow: none;
    color: #000051 !important;
    background-color: ${(props) => props.theme.palette.white.main};
  }

  .dropdown-item:hover {
    font-weight: 500;
    color: ${(props) => props.theme.palette.newTheme.headerText} !important;
    background-color: ${(props) => props.theme.palette.newTheme.mainGray};
  }
  .dropdown-item:active {
    font-weight: 500;
    color: ${(props) => props.theme.palette.newTheme.headerText} !important;
    background-color: ${(props) => props.theme.palette.newTheme.mainGray};
  }
  .dropdown-item {
    font-weight: 500;
    color: ${(props) => props.theme.palette.newTheme.headerText} !important;
    // border-radius: 22px;
    padding: 0.8rem 1rem;
  }
  button,
  .dropdown-toggle {
    font-weight: 500;
    color: #ffffff !important;
    padding: 8px;
    background-color: #252525 !important;
    border: none;
    border-radius: 8px;
  }
  &.active {
    font-weight: 700;
    color: #000051 !important;
    background-color: ${(props) => props.theme.palette.white.main} !important;
  }

  &:hover {
    font-weight: 700;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .btn-success:active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none !important;
  }
`;
export const DropdownToggleStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  transition: all 0.3s linear;
  cursor: pointer;

  &::after {
    content: none;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;

    .balance {
      font-size: ${(props) => props.theme.typography.text1};
      font-weight: 600;
      text-overflow: ellipsis;
      max-width: 9rem;
      overflow: hidden;
      margin-left: 18px;
      &.error-net {
        font-size: ${(props) => props.theme.typography.subText4};
      }
    }

    .account-number {
      max-width: 9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${(props) => props.theme.typography.subText1};
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  .user-profile {
    position: relative;
    overflow: visible;
    flex-shrink: 0;
    /* margin-bottom: 1rem; */
    img {
      border-radius: 24%;
    }
  }
`;
export const CreateButton = styled(Button)`
  padding: 8px 37px;
  background-color: #feefa4;
  color: #000;
`;
export const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0 1.5rem;
  &:focus:active:hover {
    background-color: transparent !important;
  }
  &:hover,
  &:focus {
    background-color: transparent;
  }
  .connect-wallet-icon {
  }
  & > span {
    white-space: nowrap;
    display: inline-block;
    margin-right: 1.6rem;

    .label {
      font-size: ${(props) => props.theme.typography.subText4};
    }
  }
`;
export const DropdownMenuStyled = styled(Dropdown.Menu)`
  border-radius: 22px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  padding: 0rem 1rem;
  border: none;
  min-width: 210px;
  //  left:-22px !important;
  /* right: 30%; */
  transform: translateX(-75%);
  top: 110%;

  .dropdown-item {
    padding: 0;
    margin: 10px 0;
    font-size: 16px;
    cursor: pointer;
    cursor: pointer;

    &.notification-item {
      display: none;
    }

    &:first-child {
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        font-weight: 500;
        font-size: ${(props) => props.theme.typography.text2};
        line-height: 2.5rem;
        margin-left: 18px;
      }
    }

    &.user-info-item {
      border-bottom: none !important;
      margin-bottom: 2rem;
      .user-info-mobile {
        max-width: 100%;
        .balance {
          font-weight: 800;
          line-height: 1.8rem;
          margin-bottom: 0.4rem;
        }
        .account-number {
          max-width: 100%;
          overflow: hidden;
          font-weight: 500;
          font-size: 18px;
          text-overflow: ellipsis;
          font-size: 16px;
          line-height: 1.6rem;
        }
      }
    }

    .dropdown-item-icon {
      width: 1.8rem;
      height: 1.8rem;
    }

    span {
      display: inline-block;
      transition: all 0.3s ease-in-out;
      font-size: ${(props) => props.theme.typography.subText4};
      font-weight: 500;
      margin-left: 1rem;
    }
    &:hover {
      background-color: transparent;
      /* span:not(:first-child) {
        transform: translateX(10px);
      } */
      .arrow-icon {
        transform: rotate(360deg);
      }
    }

    &:not(:last-child):not(:first-child) {
      /* border-bottom: 1px solid rgba(157, 157, 157, 0.1); */
    }

    .arrow-icon {
      width: 1.7rem;
      height: 1rem;
      margin-left: auto;
    }
  }

  @media ${deviceQuery.laptopS} {
    transform: translateX(0%);
  }
`;
export const HeaderStyled = styled.header`
  padding: 10px 0px;
  z-index: 100;
  background: ${(props) =>
    props.$showDrawer ? "#252525" : "#252525"} !important;
  /* border-bottom: 1px solid ${(props) =>
    props.theme.palette.common.border.light}; */
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  align-items: center;
  z-index: 1040;
  .container {
    align-items: center;
    justify-content: center;
  }
  p {
    /* color: #ffffff !important; */
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 2rem;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 16px;
    font-family: ${(p) => p.theme.ff.poppins};
  }
  .AvtarButton {
    box-shadow: none !important;
    margin: 0px 6px;
    border: none;
    background-color: #fff;
  }
  .logo-text {
    color: ${(props) => props.theme.palette.text.primary};
    font-weight: 300;
    font-size: 30px;
    display: flex;
    align-items: center;
  }
  .logo-text h1 {
    font-weight: 600 !important;
    font-size: 30px;
    margin-bottom: 0px;
    margin-right: 6px;
  }
  @media ${deviceQuery.laptopS} {
    padding: 10px 16px;
    height: 100px;
  }
  @media ${deviceQuery.laptopM} {
    .HeaderView {
      display: none;
    }
  }
  .col-logo {
    @media ${deviceQuery.tabletM} {
      flex-grow: 1;
    }

    .logoWrap {
      width: 20rem;
      .logo {
        width: 100%;
      }

      @media ${deviceQuery.tabletM} {
        margin: auto;
        width: 14.6rem;
        margin-left: ${(props) => (props.$showDrawer ? "0" : "auto")};
      }
    }
  }

  .connect-btn-container {
    ${(props) =>
      props.$showDrawer &&
      css`
        display: none !important;
      `}

    @media ${deviceQuery.tabletM} {
      margin-left: auto;
      padding-right: 0;
      padding-left: 0;

      & > button {
        padding: 0;
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    @media ${deviceQuery.tabletM} {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .navbarWrap {
    flex-grow: 1;
    padding: 0;
    @media ${deviceQuery.tabletM} {
      display: none;
    }
    .navbar {
      justify-content: center;
      padding: 0;
    }
  }
  .navbar {
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-grow: 1;
  }
`;
export const HeadingSubText = styled.h6`
  color: #ffffff;
`;
export const TextContent = styled.p`
  color: #ffffff;
`;
export const NavLinkStyled = styled(NavLink)`
  padding: 0px;
  font-size: ${(props) => props.theme.typography.subText4} !important;
  color: #ffffff !important;
  font-weight: 500;
  width: 100%;
  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.palette.text.tertiary} !important;
  }

  &:hover {
    color: ${(props) => props.theme.palette.text.tertiary};
  }
`;
export const FormSelect = styled(Form.Select)`
  // padding: 6px 5px !important;
  padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
  font-weight: 500;
  text-align: center;
  background: #252525;
  border: 1px solid ${(props) => props.theme.palette.newTheme.darkYellow};
  color: #ffffff !important;
  //     border-radius: 10px !important;
  &:focus {
    border: 1px solid ${(props) => props.theme.palette.newTheme.darkYellow};
    box-shadow: none;
  }
`;

export const CustomConnectButton = styled.div`
  padding: 0px 10px;
  font-weight: 700;
  align-items: center;
  /* border-radius: 7px; */
  font-size: 15px;
  background: linear-gradient(180deg, #ffb11f 0%, #ffc861 100%);
  /* border-radius: 8px; */
  border: none;
  display: flex;
  width: 200px;
  img {
    width: 20px;
    height: 20px;
  }
  button {
    padding: 8px 14px;
    font-weight: 700;
    font-size: 15px;
    background-color: ${(props) => props.theme.palette.newTheme.darkBlueButton};
    border: none;
  }
  button:hover,
  button:active,
  button:focus-visible {
    border: none;
    box-shadow: none;
    background-color: ${(props) =>
      props.theme.palette.newTheme.darkBlueButton} !important;
  }
`;

export const Balance = styled.p`
  font-size: 0.9rem;
  color: #080b35 !important;
`;
