import styled, { css } from 'styled-components';

export const StyledEtherScanLink = styled.span`
  & > .custom-link {
    font-size: ${(props) => props.theme.typography.subText4};
    color: ${(props) => props.theme.palette.text.secondary};
    line-height: 2.2rem;
    font-weight: 500;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${(props) =>
      props.$borderLess &&
      css`
        border: none;
      `}
    ${(props) =>
      props.$disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.8;
      `}
    &:hover,&:focus,&:active {
      background-color: transparent;
    }
  }

  .etherscan-wrapper {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
  .etherscan-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .externalLink-icon {
    width: 2rem;
    height: 2rem;
  }
`;
