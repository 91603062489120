import { isJwtExpired } from "jwt-check-expiration";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "./localStorage";
import {
  STORAGES,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_VIDEO_FORMATS,
  ASSET_TYPES,
  NETWORK,
} from "../../constants/appConstants";
import Web3 from "web3";

export const parseFormat = (data) => {
  const i = data.indexOf("data:") + 5;
  const l = data.indexOf(";");
  return data.substring(i, l);
};

export const parseBalance = (num, decimalPlace = 4) =>
  parseFloat((num / Math.pow(10, 18)).toFixed(decimalPlace));

export const convertEthToNumber = (num, decimalPlace = 4) => {
  num = Number(num);
  if (!num) return num;
  if (Math.abs(num) < 1.0) {
    var e = parseInt(num.toString().split("e-")[1]);
    if (e) {
      num *= Math.pow(10, e - 1);
      num = "0." + new Array(e).join("0") + num.toString().substring(2);
    }
  } else {
    var e = parseInt(num.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      num /= Math.pow(10, e);
      num += new Array(e + 1).join("0");
    }
  }
  return Web3.utils.fromWei(num.toString(), "ether");

  // const str = num.toString();
  // console.log(str.length, str);
  // if (str.length < 18) {
  //   const newStr = str.padStart(18, "0");
  //   const firstNonZeroFromLast = newStr
  //     .split("")
  //     .reverse()
  //     .findIndex((el) => Number(el) > 0);
  //   const updateStr = newStr.substring(0, 17 - firstNonZeroFromLast + 1);
  //   return "0.".concat(updateStr);
  // } else {
  //   return parseBalance(num, decimalPlace);
  // }
  // return ethers.utils.formatEther();
  // return Web3.utils.fromWei(num.toString(), "ether");
};

export const checkIfImgUrlVideoUrl = (
  str,
  supportedFormatTypes = ["image"]
) => {
  if (!str) return false;
  let supportedFormats = [];
  if (supportedFormatTypes.includes("image")) {
    supportedFormats = [
      ...supportedFormats,
      ...SUPPORTED_IMAGE_FORMATS.map((format) => format.split("/")[1]),
    ];
  }
  if (supportedFormatTypes.includes("video")) {
    supportedFormats = [
      ...supportedFormats,
      ...SUPPORTED_VIDEO_FORMATS.map((format) => format.split("/")[1]),
    ];
  }

  const regex = new RegExp(`[${supportedFormats.join("|")}]$`);

  return !!str.match(regex);
};

export const checkIfLogin = () => {
  const token = getLocalStorageItem(STORAGES.token);
  return !!token && !isJwtExpired(token);
};

export const setLocalStorageForLogout = () => {
  removeLocalStorageItem(STORAGES.token);
  removeLocalStorageItem(STORAGES.id);
  removeLocalStorageItem(STORAGES.walletType);
  localStorage.removeItem("account");
  localStorage.removeItem("isCreator");
  localStorage.removeItem("isAdmin");
};

export const setLocalStorageForLogin = (keyValues) => {
  keyValues.forEach(({ key, value }) => {
    setLocalStorageItem(key, value);
  });
};

export const checkIfCorrectChain = (connectedChainId, correctChainId) => {
  if (typeof correctChainId !== "number") return false;
  else return correctChainId === connectedChainId;
};

export const copyToClipboard = async ({ text = "", onSuccess, onError }) => {
  try {
    await navigator.clipboard.writeText(text);
    onSuccess && onSuccess();
  } catch (error) {
    console.error(error);
    onError && onError();
  }
};

export const checkIfBrave = async () =>
  (navigator.brave && (await navigator.brave.isBrave())) || false;

export const embedMessageString = (message, embedString = "") => {
  if (message) {
    const regex = new RegExp(/\|___\|/, "g");

    let resultantMessage = message;

    if (Array.isArray(embedString)) {
      const matches = [];
      let result;
      while ((result = regex.exec(message))) {
        matches.push(result);
      }

      matches.forEach((match, index) => {
        if (index < embedString.length) {
          resultantMessage = resultantMessage.replace(
            /\|___\|/,
            embedString[index]
          );
        }
      });
    } else {
      resultantMessage = resultantMessage.replace(regex, embedString);
    }

    return resultantMessage;
  } else {
    return "";
  }
};

export const getSupportedFileString = (type = ["image"]) => {
  let supportedString = [];

  if (type.includes(ASSET_TYPES.image)) {
    supportedString = [...supportedString, ...SUPPORTED_IMAGE_FORMATS];
  }
  if (type.includes(ASSET_TYPES.video)) {
    supportedString = [...supportedString, ...SUPPORTED_VIDEO_FORMATS];
  }
  return supportedString.join(",");
};

export const getEtherscanLink = (network, txHash) =>
  `https://rinkeby.etherscan.io/tx/${txHash}`;

export const getWethioscanLink = (network, txHash) =>
  `https://explorer.wethio.io/txs/${txHash}`;

export const filterNonEmptyAttributes = (attributes) =>
  attributes.filter((attribute) =>
    Object.values(attribute).every((val) => !!val)
  );

export const scrollTopTop = () => window.scrollTo(0, 0);

export const convertToEthAmount = (amount) => {
  if (Number.parseFloat(amount) < 1) {
    const convertedAmount = amount * Math.pow(10, 18);
    if (convertEthToNumber.toString().includes(".")) {
      // for error cases due to the floating point multiplication precision.
      return Math.floor(convertedAmount).toString();
    } else {
      return convertedAmount.toString();
    }
  } else {
    if (amount.includes(".")) {
      return `${amount.slice(0, amount.indexOf("."))}${amount
        .slice(amount.indexOf(".") + 1)
        .padEnd(18, "0")}`;
    } else {
      return amount.padEnd(amount.length + 18, "0");
    }
  }
};
export const convertToEthAmountBigNumberIssue = (amount) => {
  if (Number.parseFloat(amount) <= 1) {
    return (amount * Math.pow(10, 18)).toString();
  } else {
    return amount.toString().split(".").join("").padEnd(18, "0");
  }
};
export function range(from = 0, to = 0) {
  let array = [];
  for (let i = from; i <= to; i++) {
    array.push(i);
  }
  return array;
}
export const convertNumberUnit = (number, decimals, recursiveCall) => {
  const decimalPoints = decimals || 2;
  const noOfLakhs = number / 100000;
  let displayStr;
  let isPlural;

  // Rounds off digits to decimalPoints decimal places
  function roundOf(integer) {
    return +integer.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    });
  }

  if (noOfLakhs >= 1 && noOfLakhs <= 99) {
    const lakhs = roundOf(noOfLakhs);
    isPlural = lakhs > 1 && !recursiveCall;
    displayStr = `${lakhs} Lakh${isPlural ? "s" : ""}`;
  } else if (noOfLakhs >= 100) {
    const crores = roundOf(noOfLakhs / 100);
    const crorePrefix =
      crores >= 100000 ? convertNumberUnit(crores, decimals, true) : crores;
    isPlural = crores > 1 && !recursiveCall;
    displayStr = `${crorePrefix} Crore${isPlural ? "s" : ""}`;
  } else {
    // displayStr = roundOf(+number);
    const noOfthousand = number / 1000;
    if (noOfthousand >= 1 && noOfthousand <= 99) {
      const thousands = roundOf(noOfthousand);
      isPlural = thousands > 1 && !recursiveCall;
      displayStr = `${thousands}k`;
    } else {
      displayStr = number;
    }
  }

  return displayStr;
};
