import styled from "styled-components";
import {Card} from "react-bootstrap"
import {
  Container,

} from "react-bootstrap";



export const UserProfileStyled = styled.div`
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    color: #b5b6c7;
  }
`;
export const CardTitle=styled(Card.Title)`
color: ${(props) => props.theme.palette.newTheme.black};
margin:20px 0px !important;
`
export const CardText=styled(Card.Text)`
color: ${(props) => props.theme.palette.newTheme.text};
font-weight:400;
margin:20px 0px;
`
export const CardStyled=styled(Card.Title)`
margin:20px 10px !important;
box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
border-radius:12px;
padding:30px !important;
text-align:center;
width:400px;
cursor:pointer;
border:none;
`
export const CardLayout=styled.div`
display: flex;
    margin: 20px 10px;
    text-align: center;
    justify-content: center;
 img{
  width:130px;

 }
`

export const UserProfileContainer = styled(Container)`
  max-width: 800px !important;


`;


