import React, { useEffect } from 'react';

import { FormControl, FormLabel } from 'react-bootstrap';
import { useField } from 'formik';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import FormErrorMsg from '../FormErrorMsg/FormErrorMsg';

const InputWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Checkmark = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.width ? props.width : '2.6rem')};
  width: ${(props) => (props.width ? props.width : '2.6rem')};
  background-color: ${(props) => props.theme.palette.secondary.light};
  border: 2px solid ${(props) => props.theme.palette.secondary.main};
  margin: 0 0.8rem 0 0;
  padding: 0.6rem;
  font-size: 2rem;
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 10px;

  &.checked {
    background-color: ${(props) => props.theme.palette.secondary.main} !important;
  }

  span {
    color: white;
  }
`;

const LabelStyled = styled(FormLabel)`
  font-size: ${(props) => props.theme.typography.subText4};
  font-weight: 600;
  line-height: 1.6rem;
  margin-bottom: 0;
`;

const InputStyled = styled(FormControl)`
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
`;

const Checkbox = ({
  label,
  labelProps = {},
  onChecked,
  onUnChecked,
  checkUnsave,
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  useEffect(() => {
    if (field.value) {
      onChecked && onChecked();
    } else {
      onUnChecked && onUnChecked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <>
      <InputWrap>
        <Checkmark
          htmlFor={props.id || props.name}
          className={`checkmark ${field.value ? 'checked' : ''}`}
        >
          {field.value && <span>&#10003;</span>}
        </Checkmark>

        <InputStyled
          {...field}
          {...props}
          type="checkbox"
          $unsaved={checkUnsave && meta.initialValue !== meta.value}
          isInvalid={meta.error && meta.touched}
        />

        {label && (
          <LabelStyled htmlFor={props.id || props.name} {...labelProps}>
            {label}
          </LabelStyled>
        )}
      </InputWrap>

      {meta.touched && meta.error && <FormErrorMsg>{meta.error}</FormErrorMsg>}
    </>
  );
};

Checkbox.propTypes = {
  ...FormControl.propTypes,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChecked: PropTypes.func,
  onUnChecked: PropTypes.func,
  checkUnsave: PropTypes.bool,
};

export default Checkbox;
