export const setLocalStorageItem = (key, value) => {
  const stringify = JSON.stringify(value);

  localStorage.setItem(key, stringify);
};

export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

export const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  return JSON.parse(item);
};
