import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { css } from "glamor";
import { Form as FormikForm, useFormikContext } from "formik";
// import defaultConfig from "../../configuration/config";
import SelectInput from "../SelectInput/SelectInput";
import TextInput from "../TextInput/TextInput";
import Textarea from "../Textarea/Textarea";
import { Form } from "react-bootstrap";
import Button from "../Button/Button";
import ProfileImage from "../Profile/ProfileImage";
import { apiHandler } from "../../services/axios";
import { UploadAsset } from "../../services/assetServices";
import { getSupportedFileString } from "../../common/helpers/helper";
import FormErrorMsg from "../FormErrorMsg/FormErrorMsg";
import { Spinner } from "react-bootstrap";
import store from "../../store/store";
import { useSelector } from "react-redux";
toast.configure();

const CollectionCreateForm = ({ isSubmiting }) => {
  const defaultConfig = useSelector((state) => state.global.activeConfig);


  const [isUploading, setIsUploading] = useState({
    cover_image_details: false,
  });
  const [selectAddressOptions, setSelectAddressOptions] = useState([
    { label: "Select a Contract Address", value: "" },
    {
      label: "ZyneCoin Gold",
      value: defaultConfig?.mintingContractAddressZyneCoinGold,
    },
    {
      label: "BitCoin nft",
      value: defaultConfig?.mintingContractAddressBitCoinNft,
    },
  ]);
  const formik = useFormikContext();

  const onImageUpload = (value) => {
    setIsUploading((prev) => ({ ...prev, cover_image_details: true }));
    apiHandler(() => UploadAsset(value), {
      onSuccess: (data) => {
        formik.setFieldValue("cover_image_details", {
          base_url: data.url,
          folder: data.folder,
          blobName: data.blobName,
        });
      },
      onError: (error, res) => {
        console.error(error);
        toast.error("File limit exceeded", {
          className: css({
            background: "red !important",
          }),
        });
      },
      final: () =>
        setIsUploading((prev) => ({ ...prev, cover_image_details: false })),
    });
  };
  return (
    <FormikForm className="form">
      <Form.Group className="form-group mt-5">
        <SelectInput
          name="contract_address"
          id="contract_address"
          options={selectAddressOptions}
          outline="true"
          color="white"
          transparent="true"
          extraItem={<Spinner animation="true" />}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <TextInput
          name="name"
          id="name"
          type="text"
          outline="true"
          color="white"
          label="name"
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Textarea
          name="description"
          id="description"
          label="Description"
          outline="true"
          color="white"
        />
      </Form.Group>
      <Form.Group className="form-group">
        <ProfileImage
          onImageUpload={onImageUpload}
          accept={getSupportedFileString(["image", "video"])}
          id="cover_image_details"
          assetComponentProps={{
            disableLocalLoad: true,
            loading: isUploading.cover_image_details,
          }}
        />
        {formik.errors.cover_image_details &&
          formik.touched.cover_image_details && (
            <FormErrorMsg outSideContainer>
              {formik.errors.cover_image_details}
            </FormErrorMsg>
          )}
      </Form.Group>
      <Button
        type="submit"
        disabled={isSubmiting || isUploading?.cover_image_details}
        className="mt-5 mx-auto"
      >
        Submit
      </Button>
    </FormikForm>
  );
};

CollectionCreateForm.propTypes = {
  isSubmiting: PropTypes.bool,
};

export default CollectionCreateForm;
