import React, { Suspense } from "react";

import { ROUTES } from "../constants/routes";
import { LayoutStyled, StyledComp } from "../Components/style";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../routes/PrivateRoute";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import UserProfileDetails from "./UserProfile/UserProfileDetails";
import UserProfile from "./UserProfile/UserProfile";
import Home from "./Home/Home";
import "./Style.css";
import CreateCollection from "../pages/Collection/CreateCollection";
import CollectionRoute from "../pages/Collection/CollectionRoute";
import CollectionDetails from "../pages/Collection/CollectionDetails";
import AllCollection from "../pages/Collection/AllCollection";
import ItemListingRoute from "../pages/ItemListing/ItemListingRoute";
import ApproveAssets from "../pages/ApproveAssets/ApproveAssets";
import ImportCollection from "../pages/ImportCollection/ImportCollection";
import ApproveAssetsDetails from "../pages/ApproveAssets/ApproveAssetsDetails";
import PlaceBidRoute from "../pages/PlaceBid/PlaceBidRoute";
import BitcoinGoldToken from "../pages/BitcoinGoldToken";
import ZynecoinGoldToken from "../pages/ZynecoinGoldToken";
import MarketPlacePage from "../pages/MarketPlacePage";
import ArtistDetails from "./ArtistDetails/ArtistDetails";
import ApproveCreators from "../pages/ApproveCreators/ApproveCreators";
import CreateAssetRoute from "../routes/CreateAssetRoute";
import AllCreators from "../pages/AllCreators/AllCreators";
import AdminRoutes from "../routes/AdminRoute";
import LoginWalletPage from "../pages/LoginWalletPage/LoginWalletPage";
import ApproveAsssetsCreator from "../pages/ApproveAssetsCreator/ApproveAssetsCreator";
import WethioGoldToken from "../pages/WethioGoldToken";
import Zynecoin18kToken from "../pages/Zynecoin18kToken";


const AuthTestComp = () => <h1>Sahi h</h1>;

const NotFound = React.lazy(() => import("../pages/notFound/notFound"));
const AuctionDetail = React.lazy(() =>
  import("../Components/UserProfile/onSaleCollectionDetails")
);
const ApproveImportedCollection = React.lazy(() =>
  import("../pages/ApproveImportedCollection/ApproveImportedCollection")
);

function Layout() {
  return (
    <LayoutStyled>
      <Header />
      <StyledComp>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route
              path={ROUTES.edit}
              element={
                <PrivateRoute>
                  <AuthTestComp />
                </PrivateRoute>
              }
            />
            <Route path={ROUTES.home} element={<Home />} />
            <Route path={ROUTES.login} element={<LoginWalletPage />} />
            <Route
              path={ROUTES.userDetails}
              element={
                <PrivateRoute>
                  <UserProfileDetails />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.userProfile}
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.createCollection}
              element={
                <CreateAssetRoute>
                  <CreateCollection />
                </CreateAssetRoute>
              }
            />
            <Route
              path={ROUTES.collectionStep + "/*"}
              element={
                <CreateAssetRoute>
                  <CollectionRoute />
                </CreateAssetRoute>
              }
            />
            <Route
              path={ROUTES.collectionDetails}
              element={<CollectionDetails />}
            />
            <Route path={ROUTES.allCollection} element={<AllCollection />} />
            <Route path={ROUTES.artistDetails} element={<ArtistDetails />} />
            <Route path={ROUTES.creators} element={<AllCreators />} />
            <Route path={ROUTES.itemListing} element={<ItemListingRoute />} />
            <Route path={ROUTES.placebid} element={<PlaceBidRoute />} />
           
            <Route
              path={ROUTES.approveAssets}
              element={
                <AdminRoutes>
                  <ApproveAssets />
                </AdminRoutes>
              }
            />
             <Route
              path={ROUTES.approveAssetsCreator}
              element={
                <AdminRoutes>
                  <ApproveAsssetsCreator />
                </AdminRoutes>
              }
            />
            <Route
              path={ROUTES.approveCreators}
              element={
                <AdminRoutes>
                  <ApproveCreators />
                </AdminRoutes>
              }
            />
          
            <Route path={ROUTES.zynecoin} element={<ZynecoinGoldToken />} />
            {/* <Route path={ROUTES.wethioGold} element={<WethioGoldToken />} /> */}
            <Route path={ROUTES.zynecoin18k} element={<Zynecoin18kToken />} />
            <Route path={ROUTES.bitcoin} element={<BitcoinGoldToken />} />
            <Route
              path={ROUTES.approveAssetsDetails}
              element={
                <AdminRoutes>
                  <ApproveAssetsDetails />
                </AdminRoutes>
              }
            />
            <Route
              path={ROUTES.importCollection}
              element={
                <PrivateRoute>
                  <ImportCollection />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.marketplacepage}
              element={<MarketPlacePage />}
            />
            <Route path={ROUTES.auctionDetails} element={<AuctionDetail />} />
            <Route
              path={ROUTES.approveImportedCollection}
              element={<ApproveImportedCollection />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </StyledComp>
      <Footer />
    </LayoutStyled>
  );
}

export default Layout;
