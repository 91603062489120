import * as ActionTypes from "../../constants/actionTypes";

export const setActiveConfig = (config) => {
  return {
    type: ActionTypes.SET_ACTIVE_CONFIG,
    payload: { config },
  };
};

export const setBlockchain = (blockchain) => {
  return {
    type: ActionTypes.UPDATE_BLOCKCHAIN,
    payload: { blockchain },
  };
};
