import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import {
  UserProfileStyled,
  ProfileCard,
  Button,
  CommonButton,
  ContainerDiv,
  Text,
  ButtonList,
  AddressBtn,
  Image,
  UserNameText,
  AddressText,
  SubText,
  Text1,
  DescriptionText,
  AvtarImg,
  ImageLayout,
  EditProfile,
  DataNotFoundStyled,
  TabsStyled,
  StyledCard,
  ProfileSectionLayout,
  StyledPopper,
  SubMainText,
} from "./style";
import getAxiosInst, { apiHandler } from "../../services/axios";
import { getCreatedAssets, getOwnedAssets } from "../../services/userServices";
import { Tabs, Tab, OverlayTrigger, Row } from "react-bootstrap";
import { useParams } from "react-router";
import UserIcon from "../../assets/icons/UserIcon";
import { useDispatch, useSelector } from "react-redux";
import AssetCard from "../AssetCard";
import AssetCardSale from "../AssetCardSale";
import { getOnSaleAssets } from "../../services/userServices";
import { HeadingText } from "../../pages/PlaceBid/style";
import { toast } from "react-toastify";
import AssetCardOwned from "../AssetCardOwned";
import CommonCreatorCard from "../CommonCreatorCard/CommonCreatorCard";

import CommonAssetCard from "../CommonAssetCard/CommonAssetCard";
import TransferAssetModal from "../TransferAssetModal/TransferAssetModal";
import MainTopSection from "../MainTopSection";

const ItemNotFoundComponent = () => {
  return (
    <DataNotFoundStyled>
      <HeadingText>No items found</HeadingText>
    </DataNotFoundStyled>
  );
};

function UserProfileDetails() {
  const [isSubmiting, setIsSubmiting] = useState({ editUser: false });
  const [allAssets, setAllAssets] = useState();
  const [assetTokenId, setAssetTokenId] = useState(null);
  const [show, setShow] = useState(false);
  const [onSaleAssets, setOnSaleAssets] = useState(null);
  const [ownedAssets, setOwnedAssets] = useState(null);
  const globalState = useSelector((state) => state.global);
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(null);
  const [filters, setFilters] = useState({
    createdContractAddress: "",
    ownedContractAddress: "",
    createdQ: "",
    ownedQ: "",
  });
  const [followingCreators, setFollowingCreators] = useState([]);
  const [followingAssets, setFollowingAssets] = useState([]);
  const [transferAssetId, setTransferAssetId] = useState(null);
  const [transferAssetContractAddress, setTransferAssetContractAddress] =
    useState(null);
  const { userData } = userState;
  const userId = userData?._id;

  const { account } = globalState;
  let tem = account;
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [address, setAddress] = useState(tem);
  const { acc } = useParams();
  const timer = useRef(null);
  const accWrapperRef = useRef(null);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const [selectAddressOptions, setSelectAddressOptions] = useState([
    {
      label: "All",
      value: "",
    },
    {
      label: "Wethio Public Minting",
      value: defaultConfig?.publicMintingContractAddress,
    },
    {
      label: "Wethio Gold ZYN",
      value: defaultConfig?.mintingContractAddressZyneCoinGold,
    },
    {
      label: "Wethio Gold 18K",
      value: defaultConfig?.mintingContractAddressZyneCoin18k,
    },
    {
      label: "Wethio Gold BTC",
      value: defaultConfig?.mintingContractAddressBitCoinNft,
    },
  ]);
  useEffect(() => {
    setSelectAddressOptions([
      {
        label: "All",
        value: "",
      },
      {
        label: "Wethio Public Minting",
        value: defaultConfig?.publicMintingContractAddress,
      },
      {
        label: "Wethio Gold ZYN",
        value: defaultConfig?.mintingContractAddressZyneCoinGold,
      },
      {
        label: "Wethio Gold 18K",
        value: defaultConfig?.mintingContractAddressZyneCoin18k,
      },
      {
        label: "Wethio Gold BTC",
        value: defaultConfig?.mintingContractAddressBitCoinNft,
      },
    ]);
  }, [defaultConfig]);
  const toggleCopiedText = (state) =>
    setShowCopiedText((prev) => (typeof state === "boolean" ? state : !prev));
  const handleTransferAssetModalClose = () => {
    setShow(false);
  };

  const handleTransferAssetModalShow = (
    asset_token_id,
    assetId,
    contract_address,
    setTransferButtonLoading
  ) => {
    setAssetTokenId(asset_token_id);
    setTransferAssetId(assetId);
    setTransferAssetContractAddress(contract_address);
    setShow(true);
    setLoading(() => setTransferButtonLoading);
  };

  // useEffect(() => {
  //   if (userId) {
  //     const blockchain = localStorage.getItem("blockchain");
  //     getAxiosInst({ withAuth: true })
  //       .get(`/users/creators/${userId}?network=${blockchain}`)
  //       .then((data) => console.log(data))
  //       .catch((error) => console.log(error));
  //   }
  // }, [userId]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(acc);
      toggleCopiedText();
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(() => toggleCopiedText(false), 2000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const blockchain = localStorage.getItem("blockchain");
    if (userId) {
      getAxiosInst({ withAuth: true })
        .get(
          `/users/creators/${userId}?followingDetails=true&network=${blockchain}`
        )
        .then((data) => {
          setFollowingAssets(data?.data?.creator.followingAssets);
          setFollowingCreators(data?.data?.followingCreators);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [userId]);

  const history = useNavigate();
  const EditProfilePage = () => {
    history("/userProfile");
  };

  const getAssetDetails = async () => {
    apiHandler(() => getCreatedAssets(userData?._id, filters), {
      onSuccess: (data) => {
        setAllAssets(data.created_asset);
      },
      onError: (error) => console.error(error),
      final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
    });
  };

  useEffect(() => {
    if (userData?._id) {
      getAssetDetails();
    }

    const curTime = timer.current;
    return () => {
      curTime && clearTimeout(curTime);
    };
  }, [
    account,
    userState,
    userData?._id,
    filters?.createdContractAddress,
    filters?.createdQ,
  ]);

  const getOnSaleAssetDetails = async () => {
    apiHandler(() => getOnSaleAssets(userData?._id), {
      onSuccess: (data) => {
        setOnSaleAssets(data.onsale_asset);
      },
      onError: (error) => console.error(error),
    });
  };

  const getOwnedAssetDetails = async () => {
    apiHandler(() => getOwnedAssets(userData?._id, filters), {
      onSuccess: (data) => {
        setOwnedAssets(data.owned_asset);
      },
      onError: (error) => console.error(error),
    });
  };

  useEffect(() => {
    if (userData?._id) {
      getOwnedAssetDetails();
    }
  }, [userData, userData?._id, filters?.ownedContractAddress, filters?.ownedQ]);

  useEffect(() => {
    if (userData?._id) {
      getOnSaleAssetDetails();
    }
  }, [userData, userData?._id]);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="User profile"
        descriptionText={""}
        routePath="/userDetails"
      />

      <UserProfileStyled>
        <ProfileCard>
          <TransferAssetModal
            transferFrom={userData?.wallet_address}
            show={show}
            tokenId={assetTokenId}
            setOwnedAssets={setOwnedAssets}
            assetId={transferAssetId}
            loading={loading}
            transferAssetContractAddress={transferAssetContractAddress}
            handleClose={handleTransferAssetModalClose}
          />
          <ContainerDiv>
            <ProfileSectionLayout>
              <div className="d-flex profileImage">
                <ImageLayout $imgUrl={userData?.profile_image_url}>
                  {userData?.profile_image_url ? (
                    <img
                      className="asset-img"
                      src={userData?.profile_image_url}
                      alt="Card cap"
                      onClick={EditProfilePage}
                    />
                  ) : (
                    <AvtarImg onClick={EditProfilePage}>
                      <UserIcon />
                    </AvtarImg>
                  )}
                </ImageLayout>
                <div
                  className="authorProfile"
                  style={{ marginLeft: "20px", wordBreak: "break-all" }}
                >
                  <div
                    style={{
                      margin: "30px 0px 20px 0px",
                      wordBreak: "break-all",
                    }}
                  >
                    <SubMainText>Author Profile</SubMainText>

                    {userData?.username ? (
                      <UserNameText>{userData?.username}</UserNameText>
                    ) : (
                      <Text1>@anonymous</Text1>
                    )}

                    {userData?.bio ? (
                      <DescriptionText>{userData?.bio}</DescriptionText>
                    ) : null}
                  </div>
                  <div className="d-flex  align-items-center mobile-view-ui">
                    <AddressBtn>
                      <SubText style={{ color: "white" }}>{account}</SubText>
                      <img
                        className="asset-img1 copy-img"
                        src="./CopyAdress.png"
                        alt="Card cap"
                        onClick={() => {
                          navigator.clipboard.writeText(address);
                          toast("Account address copied successfully");
                        }}
                      />
                    </AddressBtn>
                    <EditProfile
                      variant="outline-dark"
                      onClick={EditProfilePage}
                    >
                      Customise
                    </EditProfile>
                  </div>
                </div>
              </div>
            </ProfileSectionLayout>
            <TabsStyled
              defaultActiveKey="Created"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="Created"
                title="Created"
                className="text-center p-3"
              >
                {" "}
                <>
                  {setFilters && (
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                    >
                      <select
                        style={{
                          borderRadius: "10px",
                          background: "white",
                          padding: "5px 10px",
                        }}
                        name="contract_address"
                        id="contract_address"
                        onChange={(e) =>
                          setFilters((prev) => {
                            return {
                              ...prev,
                              createdContractAddress: e.target.value,
                            };
                          })
                        }
                        placeholder="Select a Contract Address"
                      >
                        {selectAddressOptions?.map((option, i) => {
                          return (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                      <input
                        onChange={(e) =>
                          setFilters &&
                          setFilters((prev) => {
                            return {
                              ...prev,
                              createdQ: e.target.value,
                            };
                          })
                        }
                        style={{
                          borderColor: "black",
                          outline: "none",
                          borderRadius: "10px",
                          padding: "5px 10px",
                        }}
                        placeholder="Search for your nft"
                      />
                    </div>
                  )}
                  {allAssets?.length > 0 ? (
                    <AssetCard
                      selectAddressOptions={selectAddressOptions}
                      setFilters={setFilters}
                      allAssets={allAssets}
                    />
                  ) : (
                    <ItemNotFoundComponent />
                  )}
                </>
              </Tab>
              <Tab eventKey="Owned" title="Owned" className="text-center p-3">
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  <select
                    name="contract_address"
                    id="contract_address"
                    style={{
                      borderRadius: "10px",
                      background: "white",
                      padding: "5px 10px",
                    }}
                    onChange={(e) => {
                      setFilters((prev) => {
                        return {
                          ...prev,
                          ownedContractAddress: e.target.value,
                        };
                      });
                    }}
                    placeholder="Select a Contract Address"
                  >
                    {selectAddressOptions?.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    style={{
                      borderColor: "black",
                      outline: "none",
                      borderRadius: "10px",
                      padding: "5px 10px",
                    }}
                    onChange={(e) =>
                      setFilters((prev) => {
                        return {
                          ...prev,
                          ownedQ: e.target.value,
                        };
                      })
                    }
                    placeholder="Search for your nft"
                  />
                </div>
                {ownedAssets?.length > 0 ? (
                  <div style={{ textAlign: "left" }}>
                    <Row>
                      {ownedAssets?.map((items, i) => {
                        return (
                          <AssetCardOwned
                            key={i}
                            handleTransferAssetModalShow={
                              handleTransferAssetModalShow
                            }
                            items={items}
                            allAssets={ownedAssets}
                          />
                        );
                      })}
                    </Row>
                  </div>
                ) : (
                  <ItemNotFoundComponent />
                )}
              </Tab>
              <Tab eventKey="On sale" title="On sale">
                {onSaleAssets?.length > 0 ? (
                  <AssetCardSale
                    onSaleAssets={onSaleAssets}
                    detailsPageType={"auction"}
                  />
                ) : (
                  <ItemNotFoundComponent />
                )}
              </Tab>

              <Tab eventKey="FollowingAssets" title="Following Assets">
                {followingAssets?.length > 0 ? (
                  <Row>
                    {followingAssets?.map((items, i) => {
                      return (
                        <CommonAssetCard
                          key={i}
                          assets={items}
                          auctions={items?.auctions}
                          collectionId={items?._id}
                          collectionImage={items?.metadata_fields?.image_url}
                          title={items?.name}
                          createrId={items?.creator_id}
                          status={items?.status}
                          reservePrice={items?.auctions?.reservePrice}
                          followCount={items?.followersCount}
                          time={items?.auctions?.endTime}
                          highestBid={items?.auctions?.highestBid}
                          isAdminUserTrue={true}
                        />
                      );
                    })}
                  </Row>
                ) : (
                  <ItemNotFoundComponent />
                )}
              </Tab>

              <Tab eventKey="FollowingCreators" title="Following Creators">
                {followingCreators?.length > 0 ? (
                  <StyledCard className="asset-card">
                    <Row>
                      {followingCreators?.map((items, i) => {
                        return (
                          <CommonCreatorCard
                            key={i}
                            creatorId={items?.user_id?._id}
                            coverImage={items?.user_id?.profile_image_url}
                            allAssets={items?.created_assets}
                            username={items?.user_id?.username}
                          />
                        );
                      })}
                    </Row>
                  </StyledCard>
                ) : (
                  <ItemNotFoundComponent />
                )}
              </Tab>
            </TabsStyled>
          </ContainerDiv>
        </ProfileCard>
      </UserProfileStyled>
    </>
  );
}

export default UserProfileDetails;
