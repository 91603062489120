import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  CreateCollectionStyled,
  CommonButton,
  SubText,
  HeadingText,
  Heading,
  Text,
  CustomCard,
  StyledCard,
  LoaderArea,
  LoadMoreButton,
} from "./style";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { apiHandler } from "../../services/axios";
import { getAllCollection } from "../../services/collectionServices";
import AssetCard from "../../Components/AssetCard";
import FullSizeLoader from "../../Components/FullSizeLoader";
import CommonAssetCard from "../../Components/CommonAssetCard/CommonAssetCard";
import { getAllAssets } from "../../services/assetServices";
import InfiniteScrollWrapper from "../../Components/InfiniteScrollWrapper";
import MainTopSection from "../../Components/MainTopSection";

const initialLimit = 5;
const initialPageNumber = 1;

function AllCollection() {
  const [allCollection, setAllCollection] = useState({
    loading: true,
    data: [],
  });

  const [limit, setLimit] = useState(initialLimit);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [apiLoading, setApiLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [commonLoadMore, setCommonLoadMore] = useState(9);
  const [totalResult, setTotalResult] = useState();
  const [approvedCollection, setApprovedCollection] = useState();

  const onSuccessHanadler = useCallback(
    (data, limit, pageNumber) => {
      const { offset: offsetData, total } = data.pagination;
      setAllCollection((prevState) => {
        if (pageNumber === 1)
          return { ...prevState, data: data.results, loading: false };
        else
          return {
            ...prevState,
            data: [...prevState.data, ...data.results],
            loading: false,
          };
      });
      setPageNumber((prevState) => {
        return prevState + 1;
      });
 
      setHasMore(offsetData + limit < total);
    },
    [setPageNumber, setHasMore, setAllCollection]
  );

  const next = useCallback(() => {
      const api = getAllAssets;
      setApiLoading(true);
      apiHandler(() => api({ pageNumber, limit }), {
        onSuccess: (data) => onSuccessHanadler(data, limit, pageNumber),
        final: () => setApiLoading(false),
      });


  }, [limit,commonLoadMore, pageNumber, onSuccessHanadler]);

  const getCollectionDetails = useCallback(
    (pageNumber = initialPageNumber, limit = initialLimit) => {
      setApiLoading(true);
      setAllCollection({ ...allCollection, loading: true });
      apiHandler(() => getAllAssets({ pageNumber, limit}), {
        onSuccess: (data) => {
          onSuccessHanadler(data, limit, pageNumber);
          setTotalResult(data.pagination.total)
  

        },
        onError: (error) => {
          console.error(error);
          setAllCollection({ ...allCollection, loading: false });
        },
        final: () => setApiLoading(false),
      });
    },
    [onSuccessHanadler]
  );

  const getApprovedCollection = useCallback(() => {
    if (pageNumber <= 1) return;
    let approvedata = allCollection.data?.filter(
      (items) =>
        items?.auctions?.approvalStatus !== "APPROVED" ||
        items?.auctions === null
    );
  

    if (!approvedata.length && hasMore) {
      next();
    }
    setApprovedCollection(approvedata);
  }, [hasMore, next, pageNumber, allCollection]);




  const FullWidthSpinner = () => (
    <div className="d-flex justify-content-center">
      <Spinner variant="light" animation="border" />
    </div>
  );
  const onClickLoadMore = (value) => {
    setCommonLoadMore(value + 3);
  };
  useEffect(() => {
    getApprovedCollection();
  }, [getApprovedCollection]);

  useEffect(() => {
    getCollectionDetails();
    const initialLimit = 5;
  }, [getCollectionDetails]);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="All Assets"
        descriptionText={""}
        routePath="/"
      />
      <CreateCollectionStyled>
        {allCollection.loading ? (
          <LoaderArea>
            <FullSizeLoader />
          </LoaderArea>
        ) : (
          <>
            <Row className="infinite mb-5"
               as={InfiniteScrollWrapper}
               loading={apiLoading}
               dataLength={allCollection.data.length}
               loader={<FullWidthSpinner />}
               next={next}
               hasMore={hasMore}
         
               endMessage={<p></p>}
            >
              {approvedCollection?.length > 0 ? (
                approvedCollection?.map((items, i) => {
                    return (
                      <CommonAssetCard
                        key={i}
                        assets={items}
                        auctions={items?.auctions}
                        collectionId={items?._id}
                        collectionImage={items?.metadata_fields.image_url}
                        title={items?.name}
                        createrId={items?.creator_id}
                        followCount={items?.followersCount}
                        status={items?.status}
                        reservePrice={items?.auctions?.reservePrice}
                        time={items?.auctions?.endTime}
                        highestBid={items?.auctions?.highestBid}
                        isAdminUserTrue={true}
                      />
                    );
                  })
              ) : (
                <div className="text-center mt-3 mb-3">
                  <Text>
                    <HeadingText>No items found</HeadingText>
                  </Text>
                </div>
              )}
            </Row>
            {/*
            {approvedCollection &&
            approvedCollection?.length > commonLoadMore ? (
              <div className="mt-3">
                <LoadMoreButton
                  type="button"
                  onClick={() => onClickLoadMore(commonLoadMore)}
                  className=" d-block mx-auto"
                  color="primary"
                >
                  Load More
                </LoadMoreButton>
              </div>
            ) : null} */}
          </>
        )}
      </CreateCollectionStyled>
    </>
  );
}

export default AllCollection;
