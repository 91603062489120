import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { loginReducer } from "./reducer/loginReducer";
import { globalReducer } from "./reducer/globalReducer";
import { userReducer } from "./reducer/userReducer";
import { contractReducer } from "./reducer/contractReducer";
import { userAssetsCreatedReducer, userAssetsReducer } from "./reducer/userAssetsReducer";
import { userAssetsCollectionReducer } from "./reducer/userReducer";
import SearchReducer from "./reducer/searchReducer";
const rootReducer = combineReducers({
  global: globalReducer,
  login: loginReducer,
  user: userReducer,
  search: SearchReducer,
  contractState: contractReducer,
  assetsData: userAssetsReducer,
  assetsCollectionId: userAssetsCollectionReducer,
  assetDetails:userAssetsCreatedReducer,
});

const composeEnhancers =
  process?.env?.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

export default store;
