import React, { useState, useEffect, useRef } from "react";
import {
  CreateCollectionStyled,
  SubText,
  HeadingText,
  Heading,
  Text,
  CustomCard,
} from "../ApproveAssets/style";
import { CommonButton } from "./style";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import getAxiosInst, { apiHandler } from "../../services/axios";
import { getAllCollection } from "../../services/collectionServices";
import AssetCard from "../../Components/AssetCardApprove";
import { getAllAssets } from "../../services/assetServices";
import ArtistCard from "./ArtistCard";
import { createContractInstance } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { CONTRACT_NAME } from "../../constants/appConstants";
import { setContract } from "../../store/actions/contractActions";
import { toast } from "react-toastify";
import MiniLoader from "../../Components/MiniLoader";
import MainTopSection from "../../Components/MainTopSection";
toast.configure();
function ApproveCreators() {
  const [allCollection, setAllCollection] = useState();
  const [approveAllLoader, setApproveAllLoader] = useState(false);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.global);
  const user = useSelector((state) => state.user);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const { web3Instance } = globalState;
  const getUnapprovedCreators = async () => {
    const blockchain = localStorage.getItem("blockchain");

    getAxiosInst({ withAuth: true })
      .get(`/users/creators/unapproved?network=${blockchain}`)
      .then((data) => {
        setAllCollection(data?.data);
      })
      .catch((err) => console.log(err));
  };



  useEffect(() => {
    getUnapprovedCreators();
  }, []);

  return (
    <>
    <MainTopSection
      backButton={false}
      headingText="Creators Pending For Approval"
      descriptionText={""}
      routePath="/"
    />
    <CreateCollectionStyled>
      {allCollection?.length > 0 ? (
        <div style={{ display: "flex", gap: "60px", flexWrap: "wrap" }}>
   
          {allCollection?.map((items, index) => {
            return (
              <ArtistCard
                key={index}
                items={items}
                approveAllLoader={approveAllLoader}
                setAllCollection={setAllCollection}
                allCollection={allCollection}
                status={"PENDING"}
              />
            );
          })}
   
        </div>
      ) : (
        <div className="text-center mt-3 mb-3">
          <Text>
            <HeadingText>No items found</HeadingText>
          </Text>
        </div>
      )}
    </CreateCollectionStyled>
    </>
  );
}

export default ApproveCreators;
