import { css, keyframes } from "styled-components";

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const flexBetween = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const rotate = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
`;
export const poppinsRegular = css`
    font-family: ${p => p.theme.ff.poppins};
    font-weight: ${p => p.theme.fw.regular};
`;
export const poppinsBold = css`
    font-family: ${p => p.theme.ff.poppins};
    font-weight: ${p => p.theme.fw.bold};
`;
export const poppinsSemibold = css`
    font-family: ${p => p.theme.ff.poppins};
    font-weight: ${p => p.theme.fw.semiBold};
`;
export const poppinsMedium = css`
    font-family: ${p => p.theme.ff.poppins};
    font-weight: ${p => p.theme.fw.medium};
`;
export const poppinsLight = css`
    font-family: ${p => p.theme.ff.poppins};
    font-weight: ${p => p.theme.fw.light};
`;