import React, { useRef, useEffect, useCallback } from 'react';

import { useField } from 'formik';

import styled, { css } from 'styled-components';
import { InputStyled, LabelStyled } from '../TextInput/TextInput';

import FormErrorMsg from '../FormErrorMsg/FormErrorMsg';

import Proptypes from 'prop-types';

const TextareaWrapperStyled = styled.div`
  .hidden-div {
    display: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    min-height: ${(props) => props.height || '170px'};
    font-size: ${(props) => props.theme.typography.text1};
    padding: 0.4rem 0 0.8rem 0;
  }
  ${(props) =>
    props.isInvalid &&
    css`
      border-color: ${props.theme.palette.common.red + '!important'};
    `}
`;

const TextareaStyled = styled(InputStyled)`
  &.height-fix {
    overflow: hidden;
    padding-bottom: 0.8rem;
    height: ${(props) => props.height || '170px'};
    min-height: ${(props) => props.height};
    resize: none;
    width: 100%;
   //border: none;
  }
  ${(props) =>
    props.$unsaved &&
    css`
      border-color: ${props.theme.palette.common.yellow};
    `}
`;

TextareaStyled.propTypes = {
  height: Proptypes.string,
};

const Textarea = ({
  label,
  labelProps = {},
  height,
  checkUnsave,
  ...props
}) => {
  const [field, meta] = useField(props);

  const textareaRef = useRef(null);
  const hiddenDivRef = useRef(null);

  const initialValue = meta.initialValue;

  const onInputHandler = useCallback((e) => {
    const textareaEl = textareaRef.current;
    const hiddenDivEl = hiddenDivRef.current;

    hiddenDivEl.innerHTML = e?.target?.value || textareaEl?.value;

    hiddenDivEl.style.visibility = 'hidden';
    hiddenDivEl.style.display = 'block';
    textareaEl.style.height = hiddenDivEl.offsetHeight + 'px';

    // Make the hidden div display:none again
    hiddenDivEl.style.visibility = 'visible';
    hiddenDivEl.style.display = 'none';

    // for cases when height increases due to return(next line)
    const scrollHeight = textareaEl.scrollHeight;
    textareaEl.style.height = scrollHeight + 'px';
  }, []);

  useEffect(() => {
    onInputHandler();
  }, [onInputHandler, initialValue]);

  return (
    <>
      {label && (
        <LabelStyled htmlFor={props.id || props.name} {...labelProps}>
          {label}
        </LabelStyled>
      )}
      <TextareaWrapperStyled height={height}>
        <TextareaStyled
          {...field}
          {...props}
          isInvalid={meta.error && meta.touched}
          as="textarea"
          className="height-fix form-control"
          height={height}
          ref={textareaRef}
          onInput={(e) => onInputHandler(e)}
          $unsaved={checkUnsave && meta.initialValue !== meta.value}
        />
        <div className="hidden-div" ref={hiddenDivRef}></div>
      </TextareaWrapperStyled>
      {meta.touched && meta.error && (
        <FormErrorMsg className="error" outSideContainer>
          {meta.error}
        </FormErrorMsg>
      )}
    </>
  );
};

Textarea.propTypes = {
  label: Proptypes.string,
  labelProps: Proptypes.object,
  height: Proptypes.string,
  name: Proptypes.string.isRequired,
  id: Proptypes.string.isRequired,
  checkUnsave: Proptypes.bool,
};

export default Textarea;
