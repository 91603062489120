import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PaginationContainer } from './style';
import { Pagination } from 'react-bootstrap';
import { range } from '../../common/helpers/helper';
import { InputStyled } from '../TextInput/TextInput';

const PAGE_LIMIT_OPTIONS = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
];

const CustomPagination = ({
  active: _active = 1,
  totalItems,
  onPageChange,
  onLimitChange,
  pageLimit = 10,
  hideLimit = false,
}) => {
  const [active, setActive] = useState(_active);
  const [limit, setLimit] = useState(pageLimit);
  const [pageCount, setPageCount] = useState(0);
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    if (totalItems) {
      setPageCount(Math.ceil(totalItems / limit));
    }
  }, [totalItems, limit]);

  const getVisiblePages = useCallback(() => {
    if (pageCount > 5) {
      if (active <= 3) {
        return range(1, 5);
      } else if (active >= pageCount - 2) {
        return range(pageCount - 4, pageCount);
      }
      return range(active - 2, active + 2);
    }
    return range(1, pageCount);
  }, [pageCount, active]);

  useEffect(() => {
    setVisiblePages(getVisiblePages());
  }, [active, getVisiblePages]);

  const limitChangeHandler = (e) => {
    const newLimit = e.target.value;
    setLimit(newLimit);
    setActive(1);
    if (onLimitChange) {
      onLimitChange(1, newLimit);
    }
  };

  const navigateToFirstPage = () => {
    setActive(1);
    if (onPageChange) {
      onPageChange(1, limit);
    }
  };

  const navigateToLastPage = () => {
    setActive(pageCount);
    if (onPageChange) {
      onPageChange(pageCount, limit);
    }
  };

  const navigateToNextPage = () => {
    if (active < pageCount) {
      setActive(active + 1);
      if (onPageChange) {
        onPageChange(active + 1, limit);
      }
    }
  };

  const navigateToPreviousPage = () => {
    if (active > 1) {
      setActive(active - 1);
      if (onPageChange) {
        onPageChange(active - 1, limit);
      }
    }
  };

  const navigateToSpecificPage = (page) => {
    setActive(page);
    if (onPageChange) {
      onPageChange(page, limit);
    }
  };

  if (pageCount > 1) {
    return (
      <PaginationContainer>
        {hideLimit ? null : (
          <InputStyled
            name="pageLimit"
            id="pageLimit"
            value={limit}
            as="select"
            onChange={limitChangeHandler}
            className="page-limit-select"
            onBlur={limitChangeHandler}
          >
            {PAGE_LIMIT_OPTIONS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </InputStyled>
        )}

        <Pagination>
          <Pagination.First onClick={navigateToFirstPage} />
          <Pagination.Prev onClick={navigateToPreviousPage} />
          {pageCount > 5 && active > 3 && (
            <>
              {' '}
              <Pagination.Item onClick={() => navigateToSpecificPage(1)}>
                {1}
              </Pagination.Item>
              {active > 4 && <Pagination.Ellipsis />}
            </>
          )}

          {visiblePages?.map((page) => (
            <Pagination.Item
              key={page}
              active={page === active}
              onClick={() => navigateToSpecificPage(page)}
            >
              {page}
            </Pagination.Item>
          ))}

          {pageCount > 5 && active < pageCount - 2 && (
            <>
              {' '}
              {active < pageCount - 3 && <Pagination.Ellipsis />}
              <Pagination.Item
                onClick={() => navigateToSpecificPage(pageCount)}
              >
                {pageCount}
              </Pagination.Item>
            </>
          )}
          <Pagination.Next onClick={navigateToNextPage} />
          <Pagination.Last onClick={navigateToLastPage} />
        </Pagination>
      </PaginationContainer>
    );
  } else {
    return null;
  }
};

export default CustomPagination;

CustomPagination.propTypes = {
  active: PropTypes.number,
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  totalItems: PropTypes.number,
  hideLimit: PropTypes.bool,
  pageLimit: PropTypes.number,
};
