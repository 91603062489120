import React, { useState, useEffect, useCallback } from "react";
import {
  HeadingText,
  SubHeadingText,
  SubText,
  ButtonsDiv,
  SubTextRight,
  Data,
  FirstSection,
  Left,
  Right,
  ImageIcon,
  HomeC,
  ConnectButton,
  CreateButton,
  Heading,
  LastSection,
  MainToSectionStyled,
  WethioNftCard,
  BitCoinCard,
  HowItSectionLayout,
  StyledContainer,
} from "./style";
import { createContractInstance } from "../../common/common";
import one from "../../assets/one.png";
import two from "../../assets/two.png";

import three from "../../assets/three.png";
import four from "../../assets/four.png";

import { useDispatch, useSelector } from "react-redux";
import bitcoin from "../../assets/icons/bitcoin.svg";
import zynecoin from "../../assets/icons/zynecoin.svg";

import cube from "../../assets/icons/cube.svg";

import LoadingModal from "../LoadingModal/LoadingModal";
import HeartIcon from "../../assets/icons/heartIcon";

import {
  toggleWalletModal,
  updateProvider,
  setChainId,
  setError,
} from "../../store/actions/globalActions";

import {
  getSignInMsg,
  logout,
  setLoginLoading,
} from "../../store/actions/loginActions";
import { ONE_ETHER, WALLET_TYPE } from "../../constants/appConstants";
import { createAndSwitchProvider } from "../../common/helpers/providerHelper";
import { getChainId } from "../../common/common";
import { embedMessageString, scrollTopTop } from "../../common/helpers/helper";
import { WALLET_MESSAGE } from "../../constants/messages";

import HotCollection from "./HotCollection";
import { useNavigate } from "react-router";
import ExploreMarket from "./ExploreMarket";
import TrendingCards from "./TrendingCards/Trending";
import Web3 from "web3";
import { toast } from "react-toastify";
import { ROUTES } from "../../constants/routes";
import getAxiosInst from "../../services/axios";
import MiniLoader from "../MiniLoader/MiniLoader.js";

function Home() {
  const globalState = useSelector((store) => store.global);
  const [wallets] = useState([
    {
      walletType: WALLET_TYPE.metamask,
    },
  ]);
  const isCreator = useSelector((state) => state.global.isCreator);
  const userState = useSelector((store) => store.user);
  const { userData } = userState;
  const [modalLoading, setModalLoading] = useState(wallets.map(() => false));
  
  const [toggleNavBar, setToggleNavBar] = useState(false);
  const [totalsupply, setTotalSupply] = useState();
  const [totalsupplySecond, setTotalSupplySecond] = useState();
  const [totalsupplyThird, setTotalSupplyThird] = useState();
  
  const loginState = useSelector((state) => state.login);
  const { isLogin, requestStatus, loading } = loginState;
  const [isAdminUser, setIsAdminUser] = useState();
  const [creatorApprovalStatus, setCreatorApprovalStatus] = useState('');
  const isBlocked = useSelector((state) => state.global.isBlocked);

  const blockchain = useSelector((state) => state.global.blockchain);
  const { web3Instance, hasMetamask, account, error, walletModal } =
    globalState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleWalletList = useCallback(
    () => dispatch(toggleWalletModal()),
    [dispatch]
  );
  const [loadingCreator,setLoadingCreator] = useState(false);

  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const marketAllCollection = () => {
    navigate(`/marketPlacePage`);
  };

  const getAuctionTime = useCallback(async () => {
    try {
      const auctionContractInstance = await createContractInstance(
        web3Instance,
        defaultConfig?.mintingABI,
        defaultConfig?.mintingContractAddressBitCoinNft
      );
      const getzyneCoin = await auctionContractInstance.methods
        .totalSupply()
        .call();

      setTotalSupply(getzyneCoin);
      const auctionContractInstanceSecond = await createContractInstance(
        web3Instance,
        defaultConfig?.mintingABI,
        defaultConfig?.mintingContractAddressZyneCoinGold
      );
      const getmintingGold = await auctionContractInstanceSecond.methods
        .totalSupply()
        .call();

      setTotalSupplySecond(getmintingGold);
      const auctionContractInstanceThird = await createContractInstance(
        web3Instance,
        defaultConfig?.mintingABI,
        defaultConfig?.mintingContractAddressZyneCoin18k
      );
      const getmintingWethio = await auctionContractInstanceThird.methods
        .totalSupply()
        .call();
      setTotalSupplyThird(getmintingWethio);
    } catch (error) {}
  }, [defaultConfig, web3Instance]);

  const id = useSelector(state=>state?.user?.userData?._id);

  useEffect(() => {
    const blockchain = localStorage.getItem("blockchain");
    if (id)
 {
  setLoadingCreator(true);
      getAxiosInst({ withAuth: true })
        .get(
          `/users/creators/${id}?network=${blockchain}`
        )
        .then((data) => {
       
          setCreatorApprovalStatus(data?.data?.creatorApprovalStatus);
          setLoadingCreator(false)

        })
        .catch((err) => {
          setLoadingCreator(false)

          toast.error(err?.response?.data?.message)});
    }
  }, [id]);
  const OpenAssetPage = () => {
    if (isAdminUser) {
      navigate("/collectionSteps?type=image");
    } else if (isBlocked) {
      navigate("/userProfile", {
        state: { msg: "You are blocked by admin" },
      });
    } else if (isCreator) {
      navigate("/collectionSteps?type=image");
    } else if(creatorApprovalStatus ==='REJECTED'){
      navigate("/userProfile", {
        state: { msg: "You are rejected by admin" },
      });

    }else if (
      (!isCreator && userData?.instagramUsername) ||
      userData?.twitterUsername
    ) {
      navigate("/userProfile", {
        state: { msg: "Please wait for approval from admin" },
      });
    } else if (
      !isCreator &&
      (!userData?.instagramUsername || !userData?.twitterUsername)
    ) {
      navigate("/userProfile", {
        state: { msg: "Please complete your profile" },
      });
    }
  };
  const viewAllAsset = () => {
    navigate(`/allAssets`);
  };
  const marketAllAsset = () => {
    navigate(`/marketPlacePage`);
  };

  const checkUserIsAdmin = async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  };
  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account]);

  useEffect(() => {
    // if (isLogin) {
    //   getAuctionTime();
    // } else {
      getAxiosInst({ withAuth: true })
        .get(`/assets/totalSupply?network=${blockchain}`)
        .then((data) => {
          data.data?.map((item) => {
            if (
              item.contract == defaultConfig?.mintingContractAddressZyneCoinGold
            ) {
              setTotalSupplySecond(item.totalSupply);
            }
            if (
              item.contract == defaultConfig?.mintingContractAddressZyneCoin18k
            ) {
              setTotalSupplyThird(item.totalSupply);
            }
            if (
              item.contract == defaultConfig?.mintingContractAddressBitCoinNft
            ) {
              setTotalSupply(item.totalSupply);
            }
          });
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    // }
  }, [blockchain, isLogin]);

  return (
    <HomeC style={{ padding: "0" }}>
      <StyledContainer
        style={{
          background: "#252525",
          margin: 0,
          minWidth: "100%",
          // minHeight: " calc(100vh - 76px)",
        }}
      >
        <FirstSection style={{ marginBottom: "0" }} className="row">
          <Left className="col-md-5 banner-heading">
            <Heading className="pt-5">
              Discover,
              <br />
              collect <br />
              and sell &nbsp;<span>NFT's</span>
            </Heading>
            <ButtonsDiv>
              {!isLogin ? (
                <>
                  <ConnectButton onClick={() => navigate(`/login`)}>
                    Connect Wallet
                  </ConnectButton>
                  <CreateButton
                    onClick={() => toast.error("Please Connect your wallet")}
                  >
                    Create Asset
                  </CreateButton>
                </>
              ) : (
                <>
                  <CreateButton disabled={loadingCreator} onClick={OpenAssetPage}>
                   Create NFT
                  </CreateButton>
                  <CreateButton onClick={() => navigate("/importCollection")}>
                    Import NFT
                  </CreateButton>
                </>
              )}
            </ButtonsDiv>
          </Left>
          <div className="col-md-1"></div>
          <Right style={{ padding: 0 }} className="col-md-6">
            <img className="w-100" src="./homebackground.png" alt="images" />
          </Right>
        </FirstSection>
      </StyledContainer>

      <MainToSectionStyled>
        <StyledContainer
          cards={true}
          style={{
            padding: "0 40px",
            margin: "auto",
            backgroundColor: "#E0E0E0",
            gap: "40px",
          }}
          className="d-flex justify-content-center mobile-view-card"
        >
         
          <WethioNftCard onClick={() => navigate(ROUTES.zynecoin18k)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <HeadingText>Wethio Gold 18K</HeadingText>
                <div className="d-flex mb-3 mt-3 align-items-center">
                  <HeartIcon />
                  <h2 style={{ color: "#4A4A4A", fontSize: "1.4rem" }}>
                    {totalsupplyThird}
                  </h2>
                  <span className="text-color-gray">NFT's created</span>
                </div>
              </div>
              <div>
                <img
                  src={zynecoin}
                  alt=""
                  style={{ height: "auto", width: "auto" }}
                />
              </div>
            </div>
          </WethioNftCard>
          <WethioNftCard onClick={() => navigate(ROUTES.bitcoin)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <HeadingText>Wethio Gold BTC</HeadingText>
                <div className="d-flex mb-3 mt-3 align-items-center">
                  <HeartIcon />
                  <h2 style={{ color: "#4A4A4A", fontSize: "1.4rem" }}>
                    {totalsupply}
                  </h2>{" "}
                  {/* <SubHeadingText>{totalsupply}</SubHeadingText> */}
                  <span className="text-color-gray">NFT's created</span>
                </div>
              </div>
              <div>
                {" "}
                <img
                  src={cube}
                  alt=""
                  style={{ height: "auto", width: "auto" }}
                />
              </div>
            </div>
          </WethioNftCard>
          <WethioNftCard onClick={() => navigate(ROUTES.zynecoin)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <HeadingText>Wethio Gold ZYN</HeadingText>
                <div className="d-flex mb-3 mt-3 align-items-center">
                  <HeartIcon />
                  <h2 style={{ color: "#4A4A4A", fontSize: "1.4rem" }}>
                    {totalsupplySecond}
                  </h2>
                  <span className="text-color-gray">NFT's created</span>
                </div>
              </div>
              <div>
                <img
                  src={bitcoin}
                  alt=""
                  style={{ height: "auto", width: "auto" }}
                />
              </div>
            </div>
          </WethioNftCard>
        </StyledContainer>
      </MainToSectionStyled>
      <StyledContainer>
        <div className="d-flex mt-5 mb-5 align-items-end">
          <HeadingText sectionHeading>Trending Auctions</HeadingText>
          <SubTextRight
            className="text-right-side ml-auto"
            onClick={marketAllAsset}
          >
            Explore
          </SubTextRight>
        </div>
        <TrendingCards />
        <div className="d-flex mt-5 mb-5 align-items-end">
          <HeadingText sectionHeading>Hot Assets</HeadingText>
          <SubTextRight
            className="text-right-side ml-auto"
            onClick={viewAllAsset}
          >
            View all
          </SubTextRight>
        </div>
        <HotCollection />

        <div className="d-flex mt-5 mb-3">
          <HeadingText sectionHeading>Explore Marketplace</HeadingText>
          <SubTextRight
            className="text-right-side"
            onClick={marketAllCollection}
          >
            Explore Market
          </SubTextRight>
        </div>
        <ExploreMarket />
        {/* <CardsDetails /> */}
        {/* <div className="d-flex mt-5 mb-5">
          <HeadingText sectionHeading>Creators</HeadingText>
          <ImageIcon src="./diamondlogo.png" alt="images" />
          <SubTextRight className="text-right-side">
            View all creators
          </SubTextRight>
        </div> */}

        {/* <CardsDetails /> */}
      </StyledContainer>
      <HowItSectionLayout>
        <StyledContainer>
          <div className="row pt-5 pb-5">
            <div className="col-md-4 mt-3" style={{ paddingRight: "2rem" }}>
              <HeadingText
                style={{ color: "black", fontSize: "35px" }}
                className="mt-4 mb-4"
              >
                How it works
              </HeadingText>

              <br />
              <SubText style={{ color: "black", fontWeight: "500" }}>
                Get started by following steps <br /> bellow
              </SubText>
              <a
                style={{ textDecoration: "none" }}
                href="mailto:gold@zynecoin.io"
              >
                Support : gold@zynecoin.io
              </a>
            </div>
            <div className="col-md-4 mt-3">
              <ImageIcon src={one} alt="images" />
              <SubText subHeading>Set up your wallet</SubText>
              <SubText>
                Once you've set up your wallet of choice, connect it to NFT
                Marketplace by clicking this link
              </SubText>
            </div>
            <div className="col-md-4 mt-3">
              <ImageIcon src={two} alt="images" />
              <SubText subHeading>Browse Assets</SubText>
              <SubText>
                Select and set up at an asset. Add social links, a
                description,profile secondary sales fee.
              </SubText>
            </div>
            <div className="col-md-4 mt-3"></div>
            <div className="col-md-4 mt-3">
              <ImageIcon src={three} alt="images" />
              <SubText subHeading>Add your NFTs</SubText>
              <SubText>
                Upload your file, add a title, a description and the properties
                of your NFT .
              </SubText>
            </div>
            <div className="col-md-4 mt-3">
              <ImageIcon src={four} alt="images" />
              <SubText subHeading>List item for sale</SubText>

              <SubText>
                Select auction duration and set floor price or fixed price
                listing
              </SubText>
            </div>
          </div>
        </StyledContainer>
      </HowItSectionLayout>
    </HomeC>
  );
}

export default Home;
