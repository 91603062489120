import React, { useEffect } from 'react';

import { InputGroup, FormControl, FormLabel } from 'react-bootstrap';
import { useField } from 'formik';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import FormErrorMsg from '../FormErrorMsg/FormErrorMsg';
import { PalleteColorTypes } from '../../constants/appConstants';
import { deviceQuery } from '../../styles/mediaSizes';

const InputGroupStyled = styled(InputGroup)`
  .input-group-prepend,
  .input-group-append {
    position: relative;
    z-index: 5;

    .item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: ${(props) => props.theme.typography.subText4};
      color: ${(props) => props.theme.palette.text.primary};
      background-color: ${(props) => props.theme.palette.secondary.light};
    }
  }

  .input-group-prepend {
    .item {
      left: 2px;
    }
  }

  .input-group-append {
    .item {
      right: 2px;
    }
  }
`;

export const LabelStyled = styled(FormLabel)`
  font-size: ${(props) => props.theme.typography.text1};
  font-weight: 600;
  line-height: 1.6rem;
  line-height: 2.1rem;
  opacity: 0.6;
  text-transform: capitalize;

  @media ${deviceQuery.mobileL} {
    font-size: ${(props) => props.theme.typography.text};
  }
`;

export const InputStyled = styled(FormControl)`
  height: ${(props) => (props.height ? props.height : '48px')};

  background-color: ${({ theme, transparent, color = 'secondary' }) =>
    `${theme.palette[color].light}${transparent ? '11' : ''}`};
  /* border: 2px solid transparent; */
  // box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  // border: none;
  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width};
    `}
    $::placeholder { 
      color:${(props) => props.theme.palette.newTheme.lightGray} !important;
      opacity: 1; 
    }
  border-radius:6px;
  ${({ outline, theme, color = 'secondary' }) =>
    outline &&
    css`
      background-color: ${theme.palette[color].light + '11'};
      /* border-color: ${theme.palette[color].light}; */
    `}
  ${(props) =>
    props.isInvalid &&
    css`
      border-color: ${props.theme.palette.common.red + '!important'};
    `}
  ${(props) =>
    props.$unsaved &&
    css`
      border-color: ${props.theme.palette.common.yellow};
    `}
  font-size: ${(props) => props.theme.typography.text1};
  color: ${({ theme, color = 'secondary' }) =>
    color === 'white' ? '#212529' : '#212529'};

  &:focus {
    background-color: ${({ theme, transparent, color = 'secondary' }) =>
      `${theme.palette[color].light}${transparent ? '11' : ''}`};
    ${({ outline, theme, color = 'secondary' }) =>
      outline &&
      css`
        background-color: ${theme.palette[color].light + '11'};
        border-color: ${theme.palette[color].light};
      `}
    color: ${({ theme, color = 'secondary' }) =>
      color === 'white' ? theme.palette.text.dark : theme.palette.text.light};
  }

  &[readonly] {
    background-color: ${({ theme, transparent, color = 'secondary' }) =>
      `${theme.palette[color].light}${transparent ? '11' : ''}`};
  }

  // for autocomplete styled by user-agent
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill::first-line {
    /* border: 2px solid ${(props) =>
      props.theme.palette.common.border.light}; */
    -webkit-text-fill-color: ${(props) =>
      props.theme.palette.text[props.color === 'white' ? 'dark' : 'primary']};
    ${({ theme, transparent, color = 'secondary' }) =>
      css`
        -webkit-box-shadow: 0 0 0px 1000px ${theme.palette[color].main}${transparent
              ? '11'
              : ''} inset,
          0 3px 6px 0 rgb(0 0 0 / 16%);
        box-shadow: 0 0 0px 1000px ${theme.palette[color].main}${transparent
              ? '11'
              : ''} inset,
          0 3px 6px 0 rgb(0 0 0 / 16%);
      `};
    transition: background-color 5000s ease-in-out 0s;
    font-size: ${(props) => props.theme.typography.text1};
    ${({ outline, theme, color = 'secondary' }) =>
      outline &&
      css`
        background-color: ${theme.palette[color].light + '11'};
        /* border-color: ${theme.palette[color].light}; */
      `}
  }
`;

const TextInput = ({
  label,
  labelProps = {},
  showError = true,
  appendElement = null,
  prependElement = null,
  checkUnsave,
  validate,
  onchange,
  value,
  className = '',
  ...props
}) => {
  const [field, meta, helpers] = useField({ ...props, validate });

  useEffect(() => {
    if (value) {
      helpers.setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {label && (
        <LabelStyled htmlFor={props.id || props.name} {...labelProps}>
          {label}
        </LabelStyled>
      )}
      <InputGroupStyled className={className}>
        {!!prependElement && (
          <InputGroup.Prepend>
            <div className="item">{prependElement}</div>
          </InputGroup.Prepend>
        )}

        <InputStyled
          {...field}
          {...props}
          $unsaved={checkUnsave && meta.initialValue !== meta.value}
          isInvalid={showError && meta.error && meta.touched}
        />

        {!!appendElement && (
          <InputGroup.Append>
            <div className="item">{appendElement}</div>
          </InputGroup.Append>
        )}

        {showError && meta.touched && meta.error && (
          <FormErrorMsg>{meta.error}</FormErrorMsg>
        )}
      </InputGroupStyled>
    </>
  );
};

TextInput.propTypes = {
  ...FormControl.propTypes,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  prependElement: PropTypes.node,
  appendElement: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  showError: PropTypes.bool,
  checkUnsave: PropTypes.bool,
  validate: PropTypes.func,
  onchange:PropTypes.func,
  color: PropTypes.oneOf(PalleteColorTypes),
  transparent: PropTypes.number,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(TextInput);
