import styled from 'styled-components';

import Modal from '../Modal/Modal';

export const StyledCollectionCreateModal = styled(Modal)`
  .modal-content {
    .modal-body {
      min-height: 440px;
      .form {
        text-align: left;
        .form-group {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
`;
