import React, { useState, useEffect, useCallback } from "react";
import { SubTextRight } from "../style";
import {
  StuledExploreContainer,
  CardGrid,
  Heading,
  Header,
  HeadingText,
  CreateCollectionStyled,
  SubText,
  StyledUl,
} from "./style";

import { getAllAuctions } from "../../../services/auctionServices";

import { Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { apiHandler } from "../../../services/axios";
import CollectionAuctionCard from "../../CollectionAuctionCard/CollectionAuctionCard";

import InfiniteScroll from "react-infinite-scroll-component";
import { ROUTES } from "../../../constants/routes";
import { useSelector } from "react-redux";

const FullWidthSpinner = () => (
  <div className="d-flex justify-content-center">
    <Spinner variant="light" animation="border" />
  </div>
);

function ExploreMarket() {
  const [allAuctionsData, setAllAuctionsData] = useState([]);
  const [allAuctionsTrending, setAllAuctionsTrending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const blockchain = useSelector((state) => state.global.blockchain);

  const navigate = useNavigate();

  const onSuccessHanadler = useCallback(
    (data, limit, pageNumber) => {
      const { offset: offsetData, total } = data.pagination;
      setAllAuctionsData((prevState) => {
        if (pageNumber === 0) return data.results;
        else return [...prevState, ...data.results];
      });
      setPageNumber((prevState) => prevState + 1);
      setHasMore(offsetData + limit < total);
    },
    [setPageNumber, setHasMore, setAllAuctionsData]
  );

  const onErrorHandler = useCallback(() => {
    setAllAuctionsData([]);
  }, [setAllAuctionsData]);

  const next = () => {
    const api = getAllAuctions;
    apiHandler(() => api(pageNumber, limit), {
      onSuccess: (data) => onSuccessHanadler(data, limit, pageNumber),
    });
  };

  const navigateToAuctionDetailsPage = (auctionId) => {
    navigate(`${ROUTES.auctionDetails.replace(":auctionId", auctionId)}`);
  };

  const filterData = () => {
    let data = allAuctionsData.filter((item) => {
      return (
        item.status === "CLOSE" ||
        (item.status === "CREATED" && item.approvalStatus === "APPROVED")
      );
    });

    setAllAuctionsTrending(data);
  };

  useEffect(() => {
    filterData();
  }, [allAuctionsData]);

  useEffect(() => {
    setLoading(true);
    apiHandler(() => getAllAuctions(0, limit), {
      onSuccess: (data) => {
        setAllAuctionsData(data.results);
      },
      onError: (error) => {
        console.error(error);
        onErrorHandler();
      },
      final: () => setLoading(false),
    });
  }, [limit, onErrorHandler, onSuccessHanadler, blockchain]);

  
  return (
    <>
      <div className="d-flex w-100 justify-content-between mb-5">
        <StyledUl>
          <li>Photography</li>
          <li>Architecture</li>
          <li>Sport</li>
          <li>Gaming</li>
          <li>Arts</li>
          <li>Nature</li>
        </StyledUl>

       
      </div>

      <CreateCollectionStyled>
        {loading ? (
          <FullWidthSpinner />
        ) : allAuctionsData && allAuctionsData?.length ? (
          <Row>
            {allAuctionsData?.slice(0, 9).map((item, i) => (
              <CollectionAuctionCard
                key={i + item._id}
                creator_id={item?.creator_id?._id}
                collectionImage={item?.asset_id?.metadata_fields?.image_url}
                status={item?.status}
                reservePrice={item?.reservePrice}
                contractAuctionId={item?.auctionId}
                followCount={item?.asset_id?.followersCount}
                time={item?.endTime}
                title={item?.asset_id?.name}
                onClick={() => navigateToAuctionDetailsPage(item._id)}
                highestBid={item?.highestBid}
              />
            ))}
            </Row>
        
        ) : (
          <div className="text-center mt-3 mb-3">
            <div>
              <HeadingText>No items found</HeadingText>
            </div>
          </div>
        )}
      </CreateCollectionStyled>
    </>
  );
}

export default ExploreMarket;
