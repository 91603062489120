import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import styled from "styled-components";
import getAxiosInst from "../../services/axios";
import { setUser } from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
const StyledResendButton = styled(ResendOTP)`
  & button {
    background-color: #cfd2cf;
    border: none;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
  }
`;
const OtpModal = ({
  show,
  handleClose,
  email,
  username,
  profile_image_details,
  cover_image_details,
  bio,
  website,
}) => {
  const [emailOTP, setEmailOTP] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const handleOtpSubmit = () => {
    if (emailOTP) {
      getAxiosInst({ withAuth: true })
        .post(`/auth/email/verify`, {
          otp: Number(emailOTP),
        })
        .then((data) => {
          dispatch(setUser(data?.data));
          handleClose();
          setEmailOTP("");
          toast("Profile data updated successfully");
          navigate("/userDetails");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      toast.error("OTP is required");
    }
  };

  const handleResendOtpHandle = () => {
    getAxiosInst({ withAuth: true })
      .get(`/users/${user?.userData?._id}/resend-otp`, {
        otp: Number(emailOTP),
      })
      .then(() => {
        toast("Resend Otp Successfully");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Modal backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title
          style={{ fontSize: "18px", color: "black" }}
        >{`Enter OTP send to ${email}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: "auto", padding: "20px 0" }}>
        <OTPInput
          value={emailOTP}
          onChange={setEmailOTP}
          inputStyles={{
            outline: "none",
            background:
              "radial-gradient(106.42% 275.86% at 0% 0%, #FFFFFF 0%, #E9E6E6 100%) , #C4C4C4",
            border: "0.5px solid #9DC7CA",
            boxSizing: "border-box",
            boxShadow: "0px 0px 6px rgba(119, 116, 255, 0.35)",
            borderRadius: "6px",
          }}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
        />
        <StyledResendButton
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            alignSelf: "end",
            margin: "20px 0 0 0",
          }}
          renderTime={(remainingtime) => {
            return (
              <span style={{ fontSize: "12px", color: "black" }}>
                {remainingtime} seconds remaining
              </span>
            );
          }}
          onResendClick={() => {
            handleResendOtpHandle();
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
            setEmailOTP("");
          }}
        >
          Close
        </Button>
        <Button
          style={{ backgroundColor: "#080b35" }}
          variant="primary"
          onClick={handleOtpSubmit}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OtpModal;
