import React, { useState, useEffect } from "react";

import { Card, Row, Col, Spinner } from "react-bootstrap";

// import EthAmount from '../EthAmount';
import {
  CardFooterSectionLayout,
  CardMoreDetails,
  CardSection,
  CardTitle,
  CountDownTimerStyled,
  CustomImage,
  Footer,
  HeadingText,
  ImageLayout,
  ProfileText,
  ProfileUser,
  StyledCard,
  TimingDiv,
  ToolTipValues,
} from "./style";
import UserIcon from "../../assets/icons/UserIcon";
import PropTypes from "prop-types";
import ProtagonistChip from "../Protagonist";
// import { useLocaleNumber } from "../../hooks/useLocaleNumber";
import { useNavigate } from "react-router-dom";
import AssetComponent from "../AssetComponent";
import { convertEthToNumber } from "../../common/helpers/helper";
import defaultConfig from "../../configuration/config";
import { Avatar } from "@mui/material";
import { AUCTION_STATUS } from "../../constants/appConstants";
import Timer from "../Timer/Timer";
import ShareIcon from "../../assets/icons/shareIcon";
import ShareModal from "../ShareModal/ShareModal";
import TransferIcon from "../../assets/icons/transferIcon";
import { useSelector } from "react-redux";
import { AniMationStyled } from "../CollectionAuctionCard/style";
// import { apiHandler } from "../../services/axios";
// import defaultConfig from "../../configuration/config";
// import { importCollections } from "../../services/assetServices";

const { Body: CardBody } = Card;

const AssetCard = (props) => {
  const {
    status,
    // intialStatus,
    // statusApprove,
    allCollection: _allCollection,
    detailsPageType,
  } = props;

  const [allCollection, setAllCollection] = useState(_allCollection);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const [show, setShow] = useState(false);
  // const formatNumber = useLocaleNumber();
  // const bidValue = 0.917;
  // const formatedBidValue = formatNumber(bidValue);
  const navigate = useNavigate();

  useEffect(() => {}, [status]);

  const getDetailsPage = (asset) => {
    // if (detailsPageType === "auction") {
    //   navigate(`/auctionDetails/${id}`);
    // } else navigate(`/collectionDetails/${id}`);
    // if (
    //   (asset?.onSale === false && asset?.sold === false) ||
    //   asset?.auctions === null
    // ) {
    navigate(`/collectionDetails/${asset?._id}`);
    // } else navigate(`/auctionDetails/${asset?.auctions?._id}`);
  };

  const getFixedPriceValue = (amount, decimal) => {
    const result = convertEthToNumber(amount, decimal);
    return Number(result).toFixed(4);
  };

  const handleShareModaClose = () => setShow(false);
  const handleShareModalShow = () => setShow(true);
  useEffect(() => {
    if (detailsPageType === "auction" && _allCollection) {
      const ass = _allCollection?.map(({ collection_id, _id }) => ({
        ...collection_id,
        auctionId: _id,
      }));

      setAllCollection(ass);
    }
  }, [detailsPageType, _allCollection]);

  return (
    <Row>
      {props.allAssets?.map((items, i) => {
        return (
          <Col key={i} md={4} xs={12} sm={6}>
            <CardSection>
              <CustomImage onClick={() => getDetailsPage(items)}>
                <AssetComponent
                  assetsType="image"
                  src={items?.metadata_fields.image_url}
                />
              </CustomImage>

              {items?.approvalStatus !== "PENDING" ? (
                items?.reservePrice ||
                (items?.auctions && items?.auctions.reservePrice) ? (
                  <ToolTipValues>
                    <p>Current Bid</p>
                    <div className="d-flex align-items-center">
                      {items?.auctions?.status === AUCTION_STATUS.open ? (
                        <AniMationStyled>
                          <div className="solidDot"></div>
                        </AniMationStyled>
                      ) : null}
                      <h4>
                        {items?.auctions?.highestBid
                          ? getFixedPriceValue(
                              items?.auctions?.highestBid?.amount,
                              18
                            )
                          : items?.reservePrice || items?.auctions?.reservePrice
                          ? getFixedPriceValue(
                              items?.reservePrice ||
                                items?.auctions?.reservePrice,
                              10
                            )
                          : 0}
                        &nbsp;
                        {defaultConfig?.chainInfo?.name === "wethio"
                          ? "ZYN"
                          : "ETH"}
                      </h4>
                    </div>
                  </ToolTipValues>
                ) : null
              ) : null}

              <Card.Body
                className="d-flex flex-column "
                onClick={() => getDetailsPage(items)}
              >
                <CardTitle>{items?.name}</CardTitle>
                <CardFooterSectionLayout>
                  <div className="d-flex">
                  {items?.creator_id?.profile_image_url ? <img style={{borderRadius:"20px",height:'40px',width:"40px"}} src={items?.creator_id?.profile_image_url} alt='profile'/>: <Avatar
                $size="small"
                $width="40px"
                $height="40px"
                $imgURL={items?.creator_id?.profile_image_url}
                alt="images"
              />}
                    <div>
                      <ProfileUser>User</ProfileUser>
                      <ProfileText>
                        {items?.creator_id?.username || "anonymous"}
                      </ProfileText>
                    </div>
                  </div>

                  {items?.auctions?.status === AUCTION_STATUS.open ? (
                    <CountDownTimerStyled>
                      <h4>Ending in</h4>
                      <TimingDiv>
                        <Timer
                          timeStamp={items?.auctions?.endTime}
                          unitLabels={{
                            hours: "h",
                            minutes: "m",
                            seconds: "s",
                          }}
                        />
                      </TimingDiv>
                    </CountDownTimerStyled>
                  ) : null}
                </CardFooterSectionLayout>
              </Card.Body>
              <Footer
                className="d-flex justify-content-between align-items-center"
                approvalStatus={items?.approvalStatus}
                status={status}
              >
                {items?.approvalStatus === "PENDING" ? (
                  <HeadingText style={{ margin: "18px 0px" }}>
                    Coming Soon
                  </HeadingText>
                ) : items?.approvalStatus !== "PENDING" ? (
                  <>
                    <CardMoreDetails>
                      <h4>Followers</h4>
                      <p>{items?.followersCount}</p>
                    </CardMoreDetails>

                    <CardMoreDetails>
                      <h4>Reserved Price</h4>
                      <p>
                        {items?.auctions?.reservePrice
                          ? getFixedPriceValue(
                              items?.auctions?.reservePrice,
                              10
                            )
                          : 0}
                        &nbsp;
                        {defaultConfig?.chainInfo?.name === "wethio"
                          ? "ZYN"
                          : "ETH"}
                      </p>
                    </CardMoreDetails>
                    <CardMoreDetails
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShareModalShow()}
                      className="d-flex align-items-center"
                    >
                      <ImageLayout>
                        <TransferIcon />
                      </ImageLayout>
                    </CardMoreDetails>
                    <ShareModal
                      image={items?.metadata_fields?.image_url}
                      show={show}
                      handleClose={handleShareModaClose}
                    />
                  </>
                ) : status === AUCTION_STATUS.close ||
                  status === AUCTION_STATUS.completed ? (
                  <div>
                    <HeadingText>Highest Bid</HeadingText>
                    <p className="mt-2">
                      {getFixedPriceValue(items?.highestBid?.amount, 18)}&nbsp;
                      {defaultConfig?.chainInfo?.name === "wethio"
                        ? "ZYN"
                        : "ETH"}
                    </p>
                  </div>
                ) : (
                  <div className="w-100 d-flex justify-content-center p2">
                    <Spinner animation="border" />
                  </div>
                )}
              </Footer>
            </CardSection>
          </Col>
        );
      })}
    </Row>
  );
};

export default AssetCard;

AssetCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  soldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  remainingTime: PropTypes.string,
  detailsPageType: PropTypes.oneOf(["auction"]),
};
