export const SUPPORTED_IMAGE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
];

export const SUPPORTED_VIDEO_FORMATS = ["video/mp4", "video/webm"];

export const SUPPORTED_ASSET_FORMATS = [
  ...SUPPORTED_IMAGE_FORMATS,
  ...SUPPORTED_VIDEO_FORMATS,
];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const STORAGES = {
  id: "id",
  walletType: "walletType",
  token: "token",
  userData: "userData",
};

export const WALLET_TYPE = {
  metamask: "METAMASK",
};

export const CURRENCY_SYMBOLS = {
  ether: "ETH",
  wrappedEther: "ZYN",
};

export const SUPPORTED_FILE_SIZE = 10 * 1024 * 1024;
export const PalleteColorTypes = [
  "primary",
  "secondary",
  "tertiary",
  "white",
  "black",
];

export const ButtonSizes = ["x-small", "small", "medium", "large"];

export const ONE_ETHER = 10 ** 18;
export const MIN_ETHER = 1 / ONE_ETHER;

export const NETWORK = {
  mainnet: "mainnet",
  rinkeby: "rinkeby",
};

export const CONTRACT_NAME = {
  minting: "minting",
  auction: "auction",
  publicMinting: "publicMinting",
};

export const ASSET_TYPES = {
  image: "image",
  video: "video",
  audio: "audio",
  "3d": "3d",
};

export const AUCTION_STATUS = {
  created: "CREATED",
  open: "OPEN",
  close: "CLOSE",
  completed: "COMPLETED",
};
