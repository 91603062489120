import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const StyledLoader = styled.div`
  display: flex;
  flex-direction: ${({ vertical = false }) => (vertical ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;

  svg {
    width: ${({ width = 'auto' }) => width ?? '24px'};
    height: ${({ width = 'auto' }) => width ?? '24px'};
    animation: ${rotateAnimation} 0.6s infinite linear;
  }

  span.text {
    line-height: 112.1%;
    letter-spacing: 0.1em;
    max-width: 100vw;
    text-align: ${({ vertical = false }) => (vertical ? 'center' : 'left')};
    text-transform: uppercase;
    font-size: 1.4rem;
    margin: ${({ vertical = false }) =>
      vertical ? '2.4rem 0 0' : '0 0 0 1.6rem'};
  }
`;
