import React, { useState } from "react";

import { Card, Row, Col, Spinner } from "react-bootstrap";
import {
  CardMoreDetails,
  CardSection,
  CountDownTimerStyled,
  CustomImage,
  Footer,
  HeadingText,
  ImageLayout,
  ProfileText,
  ProfileUser,
  StyledCard,
  TimingDiv,
  ToolTipValues,
} from "./style";
import UserIcon from "../../assets/icons/UserIcon";
import PropTypes from "prop-types";
import ProtagonistChip from "../Protagonist";
import { useNavigate } from "react-router-dom";
import AssetComponent from "../AssetComponent";
import { convertEthToNumber } from "../../common/helpers/helper";
// import defaultConfig from "../../configuration/config";
import { Avatar } from "@mui/material";
import { AUCTION_STATUS } from "../../constants/appConstants";
import Timer from "../Timer/Timer";
import ShareIcon from "../../assets/icons/shareIcon";
import ShareModal from "../ShareModal/ShareModal";
import TransferIcon from "../../assets/icons/transferIcon";
import { useSelector } from "react-redux";

const { Body: CardBody, Title: CardTitle } = Card;

const AssetCard = (props) => {
  const { status, allCollection } = props;
  const [show, setShow] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const navigate = useNavigate();

  const getFixedPriceValue = (amount, decimal) => {
    const result = convertEthToNumber(amount, decimal);
    return Number(result).toFixed(4);
  };
  const handleShareModaClose = () => setShow(false);
  const handleShareModalShow = () => setShow(true);

  const getDetailsPage = (id) => {
    navigate(`/approveAssetsDetails/${id}`);
  };

  return (
    <Row>
      {allCollection
        ?.filter((val) => {
          if (val?.approvalStatus === status) {
            return val;
          }
        })
        .map((items, index) => {
          return (
            <Col
              md={4}
              xs={12}
              sm={6}
              onClick={() => getDetailsPage(items?._id)}
              key={index}
            >
              <CardSection>
                <CustomImage>
                  <AssetComponent
                    assetsType="image"
                    src={items?.asset_id?.metadata_fields.image_url}
                  />
                </CustomImage>

                {items?.approvalStatus !== "PENDING" ? (
                  items && items.reservePrice ? (
                    <ToolTipValues>
                      <p>Current Bid</p>
                      <h4>
                        {items?.highestBid
                          ? getFixedPriceValue(items?.highestBid?.amount, 18)
                          : items?.reservePrice
                          ? getFixedPriceValue(
                              items?.reservePrice || items?.reservePrice,
                              10
                            )
                          : 0}
                        &nbsp;
                        {defaultConfig?.chainInfo?.name === "wethio"
                          ? "ZYN"
                          : "ETH"}
                      </h4>
                    </ToolTipValues>
                  ) : null
                ) : null}

                <Card.Body className="d-flex flex-column ">
                  <CardTitle>{items?.name}</CardTitle>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="d-flex">
                      <Avatar
                        $size="small"
                        $width="40px"
                        $height="40px"
                        $imgURL={items?.creator_id?.profile_image_url}
                        alt="images"
                      />
                      <div>
                        <ProfileUser>User</ProfileUser>
                        <ProfileText>
                          {items?.creator_id?.username || "anonymous"}
                        </ProfileText>
                      </div>
                    </div>
                    {status === AUCTION_STATUS.open ? (
                      <CountDownTimerStyled>
                        <h4>Ending in</h4>
                        <TimingDiv>
                          <Timer
                            timeStamp={items?.endTime}
                            unitLabels={{
                              hours: "h",
                              minutes: "m",
                              seconds: "s",
                            }}
                          />
                        </TimingDiv>
                      </CountDownTimerStyled>
                    ) : null}
                  </div>
                </Card.Body>
                <Footer
                  className="d-flex justify-content-between align-items-center"
                  approvalStatus={items?.approvalStatus}
                  status={status}
                >
                  {items?.approvalStatus === "PENDING" ? (
                    <HeadingText style={{ margin: "18px 0px" }}>
                      Coming Soon
                    </HeadingText>
                  ) : items?.approvalStatus !== "PENDING" ? (
                    <>
                      <CardMoreDetails>
                        <h4>Followers</h4>
                        <p>{items?.followersCount}</p>
                      </CardMoreDetails>

                      <CardMoreDetails>
                        <h4>Reserved Price</h4>
                        <p>
                          {items?.reservePrice
                            ? getFixedPriceValue(items?.reservePrice, 10)
                            : 0}
                          {defaultConfig?.chainInfo?.name === "wethio"
                            ? "ZYN"
                            : "ETH"}
                        </p>
                      </CardMoreDetails>
                      <CardMoreDetails
                        style={{ cursor: "pointer" }}
                        onClick={() => handleShareModalShow()}
                        className="d-flex align-items-center"
                      >
                        <ImageLayout>
                          <TransferIcon />
                        </ImageLayout>
                      </CardMoreDetails>
                      <ShareModal
                        image={items?.asset_id?.metadata_fields?.image_url}
                        show={show}
                        handleClose={handleShareModaClose}
                      />
                    </>
                  ) : status === AUCTION_STATUS.close ||
                    status === AUCTION_STATUS.completed ? (
                    <div>
                      <HeadingText>Highest Bid</HeadingText>
                      <p className="mt-2">
                        {getFixedPriceValue(items?.highestBid?.amount, 18)}
                        {defaultConfig?.chainInfo?.name === "wethio"
                          ? "ZYN"
                          : "ETH"}
                      </p>
                    </div>
                  ) : (
                    <div className="w-100 d-flex justify-content-center p2">
                      <Spinner animation="border" />
                    </div>
                  )}
                </Footer>
              </CardSection>
            </Col>
          );
        })}
    </Row>
  );
};

export default AssetCard;

AssetCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  soldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  remainingTime: PropTypes.string,
};
