import styled, { css } from "styled-components";
import { flexCenter, poppinsMedium, poppinsBold } from "../../styles/mixins";
import {
  Container,
  Card,
  Button,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { deviceQuery } from "../../styles/mediaSizes";

export const CardTitle = styled(Card.Title)`
  ${poppinsMedium};
  font-size: 1.7rem;
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const CreateCollectionStyled = styled(Container)`
  .card:hover {
    background-color: #fff;
  }
  .card {
    border: none;
    background-color: #fff0cc;
  }
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    color: #b5b6c7;
  }
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px;
    display: inline-block;
  }
`;
export const SwitchContainDiv = styled.div`
  margin-bottom: 1rem;
`;
export const PreviewDiv = styled.div`
  padding: 40px 0px 15rem 0px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px 25px;
  margin-top: 60px;
  margin-bottom: 30px;
  width: 100%;
   
`;
export const RouteStyled = styled(Container)`
  padding: 40px 0px 100px 0px;
  @media ${deviceQuery.laptopM} {
    .MobileView {
      width: 100%;
      display: flex;
    }
    .MobileView div {
      width: 100%;
      text-align: center;
    }
    .MobileView .Horizantalline::after {
      content: "";
      position: absolute;
      width: 16.4%;
      height: 4px;
      background-color: #dcdcdc;
      margin-top: 52px;
    }
  }
`;

export const BackButton = styled(Container)``;

export const VerticalLine = styled.div`
  border-left: 2px solid #b5b6c7;
  height: 100px;
  margin: 20px 0px 20px 46px;
  @media ${deviceQuery.laptopM} {
    display: none;
  }
`;

export const AddressBtn = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.text.gray};
  margin: 0px 0px 0px 8px;
  display: flex;

  padding: 10px 20px;
  border-radius: 40px;
  height: 40px;
  align-items: center;
  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px 10px 0px 0px;
  }
  img {
    width: 16px;
  }
`;

export const TextContent = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.secondary};
 
`;
export const CreateButton = styled(Button)`
  padding: 8px 37px;
  background-color: #feefa4;
  color: #000;
`;
export const Heading = styled.h1`
  margin-top: 4rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const HeadingItem = styled.h1`
  color: ${(props) => props.theme.palette.text.secondary};
  ${poppinsBold};
  font-size: 2rem;
`;
export const MyAccount = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const Icondiv = styled.div`
  text-align: center;
  margin-top: 15px;
  svg {
    width: 80px;
  }
`;

export const StyleInput = styled.div`
  color: ${(props) => props.theme.palette.text.light};
  ${flexCenter};
  background-color: ${(props) => props.theme.palette.text.secondary};
  border-radius: 10px;
  position: relative;
  margin: 0.5rem 0 2rem;

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: none !important;
  }

  input {
     color:#fff !important;
    font-size: 1.6rem;
  }

  .invalid-feedback {
    position: absolute;
    bottom: -20px;
    left: 5px;
  }

  input::placeholder {
    font-size: 1.8rem;
    color: ${(props) => props.theme.palette.text.light};
  }

  input,
  input:focus {
    border: none;
    box-shadow: none !important;
    /* margin: 1rem 0rem; */
    outline: none;
    background: transparent;
  }
`;
export const HeadingEth = styled.h1`
  display: inline-block;
  padding-right: 10px;
  font-size: 1.8rem;
  color: ${(props) => props.theme.palette.text.light};
`;
export const TextHeading = styled.h4`
  font-size: 1.3rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SubText = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const LabelStyled = styled(FormLabel)`
  font-size: 14px;
  margin-left: 50px;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SaveButton = styled.button`
  padding: 15px 45px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10rem 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;

export const MintButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10px 0px;
  background-color: #080b35 !important;

  border: none;
`;

export const ListButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 50%;
  margin: 20px 0px 10px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const InputStyled = styled(FormControl)`
  padding: 12px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none !important;
`;

export const CustomCardStyled = styled(Card)`
  width: 18rem;
  text-align: center;
  color: #000000;
  margin: 20px;
  background-color: #edfbff;
`;
export const CustomCard = styled(Card)`
  padding: 30px 30px 50px;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  .card-title {
    color: ${(props) => props.theme.palette.text.primary};
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      pointer-events: none;
      background-color: #eaeaea !important;
    `}
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  img {
    height: 140px;
  }
  @media ${deviceQuery.laptopM} {
    img {
      height: 100%;
    }
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }
`;
export const Span = styled.span`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const CreateCollectionDiv = styled(Container)`
  .action-btn {
    border-radius: 14px;
    color: #fff;
  }
  .card:hover {
    background-color: #fff;
  }
  .card {
    margin: 0px 30px;
    background-color: #fff;
  }
`;

export const ContractAddess = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const RoundImage = styled.div`
  h1 {
    margin-left: 20px;
  }
  img {
    border-radius: 50%;
    color: #fff;
    width: 70px;
    height: 70px;
  }
`;
export const CoverImage = styled.div`
  text-align: center;
  img {
    width: 386px;
    height: 342px;
    border-radius: 32px;
    color: #fff;
  }
`;
export const Text1 = styled.h1`
  margin-left: 20px;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const Text2 = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const WalletDiv = styled.div`
  width: 400px;
  margin: 10rem 2rem 2rem 2rem;
`;

export const ConnectButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  margin-top: 2rem;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const CommonButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const Text = styled.div`
  color: #080b35;
`;
export const CardProfile = styled(Card)`
  overflow: hidden;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  height: 400px;
  margin: 42px;
  img {
    height: 200px;
  }
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.palette.text.pink} !important;
  .card-title {
    text-align: left;
  }
  .card-body {
    text-align: center;
    margin-top: 60px;
  }
`;
