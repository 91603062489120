import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { css } from "glamor";
import { useLocation, useNavigate } from "react-router-dom";
import getAxiosInst, { apiHandler } from "../../services/axios";
import { updateUser } from "../../services/userServices";
import { UploadAssetForUserProfile } from "../../services/assetServices";
import ProfileImage from "./../../Components/Profile/ProfileImage";
import { Form, Col, Row } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import TextInput from "../../Components/TextInput/TextInput";
import TextArea from "../../Components/Textarea/Textarea";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { setUser } from "../../store/actions/userActions";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import InstagramLogin from "react-instagram-login";
import { BsInstagram } from "react-icons/bs";
import { PROFILE_VALIDATION_MESSAGE } from "../../constants/messages";
import { getSupportedFileString } from "../../common/helpers/helper";
import { FiTwitter } from "react-icons/fi";
import {
  SUPPORTED_ASSET_FORMATS,
  SUPPORTED_IMAGE_FORMATS,
} from "../../constants/appConstants";
import { MdVerified } from "react-icons/md";
import {
  UserProfileContainer,
  Text1,
  InputStyled,
  SaveButton,
  LabelStyled,
  CreateButton,
  FormCheckStyled,
  CustomDivideLine,
  ProfileDiv,
  Button,
  HeadingText,
  StyledInstagramButtonContainer,
  ProfileLayout,
} from "./style";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { getLocalStorageItem } from "../../common/helpers/localStorage";
import OtpModal from "../OtpModal/OtpModal";
import MainTopSection from "../MainTopSection";

const {
  requiredName,
  requiredUsername,
  invalidEmail,
  longUsername,
  shortUsername,
  Divide,

  longName,
  invalidUsername,
  unSupportedFile,
} = PROFILE_VALIDATION_MESSAGE;

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .test("valid-username", invalidUsername.message, (value) =>
      /^(?=[a-zA-Z0-9._])(?!.*[_.]{2})[^_.].*[^_.]$/.test(value)
    )
    .min(5, shortUsername.message)
    .max(100, longUsername.message)
    .required(requiredUsername.message),
  email: Yup.string().email(invalidEmail.message).required(invalidEmail.messageValid),
  bio: Yup.string().required("Bio Required"),
  website: Yup.string().url(),
});
toast.configure();
function UserProfile() {
  const [isSubmiting, setIsSubmiting] = useState({ editUser: false });
  const [isUploading, setIsUploading] = useState({
    profile_image_details: false,
  });
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const location = useLocation();
  const message = location.state?.msg;
  const [disable, setDisable] = useState(true);
  const [formikKey, setFormikKey] = useState("no-user");
  const [validationerror, setValidationError] = useState();
  const [deleteImage, setDeleteImage] = useState(false);
  const userState = useSelector((store) => store.user);
  const { userData } = userState;
  const dispatch = useDispatch();
  const history = useNavigate();
  const back = () => {
    history("/userDetails");
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isTwitterLoader, setIsTwitterLoader] = useState(false);
  const handleEditImage = () => {};

  const handleRemoveImage = (formik) => {
    formik.setFieldValue("profile_image_details", null);
    setDeleteImage(true);
    getAxiosInst({ withAuth: true })
      .put(`users/${userData?._id}/profile-image-details`, {
        profile_image_details: null,
      })
      .then((data) => {
        dispatch(setUser(data?.data));
        toast("Image removed successfully");
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  };

  //OTP STATES
  const [show, setShow] = useState(false);

  const handleOtpModaClose = () => setShow(false);
  const handleOtpModalShow = () => setShow(true);

  // for instagram verfication
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");
  const state = query.get("state");


  useEffect(() => {
    if (userData) {
      setDisable(false);
    }
  }, [userData]);

  useEffect(() => {
    if (message) {
      toast.error(message);
    }
  }, [message]);

  useEffect(() => {
    if (code && !state) {
      setIsLoading(true);
      const blockchain = localStorage.getItem("blockchain");

      getAxiosInst({ withAuth: true })
        .post(`/auth/instagram/verify?network=${blockchain}`, {
          code: code,
        })
        .then((data) => {
          dispatch(setUser(data?.data));
          history("/userProfile");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          history("/userProfile");
          toast.error(err?.response?.data?.message, {
            className: css({
              background: "red !important",
            }),
          });
        });
    }
  }, [code, dispatch, state]);

  useEffect(() => {
    if (code && state) {
      setIsTwitterLoader(true);
      const blockchain = localStorage.getItem("blockchain");

      getAxiosInst({ withAuth: true })
        .post(`/auth/twitter/verify?network=${blockchain}`, {
          code: code,
        })
        .then((data) => {
          dispatch(setUser(data?.data));
          history("/userProfile");
          setIsTwitterLoader(false);
        })
        .catch((err) => {
          setIsTwitterLoader(false);
          history("/userProfile");
          toast.error(err?.response?.data?.message, {
            className: css({
              background: "red !important",
            }),
          });
        });
    }
  }, [code, dispatch, state]);

  const onCollectionSubmitHandler = async (value) => {
    setIsSubmiting((prev) => ({ ...prev, editUser: true }));

    const reqData = {
      username: value.username,
      bio: value.bio,
      email: value.email,
      website: value.website,
    };

    if (value.profile_image_details === null) {
      reqData.profile_image_details = null;
    } else if (value.profile_image_details) {
      reqData.profile_image_details = Object.values(
        value.profile_image_details
      ).every((val) => !!val)
        ? value.profile_image_details
        : undefined;
    }

    try {
      apiHandler(() => updateUser(userData._id, reqData), {
        onSuccess: (data) => {
          if (!data?.isEmailVerified) {
            handleOtpModalShow();
          } else {
            toast("Profile data updated successfully");
            dispatch(setUser(data));
            history("/userDetails");
          }
        },
        onError: (error, res) => {
        

          toast.error(res.data.message, {
            className: css({
              background: "red !important",
            }),
          });

   
        },
        final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        className: css({
          background: "red !important",
        }),
      });
    }
  };

  const responseInstagram = (response) => {
  
  };
  const onImageUploadSuccess = (formik) => (image) => {
    formik.setFieldValue("profile_image_details", image);
  };
  const onImageUpload = (formik) => (value) => {
    setIsUploading((prev) => ({ ...prev, profile_image_details: true }));
    apiHandler(() => UploadAssetForUserProfile(value), {
      onSuccess: (data) => {
        formik.setFieldValue("profile_image_details", {
          base_url: data.url,
          folder: data.folder,
          blobName: data.blobName,
        });
        getAxiosInst({ withAuth: true })
          .put(`users/${userData?._id}/profile-image-details`, {
            profile_image_details: {
              base_url: data?.url,
              folder: data?.folder,
              blobName: data?.blobName,
            },
          })
          .then((data) => dispatch(setUser(data?.data)))
          .catch((err) => console.log(err));
      },
      onError: (error, res) => {
        toast.error("File limit exceeded", {
          className: css({
            background: "red !important",
          }),
        });
      },
      final: () =>
        setIsUploading((prev) => ({ ...prev, profile_image_details: false })),
    });
  };

  useEffect(() => {
    if (userData) {
      setFormikKey("with-user");
    } else {
      setFormikKey("no-user");
    }
  }, [userData]);

  return (
    <>
      <MainTopSection
        backButton={true}
        headingText="User profile"
        descriptionText={""}
        routePath="/userDetails"
      />
      <UserProfileContainer>
        <Formik
          initialValues={{
            username: userData?.username || "",
            bio: userData?.bio || "",
            email: userData?.email || "",
            profile_image_details: userData?.profile_image_details || {
              base_url: "",
              folder: "",
              blobName: "",
            },
            cover_image_details: "",
            website: userData?.website || "",
          }}
          validationSchema={validationSchema}
          onSubmit={onCollectionSubmitHandler}
          key={formikKey}
        >
          {(formik) => (
            <FormikForm>
              <OtpModal
                username={formik.values.username}
                bio={formik.values.bio}
                email={formik.values.email}
                cover_image_details={formik.values.cover_image_details}
                profile_image_details={formik.values.profile_image_details}
                website={formik.values.website}
                handleClose={handleOtpModaClose}
                show={show}
              />

              <Row>
                <Col md={12}>
                  <ProfileDiv $imageUrl={userData?.profile_image_url}   className="d-flex justify-content-center upload-profile-image">
                    <ProfileImage
                      accept={getSupportedFileString(["image"])}
                      onImageUpload={onImageUpload(formik)}
                      id="profile_image_details"
                     
                      onImageUploadSuccess={onImageUploadSuccess(formik)}
                      imageUrl={userData?.profile_image_url || ""}
                      resetImage={deleteImage}
                      setResetImage={setDeleteImage}
                      assetComponentProps={{
                        disableLocalLoad: true,
                        loading: isUploading.profile_image_details,
                      }}
                    />
                  </ProfileDiv>
                </Col>
                <Col md={12}>
                  <div className="d-flex justify-content-center">
                    <Form.Group className="mb-3">
                      {userData?.profile_image_details && (
                        <CreateButton
                          className="bit-btn"
                          color="white"
                          variant="outline-dark"
                          style={{ border: "none", borderRadius: "20px" }}
                          onClick={() => handleRemoveImage(formik)}
                          type="button"
                        >
                          Remove Image
                        </CreateButton>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3"></Form.Group>
                  </div>
                </Col>
              </Row>

              <CustomDivideLine>
                <hr className="horizontal-line  hr3" />
                <span>Verfiy via</span>
                <hr className="horizontal-line  hr3" />
              </CustomDivideLine>

              <ProfileLayout>
                <StyledInstagramButtonContainer
                  instagramUsername={userData?.instagramUsername}
                  disable={true}
                >
                  <InstagramLogin
                    clientId={defaultConfig?.instagram_client_id}
                    cssClass="insta-button-disabled"
                    scope="user_profile"
                    onSuccess={responseInstagram}
                    onFailure={responseInstagram}
                  >
                    <img src="./Insta.png" alt="images" style={{ filter: "grayscale(100%)"}} />
        
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <span>
                          {userData?.instagramUsername
                            ? userData?.instagramUsername
                            : "Verify via Instagram"}
                        </span>
                        {userData?.instagramUsername && <MdVerified />}
                      </>
                    )}
                  </InstagramLogin>
                </StyledInstagramButtonContainer>
                <StyledInstagramButtonContainer
                  instagramUsername={userData?.twitterUsername}
                  disable={disable}
                >
                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${defaultConfig?.twitter_client_id}&redirect_uri=${window.location.origin}/userProfile&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`}
                  >
                    <Button type="button" className="insta-button">
                    <img src="./twitterIcon.png" alt="images" />
                 
                      {isTwitterLoader ? (
                        <CircularProgress size={20} />
                      ) : (
                        <>
                          <span>
                            {userData?.twitterUsername
                              ? userData?.twitterUsername
                              : "Verify via Twitter"}
                          </span>
                          {userData?.twitterUsername && <MdVerified />}
                        </>
                      )}
                    </Button>
                  </a>
                </StyledInstagramButtonContainer>
              </ProfileLayout>
              <Form.Group className="mb-3">
                <TextInput
                  name="username"
                  id="username"
                  type="text"
                  placeholder="User Name"
                  outline="true"
                  color="white"
                />
              </Form.Group>

              <Form.Group style={{ position: "relative" }} className="mb-3">
                <TextInput
                  name="email"
                  id="email"
                  type="text"
                  placeholder="Email"
                  outline="true"
                  color="white"
                />
                {userData?.isEmailVerified &&
                  formik.values.email === userData?.email && (
                    <MdVerified
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translate(0,-50%)",
                        color: "black",
                      }}
                    />
                  )}
              </Form.Group>

              <Form.Group className="mb-3">
           

                <TextArea
                  name="bio"
                  id="bio"
                  outline="true"
                  placeholder="Bio"
                  color="white"
                  height="170px"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <TextInput
                  name="website"
                  id="website"
                  type="text"
                  placeholder="Website"
                  outline="true"
                  color="white"
                />
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <TextInput
                  name="instagramLink"
                  id="instagramLink"
                  type="text"
                  placeholder="Instagram Link"
                  outline="true"
                  color="white"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <TextInput
                  name="twitterLink"
                  id="twitterLink"
                  type="text"
                  placeholder="Twitter Link"
                  outline="true"
                  color="white"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <TextInput
                  name="facebookLink"
                  id="facebookLink"
                  type="text"
                  placeholder="Facebook Link"
                  outline="true"
                  color="white"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormCheckStyled>
                  <Checkbox
                    className="checkBox"
                    id="AuctionNotification"
                    label="Auctions notifications"
                    name="AuctionNotification"
                  />
                </FormCheckStyled>

                <LabelStyled>
                  Receive email notifications when bids you place are confirmed,
                  or you have been outbid and an auction has ended
                </LabelStyled>
              </Form.Group>
              <Form.Group className="mb-3">
                <FormCheckStyled>
                  <Checkbox
                    id="privateSaleNotification"
                    label="Private sale Notifications"
                    name="privateSaleNotification"
                  />
                </FormCheckStyled>

                <LabelStyled>
                  Receive email notifications when someones send you a private
                  sale proposal, or when someone accepts your private sale
                  proposal
                </LabelStyled>
              </Form.Group>
              <Form.Group className="mb-3">
                <FormCheckStyled>
                  <Checkbox
                    id="newNftListingNotification"
                    label="New NFT Listings"
                    name="newNftListingNotification"
                  />
                </FormCheckStyled>

                <LabelStyled>
                  Receive email notifications when profites that you follow list
                  a new NFT on Wethio NFT
                </LabelStyled>
              </Form.Group> */}
              <div>
                <SaveButton
                  variant="primary"
                  type="submit"
                  disabled={
                    isSubmiting?.editUser || isUploading.profile_image_details
                  }
                >
                  Save Changes
                </SaveButton>
              </div>
            </FormikForm>
          )}
        </Formik>
      </UserProfileContainer>
    </>
  );
}

export default UserProfile;
