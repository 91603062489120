import React, { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { RejectedButton, StyledCard } from "./style";
import ProtagonistChip from "../../Components/Protagonist";
import { useNavigate } from "react-router-dom";
import { FiInstagram, FiTwitter } from "react-icons/fi";
import { CommonButton } from "./style";
import MiniLoader from "../../Components/MiniLoader";
import { createContractInstance } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { setContract } from "../../store/actions/contractActions";
import { CONTRACT_NAME } from "../../constants/appConstants";
import getAxiosInst from "../../services/axios";
import { toast } from "react-toastify";

import UserIcon from "../../assets/icons/UserIcon";

const { Body: CardBody, Title: CardTitle } = Card;
toast.configure();
const ArtistCard = (props) => {
  const { status, allCollection, approveAllLoader, setAllCollection, items } =
    props;
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const globalState = useSelector((state) => state.global);

  const getDetailsPage = (id) => {
    navigate(`/artistDetails/${id}`);
  };
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const handleUnapprovedCreators = async (wallet_address, id, status) => {
    try {
      if (status === "APPROVED") {
        const treasuryContractInstance = await createContractInstance(
          globalState?.web3Instance,
          defaultConfig?.treasuryABI,
          defaultConfig?.treasuryContractAddress
        );
   

        await treasuryContractInstance.methods
          .grantMinter(wallet_address)
          .send({ from: user?.userData?.wallet_address });
      }

      const blockchain = localStorage.getItem("blockchain");

      const response = await getAxiosInst({ withAuth: true }).put(
        `/users/creators/approve?network=${blockchain}`,
        { id: id, approvalStatus: status }
      );
      setAllCollection(response?.data);
      toast.success(status);

      setApproveLoading(false);
      setRejectLoading(false);
    } catch (e) {
      toast.error(e?.message);
      setApproveLoading(false);
      setRejectLoading(false);
    }
  };

  return (
    <StyledCard className="asset-card">

      <Col
        md={3}
        style={{ position: "relative", width: "100%", maxWidth: "300px" }}
      >
        <Card
          style={{ gap: "10px", minHeight: "480px" }}
          onClick={() => getDetailsPage(items?._id)}
        >
          {items?.profile_image_url ? (
            <img
              className="asset-img-collection"
              src={items?.profile_image_url}
              alt="Card cap"
            />
          ) : (
            <div
              style={{
                height: "270px",
                width: "100%",
                display: "flex",
                alignItems: "center ",
                justifyContent: "center",
              }}
            >
              <UserIcon style={{ height: "90px", width: "100%" }} />
            </div>
          )}

          <CardBody
            style={{ padding: "0rem 2.4rem 0rem 2.4rem" }}
            className="body mt-2"
          >
            <h4 className="title1 ">{items?.username || "@anonymous"}</h4>
       
            <div
              style={{
                marginTop: "20px",
              }}
            >
              {items?.instagramUsername && (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <FiInstagram />
                  <p
                    style={{
                      width: "100px",
                    }}
                  >
                    {items?.instagramUsername}
                  </p>
                </div>
              )}
              {items?.twitterUsername && (
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <FiTwitter />
                  <p
                    style={{
                      width: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {items?.twitterUsername}
                  </p>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
        <CommonButton
          disabled={rejectLoading || approveLoading || approveAllLoader}
          onClick={() => {
            setApproveLoading(true);
            handleUnapprovedCreators(
              items?.wallet_address,
              items?._id,
              "APPROVED"
            );
          }}
          style={{
            zIndex: "1000",
            position: "absolute",
            bottom: "0rem",
            left: "10px",
            
          }}
        >
          {approveLoading || approveAllLoader ? <MiniLoader /> : "Approve"}
        </CommonButton>
        <RejectedButton
          disabled={rejectLoading || approveLoading || approveAllLoader}
          style={{
            zIndex: "1000",
            position: "absolute",
         
          }}
          onClick={() => {
            setRejectLoading(true);
            handleUnapprovedCreators(
              items?.wallet_address,
              items?._id,
              "REJECTED"
            );
          }}
        >
          {rejectLoading || approveAllLoader ? <MiniLoader /> : "Reject"}
        </RejectedButton>
      </Col>
 
    </StyledCard>
  );
};

export default ArtistCard;
