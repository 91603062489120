import React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({
  fillColor = "#41416e",
  pointer = "pointer",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <path
        d="m564.5 291.763-4.737 4.737 4.735 4.735a.9.9 0 0 1-1.267 1.265l-4.733-4.735-4.735 4.735a.9.9 0 0 1-1.267-1.265l4.735-4.735-4.735-4.735a.895.895 0 1 1 1.265-1.265l4.735 4.735 4.735-4.735a.9.9 0 0 1 1.269 1.263zm11-.632v10.737a11.645 11.645 0 0 1-11.632 11.632h-10.736a11.645 11.645 0 0 1-11.632-11.632v-10.736a11.645 11.645 0 0 1 11.632-11.632h10.737a11.645 11.645 0 0 1 11.631 11.632zm-1.789 0a9.853 9.853 0 0 0-9.842-9.842h-10.737a9.853 9.853 0 0 0-9.842 9.842v10.737a9.853 9.853 0 0 0 9.842 9.842h10.737a9.853 9.853 0 0 0 9.842-9.842z"
        transform="translate(-541.5 -279.5)"
        style={{ fill: fillColor, cursor: pointer }}
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default CloseIcon;
