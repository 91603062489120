import React, { useState } from "react";
import { Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { RejectedButton, StyledCard } from "./style";
import { useNavigate } from "react-router-dom";
import { FiInstagram, FiTwitter } from "react-icons/fi";
import { CommonButton } from "./style";
import MiniLoader from "../../Components/MiniLoader";
import { createContractInstance } from "../../common/common";
import getAxiosInst from "../../services/axios";
import { toast } from "react-toastify";
import UserIcon from "../../assets/icons/UserIcon";
import { useDispatch, useSelector } from "react-redux";
import { CardProfile, SubText } from "../Collection/style";
import AssetComponent from "../../Components/AssetComponent";
import Avatar from "../../Components/Avatar/Avatar";

const { Body: CardBody, Title: CardTitle } = Card;
toast.configure();
const ApproveAsssetsCard = (props) => {
  const { username,assetTitle,image_url,profile_image_url,assetId,updatedApiData, setUpdatedApiData,approveAllLoader } =
    props;
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const globalState = useSelector((state) => state.global);

  const getDetailsPage = (id) => {
    navigate(`/collectionDetails/${id}`);
  };
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const handleUnapprovedCreators = async ( id, status) => {
    try {

        const blockchain = localStorage.getItem("blockchain");

        const response = await getAxiosInst({ withAuth: true }).put(
          `/assets/${id}/update-status?network=${blockchain}`,
          { status }
        );
        toast.success(status);
        setUpdatedApiData(true)
        setApproveLoading(false);
        setRejectLoading(false);
      


    } catch (e) {
      toast.error(e?.message);
      setApproveLoading(false);
      setRejectLoading(false);
    }
  };

  return (
      <Col
        md={4}
        
      >
          <CardProfile style={{margin:"20px 10px"}}>
            {image_url ? (
              <AssetComponent
                assetType="image"
                className="createNftImage"
                freeSize
                src={image_url}
                onClick={() => getDetailsPage(assetId)}
              />
            ) : (
              <Spinner animation="border" />
            )}
            <Card.Body>
              <CardTitle>{assetTitle}</CardTitle>
              <div className="d-flex align-items-center pt-3 pb-3">
                <Avatar
                  $imgURL={profile_image_url}
                  $height="48px"
                  $width="60px"
                  $size="small"
                />
                <SubText className="mb-0 pl-3" stye={{marginLeft:"12px",textAlign:"left"}}>
                  {username ? username : "@anonymous"}
                </SubText>
              </div>
        <div className="d-flex justify-content-center">
            <CommonButton
          disabled={rejectLoading || updatedApiData || approveLoading || approveAllLoader}
          onClick={() => {
            setApproveLoading(true);
            handleUnapprovedCreators(
              assetId,
              "APPROVED"
            );
          }}
        >
          {approveLoading || approveAllLoader ? <MiniLoader /> : "Approve"}
        </CommonButton>
        <RejectedButton
          disabled={rejectLoading || approveLoading || approveAllLoader}
          onClick={() => {
            setRejectLoading(true);
            handleUnapprovedCreators(
              assetId,
              "REJECTED"
            );
          }}
        >
          {rejectLoading || approveAllLoader ? <MiniLoader /> : "Reject"}
        </RejectedButton>
        </div>
        </Card.Body>
          </CardProfile>

  
      </Col>
  );
};

export default ApproveAsssetsCard;
