import { useEffect } from 'react';
import { COMMON_MESSAGE } from '../constants/messages';

export const useWarnForUnsavedChanges = ({
  check,
  msg = COMMON_MESSAGE?.unsavedChanges?.message,
}) => {
  const onberforeUnloadHandler = (e) => {
    if (check) {
      e.preventDefault();
      return (e.returnValue = '');
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onberforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', onberforeUnloadHandler);
    };
  });

  return null;
};
