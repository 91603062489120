import React from "react";
import { useNavigate } from "react-router";

import styled from "styled-components";

export const MainToSectionStyled = styled.div`
  background-color: #e0e0e0;
  text-align: center;
  padding: 30px 0px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  gap:40px;
  align-items: center;
  .backButton {
    cursor: pointer;
  }
`;
export const Text1 = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  margin: 0px 0px 0px 8px;
`;
export const TextStyled = styled.div`
  width: 48%;
`;

export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.newTheme.mainHeadingTex};
`;
export const SubMainText = styled.p`
  color: #1f1f2c;
`;

const MainTopSection = ({
  backButton,
  headingText,
  descriptionText,
  routePath,
  text,
}) => {
  const history = useNavigate();

  return (
    <MainToSectionStyled>
      {backButton ? (
        <div
          className="d-flex backButton align-items-center "
          onClick={() => history(routePath)}
        >
          <img src="./backIcon.png" alt="Card cap" />
          <Text1>Go back</Text1>
        </div>
      ) : null}
      <TextStyled>
        <HeadingText>{headingText}</HeadingText>
        {descriptionText !== "" ? (
          <SubMainText className=" text1">
            <span className="content">{descriptionText}</span>
            <br />
            <span> {text}</span>
          </SubMainText>
        ) : null}
      </TextStyled>
    </MainToSectionStyled>
  );
};

export default MainTopSection;
