import styled from "styled-components";

export const SearchField = styled.div`
  min-width: 150px;
  margin-left: 20px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.4px solid #080b35;
  border-radius: 10px;
  overflow: hidden;

  input {
    margin: 0;
    flex: 1;
    min-width: 1px;
    height: 45px;
    border: none;
    outline: none;
    padding-left: 0;
    padding-right: 5px;

    &:focus {
      box-shadow: none;
    }
  }
  svg {
    width: 29px;
    height: 23px;
    margin: 6px;
    padding: 1px;
  }
`;
