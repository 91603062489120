import React, { useState, useEffect } from "react";
import {
  CommonButton,
  SubText,
  HeadingText1,
  HeadingText,
  Heading,
  Text,
  CustomCard,
  LoaderArea,
} from "../../pages/Collection/style";
import { StyledCard } from "../../Components/CommonAssetCard/style";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { apiHandler } from "../../services/axios";
import { getAllCollection } from "../../services/collectionServices";
import CommonAssetCard from "../../Components/CommonAssetCard/CommonAssetCard";
import FullSizeLoader from "../../Components/FullSizeLoader";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../services/userServices";
import {
  createWeb3Instance,
  createContractInstance,
  createProvider,
} from "../../common/common";
import { getAllAssets } from "../../services/assetServices";
import { CreateCollectionStyled } from "./style";

function HotCollection() {
  const [allCollection, setAllCollection] = useState({
    loading: true,
    data: [],
  });
  const [approvedCollection, setApprovedCollection] = useState();
  const globalState = useSelector((store) => store.global);
  const defaultClass = "protagnistChip";
  const [className, setClassName] = useState(defaultClass);
  const [isAdminUser, setIsAdminUser] = useState();
  const blockchain = useSelector((state) => state.global.blockchain);

  const { web3Instance, error } = globalState;
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const IsAdmin = async (address) => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    if (address) {
      const isAdmin = await ContractInstance.methods.isAdmin(address).call();

      setIsAdminUser(isAdmin);
    }
  };
  const getCreaterDetails = async (id) => {
    if (id) {
      apiHandler(() => getUser(id), {
        onSuccess: (data) => {
          IsAdmin(data?.wallet_address);
        },
        onError: () => {
          console.error(error);
        },
        final: () => {},
      });
    }
  };

  const checkUserIsAdmin = async () => {
    allCollection?.data?.map((createrId) => {
      getCreaterDetails(createrId?.creator_id?._id);
    });
  };
  useEffect(() => {
    checkUserIsAdmin();
  }, [allCollection]);
  const page = 1;
  const limit = 100;

  const getCollectionDetails = async () => {
    setAllCollection({ ...allCollection, loading: true });
    apiHandler(() => getAllAssets({ page, limit }), {
      onSuccess: (data) => {
        setAllCollection({ data: data.results, loading: false });
      },
      onError: (error) => {
        console.error(error);
        setAllCollection({ ...allCollection, loading: false });
      },
    });
  };

  const getApprovedCollection = () => {
    let approvedata = allCollection?.data?.filter(
      (items) =>
        items?.auctions?.approvalStatus !== "APPROVED" ||
        items?.auctions === null
    );

    setApprovedCollection(approvedata);
  };

  useEffect(() => {
    getApprovedCollection();
  }, [allCollection, blockchain]);

  useEffect(() => {
    getCollectionDetails();
  }, [blockchain]);


  return (
    <CreateCollectionStyled>
      {allCollection.loading ? (
        <LoaderArea>
          <FullSizeLoader />
        </LoaderArea>
      ) : (
   
          <Row>
            {approvedCollection?.length > 0 ? (
              approvedCollection?.slice(0, 3).map((items, i) => {
                return (
                  <CommonAssetCard
                    key={i + items._id}
                    createrId={items?.creator_id}
                    collectionId={items?._id}
                    collectionImage={items?.metadata_fields?.image_url}
                    status={items?.status}
                    reservePrice={items?.reservePrice}
                    contractAuctionId={items?.auctionId}
                    time={items?.endTime}
                    followCount={items?.followersCount}
                    title={items?.name}
                    highestBid={items?.highestBid}
                    isAdminUserTrue={true}
                  />
                );
              })
            ) : (
              <div className="text-center">
                <Text>
                  <HeadingText1>No items found</HeadingText1>
                </Text>
              </div>
            )}
          </Row>
   
      )}
    </CreateCollectionStyled>
  );
}

export default HotCollection;
