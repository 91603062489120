import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StyledLoader } from './style';

const LineLoader = (props) => {
  const { color, active, width = 100, ...rest } = props;
  const [parentWidth, setParentWidth] = React.useState(0);
  const parentElement = useRef(null);

  useEffect(() => {
    if (parentElement.current) {
      setParentWidth(parentElement.current?.offsetWidth);
    
    }
  }, []);

  return (
    <StyledLoader
      ref={parentElement}
      color={color}
      width={width}
      $active={active}
      parentWidth={parentWidth}
      {...rest}
    >
      <div className="wanderer"></div>
    </StyledLoader>
  );
};

export default LineLoader;

LineLoader.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
};
