import styled from "styled-components"
import { deviceQuery } from "../../styles/mediaSizes";


export const HeadingText= styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: ${p => p.theme.ff.unifont};
`;


export const SubTextGray = styled.p`
  color: ${(props) => props.theme.palette.text.gray};


  @media ${deviceQuery.tabletM} {
    width:100% !important;
  }
`;