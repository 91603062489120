import React from 'react';
const style={fill:'#b5b6c7',stroke: 'rgb(181, 182, 199)'}
const styleTwo={opacity:0.25}
const mintingStepTwo = ({ fillColor = '#080b35' }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="95.12" height="104.642" viewBox="0 0 95.12 104.642">
    <defs>

    </defs>
    <g id="Groupe_6659" data-name="Groupe 6659" style={styleTwo}>
        <path id="Tracé_18495" data-name="Tracé 18495" className="cls-2"  style={style} d="M0 54.792 47.7 27.25l47.42 27.376-47.793 27.157z" transform="translate(0 22.859)"/>
        <path id="Tracé_18496" data-name="Tracé 18496" className="cls-2" style={style} d="m0 82.025 47.7-27.541v50.109L0 132.134z" transform="translate(0 -54.484)"/>
        <path id="Tracé_18497" data-name="Tracé 18497" d="M48.133 104.761V54.652l47.415 27.375v50.109z" transform="translate(-.428 -54.652)" style={{fill:fillColor}}/>
    </g>
</svg>
  );
};

export default mintingStepTwo;
