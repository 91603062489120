import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { ImFacebook } from "react-icons/im";
import ShareIt from "share.it";
import { FiTwitter } from "react-icons/fi";
import { useEffect } from "react";

const ShareModal = ({ image, show, handleClose }) => {
  useEffect(() => {
    var share = new ShareIt({
      specs: {
        width: 720,
        height: 300,
      },
    });

    share.init();
  }, [show]);

  return (
    <Modal backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ fontSize: "18px", color: "black" }}>
          Share to Facebook, Twitter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ margin: "auto", padding: "20px 0" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            style={{
              padding: "5px 10px",
              backgroundColor: "#4267B2",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
            data-share
            data-share-facebook
            data-share-facebook-url={window.location.href}
          >
            <ImFacebook />
            <span style={{ marginLeft: "5px" }}>Facebook</span>
          </button>

          <button
            style={{
              padding: "5px 10px",
              backgroundColor: "#1DA1F2",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
            data-share
            data-share-twitter
            data-share-twitter-url={window.location.href}
          >
            <FiTwitter />
            <span style={{ marginLeft: "5px" }}>Twitter</span>
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
