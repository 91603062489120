import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiHandler } from "../../services/axios";
import { importCollections } from "../../services/assetServices";
import { toast } from "react-toastify";
import { css } from "glamor";

import {
  Form,
  Col,
  Row,
  Spinner,

} from "react-bootstrap";

import { Formik, Form as FormikForm, FieldArray } from "formik";
import TextInput from "../../Components/TextInput/TextInput";
import SelectInput from "../../Components/SelectInput/SelectInput";
import { addTokenID, DeleteTokenID } from "../../store/actions/userActions";
import * as Yup from "yup";
import CloseIcon from "../../assets/icons/closeIcon";
import { useDispatch, useSelector } from "react-redux";

import { PROFILE_VALIDATION_MESSAGE } from "../../constants/messages";

import {
 
  createContractInstance,

} from "../../common/common";


import {
  UserProfileContainer,
  Text1,

  SaveButton,
  LabelStyled,
  StyleInput,

  CloseIconDiv,
  AddButton,
  StyledButton
} from "./style";
import MainTopSection from "../../Components/MainTopSection";


const {

  contractAddress,

} = PROFILE_VALIDATION_MESSAGE;

const validationSchema = Yup.object().shape({
  contract_address: Yup.string()
    .test("valid-username", contractAddress.message, (value) =>
      /^(?=[a-zA-Z0-9._])(?!.*[_.]{2})[^_.].*[^_.]$/.test(value)
    )
    .min(5, contractAddress.message)
    .max(100, contractAddress.message)
    .required(contractAddress.message),
  tokenId: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().required("Please Add the Token Id"),
    })
  ),

});



function ImportCollection() {

  const [loading, setLoading] = useState(false);
  const [addloading, setAddLoading] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const [formikKey, setFormikKey] = useState("no-user");
  const [itemAdded, setItemAdded] = useState(0);
  const [inputValue, setInputValue] = useState();
  const [metadataUrl, setTMetaDataUrl] = useState();
  const userState = useSelector((store) => store.user);
  const globalState = useSelector((store) => store.global);
  const assetsData = useSelector((store) => store.assetsData);
  const { account } = globalState;
  const { web3Instance } = globalState;
  const { userData } = userState;
  const [noOfTokenAdded, setAddItem] = useState(0);

  const [selectAddressOptions, setSelectAddressOptions] = useState([
    {
      label: "Wethio Public Minting",
      value: defaultConfig?.publicMintingContractAddress,
    },
    {
      label: "Wethio Gold ZYN",
      value: defaultConfig?.mintingContractAddressZyneCoinGold,
    },
    {
      label: "Wethio Gold 18K",
      value: defaultConfig?.mintingContractAddressZyneCoin18k,
    },
    {
      label: "Wethio Gold BTC",
      value: defaultConfig?.mintingContractAddressBitCoinNft,
    },
  ]);

  toast.configure();
  const id = userData?._id;
  const dispatch = useDispatch();
  const history = useNavigate();
  const back = () => {
    history("/");
  };

  const EMPTY_ATTRIBUTE_VALUE = { value: "" };

  const onDeleteListItemHandler = (arrayHelpers, index, formik) => {
    dispatch(DeleteTokenID(index));
    if (formik.values.tokenId.length === 1) {
      arrayHelpers.replace(0, EMPTY_ATTRIBUTE_VALUE);
    } else {
      arrayHelpers.remove(index);
    }
    setAddItem(noOfTokenAdded - 1);
  };



  const onSubmitTokenId = async (i, formik) => {
    let InputId = formik.values.tokenId[i].value;
    setInputValue(formik.values.tokenId[i].value);
    setAddLoading(true);

    let d = assetsData?.id?.filter((id) => {
      return id === InputId;
    });

    if (id && d.length === 0) {
      const treasuryContractInstance = await createContractInstance(
        web3Instance,
        defaultConfig?.mintingABI,
        formik.values.contract_address
      );

      if (InputId?.trim()) {
        try {
          const checkOwner = await treasuryContractInstance.methods
            .ownerOf(InputId)
            .call();

          let addressCheck = checkOwner.toLowerCase();
          let Useraccount = account.toLowerCase();

          if (addressCheck === Useraccount) {
            const getMetaDataUrl = await treasuryContractInstance.methods
              .tokenURI(InputId)
              .call();
            const res = await dispatch(addTokenID(InputId, getMetaDataUrl));
            setItemAdded(itemAdded + 1);
            setAddItem(noOfTokenAdded + 1);
            setAddLoading(false);
            setTMetaDataUrl(getMetaDataUrl);
            toast("Items added successfully");
            setAddLoading(false);
          } else {
            toast.error("Items are not found on your account");
            setAddLoading(false);
          }
        } catch (error) {
          toast.error("Assets id does not exist", {
            className: css({
              background: "red !important",
            }),
          });
          setAddLoading(false);
        }
      } else {
        toast.error("Items should not empty");

        setAddLoading(false);
      }
    } else {
      if (d.length > 0) {
        toast.error("Token id must be uniquie");
      } else {
        toast.error("Items not added");
      }
 
      setAddLoading(false);
    }
  };

  const onSubmitHandler = async (value) => {
    try {
     
       if(!metadataUrl){
        toast.error("Please validate token Id with add button");
       }
      const reqData = {
        contract_address: value.contract_address,
        token_id: assetsData.id[0],
      };
      setLoading(true);
      await apiHandler(() => importCollections(reqData), {
        onSuccess: (data) => {
          toast("Data imported");

          setLoading(false);
          history("/userDetails");
          Formik.resetForm();
        },

        onError: (error) => setLoading(false),
        final: () => {},
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  useEffect(() => {
    if (userData) {
      setFormikKey("with-user");
    } else {
      setFormikKey("no-user");
    }
  }, [userData]);

  return (
    <>
       <MainTopSection
        backButton={true}
        headingText="Import Asset"
        descriptionText={""}
        routePath="/"
      />
      
      <UserProfileContainer>
       
 
        <Formik
          initialValues={{
            contract_address: "",
            tokenId: [{ value: "" }],
     
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await onSubmitHandler(values);
            resetForm();
          }}
     
          key={formikKey}
        >
          {(formik) => (
            <FormikForm>
              <Form.Group className="mb-3 mt-3">
                <LabelStyled>Contract Name</LabelStyled>
                <SelectInput
                  name="contract_address"
                  id="contract_address"
                  options={selectAddressOptions}
                  placeholder="Select a Contract Address"
                  outline="true"
                  color="white"
                  transparent="true"
                  // extraItem={<Spinner animatio="true" />}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                {
                  <FieldArray name={"tokenId"}>
                    {(arrayHelpers) => (
                      <>
                        {formik.values?.tokenId?.map((tokenId, i) => (
                          <Row
                            key={i}
                            className="align-items-center mt-5 justify-content-center"
                          >
                            <Col xs={2}>
                              <LabelStyled>Token Id</LabelStyled>
                            </Col>
                            <Col xs={6}>
                              <TextInput
                                name={`tokenId.${i}.value`}
                                id={`tokenId.${i}.value`}
                                type="text"
                                placeholder="Enter Value"
                                outline="true"
                                color="white"
                              />
                            </Col>
                            <Col xs={4} className="d-flex">
                              {tokenId?.value &&
                              assetsData?.id &&
                              tokenId?.value === assetsData?.id[i] ? (
                                <CloseIconDiv
                                  style={{
                                    cursor: "ponter",
                                    marginLeft: "60px",
                                  }}
                                >
                                  <CloseIcon
                                    onClick={() =>
                                      onDeleteListItemHandler(
                                        arrayHelpers,
                                        i,
                                        formik
                                      )
                                    }
                                  />
                                </CloseIconDiv>
                              ) : addloading ? (
                                <div className="spinner-wrapper d-flex justify-content-center">
                                  <Spinner
                                    animation="border"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      color: "#080b35",
                                    }}
                                  />
                                </div>
                              ) : (
                                <AddButton>
                                  <StyledButton
                                    type="button"
                                    onClick={() => onSubmitTokenId(i, formik)}
                                    className=" d-block mx-auto"
                                    color="primary"
                                    disabled={tokenId?.value ? false : true}
                                  >
                                    Add Item
                                  </StyledButton>
                                </AddButton>
                              )}

                              {/* )} */}
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </FieldArray>
                }
              </Form.Group>

              {loading ? (
                <div className="spinner-wrapper d-flex justify-content-center mt-5">
                  <Spinner
                    animation="border"
                    style={{ width: "60px", height: "60px", color: "#080b35" }}
                  />
                </div>
              ) : (
                <div>
                  <SaveButton variant="primary" type="submit">
                    Submit
                  </SaveButton>
                </div>
              )}
            </FormikForm>
          )}
        </Formik>
      </UserProfileContainer>
      ;
    </>
  );
}

export default ImportCollection;
