import React from "react";

import PropTypes from "prop-types";

import { Navigate } from "react-router-dom";

import { checkIfLogin } from "../common/helpers/helper";

function AdminRoutes({ children }) {
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  return checkIfLogin() && isAdmin ? (
    children
  ) : (
    <>
      <Navigate to="/" />
    </>
  );
}

AdminRoutes.propTypes = {
  children: PropTypes.node,
};

export default AdminRoutes;
