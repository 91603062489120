import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { apiHandler } from "../../services/axios";
import { createCollection } from "../../services/collectionServices";
import { css } from "glamor";
import { StyledCollectionCreateModal } from "./style";
import CollectionCreateForm from "./CollectionCreateForm";
import { Formik } from "formik";
toast.configure();
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  contract_address:Yup.string().required("is required"),
  description: Yup.string().required("Description is required"),
  cover_image_details: Yup.mixed().test(
    "coverImageRequired",
    "Cover Image Is Required",
    (value) => Object.values(value).every((val) => !!val)
  ),
});

const CollectionCreateModal = ({ show, onHide, onSubmit }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onCollectionSubmitHandler = async (value) => {
    setIsSubmiting(true);
    const reqData = {
      name: value.name,
      contract_address:value.contract_address,
      description: value.description,
      cover_image_details: value.cover_image_details,
    };

   try{ apiHandler(() => createCollection(reqData), {
      onSuccess: (data) => {
        toast("New collection created");

        onSubmit && onSubmit(data);
        onHide && onHide();
      },
      onError: (error) => {
        toast.error("Please keep unique name of collection", {
          className: css({
            background: "red !important",
          }),
        });
        console.error(error);
      },
      final: setIsSubmiting(false),
    });

  }catch(error){
    toast.error("Failed collection", {
      className: css({
        background: "red !important",
      }),
    });

  }
  };

  return (
    <StyledCollectionCreateModal
      show={show}
      onHide={onHide}
      centered
      showCloseButton
    >
      <Formik
        initialValues={{
          name: "",
          contract_address:"",
          description: "",
          cover_image_details: {
            base_url: "",
            folder: "",
            blobName: "",
          },
        }}
        validationSchema={validationSchema}
        onSubmit={onCollectionSubmitHandler}
      >
        {() => <CollectionCreateForm isSubmiting={isSubmiting} />}
      </Formik>
    </StyledCollectionCreateModal>
  );
};

CollectionCreateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CollectionCreateModal;
