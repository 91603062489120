import * as actionTypes from "../../constants/actionTypes";
import config from "../../configuration/config";

const blockchain = localStorage.getItem("blockchain");
const initGlobalState = {
  loading: false,
  account: "",
  signature: "",
  balance: 0,
  wethBalance: 0,
  activeConfig: config?.wethConfig,
  error: null,
  errorMsg: "",
  errorTitle: "",
  isCreator: false,
  isBlocked: false,
  errorBtnLabel: "",
  errorCallback: null,
  blockchain: blockchain,
  walletModal: {
    show: false,
  },

  currentChainId: null,

  etherCurPrice: 0,

  web3Instance: null,
  provider: null,

  walletType: "",

  hasMetamask: !!window.web3 || !!window.ethereum, // to check if the user has metamask
};

export const globalReducer = (state = initGlobalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: payload.loading };
    case actionTypes.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        account: payload.account,
        signature: payload.signature,
        balance: payload.balance,
      };
    case actionTypes.SET_HAS_METAMASK:
      return { ...state, hasMetamask: payload.hasMetamask };
    case actionTypes.UPDATE_BLOCKCHAIN:
      return {
        ...state,
        blockchain: payload.blockchain,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: payload.error,
        errorMsg: payload.errorMsg,
        errorTitle: payload.errorTitle,
        errorBtnLabel: payload.errorBtnLabel,
        errorCallback: payload.errorCallback ?? null,
      };
    case actionTypes.SET_CREATOR:
      return {
        ...state,
        isCreator: payload.isCreator,
      };
    case actionTypes.SET_BLOCKED:
      return {
        ...state,
        isCreator: payload.isBlocked,
      };
    case actionTypes.SET_CHAIN_ID:
      return { ...state, currentChainId: payload.currentChainId };
    case actionTypes.SET_ETHER_CUR_AMOUNT:
      return { ...state, etherCurPrice: payload.etherCurPrice };
    case actionTypes.SET_WEB3_INSTANCE:
      return { ...state, web3Instance: payload.web3Instance };
    case actionTypes.UPDATE_PROVIDER:
      return { ...state, provider: payload.provider };
    case actionTypes.SHOW_WALLET_MODAL:
      return { ...state, walletModal: { show: true } };
    case actionTypes.HIDE_WALLET_MODAL:
      return { ...state, walletModal: { show: false } };
    case actionTypes.TOGGLE_WALLET_MODAL:
      return { ...state, walletModal: { show: !state.walletModal?.show } };
    case actionTypes.SET_WALLET_TYPE:
      return { ...state, walletType: payload.walletType };

    case actionTypes.SET_ACTIVE_CONFIG:
      return { ...state, activeConfig: payload.config };
    default:
      return state;
  }
};
