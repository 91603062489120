import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${(props) => props?.freeSize ? 0 : `calc(100% / ${props?.aspectRatio})`};
  overflow: hidden;

  .asset-wrap {
    position: ${(props) => (props?.freeSize ? 'static' : 'absolute')};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    // border-bottom-left-radius: 37px;
    // border-bottom-right-radius: 37px;
    overflow: hidden;
    .asset {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
    img{
      border-radius:14px;
    }
  }

  .loader-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.palette.common.light}88;
    color: ${(props) => props.theme.palette.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
`;
