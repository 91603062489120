import styled, { css } from "styled-components";
import { deviceQuery } from "../../styles/mediaSizes";
import { Dropdown, Card, Container } from "react-bootstrap";
import {
  poppinsLight,
  poppinsMedium,
  poppinsSemibold,
} from "../../styles/mixins";

export const StyledContainer = styled(Container)`
  @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
    max-width: 860px;
  }
  flex-wrap: wrap;
  @media (max-width: 1400px) {
    ${(props) => props?.cards && "min-width: 100% !important"}
  }
  @media (min-width: 1400px) {
    ${(props) => props?.cards && "padding:0 !important"}
  }
  /* width: 80%; */
`;
export const CreateCollectionStyled = styled.div`
  .card:hover {
    background-color: #fff;
  }
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .img-upload .asset-wrap .asset {
    height: 255px;
  }
  .preview-img {
    object-fit: cover;
  }
  .nav-link {
    color: #b5b6c7;
  }
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px;
    display: inline-block;
  }
`;
export const HowItSectionLayout = styled.div`
  background-color: #e0e0e0;
  margin-top: 30px;
  @media ${deviceQuery.tabletM} {
    text-align: center;
    h1 {
      text-align: center;
    }
  }
`;
export const Heading = styled.h1`
  text-transform: uppercase;
  color: #ffffff;
  font-size: 3rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  span {
    color: ${(props) => props.theme.palette.newTheme.darkYellow};
  }
  /* @media ${deviceQuery.mobileL} {
        font-size: 1.4rem;
    }    */
`;
export const HomeC = styled.div`
  @media ${deviceQuery.laptopM} {
    padding-top: 5rem;
  }
  @media ${deviceQuery.tabletM} {
    padding-top: 1rem;
  }
  @media ${deviceQuery.mobileSM} {
    padding-top: 0rem;
  }
`;
export const ButtonsDiv = styled.div`
  margin: 1rem 0;

  button:nth-child(1) {
    margin-right: 1rem;
  }
`;
export const Data = styled.div`
  align-items: center;
  margin: 10px 0;

  img {
    width: 20px;
    margin-right: 10px;
  }

  span {
    font-size: 0.7rem;
    color: #888;
    margin-left: 5px;
  }
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.newTheme.darkYellow};
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  @media ${deviceQuery.mobileL} {
    font-size: 1.4rem;
  }
`;
export const CardTitle = styled(Card.Title)`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const SubHeadingText = styled.h2`
  font-size: 1.4rem;
  color: ${(props) => props.theme.palette.text.primary};
  margin: 0;
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  text-align: -webkit-center;
  justify-content: center;
`;
export const Right = styled.div`
  position: relative;
`;
export const FirstSection = styled.section`
  margin-bottom: 6rem;
  @media ${deviceQuery.tabletM} {
    display: inline;
    flex: auto;
  }
  @media ${deviceQuery.mobileSM} {
    margin-bottom: 25rem;
  }
`;
export const CustomCardStyled = styled.div`
  color: white;
  display: flex;
  position: absolute;
  left: -6rem;
  top: 247px;
  right: 0;
  padding: 2rem;
  border-top-left-radius: 50px;
  object-fit: contain;
  backdrop-filter: blur(15px);
  box-shadow: 15px 15px 50px 0 rgb(0 0 0 / 16%);
  background-color: rgba(0, 0, 0, 0.16);

  & > div {
    display: flex;
    flex-direction: column;

    .content {
      border-right: 2px solid white;
      padding: 0 3rem 0 0rem;
    }

    &:nth-child(1) {
      width: max-content;

      button {
        background: ${(p) => p.theme.palette.primary.main};
        color: ${(p) => p.theme.palette.secondary.main};
      }
    }
    &:nth-child(2) {
      padding: 0 0 0 3rem;

      button {
        margin-top: 12px;
        background: ${(p) => p.theme.palette.white.main};
        color: ${(p) => p.theme.palette.secondary.main};
        margin-left: auto;
      }
    }

    h1 {
      font-size: 1.1rem;
      font-family: Poppins "sans-serif";
    }
  }

  .value {
    color: ${(p) => p.theme.palette.secondary.main};
    font-size: 2rem;
    margin: 0;
  }

  .sm-value {
    color: ${(p) => p.theme.palette.secondary.main};
    font-size: 1.2rem;
    margin: 0;
    white-space: nowrap;
  }

  button {
    margin-top: 1.5rem;
    border: none;
    width: 100%;
    max-width: 140px;
  }

  @media ${deviceQuery.tabletM} {
    left: -1rem;
  }

  @media ${deviceQuery.mobileSM} {
    top: 150px;
    flex-direction: column;
    align-items: center;

    & > div {
      &:nth-child(1) {
        margin-bottom: 1rem;
      }
      .content {
        border: none;
      }
      &:nth-child(2) {
        button {
          margin-left: 0;
        }
      }
    }
  }
`;
export const TimingDiv = styled.div`
  display: flex;

  & > div {
    margin-right: 1rem;
  }

  h4 {
    color: ${(p) => p.theme.palette.secondary.main};
    font-size: 2rem;
  }

  p {
    color: ${(p) => p.theme.palette.text.pink};
    font-size: 1.1.rem;
  }
`;
export const HDiv = styled.div``;
export const MDiv = styled.div``;
export const SDiv = styled.div``;
export const ProfileText = styled.p`
  color: ${(props) => props.theme.palette.text.gray};
`;
export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  ${(p) => (p.subHeading ? poppinsSemibold : poppinsLight)}
  ${(p) => p.poppinsM && poppinsMedium};
  font-size: ${(p) => (p.subHeading ? "1.5rem" : "1rem")};
`;

export const SubTextRight = styled.p`
  text-align: end;
  color: #b5b6c7;
  color: ${(props) => props.theme.palette.text.gray};
  margin-left: auto;
  cursor: pointer;
  text-decoration: underline;
`;
export const ConnectButton = styled.button`
  padding: 12px 25px;
  border-radius: 14px;
  margin-top: 2rem;
  align-items: center;
  /* background: rgb(164, 20, 235); */
  background: linear-gradient(180deg, #ffb11f 0%, #ffc861 100%);
  color: #fff;
  border: none;
`;
export const BitCoinCard = styled.button`
  width: 24%;
  padding: 20px 32px;
  border-radius: 14px;
  align-items: center;
  background: rgb(164, 20, 235);
  background: linear-gradient(
    347deg,
    rgba(164, 20, 235, 1) 0%,
    rgba(255, 35, 169, 1) 0%,
    rgba(144, 41, 240, 1) 100%
  );
  color: #fff;
  border: none;
  svg path {
    stroke: white;
  }
  h1 {
    font-size: 28px;
    color: white;
  }
  img {
    width: 24px;
  }
  span {
    color: white;
  }
  h2 {
    color: white;
    padding: 0px 10px;
  }
`;
export const WethioNftCard = styled.button`
  width: 28%;
  min-width: 300px;
  padding: 5px 0 0px 32px;
  border-radius: 14px;
  align-items: center;
  background: transparent;
  color: #28282c;
  border: 2px solid ${(props) => props.theme.palette.newTheme.darkYellow};

  &:hover span,
  &:hover h2 {
    color: #000000;
  }
  &:hover h1 {
    color: #ffffff;
  }
  &:hover svg path {
    stroke: #000000;
  }
  svg {
    width: 24px;
    height: 21px;
  }
  svg path {
    stroke: #4a4a4a;
  }
  @media ${deviceQuery.mobileSM} {
    width: 80%;
    margin: 40px;
  }
  @media ${deviceQuery.mobileSM} {
    width: 80%;
    margin: 40px;
  }
  @media screen and (min-device-width: 576px) and (max-device-width: 767px) {
    width: 80%;
    margin: 24px !important;
  }
  &:hover {
    background: rgb(164, 20, 235);
    background: linear-gradient(336.28deg, #ffb11f 2.95%, #ffc861 90.95%);
    color: #fff;
    border: none;
  }

  h1 {
    font-size: 24px;
    color: #28282c;
  }
  span {
    color: #28282c;
    font-size: 16px;
  }
  img {
    width: 24px;
  }
  h2 {
    color: #818181;
    padding: 0px 10px;
  }
`;
export const MainToSectionStyled = styled.div`
  background-color: #e0e0e0;
  text-align: center;
  padding: 44px 0px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  .backButton {
    cursor: pointer;
  }
  @media ${deviceQuery.mobileSM} {
    .mobile-view-card {
      display: initial !important;
    }
  }
  @media screen and (min-device-width: 576px) and (max-device-width: 767px) {
    .mobile-view-card {
      display: initial !important;
    }
  }
`;
export const CreateButton = styled.button`
  padding: 10px 50px;
  border-radius: 8px;
  margin-top: 2rem;
  align-items: center;
  background: #252525;
  color: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.newTheme.darkYellow};
`;
export const ImageIcon = styled.img`
  height: 50px;
  margin: 15px 0px;
  object-fit: contain;
`;
export const LastSection = styled.div`
  margin-bottom: 10rem;
  h2 {
    font-family: Unifont;
  }

  p {
    ${poppinsLight};
    margin: 1rem 0;
    max-width: 437px;
  }
`;
