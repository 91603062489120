import styled from 'styled-components';
import { deviceQuery } from '../../styles/mediaSizes';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .page-limit-select {
    width: 10rem;
    margin-right: 1rem;
    height: 3rem;
  }

  .pagination {
    background-color: transparent;
    font-size: ${({ theme }) => theme.typography.text};

    & > .page-item {
      & > a {
        color: ${({ theme }) => theme.palette.text.dark};
      }

      &.active > .page-link {
        color: ${({ theme }) => theme.palette.text.primary};
        background-color: ${({ theme }) => theme.palette.primary.main};
        border-color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    @media ${deviceQuery.tabletM} {
      font-size: 1.2rem;
    }
  }

  .no-data-message {
    text-align: center;
    margin: 1rem 0;
    font-size: ${({ theme }) => theme.typography.text2};
  }
`;
