import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checkIfLoginAndUpdateState,
  logout,
} from "./store/actions/loginActions";
import {
  updateAccountDetailsOnChainChange,
  showWalletModal,
  setError,
  setHasMetamask,
} from "./store/actions/globalActions";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Components/Layout";
import Modal from "./Components/Modal/Modal";
import { Alert, Button } from "react-bootstrap";
import { setActiveConfig } from "./store/actions/configAction";
import defaultConfig from "./configuration/config";

function App() {
  const globalState = useSelector((store) => store.global);

  const { error, errorMsg, errorTitle, errorBtnLabel, errorCallback } =
    globalState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toggleErrorModal = (_, okClicked) => {
    if (okClicked && error?.code?.toString() === "4001") {
      dispatch(showWalletModal());
    }
    dispatch(
      setError(null, {
        errorMsg: "",
        errorTitle: "",
        errorBtnLabel: "",
        errorCallback: null,
      })
    );
  };

  const getBtnProps = (code) =>
    code === 0 && {
      as: "a",
      target: "_blank",
      href: "https://metamask.io/",
      className: "color-primary",
    };

  function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      console.log("Ethereum successfully detected!");
      dispatch(setHasMetamask(true));
      // Access the decentralized web!
    } else {
      console.log("Please install MetaMask!");
    }
  }

  useEffect(() => {
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });

      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    }
  }, []);

  useEffect(() => {
    dispatch(checkIfLoginAndUpdateState());
  }, [dispatch]);
  useEffect(() => {
    // registering event listener for metamask account change
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        dispatch(logout(navigate));
      });
      window.ethereum.on("chainChanged", (chainId) => {
        dispatch(logout(navigate));
        // dispatch(updateAccountDetailsOnChainChange(chainId));
      });
    }
  }, [dispatch, navigate]);

  return (
    <div className="App">
      <Layout />
      <Modal show={!!error} onHide={toggleErrorModal} centered showCloseButton>
        <h3 className="py-3 mb-2 title2">{errorTitle}</h3>
        <p className="text1">{errorMsg}</p>

        <Button
          className="mt-4"
          type="button"
          onClick={(e) => {
            toggleErrorModal(e, true);
            errorCallback && errorCallback();
          }}
          {...getBtnProps(error?.code)}
        >
          {errorBtnLabel || "Ok"}
        </Button>
      </Modal>
    </div>
  );
}

export default App;
