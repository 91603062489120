import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  CreateCollectionStyled,
  CommonButton,
  SubText,
  HeadingText,
  Heading,
  Text,
  CustomCard,
  StyledCard,
  LoaderArea,
} from "./Collection/style";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { apiHandler } from "../services/axios";
import FullSizeLoader from "../../src/Components/FullSizeLoader";
import CommonAssetCard from "../../src/Components/CommonAssetCard/CommonAssetCard";
import { getAllAssets } from "../services/assetServices";
import InfiniteScrollWrapper from "../../src/Components/InfiniteScrollWrapper";
import defaultConfig from "../configuration/config";
import { useSelector } from "react-redux";
import MainTopSection from "../Components/MainTopSection";

const initialLimit = 5;
const initialPageNumber = 1;

function BitcoinGoldToken() {
  const [allCollection, setAllCollection] = useState({
    loading: true,
    data: [],
  });

  const [limit, setLimit] = useState(initialLimit);
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [apiLoading, setApiLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [approvedCollection, setApprovedCollection] = useState();
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const onSuccessHanadler = useCallback(
    (data, limit, pageNumber) => {
      const { offset: offsetData, total } = data.pagination;
      setAllCollection((prevState) => {
        if (pageNumber === 1)
          return { ...prevState, data: data.results, loading: false };
        else
          return {
            ...prevState,
            data: [...prevState.data, ...data.results],
            loading: false,
          };
      });
      setPageNumber((prevState) => {
        return prevState + 1;
      });
      setHasMore(offsetData + limit < total);
    },
    [setPageNumber, setHasMore, setAllCollection]
  );
  const next = useCallback(() => {
    const api = getAllAssets;
    setApiLoading(true);
    apiHandler(
      () =>
        api({
          pageNumber: pageNumber,
          limit: limit,
          contractaddress: defaultConfig?.mintingContractAddressBitCoinNft,
        }),
      {
        onSuccess: (data) => onSuccessHanadler(data, limit, pageNumber),
        final: () => setApiLoading(false),
      }
    );
  }, [limit, pageNumber, onSuccessHanadler, defaultConfig]);

  const getCollectionDetails = useCallback(
    (pageNumber = initialPageNumber, limit = initialLimit) => {
      setApiLoading(true);
      setAllCollection({ ...allCollection, loading: true });
      apiHandler(
        () =>
          getAllAssets({
            pageNumber: pageNumber,
            limit: limit,
            contractaddress: defaultConfig?.mintingContractAddressBitCoinNft,
          }),
        {
          onSuccess: (data) => {
            onSuccessHanadler(data, limit, pageNumber);
          },
          onError: (error) => {
            console.error(error);
            setAllCollection({ ...allCollection, loading: false });
          },
          final: () => setApiLoading(false),
        }
      );
    },
    [onSuccessHanadler]
  );

  const getApprovedCollection = useCallback(() => {
    if (pageNumber <= 1) return;
    let approvedata = allCollection.data?.filter(
      (items) =>
        items?.auctions?.approvalStatus !== "APPROVED" ||
        items?.auctions === null
    );

    if (!approvedata.length && hasMore) {
      next();
    }
    setApprovedCollection(approvedata);
  }, [hasMore, next, pageNumber, allCollection]);

  const FullWidthSpinner = () => (
    <div className="d-flex justify-content-center">
      <Spinner variant="light" animation="border" />
    </div>
  );

  useEffect(() => {
    getApprovedCollection();
  }, [getApprovedCollection]);

  useEffect(() => {
    getCollectionDetails();
    const initialLimit = 5;
  }, [getCollectionDetails]);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="Wethio Gold BTC"
        descriptionText={""}
        routePath="/"
      />
      <CreateCollectionStyled>
        {allCollection.loading ? (
          <LoaderArea>
            <FullSizeLoader />
          </LoaderArea>
        ) : (
          <Row
            as={InfiniteScrollWrapper}
            loading={apiLoading}
            dataLength={allCollection.data.length}
            loader={<FullWidthSpinner />}
            next={next}
            hasMore={hasMore}
            className="infinite"
            endMessage={<p></p>}
          >
            {approvedCollection?.length > 0 ? (
              approvedCollection?.map((items, i) => {
                return (
                  <CommonAssetCard
                    key={i + items._id}
                    createrId={items?.creator_id}
                    collectionId={items?._id}
                    collectionImage={items?.metadata_fields?.image_url}
                    status={items?.status}
                    reservePrice={items?.reservePrice}
                    contractAuctionId={items?.auctionId}
                    time={items?.endTime}
                    followCount={items?.followersCount}
                    title={items?.name}
                    highestBid={items?.highestBid}
                    isAdminUserTrue={true}
                  />
                );
              })
            ) : (
              <div className="text-center mt-3 mb-3">
                <Text>
                  <HeadingText>No items found</HeadingText>
                </Text>
              </div>
            )}
          </Row>
        )}
      </CreateCollectionStyled>
    </>
  );
}

export default BitcoinGoldToken;
