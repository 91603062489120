import ethPubicMintingABI from "../abi/eth/PUBLIC_MINTING_ABI.json";
import wethPubicMintingABI from "../abi/weth/PUBLIC_MINTING_ABI.json";
import ethMintingABI from "../abi/eth/MINTING_ABI.json";
import wethMintingABI from "../abi/weth/PUBLIC_MINTING_ABI.json";
import ethAuctionABI from "../abi/eth/AUCTION_ABI.json";
import wethAuctionABI from "../abi/weth/AUCTION_ABI.json";
import ethTreasuryABI from "../abi/eth/THREASURY_ABI.json";
import wethTreasuryABI from "../abi/weth/TREASURY_ABI.json";

const defaultConfig = {
  // apiBaseUrl: "http://localhost:8080/api/v1/",
  apiBaseUrl: "https://nft.wethiox.io/api/v1/",
  apiBaseUrlTestner: "https://nft.testnet.wethiox.io/api/v1/",
  web3ProviderFallbackUrl: "http://localhost:8545",
  metamaskDeepLinkUrl: "https://metamask.app.link/dapp/nft.testnet.wethiox.io/",
  ethConfig: {
    publicMintingABI: ethPubicMintingABI,
    mintingABI: ethMintingABI,
    auctionABI: ethAuctionABI,
    treasuryABI: ethTreasuryABI,
    // apiBaseUrl: "http://localhost:8080/api/v1/",
    apiBaseUrl: "https://nft.testnet.wethiox.io/api/v1/",
    apiBaseUrlTestner: "https://nft.testnet.wethiox.io/api/v1/",
    web3ProviderFallbackUrl: "http://localhost:8545",
    chainInfo: {
      name: "rinkeby",
      chainId: 4,
    },
    //testnet address
    // public collection
    publicMintingContractAddress: "0xd71f38fB20c07a1A4C712DD65cc1287A61f4dD34",
    // private collection
    mintingContractAddressBitCoinNft:
      "0xB1a7Cc3E20d920A85141ce1CE8948CE90EE17e98",
    mintingContractAddressZyneCoinGold:
      "0xe545450F7dB2c4f7F428C72F2aae230b97155BBE",
    mintingContractAddressZyneCoin18k:
      "0x667Fca9f25A0478FCF436F64F59c7168BFb72a92",
    // marketing contract
    treasuryContractAddress: "0x5f40957217D59b034C37fA3fFf14b7409F284a14",
    auctionContractAddress: "0xf010dF0C7ee00Ae114AF780e45A4136EfC3C0c10",

    maxGasLimit: 489631,
    ipfsProviderUrl: "https://ipfs.infura.io:5001/api/v0",
    ipfsBaseUrl: "https://wethio.infura-ipfs.io/ipfs/",
    instagram_client_id: "3533662446868181",
    twitter_client_id: "R011NnZYNk12Mk9EYXlKNF9aeE86MTpjaQ",
  },
  wethConfig: {
    publicMintingABI: wethPubicMintingABI,
    mintingABI: wethMintingABI,
    auctionABI: wethAuctionABI,
    treasuryABI: wethTreasuryABI,
    // apiBaseUrl: "http://localhost:8080/api/v1/",
    apiBaseUrl: "https://nft.wethiox.io/api/v1/",
    apiBaseUrlTestner: "https://nft.testnet.wethiox.io/api/v1/",
    web3ProviderFallbackUrl: "http://localhost:8545",
    chainInfo: {
      name: "wethio",
      chainId: 78,
      rpc_url: "https://rpc.wethio.io",
      network_currency: "ZYN",
    },
    //testnet address
    // public collection
    publicMintingContractAddress: "0xe8ed5F98eb5b4ef46df2C5b8f7182f8505ede99e",
    // private collection
    mintingContractAddressBitCoinNft:
      "0x831cd56ffa6760D6dD296512104Ae3aB0477D999",
    mintingContractAddressZyneCoinGold:
      "0x1E37228c295c8721893E51F2aA2cF9167E0cAF8a",
    mintingContractAddressZyneCoin18k:
      "0x2233764f09FDFe270c76DaE0Fe4a3743B05CF30F",
    // marketing contract
    treasuryContractAddress: "0x9188fB9Fd8b8755AAE2d1BeaB795c8c9910E3682",
    auctionContractAddress: "0x15804804E6cd52948Fd09101C887CeCA986671A7",

    maxGasLimit: 489631,
    ipfsProviderUrl: "https://ipfs.infura.io:5001/api/v0",
    ipfsBaseUrl: "https://wethio-nft.infura-ipfs.io/ipfs/",
    instagram_client_id: "866939135063058",
    twitter_client_id: "MlhXNWlxbmV2cjkzYng3WHVVb1o6MTpjaQ",
  },
};

export default defaultConfig;
