import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import {
  UserProfileStyled,
  ProfileCard,
  Button,
  CommonButton,
  ContainerDiv,
  SubMainText,
  Text,
  ButtonList,
  AddressBtn,
  Image,
  AddressText,
  SubText,
  AvtarImg,
  EditProfile,
  StyledPopper,
  ProfileSectionLayout,
  ImageLayout,
  UserNameText,
  Text1,
  DescriptionText,
  TabsStyled,
  DataNotFoundStyled,
} from "./style";
import getAxiosInst, { apiHandler } from "../../services/axios";
import {
  blockUser,
  getCreatedAssets,
  getOwnedAssets,
} from "../../services/userServices";
import { Tabs, Tab, OverlayTrigger, Row } from "react-bootstrap";
import { useParams } from "react-router";
import UserIcon from "../../assets/icons/UserIcon";
import { useDispatch, useSelector } from "react-redux";
import AssetCard from "../AssetCard";
import AssetCardSale from "../AssetCardSale";
import { getOnSaleAssets } from "../../services/userServices";
import { HeadingText } from "../../pages/PlaceBid/style";
import { toast } from "react-toastify";
import AssetCardOwned from "../AssetCardOwned";
import { FiCopy, FiTwitter } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import { setUser } from "../../store/actions/userActions";
import { StyledCard } from "../AssetCard/style";
import { createContractInstance } from "../../common/common";
import MiniLoader from "../MiniLoader";
import MainTopSection from "../MainTopSection";
toast.configure();

const ItemNotFoundComponent = () => {
  return (
    <DataNotFoundStyled>
      <HeadingText>No items found</HeadingText>
    </DataNotFoundStyled>
  );
};

function ArtistDetails() {
  const [isSubmiting, setIsSubmiting] = useState({ editUser: false });
  const [allAssets, setAllAssets] = useState();
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [onSaleAssets, setOnSaleAssets] = useState(null);
  const [ownedAssets, setOwnedAssets] = useState(null);
  const globalState = useSelector((state) => state.global);
  const userState = useSelector((state) => state.user);
  const [isFollowed, setIsFollowed] = useState(false);
  const loginUser = useSelector((state) => state.user);
  const loginState = useSelector((state) => state.login);
  const { isLogin } = loginState;
  const [isCreatorApproved, setisApprovedCreator] = useState("");
  const [followCount, setFollowCount] = useState();
  const [isAdminUser, setIsAdminUser] = useState();
  const [isBlocked, setIsBlocked] = useState(false);
  const dispatch = useDispatch();
  // const { userData } = userState;
  // const userId = userData?._id;
  const [isCreator, setIsCreator] = useState(false);
  const { account } = globalState;
  const [isBlockLoading, setIsBlockLoading] = useState(false);
  let tem = account;
  const [showCopiedText, setShowCopiedText] = useState(false);
  const [address, setAddress] = useState(tem);
  const { acc } = useParams();
  const timer = useRef(null);
  const accWrapperRef = useRef(null);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const { web3Instance } = globalState;
  const toggleCopiedText = (state) =>
    setShowCopiedText((prev) => (typeof state === "boolean" ? state : !prev));

  const onFollowHandler = () => {
    const blockchain = localStorage.getItem("blockchain");
    if (loginUser?.userData) {
      getAxiosInst({ withAuth: true })
        .put(`/users/creators/${id}/toggle-follow?network=${blockchain}`)
        .then((data) => {
          toast.success(data?.data?.message);
          const follow =
            data?.data?.creatorInfo?.followingCreators.includes(id);
          setIsFollowed(follow);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    } else {
      toast.error("Please Connect to Wallet");
    }
  };

  useEffect(() => {
    const blockchain = localStorage.getItem("blockchain");

    if (id) {
      getAxiosInst({ withAuth: true })
        .get(`/users/creators/${id}?network=${blockchain}`)
        .then((data) => {
          const follow = data?.data?.followingCreators.includes(id);
          setIsFollowed(follow);

          setFollowCount(data?.data?.followersCount);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const blockchain = localStorage.getItem("blockchain");

      getAxiosInst({ withAuth: true })
        .get(`/users/creators/${id}?network=${blockchain}`)
        .then((data) => {
          setIsCreator(data?.data?.isCreator);
          setisApprovedCreator(data?.data?.creatorApprovalStatus);
          setIsBlocked(data?.data?.isBlocked);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [id]);

  const onBlockHandler = async (id) => {
    try {
      setIsBlockLoading(true);

      if (isBlocked) {
        const treasuryContractInstance = await createContractInstance(
          globalState?.web3Instance,
          defaultConfig?.treasuryABI,
          defaultConfig?.treasuryContractAddress
        );

        await treasuryContractInstance.methods
          .grantMinter(userData?.wallet_address)
          .send({ from: loginUser?.userData?.wallet_address });
      }
      if (!isBlocked) {
        const treasuryContractInstance = await createContractInstance(
          web3Instance,
          defaultConfig?.treasuryABI,
          defaultConfig?.treasuryContractAddress
        );

        await treasuryContractInstance.methods
          .revokeMinter(userData?.wallet_address)
          .send({ from: loginUser?.userData?.wallet_address });
      }
      const response = await blockUser(id);
      setIsCreator(response?.data?.creator?.isCreator);
      setisApprovedCreator(response?.data?.creator?.creatorApprovalStatus);
      setIsBlocked(response?.data?.creator?.isBlocked);
      toast.success(
        response?.data?.creator?.isBlocked
          ? "Blocked Successfully"
          : "Unblocked Successfully"
      );
      toast.warn(response?.message);

      setIsBlockLoading(false);
    } catch (err) {
      setIsBlockLoading(false);

      toast.error(err.response?.data?.message);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(acc);
      toggleCopiedText();
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(() => toggleCopiedText(false), 2000);
    } catch (error) {
      console.error(error);
    }
  };
  const history = useNavigate();
  const EditProfilePage = () => {
    history("/userProfile");
  };

  useEffect(() => {
    if (id) {
      const blockchain = localStorage.getItem("blockchain");
      getAxiosInst({ withAuth: true })
        .get(`/users/${id}?network=${blockchain}`)
        .then((data) => {
          setUserData(data?.data);
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  const getAssetDetails = async () => {
    apiHandler(() => getCreatedAssets(id), {
      onSuccess: (data) => {
        setAllAssets(data.created_asset);
      },
      onError: (error) => console.error(error),
      final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
    });
  };

  const checkUserIsAdmin = async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  };
  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account]);

  useEffect(() => {
    getAssetDetails();

    const curTime = timer.current;
    return () => {
      curTime && clearTimeout(curTime);
    };
  }, [account, userState, id]);

  const getOnSaleAssetDetails = async () => {
    apiHandler(() => getOnSaleAssets(id), {
      onSuccess: (data) => {
        setOnSaleAssets(data.onsale_asset);
      },
      onError: (error) => console.error(error),
    });
  };

  const getOwnedAssetDetails = async () => {
    apiHandler(() => getOwnedAssets(id), {
      onSuccess: (data) => {
        setOwnedAssets(data.owned_asset);
      },
      onError: (error) => console.error(error),
    });
  };

  useEffect(() => {
    getOwnedAssetDetails();
  }, [userData, id]);

  useEffect(() => {
    getOnSaleAssetDetails();
  }, [userData, id]);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="Author Profile"
        descriptionText={""}
        routePath="/userDetails"
      />

      <UserProfileStyled>
        <ProfileCard>
          <ContainerDiv>
            <ProfileSectionLayout>
              <div className="d-flex profileImage">
                <ImageLayout $imgUrl={userData?.profile_image_url}>
                  {userData?.profile_image_url ? (
                    <img
                      className="asset-img"
                      src={userData?.profile_image_url}
                      alt="Card cap"
                    />
                  ) : (
                    <AvtarImg>
                      <UserIcon />
                    </AvtarImg>
                  )}
                </ImageLayout>
                <div className="authorProfile" style={{ paddingLeft: "20px" }}>
                  <div style={{ margin: "30px 0px 20px 0px" }}>
                    <SubMainText>Author Profile</SubMainText>

                    {userData?.username ? (
                      <UserNameText>{userData?.username}</UserNameText>
                    ) : (
                      <Text1>@anonymous</Text1>
                    )}

                    {userData?.bio ? (
                      <DescriptionText>{userData?.bio}</DescriptionText>
                    ) : null}
                  </div>
                  <div className="d-flex  align-items-center mobile-view-ui">
                    {/* <AddressText>
                      {userData?.username
                        ? userData?.username
                        : userData?.wallet_address}
                    </AddressText> */}
                    <AddressBtn>
                      <SubText>{userData?.wallet_address}</SubText>
                      <FiCopy
                        className="asset-img1 copy-img"
                        alt="Card cap"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            userData?.wallet_address
                          );
                          toast("Account address copied successfully");
                        }}
                      />
                    </AddressBtn>
                  </div>
                  <div className="d-flex mt-3 mb-3 mobile-view-ui changeSvgIcon align-items-center">
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        margin: "0 20px",
                      }}
                    >
                      {userData?.instagramUsername && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <BsInstagram className="instagramColor" />
                          <a
                            href={`https://instagram.com/${userData?.instagramUsername}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {userData?.instagramUsername}
                          </a>
                        </div>
                      )}
                      {userData?.twitterUsername && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <FiTwitter />
                          <a
                            href={`https://twitter.com/${userData?.twitterUsername}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {userData?.twitterUsername}
                          </a>
                        </div>
                      )}
                    </div>
                    {isCreator && (
                      <CommonButton
                        className="action-btn"
                        onClick={() => onFollowHandler()}
                      >
                        {!isFollowed ? "Follow" : "Unfollow"}
                      </CommonButton>
                    )}
                    <div className="d-flex">
                      <SubText style={{ width: "10px" }}>{followCount}</SubText>
                      &nbsp;
                      <SubText>Followers</SubText>
                    </div>
                    {isAdminUser && isCreatorApproved === "APPROVED" && (
                      <CommonButton
                        disabled={isBlockLoading}
                        className="action-btn"
                        style={{
                          marginLeft: "10px",
                          padding: "8px 40px",
                        }}
                        onClick={() => onBlockHandler(userData?._id)}
                      >
                        {isBlockLoading ? (
                          <MiniLoader />
                        ) : !isBlocked ? (
                          "Block"
                        ) : (
                          "Unblock"
                        )}
                      </CommonButton>
                    )}
                  </div>
                </div>
              </div>
            </ProfileSectionLayout>
            <TabsStyled
              defaultActiveKey="Assets"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Bio" title="Bio">
                <StyledCard>
                  <Row>
                    <SubMainText>{userData?.bio}</SubMainText>
                    <SubMainText>{userData?.website}</SubMainText>
                  </Row>
                </StyledCard>
              </Tab>
              <Tab eventKey="Assets" title="Assets" className="text-center p-3">
                {allAssets?.length > 0 ? (
                  <AssetCard allAssets={allAssets} />
                ) : (
                  <div className="text-center mt-3 mb-3">
                    <Text>
                      <HeadingText>No items found</HeadingText>
                    </Text>
                  </div>
                )}
              </Tab>
              <Tab eventKey="On sale" title="On sale">
                {onSaleAssets?.length > 0 ? (
                  <AssetCardSale
                    onSaleAssets={onSaleAssets}
                    detailsPageType={"auction"}
                  />
                ) : (
                  <div className="text-center mt-5 mb-5">
                    <Text>
                      <HeadingText>No items found</HeadingText>
                      <SubText>You haven’t listed any item</SubText>
                    </Text>
                  </div>
                )}
              </Tab>
            </TabsStyled>
          </ContainerDiv>
        </ProfileCard>
      </UserProfileStyled>
    </>
  );
}

export default ArtistDetails;
