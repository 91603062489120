import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import UserIcon from '../../assets/icons/UserIcon';

const AvatarWrapper = styled.div`
  width: ${(props) => props.$width || '10rem'};
  height: ${(props) => props.$height || '10rem'};
  display: grid;
  place-items: center;

  .image-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: ${(props) => (props.$square ? '1.5rem' : '24%')};
    overflow: hidden;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.$imgURL ? 'transparent' : props.theme.palette.newTheme.profileGray};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: ${(props) => !props.$imgURL && 'none'};
    }

    svg {
      width: 32px;
      &.small {
        width: 12.8px;
      }
    }

    ${(props) =>
      props.$connectBtn &&
      css`
      background-color: ${(props) =>
        props.$imgURL ? 'transparent' : props.theme.palette.newTheme.profileGray};
        // border: 1px solid ${props.theme.palette.primary.main};
        svg {
          width: 12.8px;
        }
      `};

    .loader-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${(props) => props.theme.palette.common.light}88;
      color: ${(props) => props.theme.palette.primary.main};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

function Avatar(props) {
  const { loading: _loading = false, $size = '', ...restProps } = props;
  const [loading, setLoading] = useState(_loading);

  useEffect(() => {
    setLoading(_loading);
  }, [_loading]);

  return (
    <AvatarWrapper {...restProps}>
      <div className="image-wrap">
        {props.$imgURL ? (
          <img src={props.$imgURL} alt="user" style={{ height: 'inherit' }} />
        ) : (
          <UserIcon fill={props?.fill} className={$size} />
        )}
        {loading && (
          <div className="loader-wrap">
            <Spinner
              style={{ width: '3rem', height: '3rem' }}
              variant="dark"
              animation="border"
            >
              {''}
            </Spinner>
          </div>
        )}
      </div>
    </AvatarWrapper>
  );
}

export default Avatar;

Avatar.propTypes = {
  $imgURL: PropTypes.string,
  $connectBtn: PropTypes.bool,
  $width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  $height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  $square: PropTypes.bool,
  $size: PropTypes.oneOf(['small']),
  loading: PropTypes.bool,
  children: PropTypes.node,
  fill: PropTypes.string,
};
