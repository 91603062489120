import React from 'react';

const HeartIcon = ({ fillColor = '#fff',onClick }) => {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.28 3.153A6.444 6.444 0 0 0 18.44 1c-1.397 0-2.677.451-3.803 1.341a7.877 7.877 0 0 0-1.537 1.64 7.872 7.872 0 0 0-1.537-1.64C10.436 1.451 9.157 1 7.76 1a6.444 6.444 0 0 0-4.84 2.153C1.682 4.525 1 6.4 1 8.431c0 2.092.763 4.006 2.4 6.025 1.465 1.806 3.57 3.64 6.008 5.763.833.725 1.777 1.546 2.757 2.422a1.401 1.401 0 0 0 1.87 0c.98-.875 1.924-1.698 2.757-2.423 2.438-2.123 4.543-3.956 6.008-5.762 1.637-2.019 2.4-3.933 2.4-6.025 0-2.031-.682-3.906-1.92-5.278z" stroke="#818181" strokeWidth="2"/>
</svg>
  );
};

export default  HeartIcon;
