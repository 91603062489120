import React from 'react';

const WalletConnect = ({ fillColor = '#080b35',onClick }) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.192 6.638h3.136c.372 0 .672.286.672.637V9.49c-.004.35-.302.633-.672.637h-3.064c-.894.011-1.677-.57-1.88-1.396a1.7 1.7 0 0 1 .39-1.445c.349-.404.868-.64 1.418-.648zm.136 2.328h.296c.38 0 .688-.292.688-.652 0-.36-.308-.652-.688-.652h-.296a.7.7 0 0 0-.486.186.628.628 0 0 0-.202.459c0 .361.307.655.688.66z" fill="#F9F9FA"/>
    <path d="M14.192 5.334H18C18 2.362 16.168.625 13.064.625H4.936C1.832.625 0 2.362 0 5.296v6.408c0 2.934 1.832 4.671 4.936 4.671h8.128c3.104 0 4.936-1.736 4.936-4.671v-.273h-3.808c-1.767 0-3.2-1.358-3.2-3.033 0-1.676 1.433-3.034 3.2-3.034v-.03z" fill="#fff"/>
</svg>
  );
};

export default WalletConnect;
