import * as actionTypes from "../../constants/actionTypes";

export const setUser = (userData) => ({
  type: actionTypes.SET_USER,
  payload: {
    userData,
  },
});

export const updateProfileImg = (imageUrl) => ({
  type: actionTypes.UPDATE_PROFILE_IMG,
  payload: { imageUrl },
});

export const addMetadataUrl = (imageUrl) => ({
  type: actionTypes.ADD_METADATA_URL,
  payload: { imageUrl },
});

export const addTokenID = (id, imagesUrl) => ({
  type: actionTypes.ADD_TOKEN_ID,
  payload: { id, imagesUrl },
});
export const DeleteTokenID = (id) => ({
  type: actionTypes.DELETE_TOKEN_ID,
  payload: { id },
});

export const addCollectionID = (
  id,
  assetTokenId,
  amountEth,
  auctionId,
  txHashData
) => ({
  type: actionTypes.ADD_COLLECTION_ID,
  payload: { id, assetTokenId, amountEth, auctionId, txHashData },
});
export const DeleteCollectionID = (id, assetTokenId, amountEth, auctionId) => ({
  type: actionTypes.DELETE_COLLECTION_ID,
  payload: { id, assetTokenId, amountEth, auctionId },
});
export const updateUserData = (data) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: { data },
});

export const fetchUserData = (id) => {
  // return async (dispatch) => {
  //   return await apiHandler(() => getUser(id), {
  //     onSuccess: (data) => {
  //       dispatch(setUser(data));
  //     },
  //   });
  // };
};

export const sendProfileData = (id, data, { success, final }) => {
  // return async (dispatch) => {
  //   apiHandler(() => updateUserRemoteData(id, data), {
  //     onSuccess: (data) => {
  //       dispatch(updateUserData({ ...data }));
  //       success && success();
  //     },
  //     final: () => {
  //       final && final();
  //     },
  //   });
  // };
};

export const setCreator = (isCreator) => {
  return {
    type: actionTypes.SET_CREATOR,
    payload: { isCreator },
  };
};

export const setBlocked = (isBlocked) => {
  return {
    type: actionTypes.SET_BLOCKED,
    padyload: { isBlocked },
  };
};
