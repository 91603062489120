import styled, { css } from "styled-components";
import { Carousel } from "react-bootstrap";
import {
  Container,
  Card,
  Button,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { deviceQuery } from "../../styles/mediaSizes";
import { flexBetween, poppinsRegular } from "../../styles/mixins";

export const Header = styled.div`
  ${flexBetween};

  .left {
    padding-left: 5rem;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${(p) => p.theme.palette.common.animationBg};
    font-size: 1rem;
  }

  @media ${deviceQuery.tabletM} {
    .left {
      padding-left: 0;
    }
  }
`;
export const ImageSection = styled.div`
  img {
    width: 150px !important;
    height: 150px;
    padding: 8px;
  }
  border-radius: 46px;
  width: 140px;
  height: 140px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  margin: 30px;
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
`;
export const CarouselCaption = styled.div`
  position: relative;
  left: 0px;
  .verifiedIcon {
    position: absolute;
    top: -58px;
    left: 139px;
    width: 30px !important;
    height: 30px;
  }
  h3 {
    font-size: 16px;
    color: ${(props) => props.theme.palette.newTheme.textColor};
  }
  p {
    font-size: 14px;
    color: ${(props) => props.theme.palette.newTheme.cardGray};
  }
`;
export const CarouselItem = styled(Carousel.Item)`
  &.active {
    display: flex;
    margin: 30px;
  }
`;
export const CreateCollectionStyled = styled(Container)`
  .card:hover {
    background-color: #fff;
  }
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    color: #b5b6c7;
  }
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px;
    display: inline-block;
  }
`;
export const LoaderArea = styled.div`
  min-height: 50px;
  text-align: center;
  position: relative;
`;
export const SubHeading = styled.h5`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const FormCheckStyled = styled.div`
  height: 40px;
  input {
    width: 37px;
    height: 35px;
  }

  label {
    margin: 10px;
    background-color: ${(props) => props.theme.palette.text.light} !important;
  }

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const TextHeading = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const AuctionButton = styled.div`
  margin-top: 3rem;
  button {
    border-radius: 12px;
    background-color: #b5b6c7;
    color: ${(props) => props.theme.palette.text.light};
  }
`;
export const StyleInput = styled.div`
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: none !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  color: ${(props) => props.theme.palette.text.light};
  align-items: center;
  display: flex;
  input,
  input:focus {
    border: none;
    box-shadow: none !important;
    margin: 1rem 0rem;
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
`;
export const HeadingItem = styled.h1`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const HeadingEth = styled.h1`
  position: absolute;
  text-align: right;
  margin-left: 277px;
  margin-bottom: 0px;
  display: inline-block;
  color: ${(props) => props.theme.palette.text.light};
`;
export const PreviewDiv = styled.div`
  /* padding: 40px 0px 15rem 0px; */
  width: 200px;
  height: 250px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
`;
export const RouteStyled = styled(Container)`
  padding: 40px 0px 100px 0px;
  @media ${deviceQuery.laptopM} {
    .MobileView {
      width: 100%;
      display: flex;
    }
    .MobileView div {
      width: 100%;
      text-align: center;
    }
    .MobileView .Horizantalline::after {
      content: "";
      position: absolute;
      width: 16.4%;
      height: 4px;
      background-color: #dcdcdc;
      margin-top: 52px;
    }
  }
`;
export const BackButton = styled(Container)``;
export const VerticalLine = styled.div`
  border-left: 2px solid #b5b6c7;
  height: 100px;
  margin: 20px 0px 20px 46px;
  @media ${deviceQuery.laptopM} {
    display: none;
  }
`;
export const AddressBtn = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.text.light};
  margin: 0px 0px 0px 8px;
  display: flex;

  padding: 10px 20px;
  border-radius: 40px;
  height: 40px;
  align-items: center;
  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px 10px 0px 15px;
  }
  svg {
    width: 40px;
  }
  img {
    width: 16px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const TextContent = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: ${(p) => p.theme.fw.regular};
  ${poppinsRegular};
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const LoadMoreButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  margin: 20px 0px 4rem 0px;
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
`;
export const HeadingText1 = styled.h3`
  color: ${(props) => props.theme.palette.text.primary};
  font-family: ${(p) => p.theme.ff.unifont};
  font-size: 18px;
  font-weight: 400;
`;
export const CreateButton = styled(Button)`
  padding: 15px 25px;
  background-color: #feefa4;
  border: none;
  border-radius: 12px;
  color: #000;
`;
export const Heading = styled.h1`
  margin-top: 4rem;

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const MyAccount = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
`;
export const VerySmallText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
  font-size: 14px;
`;
export const LabelStyled = styled(FormLabel)`
  font-size: 16px;

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SaveButton = styled.button`
  padding: 15px 45px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10rem 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;

export const MintButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10px 0px;
  background-color: #080b35 !important;

  border: none;
  .disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    background-color: #eaeaea !important;
  }
`;

export const ListButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 50%;
  margin: 20px 0px 10px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const InputStyled = styled(FormControl)`
  padding: 12px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none !important;
`;
export const CardTitle = styled(Card.Title)`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const CustomCardStyled = styled(Card)`
  width: 18rem;
  text-align: center;
  color: #000000;
  margin: 20px;
  background-color: #edfbff;
`;
export const CustomCard = styled(Card)`
  padding: 30px 30px 50px;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  .card-title {
    color: ${(props) => props.theme.palette.text.primary};
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      pointer-events: none;
      background-color: #eaeaea !important;
    `}
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  img {
    height: 140px;
  }
  @media ${deviceQuery.laptopM} {
    img {
      height: 100%;
    }
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }
`;
export const CreateCollectionDiv = styled(Container)`
  .action-btn {
    border-radius: 14px;
    color: #fff;
  }
`;

export const ContractAddess = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const RoundImage = styled.div`
  h1 {
    margin-left: 20px;
  }
  img {
    border-radius: 50%;
    color: #fff;
    width: 70px;
    height: 70px;
  }
`;
export const CoverImage = styled.div`
  text-align: center;
  img {
    width: 386px;
    height: 342px;
    border-radius: 32px;
    color: #fff;
  }
`;
export const Text1 = styled.h1`
  margin-left: 20px;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const Text2 = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const DeleteButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #f86d6d;
  margin-left: 24px;
  color: #fff;
  border: none;
`;

export const CommonButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: ${(props) => (props.disabled ? "#73777B" : "#080b35")};
  color: #fff;
  border: none;
`;
export const Text = styled.div`
  color: #080b35;
`;
export const CardProfile = styled(Card)`
  overflow: hidden;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  margin: 42px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.palette.text.pink} !important;
  .card-title {
    text-align: left;
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }
`;

export const StyledCard = styled.div`
  max-width: 100%;
  margin: auto;
  margin-bottom: 5rem;
  margin-top: 3rem;
  .card {
    cursor: pointer;
    margin-top: 22px;
    border-radius: 16px;
    border: none;
  }
  @media screen and (min-device-width:768px) and (max-device-width: 991px) { 
   .card{
    height:100% !important;
   }
   .creator-mobile-view-card-style{
    display: block !important;
   }
  .asset-img-collection {

  }
  
  }
  .infinite {
  }
  .card-title {
    color: ${(props) => props.theme.palette.text.primary} !important;
    min-height: 74px;
  }
  border-radius: 2.4rem;
  .card {
    min-height: 352px;
    width: 100%;

    text-align: left;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.text.white};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media ${deviceQuery.tabletM} {
     
        height:100% !important;
    .asset-img-collection{
      padding-bottom: 12px;
      height:100% !important;
    }
   
    }
    @media ${deviceQuery.mobileSM} {
     
        height:100% !important;
        .asset-img-collection{
          padding-bottom: 13px;
          height:100% !important;
        }
    }
    .body {
      padding: 2rem 1.7rem;
      .card-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 112.1%;
        margin-bottom: 1.5rem;
      }
      .protagonist-wrap {
        .avatar {
          width: 4rem;
          height: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
          .protagonist-name {
            font-size: 1.6rem;
          }
        }
      }
    }

    .footer {
      color: ${(props) => props.theme.palette.text.light};
      background-color: ${(props) => props.theme.palette.secondary.light};
      padding: 2rem 1.7rem;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 162.1%;
        margin-bottom: 0.5rem;
      }

      .remaining-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 162.1%;
      }

      .value,
      .bid-amount {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
`;

export const MintRadio = styled.div`
  input[type="radio"] {
    display: block;
  }

  label {
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    left: 2rem;
    color: #080b35;
  }

  label::before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: transparent;
    border: 2px solid #080b35;
    border-radius: 20%;
    top: 50%;
    left: -2rem;
    transform: translateY(-50%);
    transition: border-color 400ms ease;
  }

  label::after {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: #080b35;
    border: 2px solid #080b35;
    border-radius: 20%;
    top: 50%;
    left: -2rem;
    transform: translateY(-50%) scale(0);
    transition: transform 400ms ease;
  }

  input[type="radio"]:checked + label::before {
    border-color: #080b35;
  }

  input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(0.55);
  }
`;

export const CustomCarouselStyled = styled.div`

  .carousel.carousel-slider .control-arrow {
    color: ${(props) => props.theme.palette.text.dark} !important;
    opacity: 1;
    justify-content: end;
    align-items: end;
    margin-left: 42%;
    margin-right: 42%;
    display: flex;

  }
  .carousel .control-dots {
    bottom: -10px;
}
  .carousel {
    height: 300px;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid ${(props) => props.theme.palette.text.dark} !important;
}
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid ${(props) => props.theme.palette.text.dark} !important;
  }
  .carousel-control-prev {
    left: 35%;
  }
  .carousel.carousel-slider .control-arrow:hover{
    background-color: #fff;
  }
  .carousel-control-next {
    right: 35%;
  }
  .selected {
    width: 12px !important;
    height: 12px !important;
  }
  .dot {
    width: 8px;
    height: 8px;
    border: 1.5px solid black;
    background-color: #000000;
  }

`;
