import getAxiosInst from "./axios";

export const getAllAssets = ({
  pageNumber = 1,
  limit = 100,
  contractaddress = "",
}) => {
  const blockchain = localStorage.getItem("blockchain");
  return getAxiosInst({ withAuth: false }).get(
    `/assets?limit=${limit}&offset=${
      pageNumber - 1
    }&contract_address=${contractaddress}&createdAt=desc&network=${blockchain}`
  );
};

export const getOneAsset = (assetId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/assets/${assetId}?network=${blockchain}`
  );
};
export const getTotalAssetCount = () => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/assets/totalSupply?network=${blockchain}`
  );
};
export const importCollections = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/assets/import?network=${blockchain}`,
    reqData
  );
};
export const createAsset = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/assets?network=${blockchain}`,
    reqData
  );
};

export const updateAssetWithTxData = (assetId, tx_data) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/assets/${assetId}?network=${blockchain}`,
    {
      tx_data,
    }
  );
};

export const getUploadAsset = (assetId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/collection/${assetId}?network=${blockchain}`
  );
};

export const UploadAsset = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `upload/collection?network=${blockchain}`,
    reqData
  );
};

export const UploadAssetForUserProfile = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `upload/user?network=${blockchain}`,
    reqData
  );
};

export const transferAssetService = (assetId, result) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/assets/${assetId}/transfer?network=${blockchain}`,
    {
      tx_data: result,
    }
  );
};
