import getAxiosInst from "./axios";

export const getUsersActiveAuctions = (userId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/auctions?userId=${userId}&limit=100&network=${blockchain}`
  );
};

export const getAllAuctions = ({pageNumber = 1, limit = 100, query = ""}) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/auctions?limit=${limit}&offset=${pageNumber - 1}&${query}&network=${blockchain}`
  );
};

export const getAuction = (auctionId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/auctions/${auctionId}?network=${blockchain}`
  );
};

export const postAuctionUpdateApproval = (auctionId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/auctions/${auctionId}/update-approval-status?network=${blockchain}`,
    reqData
  );
};
export const createAuction = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/auctions?network=${blockchain}`,
    reqData
  );
};

export const updateAuctionWithTxData = (auctionId, tx_data) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/auctions/${auctionId}?network=${blockchain}`,
    {
      tx_data,
    }
  );
};

export const updateAuctionWithFinalizeTxData = (auctionId, tx_data) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `auctions/${auctionId}/finalize?network=${blockchain}`,
    {
      finalizeTransactionHash: tx_data?.transactionHash,
    }
  );
};
