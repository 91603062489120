import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay, StyledWrapper } from './style';
import {
  LazyLoadComponent,
  LazyLoadImage,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Spinner } from 'react-bootstrap';
import PlayButton from '../PlayButton';
import { ButtonSizes, ASSET_TYPES } from '../../constants/appConstants';

const images = ['jpg', 'jpeg', 'webp', 'gif', 'png', 'svg'];
const videos = ['mp4', '3gp', 'mp3', 'webm'];
const imageUriIdentifier = 'data:image/';
const videoUriIdentifier = 'data:video/';

const defaultAspectRatio = 1.36;

const AssetComponent = (props) => {
  const {
    disableLocalLoad = false,
    loading: _loading,
    src,
    aspectRatio = defaultAspectRatio,
    freeSize = false,
    containerRef,
    elementProps,
    scrollPosition,
    assetContainerClass = 'asset-container',
    onClick,
    videoComponentRef,
    videoLazyPlaceholder,
    assetType,
    ...resProps
  } = props;
  const {
    alt = 'asset',
    className: _className = '',
    playButtonSize,
    ...restElementProps
  } = elementProps ?? {};
  const lazyLoadProps = {};
  scrollPosition && (lazyLoadProps['scrollPosition'] = scrollPosition);
  const defaultClass = 'asset';
  const [loading, setLoading] = useState(_loading);
  const [source, setSource] = useState(src);
  const [type, SetType] = useState(null);
  const [className, setClassName] = useState(defaultClass);

  const startLoading = () => {
    if (disableLocalLoad) return;
    setLoading(true);
  };

  const stopLoading = () => {
    if (disableLocalLoad) return;
    setLoading(false);
  };

  const loadErrorHandler = () => {
    stopLoading();
  };

  const progressHandler = () => {
    stopLoading();
  };

  useEffect(() => {
    let classText = [defaultClass, ..._className.split(' ')].join(' ');
    setClassName(classText);
  }, [_className]);

  useEffect(() => {
    setLoading(_loading);
  }, [_loading]);

  useEffect(() => {
    if (src) {
      let extension = '';
      const isDataUri =
        src.indexOf(imageUriIdentifier) === 0 ||
        src.indexOf(videoUriIdentifier) === 0;

      if (isDataUri) {
        let baseIdentifier = '';

        if (src.includes(imageUriIdentifier)) {
          baseIdentifier = imageUriIdentifier;
        } else if (src.includes(videoUriIdentifier)) {
          baseIdentifier = videoUriIdentifier;
        }
        extension = src
          .substring(baseIdentifier.length, src.indexOf(';base64'))
          .replace('+xml', '');
      } else {
        extension = src
          .split('.')
          .pop()
          .split(/#|\?|&/)[0];
      }
      if (assetType) {
        SetType(assetType);
      } else if (images.includes(extension)) {
        SetType('image');
      } else if (videos.includes(extension)) {
        SetType('video');
      }
      SetType('image');
      setSource(src);
    }
  }, [src, assetType]);

  return (
    <StyledWrapper
      ref={containerRef}
      className={assetContainerClass}
      aspectRatio={aspectRatio}
      freeSize={freeSize}
      onClick={onClick}
      {...resProps}
    >
      {type === 'image' ? (
        <LazyLoadImage
          {...lazyLoadProps}
          wrapperClassName="asset-wrap"
          useIntersectionObserver
          threshold={200}
          effect={'blur'}
          {...{ className, alt, ...restElementProps }}
          src={source}
        />
      ) : type === 'video' ? (
        <LazyLoadComponent
          useIntersectionObserver
          threshold={200}
          placeholder={videoLazyPlaceholder}
          {...lazyLoadProps}
        >
          <div className="asset-wrap">
            <VideoComponent
              {...{
                className,
                playButtonSize,
                ...restElementProps,
              }}
              ref={videoComponentRef}
              onLoadStart={startLoading}
              onError={loadErrorHandler}
              onLoad={stopLoading}
              onLoadedData={stopLoading}
              onCanPlay={stopLoading}
              onProgress={progressHandler}
              src={source}
            />
          </div>
        </LazyLoadComponent>
      ) : null}
      {loading && (
        <div className="loader-wrap">
          <Spinner
            style={{
              width: '3rem',
              height: '3rem',
              borderWidth: 3,
            }}
            variant="dark"
            animation="border"
          />
        </div>
      )}
    </StyledWrapper>
  );
};

export default AssetComponent;

AssetComponent.propTypes = {
  disableLocalLoad: PropTypes.bool,
  loading: PropTypes.bool,
  src: PropTypes.string.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.object }),
  videoComponentRef: PropTypes.shape({ current: PropTypes.object }),
  elementProps: PropTypes.shape({
    playButtonSize: PropTypes.oneOf(ButtonSizes),
    alt: PropTypes.string,
    autoplay: PropTypes.bool,
    className: PropTypes.string,
    playOnHover: PropTypes.bool,
  }),
  scrollPosition: PropTypes.object,
  aspectRatio: PropTypes.number,
  freeSize: PropTypes.bool,
  assetContainerClass: PropTypes.string,
  onClick: PropTypes.func,
  videoLazyPlaceholder: PropTypes.element,
  assetType: PropTypes.oneOf(Object.values(ASSET_TYPES)),
};

const VideoComponent = React.forwardRef((props, ref) => {
  const {
    src,
    className: _className,
    autoplay = true,
    playButtonSize,
    trackProps,
    muted = true,
    loop = true,
    overlayProps = {},
    playOnHover = false,
    playButtonClass: _customPlayButtonClass = '',
    onOverlayClicked,
    playsInline,
    poster,
    onEnded,
    ...restElementProps
  } = props;

  const defaultClass = 'asset';
  const [source, setSource] = useState(src);
  const [className, setClassName] = useState(defaultClass);
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef();
  const [isMuted, setIsMuted] = useState(muted);

  const playButtonClass = playButtonSize ? `${playButtonSize}-size` : '';

  useImperativeHandle(ref, () => ({
    play,
    pause,
    playFromStart,
    resetTimePointer,
    resetPoster,
    toggleMute,
    videoElementRef: videoRef,
    toggleLocalPlaying,
  }));

  useEffect(() => {
    let classText = [defaultClass, ..._className.split(' ')].join(' ');
    setClassName(classText);
  }, [_className]);

  useEffect(() => {
    setSource(src);
  }, [src]);

  const play = () => {
    videoRef?.current?.play();
    setPlaying(true);
  };

  const pause = () => {
    videoRef?.current?.pause();
    setPlaying(false);
  };

  const toggleLocalPlaying = (newState) => setPlaying(newState);

  const muteVideo = () => {
    if (videoRef?.current) {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const unMuteVideo = () => {
    if (videoRef?.current) {
      videoRef.current.muted = false;
      setIsMuted(false);
    }
  };

  const playFromStart = () => {
    resetTimePointer();
    videoRef.current?.play();
  };

  const resetTimePointer = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  const resetPoster = () => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  };

  const toggleVideo = () => {
    isPlaying ? pause() : play();
    onOverlayClicked && onOverlayClicked(isPlaying);
  };

  const toggleMute = () => {
    isMuted ? unMuteVideo() : muteVideo();
  };

  const contextMenuHandler = (event) => {
    event.preventDefault();
  };

  const onEndHandler = () => {
    setPlaying(false);
    onEnded && onEnded();
  };

  return (
    <>
      <video
        ref={videoRef}
        {...{ className, ...restElementProps }}
        src={source}
        loop={loop}
        autoPlay={autoplay && !playOnHover}
        muted={autoplay || isMuted}
        playsInline={playsInline || ''}
        preload="auto"
        onEnded={onEndHandler}
      >
        <track kind="captions" {...trackProps} />
      </video>

      {!autoplay && !playOnHover && (
        <>
          {isPlaying ? null : (
            <PlayButton
              className={`${playButtonClass}`}
              wrapperClass={_customPlayButtonClass}
              onClick={toggleVideo}
            />
          )}
          <Overlay
            onClick={toggleVideo}
            {...overlayProps}
            onContextMenu={contextMenuHandler}
          />
        </>
      )}
    </>
  );
});

VideoComponent.displayName = 'videoComponent';
VideoComponent.propTypes = {
  playButtonSize: PropTypes.oneOf(ButtonSizes),
  alt: PropTypes.string,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  src: PropTypes.string,
  trackProps: PropTypes.object,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  overlayProps: PropTypes.object,
  playOnHover: PropTypes.bool,
  playButtonClass: PropTypes.string,
  onOverlayClicked: PropTypes.func,
  playsInline: PropTypes.bool,
  poster: PropTypes.string,
  onEnded: PropTypes.func,
};
