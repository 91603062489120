import styled from "styled-components";
import {
  Container,
  Popover,
  FormControl,
  Card,
  FormLabel,
  Tabs,
  FormCheck,
} from "react-bootstrap";
import InstagramLogin from "react-instagram-login";
import { deviceQuery } from "../../styles/mediaSizes";

export const TabsStyled = styled(Tabs)`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  justify-content: end;
  background-color: #f5f5f5;
`;
export const UserProfileStyled = styled.div`
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    color: #b5b6c7;
  }
`;
export const DescriptionText = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.palette.newTheme.blueText};
`;
export const UserNameText = styled.h1`
  font-size: 28px;
  font-weight: 800;
  color: ${(props) => props.theme.palette.newTheme.darkBlueButton};
`;
export const Text1 = styled.h4`
  color: ${(props) => props.theme.palette.newTheme?.black};
  font-size: 16px;
  font-weight: 700;
  .CurrentText {
    padding: 7px;
  }
`;
export const Image = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  img {
    padding: 0px 10px;
  }
`;
export const StyledPopper = styled(Popover)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.subText4};
  font-weight: 500;
  line-height: 1.6rem;
  min-width: 7.3rem;
  min-height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    display: none;
  }
  .popover-body {
    color: inherit;
  }
`;
export const AddressText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvtarImg = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  text-align: center;
  svg {
    fill: white;
    height: 220px;
    width: 73px;
    path {
      fill: white;
    }
  }
`;
export const AddressBtn = styled.div`
  color: ${(props) => props.theme.palette.text.white};
  background-color: ${(props) => props.theme.palette.newTheme.themeBlue};
  margin: 0px;
  display: flex;
  border-radius: 40px;
  height: 40px;
  align-items: center;

  .copy-img {
    margin-right: 15px;
    height: 22px;
  }

  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px 10px 0px 15px;
  }
`;
export const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${deviceQuery.tabletM} {
    display: inline-block !important;
    width: 100%;
    text-align: -webkit-center;
  }
`;

export const UserProfileContainer = styled(Container)`
  max-width: 700px !important;
  img {
    width: 20px;
    height: 20px;
  }
  .backbutton {
    cursor: grabbing;
  }
  .insta-button {
    padding: 12px 60px;
    border-radius: 25px;
    display: flex;
    border: 2px solid ${(props) => props.theme.palette.newTheme.themeBlue};
    gap: 6px;
    align-items: center;
    margin-bottom: 10px;
    background: #ffffff;
    svg {
      fill: ${(props) => props.theme.palette.newTheme.themeBlue};
    }
  }
  .insta-button-disabled {
      padding: 12px 60px;
      border-radius: 25px;
      display: flex;
      border: 2px solid gray
      border: 2px solid gray;
      gap: 6px;
      align-items: center;
      margin-bottom: 10px;
      background: ligthgray;
      svg {
        fill: gray;
      }
  }
`;
export const CountDownTimerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  h4 {
    color: ${(props) => props.theme.palette.newTheme.themeBlue};
    font-size: 24px;
    font-weight: 500;
  }
  .bidAmount {
    margin: 0px;
    padding: -3px;
  }
`;

export const CreateButton = styled.button`
  padding: 8px 37px;
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  color: ${(props) => props.theme.palette.newTheme.darkBlueButton};
`;
export const EditProfile = styled.button`
  position: absolute;
  right: 0px;
  height: 40px;
  width: 140px;
  text-align: center !important;
  border: 2px solid ${(props) => props.theme.palette.newTheme.themeBlue};
  border-radius: 25px;
  background-color: ${(props) => props.theme.palette.newTheme.darkYellow};
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  @media ${deviceQuery.tabletM} {
    position: inherit;
    margin-top: 20px;
  }
`;
export const ProfileDiv = styled.div`
  padding: 4px 37px;
  margin: 40px 0px;
  color: #000;
  .card {
    // width: 72px;
    // height: 72px;
    // border-radius: 50%;
    overflow: hidden;
  }

  &.upload-profile-image,
  #profile_image_details {
    img {
      width: ${(props) => (props.$imageUrl ? "340px" : "100%")} !important;
      height: ${(props) => (props.$imageUrl ? "300px" : "100%")} !important;
      // object-fit: cover;
      border-radius: 0 !important;
    }
  }
`;
export const SubTextRight = styled.p`
  text-align: end;
  color: #b5b6c7;
  color: ${(props) => props.theme.palette.text.gray};
  margin-left: auto;
  cursor: pointer;
`;
export const LabelStyled = styled(FormLabel)`
  font-size: 14px;
  margin-left: 70px;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SubText = styled.p`
  margin-left: 6px;
  color: ${(props) => props.theme.palette.text.secondary};
  &.asset-description {
    line-height: 1.6rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.2rem;
  }
`;
export const SubTextGray = styled.p`
  color: ${(props) => props.theme.palette.text.gray};
  @media ${deviceQuery.tabletM} {
    width: 40% !important;
  }
  @media ${deviceQuery.mobileSM} {
    width: 40% !important;
  }
`;
export const DataNotFoundStyled = styled.div`
  text-align: center;
  margin: 8rem 8rem;
`;
export const SubMainText = styled.p`
  color: #585858;
`;

export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.newTheme.mainGray};
  width: 48%;
`;

export const LiveAuctionText = styled.h1`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const FormCheckStyled = styled.div`
  height: 40px;
  input {
    width: 37px;
    height: 35px;
  }

  label {
    margin: 10px;
    background-color: ${(props) => props.theme.palette.text.light} !important;
  }
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const InputStyled = styled(FormControl)`
  padding: 12px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none !important;
`;
export const ProfileSectionLayout = styled.div`
  min-height: 220px;
  object-fit: contain;
  border-top-left-radius: 12px;
  word-break: break-word;
  border-top-right-radius: 12px;
  background-color: ${(props) => props.theme.palette.newTheme.darkYellow};
  padding-bottom: 30px;

  @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
    .mobile-view-ui {
      display: inline-block !important;
    }
  }
  @media ${deviceQuery.tabletM} {
    height: 100%;
    .mobile-view-ui {
      display: inline-block !important;
    }
    .authorProfile {
      width: 100%;
      margin-left: 0px;
    }
  }
`;
export const ContainerDiv = styled(Container)`
  .nav-tabs .nav-link.active {
    color: ${(props) => props.theme.palette.newTheme.themeBlue};
    border: none !important;
    font-size: 16px;
    font-weight: 800;
    border-radius: 0px;
    background: transparent;
    height: 60px;
    border-bottom: 2px solid
      ${(props) => props.theme.palette.newTheme.themeBlue} !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    height: 60px;
    border-radius: 0px;
    background: transparent;
    color: ${(props) => props.theme.palette.secondary.main};
    border: none !important;
    font-size: 16px;
    font-weight: 400;
    outline: none;
  }
  .asset-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .profileImage {
    cursor: pointer;
    margin: 0px 21px;
    padding: 10px 0;
    position: relative;
  }

  @media ${deviceQuery.tabletM} {
    .profileImage {
      cursor: pointer;
      display: inherit !important;
      margin: 0px;
      position: relative;
    }
  }
  @media ${deviceQuery.mobileSM} {
    .profileImage {
      cursor: pointer;
      display: inherit;
      margin: 0px;
      position: relative;
    }
  }
`;

export const ButtonList = styled.button`
  padding: 8px 45px;
  border-radius: 30px;
  margin-right: 20px;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const Button = styled.button`
  padding: 10px 45px;
  border-radius: 14px;
  align-items: center;
  background-color: #fff;
  border: none;
`;
export const IconLayout = styled.div`
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  padding: 5px;
  border-radius: 40px;
  svg {
    width: 26px;
    height: 20px;
  }
`;
export const SaveButton = styled.button`
  padding: 15px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  font-weight: 700;
  width: 100%;
  margin: 24px 0px;
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
  svg {
    margin-right: 6px;
  }
`;

export const CommonButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const Text = styled.div`
  color: #080b35;
`;
export const ProfileCard = styled.div`
  margin: 8rem;
  @media ${deviceQuery.tabletM} {
    margin: 4rem;
  }
  @media ${deviceQuery.mobileSM} {
    margin: 1rem;
  }
  .cutomizebutton {
    text-align: end;
    width: 100%;
    display: inline-block;
    position: relative;
  }
`;
export const CustomDivideLine = styled.div`
  display: flex;
  margin: 30px 0px;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${(p) => p.theme.palette.newTheme.cardGray};
  hr {
    width: 100%;
    margin: 0px;
  }
  span {
    width: 45%;
    font-size: 16px;
    color: ${(p) => p.theme.palette.newTheme.cardGray};
  }
`;

export const StyledInstagramButtonContainer = styled.div`
  pointer-events: ${(props) => {
    return (props.instagramUsername || props.disable) && "none";
  }};
  cursor: ${(props) =>
    (props.instagramUsername || props.disable) && "not-allowed"};
  margin-bottom: 60px;
  @media ${deviceQuery.tabletM} {
    margin-bottom: 24px;
  }
`;

export const AuctionButton = styled.div``;

export const CreateCollectionStyled = styled.div`
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px !important;
    display: inline-block;
  }
`;
export const CustomCardStyledItems = styled(Card)`
  width: 18rem;
  text-align: center;
  color: #000000;
  margin: 20px;
  background-color: #edfbff;
`;
export const CustomCardStyled = styled.div`
  color: white;
  display: flex;
  position: absolute;
  left: -6rem;
  top: 247px;
  right: 0;
  padding: 2rem;
  border-top-left-radius: 50px;
  object-fit: contain;
  backdrop-filter: blur(15px);
  box-shadow: 15px 15px 50px 0 rgb(0 0 0 / 16%);
  background-color: rgba(0, 0, 0, 0.16);

  & > div {
    display: flex;
    flex-direction: column;

    .content {
      border-right: 2px solid white;
      padding: 0 3rem 0 0rem;
    }

    &:nth-child(1) {
      width: max-content;

      button {
        background: ${(p) => p.theme.palette.primary.main};
        color: ${(p) => p.theme.palette.secondary.main};
      }
    }
    &:nth-child(2) {
      padding: 0 0 0 3rem;

      button {
        margin-top: 12px;
        background: ${(p) => p.theme.palette.white.main};
        color: ${(p) => p.theme.palette.secondary.main};
        margin-left: auto;
      }
    }

    h1 {
      font-size: 1.1rem;
      font-family: "Kanit", sans-serif;
    }
  }

  .value {
    color: ${(p) => p.theme.palette.secondary.main};
    font-size: 2rem;
    margin: 0;
  }

  .sm-value {
    color: ${(p) => p.theme.palette.secondary.main};
    font-size: 1.2rem;
    margin: 0;
    white-space: nowrap;
  }

  button {
    margin-top: 1.5rem;
    border: none;
    width: 100%;
    max-width: 140px;
  }

  @media ${deviceQuery.tabletM} {
    left: -1rem;
  }

  @media ${deviceQuery.mobileSM} {
    top: 150px;
    flex-direction: column;
    align-items: center;

    & > div {
      &:nth-child(1) {
        margin-bottom: 1rem;
      }
      .content {
        border: none;
      }
      &:nth-child(2) {
        button {
          margin-left: 0;
        }
      }
    }
  }
`;

export const HeadingEth = styled.h1`
  position: absolute;
  text-align: right;
  margin-left: 277px;
  margin-bottom: 0px;
  display: inline-block;
  color: ${(props) => props.theme.palette.text.light};
`;

export const RoundImage = styled.div`
  border-radius: 15px;
  width: 48px;
  height: 48px;
  overflow: hidden;
  justify-content: center;
  display: flex;

  background-color: #ebebeb;
  .asset-img {
    width: 100%;
  }
`;

export const StyleInput = styled.div`
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: none !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  color: ${(props) => props.theme.palette.text.light};
  align-items: center;
  display: flex;
  input,
  input:focus {
    border: none;
    box-shadow: none !important;
    margin: 1rem 0rem;
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
`;

export const SubHeadingText = styled.h2`
  font-size: 1.4rem;
  color: ${(props) => props.theme.palette.text.primary};
  margin: 0;
`;
export const SubHeading = styled.h5`
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const ContractAddess = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
`;
export const ImageLayout = styled.div`
  margin-top: 30px;
  background-color: ${(props) => props.theme.palette.newTheme.themeBlue};
  border-radius: 24px;
  height: 220px;
  margin-left: 9px;
  overflow: hidden;
  img {
    /* object-fit: fill !important; */
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
    height: auto;
  }
  /* min-width: ${(props) => (props.$imgUrl ? "260px" : "260px")}; */
  /* max-width: 260px; */
  min-width: 260px;
  max-width: 260px;
  object-fit: contain;

  @media ${deviceQuery.tabletM} {
    width: 100%;
    height: 100%;
    margin-left: 0px;
  }
`;

export const StyledCard = styled.div`
  max-width: 100%;
  margin: auto;
  margin-bottom: 5rem;
  margin-top: 3rem;
  .card {
    cursor: pointer;
    margin-top: 22px;
    border-radius: 16px;
    border: none;
  }
  .infinite {
  }
  .card-title {
    color: ${(props) => props.theme.palette.text.primary} !important;
    min-height: 74px;
  }
  border-radius: 2.4rem;
  .card {
    min-height: 352px;
    width: 100%;

    text-align: left;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.text.white};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .body {
      padding: 2rem 1.7rem;
      .card-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 112.1%;
        margin-bottom: 1.5rem;
      }
      .protagonist-wrap {
        .avatar {
          width: 4rem;
          height: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
          .protagonist-name {
            font-size: 1.6rem;
          }
        }
      }
    }

    .footer {
      color: ${(props) => props.theme.palette.text.light};
      background-color: ${(props) => props.theme.palette.secondary.light};
      padding: 2rem 1.7rem;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 162.1%;
        margin-bottom: 0.5rem;
      }

      .remaining-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 162.1%;
      }

      .value,
      .bid-amount {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
`;

export const CustomCardLayout = styled.div`
  border-radius: 22px;
  padding: 10px 12px;
  background-color: #f8f8f8;
  p {
    color: #c5c5cf;
  }
`;

export const BidButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  margin: 24px 0px;
  color: #fff;
  width: 100%;
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
  font-weight: 700;
  cursor: not-allowed !important;
  svg {
    margin-right: 6px;
  }
`;
