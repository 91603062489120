import React from 'react';
import PropTypes from 'prop-types';

const PlayIcon = ({ fillColor = '#fff', ...props }) => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="27" cy="27" r="26.5" stroke={fillColor} />
      <path d="M32 27L24.5 32.1962L24.5 21.8038L32 27Z" fill={fillColor} />
    </svg>
  );
};

PlayIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default PlayIcon;
