import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { apiHandler } from "../../services/axios";
import ProtagonistChip from "../../Components/Protagonist";
import { getOneAsset } from "../../services/assetServices";

import { createContractInstance } from "../../common/common";

import {
  getAuctionData,
  getAuctionfinalDeatils,
} from "../../services/collectionServices";
import { useParams, useNavigate } from "react-router-dom";

import {
  CreateCollectionDiv,
  Heading,
  CardTitle,
  CardProfile,
  SubText,
} from "./style";

import { Card, Col, Row, Spinner } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import AssetComponent from "../../Components/AssetComponent";
import { toast } from "react-toastify";

function ItemListingStepTwo({ stateChange, loadingstate }) {
  const [isMinted, setIsMinted] = useState(false);
  const [assetData, setAssetData] = useState(null);
  const [auctionId, setAuctionId] = useState();
  const [txhash, setAuctiontxhash] = useState();
  const contractState = useSelector((store) => store.contractState);
  const userState = useSelector((store) => store.user);
  const [finishStatus, setfinishStatus] = useState(false);
  const [confirmationRejected, setConfirmationRejected] = useState({
    code: "",
    message: "",
  });
  const storecollection = useSelector((store) => store.assetsCollectionId);
  const globalState = useSelector((store) => store.global);
  const { account, web3Instance } = globalState;

  const txHashData = storecollection?.txHashData;
  const getAuctionId = storecollection?.auctionId;
  const { assetId } = useParams();
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const navigate = useNavigate();

  const getAuctionHashData = async () => {
    let reqDatahash = {
      tx_data: "",
    };

    if (txHashData) {
      reqDatahash = {
        tx_data: txHashData,
      };
    } else {
      reqDatahash = {
        tx_data: {},
      };
    }

    try {
      await apiHandler(
        () => getAuctionfinalDeatils(getAuctionId, reqDatahash),
        {
          onSuccess: (data) => {
            navigate(`/itemListingSteps/${assetId}/listingStepfinal`);
          },
          onError: (error) => console.error(error),
        }
      );
    } catch (error) {
      await apiHandler(
        () => getAuctionfinalDeatils(getAuctionId, reqDatahash),
        {
          onSuccess: (data) => {
            navigate(`/itemListingSteps/${assetId}/listingStepfinal`);
          },
          onError: (error) => console.error(error),
        }
      );
    }
  };

  const getCreateReserveAuction = async (assetTokenId, amountEth, dataElem) => {
    setIsMinted(true);
    const auctionContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.auctionABI,
      defaultConfig?.auctionContractAddress
    );

    try {
      if (amountEth || assetTokenId) {
        const txHashData = await auctionContractInstance.methods
          .createReserveAuction(
            assetData?.contract_address,
            assetTokenId,
            amountEth
          )
          .send({
            from: account,
          });
        const reqDatahash = {
          tx_data: txHashData,
        };

        await apiHandler(
          () => getAuctionfinalDeatils(dataElem._id, reqDatahash),
          {
            onSuccess: (data) => {
              navigate(`/itemListingSteps/${assetId}/listingStepfinal`);
            },
            onError: (error) => console.error(error),
          }
        );
      } else {
        const reqDatahash = {
          tx_data: {},
        };

        await apiHandler(
          () => getAuctionfinalDeatils(dataElem._id, reqDatahash),
          {
            onSuccess: (data) => {
              navigate(`/itemListingSteps/${assetId}`);
            },
            onError: (error) => console.error(error),
          }
        );
        navigate(`/itemListingSteps/${assetId}`);
      }

      setAuctiontxhash(txHashData);
      setIsMinted(false);
    } catch (error) {
      console.log(error, "check");
      toast.error(error.message);
      setConfirmationRejected({ code: error.code, message: error.message });
      const reqDatahash = {
        tx_data: {},
      };
      await apiHandler(
        () => getAuctionfinalDeatils(dataElem._id, reqDatahash),
        {
          onSuccess: (data) => {
            navigate(`/itemListingSteps/${assetId}/listingStepfinal`);
          },
          onError: (error) => console.error(error),
        }
      );
      setIsMinted(false);
    }
  };

  const onClickHandler = async () => {
    const storeamountEth = storecollection?.amountEth;
    const storeassetTokenId = storecollection?.assetTokenId;

    try {
      const reqData = {
        asset_id: assetId,
      };
      const getauctionId = await apiHandler(() => getAuctionData(reqData), {
        onSuccess: (data) => {
          setAuctionId(data?.auction);
          getCreateReserveAuction(
            storeassetTokenId,
            storeamountEth,
            data?.auction
          );
        },
        onError: (error) => console.error(error),
      });
    } catch (error) {
      console.log("check", error);
      toast.error(error.message);
      setConfirmationRejected({ code: error.code, message: error.message });
    }
  };

  const getAssetDetails = async () => {
    apiHandler(() => getOneAsset(assetId), {
      onSuccess: (data) => {
        setAssetData(data);
      },
      onError: (error) => console.error(error),
      final: () => {},
    });
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
        setfinishStatus(true);
        // your logic
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (txHashData) getAuctionHashData();
  }, [txHashData]);

  useEffect(() => {
    if (assetId) {
      stateChange(2);
      getAssetDetails();
    }
  }, [assetId]);

  console.log(confirmationRejected, "confirmationRejected");

  return (
    <CreateCollectionDiv>
      <Row>
        <Col md={5}>
          <CardProfile>
            {assetData ? (
              <AssetComponent
                assetType="image"
                freeSize
                src={assetData?.metadata_fields?.image_url}
              />
            ) : (
              <Spinner animation="border" />
            )}
            <Card.Body>
              <CardTitle>{assetData?.name}</CardTitle>
              <ProtagonistChip
                id={assetData?.creator_id}
                type="normal"
                className="protagonist-wrap"
              />
            </Card.Body>
          </CardProfile>
        </Col>
        <Col md={5}>
          {confirmationRejected?.code === 4001 ? (
            <>
              <Heading>Please try to List asset again </Heading>
              <SubText>Confirm with your wallet to continue.</SubText>
            </>
          ) : (
            <>
              <Heading>Waiting for confirmation</Heading>
              <SubText>Confirm with your wallet to continue.</SubText>
            </>
          )}
          {loadingstate ? (
            <div className="spinner-wrapper d-flex justify-content-center mt-5">
              <Spinner
                animation="border"
                style={{ width: "60px", height: "60px", color: "#080b35" }}
              />
            </div>
          ) : isMinted ? (
            <div className="spinner-wrapper d-flex justify-content-center mt-5">
              <Spinner
                animation="border"
                style={{ width: "60px", height: "60px", color: "#080b35" }}
              />
            </div>
          ) : (
            <div className="mt-3">
              <Button
                color="secondary"
                className="action-btn w-100"
                type="submit"
                onClick={onClickHandler}
              >
                List your NFT
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </CreateCollectionDiv>
  );
}

ItemListingStepTwo.propTypes = {};

export default ItemListingStepTwo;
