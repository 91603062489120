import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { TableContainer } from "./style";
import { Table } from "react-bootstrap";
import CustomPagination from "../CustomPagination";
import LineLoader from "../LineLoader";

const CustomTable = ({
  columns,
  rows,
  totalItems,
  loading,
  paginationProps,
  variant = "dark",
  hidePagination = false,
  ...restProps
}) => {
  const {
    pageLimit = 10,
    onLimitChange,
    ...restPaginationProps
  } = paginationProps ?? {};
  const [limit, setLimit] = useState(pageLimit);

  const handleLimitChange = (pageNo, limit) => {
    setLimit(limit);
    if (onLimitChange) {
      onLimitChange(pageNo, limit);
    }
  };

  const getRows = useCallback(() => {
    if (hidePagination) {
      return rows;
    } else {
      return rows.slice(0, limit);
    }
  }, [rows, limit, hidePagination]);

  return (
    <TableContainer variant={variant} className="custom-table-container">
      <LineLoader className="line-loader" active={loading} />
      <Table responsive striped hover variant={variant} {...restProps}>
        <thead>
          <tr>
            {columns.map(({ key, label }) => {
              return <th key={key}>{label}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {getRows()?.map((row) => {
            return (
              <tr key={row?.id}>
                {columns.map(({ key, render }) => {
                  if (render) {
                    return (
                      <td key={key}>
                        <span className={"td-item"}>{render(row)}</span>
                      </td>
                    );
                  }
                  return (
                    <td key={key}>
                      <span className={"td-item"}>{row[key]} </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {rows?.length === 0 ? (
        <p className="no-data-message">No Data Found</p>
      ) : null}

      {!hidePagination && (
        <div>
          <CustomPagination
            totalItems={totalItems}
            {...restPaginationProps}
            onLimitChange={handleLimitChange}
            pageLimit={limit}
          />
        </div>
      )}
    </TableContainer>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      render: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalItems: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  hidePagination: PropTypes.bool,
  variant: PropTypes.oneOf(["dark", "light"]),
  paginationProps: PropTypes.shape(CustomPagination.propTypes),
};
