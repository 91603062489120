import styled, { css } from "styled-components";

import {
  Container,
  Card,
  Button,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { deviceQuery } from "../../styles/mediaSizes";
import { flexBetween, poppinsRegular } from "../../styles/mixins";

export const Header = styled.div`
  ${flexBetween};

  .left {
    padding-left: 5rem;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: ${(p) => p.theme.palette.common.animationBg};
    font-size: 1rem;
  }

  @media ${deviceQuery.tabletM} {
    .left {
      padding-left: 0;
    }
  }
`;

export const SubTextGray = styled.p`
  color: ${(props) => props.theme.palette.text.gray};

  @media ${deviceQuery.tabletM} {
    width: 40% !important;
  }
`;
export const CreateCollectionStyled = styled(Container)`
  .card:hover {
    background-color: #fff;
  }
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .img-upload .asset-wrap .asset {
    height: 255px;
  }
  .preview-img {
    object-fit: cover;
  }
  .nav-link {
    color: #b5b6c7;
  }
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px;
    display: inline-block;
  }
`;
export const LoaderArea = styled.div`
  min-height: 50px;
  text-align: center;
  position: relative;
`;
export const SubHeading = styled.h5`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const FormCheckStyled = styled.div`
  height: 40px;
  input {
    width: 37px;
    height: 35px;
  }

  label {
    margin: 10px;
    background-color: ${(props) => props.theme.palette.text.light} !important;
  }

  color: ${(props) => props.theme.palette.text.secondary};

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const TextHeading = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const AuctionButton = styled.div`
  margin-top: 3rem;
  button {
    border-radius: 12px;
    background-color: #b5b6c7;
    color: ${(props) => props.theme.palette.text.light};
  }
`;
export const StyleInput = styled.div`
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: none !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  color: ${(props) => props.theme.palette.text.light};
  align-items: center;
  display: flex;
  input,
  input:focus {
    border: none;
    box-shadow: none !important;
    margin: 1rem 0rem;
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
`;
export const HeadingItem = styled.h1`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const HeadingEth = styled.h1`
  position: absolute;
  text-align: right;
  margin-left: 277px;
  margin-bottom: 0px;
  display: inline-block;
  color: ${(props) => props.theme.palette.text.light};
`;
export const PreviewDiv = styled.div`
  /* padding: 40px 0px 15rem 0px; */
  width: 100%;
  height: 250px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
`;
export const RouteStyled = styled(Container)`
  padding: 40px 0px 100px 0px;
  @media ${deviceQuery.laptopM} {
    .MobileView {
      width: 100%;
      display: flex;
    }
    .MobileView div {
      width: 100%;
      text-align: center;
    }
    .MobileView .Horizantalline::after {
      content: "";
      position: absolute;
      width: 16.4%;
      height: 4px;
      background-color: #dcdcdc;
      margin-top: 52px;
    }
  }
`;
export const BackButton = styled.button`
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.text.light};
  margin: 20px 0px;
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
  display: flex;
  padding: 10px 40px;
  border-radius: 40px;
  height: 40px;
  align-items: center;
`;
export const VerticalLine = styled.div`
  border-left: 2px solid #b5b6c7;
  height: 100px;
  background-color: ${(props) => props.theme.palette.text.light};
  margin: 20px 0px 20px 46px;
  @media ${deviceQuery.laptopM} {
    display: none;
  }
`;
export const AddressBtn = styled.button`
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.text.light};
  margin: 0px 0px 0px 8px;
  display: flex;

  padding: 10px 20px;
  border-radius: 40px;
  height: 40px;
  align-items: center;
  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px 10px 0px 15px;
  }
  svg {
    width: 40px;
  }
  img {
    width: 16px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const TextContent = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: ${(p) => p.theme.fw.regular};
  ${poppinsRegular};
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const HeadingText1 = styled.h3`
  color: ${(props) => props.theme.palette.newTheme.mainGray};

  font-size: 18px;
  font-weight: 400;
`;
export const CreateButton = styled(Button)`
  padding: 15px 25px;
  background-color: #feefa4;
  border: none;
  border-radius: 12px;
  color: #000;
`;
export const Heading = styled.h1`
  margin-top: 4rem;

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const MyAccount = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
  width: 100%;
  a {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-decoration: none;
    font-size: 14px;
  }
  &.asset-description {
    line-height: 1.6rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.2rem;
  }
`;
export const VerySmallText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
  font-size: 14px;
`;
export const LabelStyled = styled(FormLabel)`
  font-size: 16px;

  color: ${(props) => props.theme.palette.text.secondary};
`;

export const MintButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10px 0px;
  background-color: #080b35 !important;

  border: none;
  .disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    background-color: #eaeaea !important;
  }
`;

export const ListButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 50%;
  margin: 20px 0px 10px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const InputStyled = styled(FormControl)`
  padding: 12px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none !important;
`;
export const CardTitle = styled(Card.Title)`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const CustomCardStyled = styled(Card)`
  width: 18rem;
  text-align: center;
  color: #000000;
  margin: 20px;
  background-color: #edfbff;
`;
export const CustomCard = styled(Card)`
  padding: 30px 30px 50px;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  .card-title {
    color: ${(props) => props.theme.palette.text.primary};
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      pointer-events: none;
      background-color: #eaeaea !important;
    `}
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  img {
    height: 140px;
  }
  @media ${deviceQuery.laptopM} {
    img {
      height: 100%;
    }
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }
`;
export const CreateCollectionDiv = styled(Container)`
  .action-btn {
    border-radius: 14px;
    color: #fff;
  }
`;

export const ContractAddess = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;
export const RoundImage = styled.div`
  border-radius: 15px;
  width: 48px;
  height: 48px;
  overflow: hidden;
  justify-content: center;
  display: flex;

  background-color: #ebebeb;
  .asset-img {
    width: 100%;
  }
`;
// export const RoundImage = styled.div`
//   h1 {
//     margin-left: 20px;
//   }
//   img {
//     border-radius: 50%;
//     color: #fff;
//     width: 70px;
//     height: 70px;
//   }
// `;
export const CoverImage = styled.div`
  text-align: center;
  img {
    width: 386px;
    height: 342px;
    border-radius: 32px;
    color: #fff;
  }
`;

export const UserText = styled.h4`
  color: ${(props) => props.theme.palette.newTheme?.black};
  font-size: 16px;
  font-weight: 700;
`;
export const Text1 = styled.h1`
  margin-left: 20px;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const Text2 = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const DeleteButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #f86d6d;
  margin-left: 24px;
  color: #fff;
  border: none;
`;
export const MainHeading = styled.h1`
  color: #1f1f2c;
`;
export const CommonButton = styled.button`
  padding: 12px 60px;
  border-radius: 14px;
  margin-right: 12px;
  align-items: center;
  background-color: ${(props) => (props.disabled ? "#73777B" : "#eeecff")};
  color: #5142fc;
  font-weight: 700;
  border: none;
  &:disabled,
  &[disabled] {
    color: #fff;
  }
  @media ${deviceQuery.tabletM} {
    padding: 16px 45px;
  }
`;
export const Text = styled.div`
  color: #080b35;
`;
export const CustomImage = styled.div`
  margin: 24px;
  background-color: ${(props) => props.theme.palette.newTheme.cardGray};
  // box-shadow: rgb(0 0 0 / 15%) 1px 11px 10px 0px;
  box-shadow: 0 16px 16px 0 rgb(0 0 0 / 8%);
  border-radius: 12px;
  margin-top: -40px;
  // height: 300px;
`;
export const CardProfile = styled(Card)`
  overflow: hidden;
  object-fit: contain;
  border-radius: 16px;
  border: none;
  margin: 42px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.palette.text.white} !important;
  border: solid 2px ${(props) => props.theme.palette.newTheme.darkYellow};
  .card-title {
    text-align: left;
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }

  .createNftImage img {
    height: 240px !important;
  }
`;

export const StyledCard = styled.div`
  max-width: 100%;
  margin: auto;
  margin-bottom: 5rem;
  .card {
    cursor: pointer;
    margin-top: 22px;
    border-radius: 32px;
  }
  .infinite {
  }
  .card-title {
    color: ${(props) => props.theme.palette.text.primary} !important;
    min-height: 74px;
  }
  border-radius: 2.4rem;
  .card {
    min-height: 420px;
    width: 100%;
    text-align: left;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.text.white};

    img {
      padding: 0px 2px;
      height: 270px;
      border: none;
      border-radius: 32px;
      width: 100%;
    }

    .body {
      padding: 2rem 1.7rem;
      .card-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 112.1%;
        margin-bottom: 1.5rem;
      }
      .protagonist-wrap {
        .avatar {
          width: 4rem;
          height: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
          .protagonist-name {
            font-size: 1.6rem;
          }
        }
      }
    }

    .footer {
      color: ${(props) => props.theme.palette.text.light};
      background-color: ${(props) => props.theme.palette.secondary.light};
      padding: 2rem 1.7rem;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 162.1%;
        margin-bottom: 0.5rem;
      }

      .remaining-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 162.1%;
      }

      .value,
      .bid-amount {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
`;

export const MintRadio = styled.div`
  input[type="radio"] {
    display: block;
  }

  label {
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    left: 2rem;
    color: #080b35;
  }

  label::before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: transparent;
    border: 2px solid #080b35;
    border-radius: 20%;
    top: 50%;
    left: -2rem;
    transform: translateY(-50%);
    transition: border-color 400ms ease;
  }

  label::after {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: #080b35;
    border: 2px solid #080b35;
    border-radius: 20%;
    top: 50%;
    left: -2rem;
    transform: translateY(-50%) scale(0);
    transition: transform 400ms ease;
  }

  input[type="radio"]:checked + label::before {
    border-color: #080b35;
  }

  input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(0.55);
  }
`;

export const IconLayout = styled.div`
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  padding: 5px;
  border-radius: 40px;
  svg {
    width: 26px;
    height: 20px;
  }
`;
export const SaveButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  font-weight: 700;
  width: 100%;
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
`;

export const CustomCardLayout = styled.div`
  border-radius: 22px;
  padding: 10px 12px;
  background-color: #f8f8f8;
  p {
    color: #c5c5cf;
  }
`;
export const AddButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  font-weight: 700;
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
`;

export const LoadMoreButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  margin: 20px 0px 4rem 0px;
  color: ${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
`;
