import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import {
  CreateCollectionStyled,
  Text1,
  Text2,
  SaveButton,
  CustomCardStyled,
  RoundImage,
  CoverImage,
  CustomCard,
  CommonButton,
  DeleteButton,
  Text,
  AddressBtn,
  SubText,
  ContractAddess,
  HeadingEth,
  StyleInput,
  HeadingItem,
  TextHeading,
  AuctionButton,
  SubHeading,
  FormCheckStyled,
  CustomCardLayout,
  SubTextGray,
  IconLayout,
  UserText,
  MainHeading,
} from './style';
import { HeadingText } from './collectionDetailStyle';
import {
  Card,
  Row,
  Tabs,
  Form,
  Tab,
  Col,
  Spinner,
  Carousel,
} from 'react-bootstrap';

import { useLocation, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EtherScanLink from '../../Components/EtherScanLink/EtherScanLink';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import getAxiosInst, { apiHandler } from '../../services/axios';

import {
  getCollection,
  getAllCollection,
  deleteCollectionAssets,
  getfreezeCollections,
} from '../../services/collectionServices';
import UserIcon from '../../assets/icons/UserIcon';
import { getUser } from '../../services/userServices';

import Button from '../../Components/Button/Button';
import CopyAddress from '../../assets/icons/copyAddress';
import { Formik, Form as FormikForm, FieldArray } from 'formik';
import TextInput from '../../Components/TextInput/TextInput';
import Checkbox from '../../Components/Checkbox/Checkbox';
import { checkIfLogin } from '../../common/helpers/helper';

import {
  createWeb3Instance,
  createContractInstance,
  createProvider,
} from '../../common/common';
import * as Yup from 'yup';
import { getOneAsset } from '../../services/assetServices';
import { setUser } from '../../store/actions/userActions';
import { BsInstagram } from 'react-icons/bs';
import { FiTwitter } from 'react-icons/fi';
import ShareIcon from '../../assets/icons/shareIcon';
import wethioExplorer from '../../assets/icons/wethio-explorer.png';
import ShareModal from '../../Components/ShareModal/ShareModal';
import MiniLoader from '../../Components/MiniLoader';
import MainTopSection from '../../Components/MainTopSection';
import ActivityList from '../../Components/ActivityList/ActivityList';
import { ROUTES } from '../../constants/routes';
const validationScema = Yup.object().shape({
  amountEth: Yup.string().required('Is Required'),
  bidcheckbox: Yup.boolean().required('Please click on checkbox'),
});
toast.configure();
function CollectionDetails() {
  const [isSubmiting, setIsSubmiting] = useState({ editUser: false });
  const navigate = useNavigate();
  const [selectIndex, setIndex] = useState(0);
  const [checkBox, setCheckbox] = useState(false);
  const [loadcontent, setLoadContent] = useState(false);
  const [collectionDetail, setCollectionDetails] = useState();
  const [isMinted, setIsMinted] = useState(false);
  const [assetData, setAssetsData] = useState();
  const [assetAttributes, setAssetAttributes] = useState();
  const [createrdata, setCreaterData] = useState();
  const [creatorApprovalStatus, setcreatorApprovalStatus] = useState(false);
  const [ownerdata, setOwnerData] = useState();
  const [isApproved, setisApproved] = useState(false);
  const [isPrimary, setIsPrimary] = useState(false);
  const isBlocked = useSelector((state) => state.global.isBlocked);

  const [isApproveLaoding, setIsApproveLaoding] = useState(true);
  const loginUser = useSelector((state) => state.user);
  const blockchain = useSelector((state) => state.global.blockchain);
  const [listingInfo, setListingInfo] = useState([]);
  const { id } = useParams();
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const globalState = useSelector((state) => state.global);
  const userState = useSelector((state) => state.user);
  const [isAdmin, setIsAdmin] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [show, setShow] = useState(false);
  const contractState = useSelector((state) => state.contractState);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isCreatorFollowed, setIsCreatorFollowed] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [isRemoveNFTLoading, setIsRemoveNFTLoading] = useState(false);
  const {
    contract: { minting: mintingContractInstance },
  } = contractState;
  const {
    contract: { auction: auctionContractInstance },
  } = contractState;

  const dispatch = useDispatch();
  const { userData } = userState;
  const { account, web3Instance } = globalState;
  const { isLogin } = loginState;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleRemoveAsset = (id) => {
    setIsRemoveNFTLoading(true);
    const blockchain = localStorage.getItem('blockchain');
    getAxiosInst({ withAuth: true })
      .delete(`assets/${id}?network=${blockchain}`)
      .then(() => {
        toast.success('Removed successfully');
        navigate('/allAssets');
        setIsRemoveNFTLoading(false);
      })
      .catch((error) => {
        setIsRemoveNFTLoading(false);

        toast.error(error?.response?.data?.message);
      });
  };
  const handleShareModalClose = () => setShow(false);
  const handleShareModalShow = () => setShow(true);

  const checkUserIsAdmin = useCallback(async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdmin(isAdmin);
  }, [account, defaultConfig, web3Instance]);

  useEffect(() => {
    if (web3Instance && account) {
      checkUserIsAdmin();
    }
  }, [web3Instance, checkUserIsAdmin]);

  useEffect(() => {
    const blockchain = localStorage.getItem('blockchain');
    if (loginUser?.userData?._id) {
      getAxiosInst({ withAuth: true })
        .get(
          `/users/creators/${loginUser?.userData?._id}?network=${blockchain}`
        )
        .then((data) => {
          const follow = data?.data?.followingAssets.includes(id);

          setIsFollowed(follow);
          setcreatorApprovalStatus(data?.data?.creatorApprovalStatus);

          const Creatorfollow = data?.data?.followingCreators.includes(
            createrdata?._id
          );

          setIsCreatorFollowed(Creatorfollow);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [loginUser?.userData?._id, id, createrdata?._id]);

  const checkIfCurrentUserIsOwner = () => {
    return checkIfLogin() && isLogin && userData?._id === ownerdata?._id;
  };
  const { pathname } = useLocation();

  const getAssetDetails = async () => {
    apiHandler(() => getOneAsset(id), {
      onSuccess: (data) => {
        setAssetAttributes(data.metadata_fields.attributes);
        setAssetsData(data);
        getCreaterDetails(data);
        setListingInfo(data?.events);
        getOwnerDetails(data.owner_id);
      },
      onError: (error) => console.error(error),
      final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
    });
  };
  const getCreaterDetails = async (collectionData) => {
    apiHandler(() => getUser(collectionData?.creator_id), {
      onSuccess: (data) => {
        setCreaterData(data);

        // setListingInfo(collectionData?.events);
      },
      onError: (error) => console.error(error),
      final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
    });
  };

  const getOwnerDetails = async (id) => {
    apiHandler(() => getUser(id), {
      onSuccess: (data) => {
        setOwnerData(data);
      },
      onError: (error) => console.error(error),
      final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
    });
  };

  const onListingClickHandler = (id) => {
    if (!creatorApprovalStatus) {
      navigate('/userProfile', {
        state: { msg: 'Please complete your profile' },
      });
    } else if (creatorApprovalStatus === 'APPROVED') {
      navigate(`/itemListingSteps/${assetData?._id}`);
    } else if (creatorApprovalStatus === 'PENDING') {
      navigate('/userProfile', {
        state: { msg: 'Please wait for approval from admin' },
      });
    } else if (creatorApprovalStatus === 'REJECTED') {
      navigate('/userProfile', {
        state: { msg: 'You are rejected by admin' },
      });
    } else if (isBlocked) {
      navigate('/userProfile', {
        state: { msg: 'You are blocked by admin' },
      });
    }
  };

  const onApproveCLickHandler = () => {
    navigate(`/approveCollection/${assetData?._id}`);
  };

  useEffect(() => {
    getAssetDetails();
  }, [isMinted, isFollowed, id]);

  useEffect(() => {
    const blockchain = localStorage.getItem('blockchain');
    if (createrdata?._id) {
      getAxiosInst({ withAuth: true })
        .get(`/users/creators/${createrdata?._id}?network=${blockchain}`)
        .then((data) => {
          setIsCreator(data?.data?.isCreator);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [createrdata?._id]);

  const onCreatorFollowHandler = (creatorid) => {
    const blockchain = localStorage.getItem('blockchain');
    if (loginUser?.userData) {
      getAxiosInst({ withAuth: true })
        .put(`/assets/${creatorid}/toggle-follow?network=${blockchain}`)
        .then((data) => {
          toast.success(data?.data?.message);

          const follow =
            data?.data?.creatorInfo?.followingAssets.includes(creatorid);
          setIsFollowed(follow);
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    } else {
      toast.error('Please Connect to Wallet');
    }
  };

  const ownerWalletAddress = ownerdata?.wallet_address;

  useEffect(() => {
    if (ownerWalletAddress) {
      (async () => {
        try {
          const mintingContractApprove = await createContractInstance(
            web3Instance,
            defaultConfig?.mintingABI,
            assetData.contract_address
          );

          console.log(
            ownerWalletAddress,
            defaultConfig?.auctionContractAddress,
            'Approve data'
          );

          const isApprovedForAll = await mintingContractApprove?.methods
            .isApprovedForAll(
              ownerWalletAddress,
              defaultConfig?.auctionContractAddress
            )
            .call();

          const approvedAddress = await mintingContractApprove?.methods
            ?.getApproved(assetData?.token_id)
            .call();

          const isMarketApproved =
            approvedAddress.toLowerCase() ===
            defaultConfig?.auctionContractAddress?.toLowerCase();
          console.log(
            assetData?.token_id,
            isApprovedForAll,
            isMarketApproved,
            'APPROVED DATA'
          );

          setisApproved(isApprovedForAll || isMarketApproved);
          setIsApproveLaoding(false);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [ownerWalletAddress, defaultConfig, id]);

  const getIsPrimaryfetchData = async () => {
    if (assetData) {
      const value = await auctionContractInstance?.methods
        .getIsPrimary(assetData?.contract_address, assetData?.token_id)
        .call();
      setIsPrimary(value);
    }
  };

  useEffect(() => {
    getIsPrimaryfetchData();
  }, [assetData, id]);

  const showApproveButton = useMemo(() => {
    return !isApproved && !isApproveLaoding;
  }, [isApproved, isApproveLaoding]);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="Item Details"
        descriptionText={''}
        routePath="/"
      />
      <CreateCollectionStyled>
        <Row xs={1} md={4} className="g-5 mt-5 mb-5">
          <Col md={6} sm={12} xs={12}>
            <Carousel
              activeIndex={selectIndex}
              variant="dark"
              className="text-center"
              pause="hover"
              interval={null}
              onSelect={handleSelect}
            >
              <Carousel.Item>
                <img
                  className="silderImage"
                  src={assetData?.metadata_fields?.image_url}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col md={6} sm={12} xs={12}>
            <div className="owner-info d-flex align-items-center mb-2">
              <Row>
                <Col md={12}>
                  <MainHeading className="mb-2">{assetData?.name}</MainHeading>
                </Col>

                <Col md={12}>
                  <div className="d-flex align-items-center mt-2 mb-5">
                    <SubText style={{ width: '10px' }}>
                      {assetData?.followersCount}
                    </SubText>
                    &nbsp;
                    <SubText>Follow</SubText>
                    {/* {isCreator && ( */}
                    <SaveButton
                      style={{ marginRight: '10px', padding: '10px 0px' }}
                      onClick={() => onCreatorFollowHandler(assetData?._id)}
                    >
                      {!isFollowed ? 'Follow' : 'Unfollow'}
                    </SaveButton>
                    {/* )} */}
                    <ShareModal
                      show={show}
                      image={assetData?.metadata_fields?.image_url}
                      handleClose={handleShareModalClose}
                    />
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={handleShareModalShow}
                      className="ms-auto d-flex align-items-center"
                    >
                      <IconLayout>
                        <ShareIcon />
                      </IconLayout>

                      <SubText>Share</SubText>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={12} xs={12} className="mt-3 mb-3">
                  {createrdata ? (
                    <CustomCardLayout>
                      <Row
                        onClick={() => {
                          navigate(
                            `${ROUTES.artistDetails?.replace(
                              ':id',
                              createrdata?._id
                            )}`
                          );
                        }}
                      >
                        <Col md={4} sm={4} xs={4}>
                          <RoundImage>
                            {createrdata?.profile_image_url ? (
                              <img
                                className="asset-img"
                                src={createrdata?.profile_image_url}
                                alt="Card cap"
                              />
                            ) : (
                              <UserIcon />
                            )}
                          </RoundImage>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                          <p>Created By</p>
                          {createrdata?.username ? (
                            <UserText>{createrdata?.username}</UserText>
                          ) : (
                            <UserText>@anonymous</UserText>
                          )}
                        </Col>
                      </Row>
                    </CustomCardLayout>
                  ) : null}
                </Col>
                <Col md={6} sm={12} xs={12} className="mt-3 mb-3">
                  {ownerdata ? (
                    <CustomCardLayout>
                      <Row
                        onClick={() => {
                          navigate(
                            `${ROUTES.artistDetails?.replace(
                              ':id',
                              ownerdata?._id
                            )}`
                          );
                        }}
                      >
                        <Col md={4} sm={4} xs={4}>
                          <RoundImage>
                            {ownerdata?.profile_image_url ? (
                              <img
                                className="asset-img"
                                src={ownerdata?.profile_image_url}
                                alt="Card cap"
                              />
                            ) : (
                              <UserIcon />
                            )}
                          </RoundImage>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                          <p>Owned By</p>
                          {ownerdata?.username ? (
                            <UserText>{ownerdata?.username}</UserText>
                          ) : (
                            <UserText>@anonymous</UserText>
                          )}
                        </Col>
                      </Row>
                    </CustomCardLayout>
                  ) : null}
                </Col>
                <Col md={12} className="mt-3 mb-3">
                  <SubText>{assetData?.description}</SubText>
                </Col>
              </Row>
            </div>
            {assetData?.mint_tx_hash && blockchain === 'eth' ? (
              <EtherScanLink
                mintTxHash={assetData?.mint_tx_hash}
                className="mt-5 mb-5 etherscan-link"
                borderLess
                text="View on Etherscan"
              />
            ) : (
              <EtherScanLink
                mintTxHash={assetData?.mint_tx_hash}
                className="mt-5 mb-5 etherscan-link"
                borderLess
                icon={wethioExplorer}
                text="View on Wethio"
              />
            )}
            <div className="d-flex w-100 justify-content-between mt-3 mb-3">
              {!checkIfCurrentUserIsOwner() ? null : (
                <>
                  <CommonButton
                    className="action-btn  "
                    onClick={() => onListingClickHandler()}
                    disabled={
                      !isApproved ||
                      isApproveLaoding ||
                      assetData?.auctions?.approvalStatus === 'PENDING'
                    }
                  >
                    {assetData?.auctions?.approvalStatus === 'PENDING'
                      ? 'Pending for approval'
                      : ' List NFT'}
                  </CommonButton>
                  {showApproveButton && (
                    <CommonButton
                      className="action-btn "
                      onClick={() => onApproveCLickHandler()}
                    >
                      Approve
                    </CommonButton>
                  )}
                </>
              )}
              {isAdmin && (
                <CommonButton
                  disabled={isRemoveNFTLoading}
                  className="action-btn "
                  onClick={() => handleRemoveAsset(assetData?._id)}
                >
                  {isRemoveNFTLoading ? <MiniLoader /> : 'Remove NFT'}
                </CommonButton>
              )}
            </div>
          </Col>
          <Col md={12}>
            <Tabs
              defaultActiveKey="Detail"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="Information"
                title="Information"
                className="text-center "
              >
                <div>
                  <div className="d-flex text-start mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Asset Name
                    </SubTextGray>
                    <SubText>{assetData?.name}</SubText>
                  </div>
                  <div className="d-flex text-start mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Asset description
                    </SubTextGray>

                    <SubText className="asset-description">
                      {assetData?.description}
                    </SubText>
                  </div>

                  <div className="d-flex text-start mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Sale Type
                    </SubTextGray>

                    <SubText>{isPrimary ? 'Primary' : 'Secondary'}</SubText>
                  </div>
                  <div className="d-flex text-start mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Royalty (%)
                    </SubTextGray>
                    <SubText>
                      {assetData?.royalty ? `${assetData?.royalty * 100}%` : 0}
                    </SubText>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Activity"
                title="Activity"
                className="text-center p-3"
              >
                <div>
                  {
                    <ActivityList
                      listData={[]}
                      listingInfo={listingInfo}
                      finaliseInfo={[]}
                    />
                  }
                </div>
              </Tab>
              <Tab eventKey="Detail" title="Detail">
                <div>
                  <div className="d-flex mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Contract Address
                    </SubTextGray>
                    <ContractAddess>
                      {assetData?.contract_address}
                    </ContractAddess>
                  </div>
                  <div className="d-flex mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Token ID
                    </SubTextGray>

                    <SubText>{assetData?.token_id}</SubText>
                  </div>
                  <div className="d-flex mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Blockchain
                    </SubTextGray>

                    <SubText>
                      {defaultConfig?.chainInfo?.name === 'wethio'
                        ? 'Wethio'
                        : 'Ethereum'}
                    </SubText>
                  </div>

                  <div className="mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      View NFT on IPFS
                    </SubTextGray>

                    <SubText className="linkText">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={assetData?.metadata_fields?.image_url}
                      >
                        {assetData?.metadata_fields?.image_url}
                      </a>
                    </SubText>
                  </div>
                  <div className=" mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      View Metadata
                    </SubTextGray>

                    <SubText className="linkText">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={assetData?.metadata_url}
                      >
                        {assetData?.metadata_url}
                      </a>
                    </SubText>
                  </div>

                  <div className="d-flex mt-3">
                    <SubTextGray
                      className="mobile-view-responsive"
                      style={{ width: '400px' }}
                    >
                      Type
                    </SubTextGray>

                    <SubText>Physical Item</SubText>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Property" title="Property">
                <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                  {assetAttributes?.map((items, i) => {
                    return (
                      <CustomCardStyled key={i}>
                        <Card.Body>
                          <Card.Title>{items?.traitType}</Card.Title>
                          <Card.Text>
                            <SubText>{items?.value}</SubText>
                          </Card.Text>
                        </Card.Body>
                      </CustomCardStyled>
                    );
                  })}
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </CreateCollectionStyled>
    </>
  );
}

export default CollectionDetails;
