import { deviceQuery } from '../../styles/mediaSizes';

import styled, { css } from 'styled-components';

export const StyledTimer = styled.div`
  padding: 0;
  .remaing-time {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .day,
    .hour,
    .min,
    .sec,
    .colon {
      display: flex;
      align-items: baseline;

      &.day,
      &.hour,
      &.min,
      &.sec {
        width: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media ${deviceQuery.tabletM} {
          width: 70px;
        }

        @media ${deviceQuery.mobileXS} {
          width: 55px;
        }
      }

      &.colon {
        width: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 100;
        margin: 0 10px;

        @media ${deviceQuery.tabletM} {
          margin: 0 6px;
        }
      }

      ${(props) =>
        props.$vertical &&
        css`
          flex-direction: column;
        `}
      @media ${deviceQuery.mobileM} {
        font-size: 24px;
      }

      .value {
        color:${(props) => props.theme.palette.newTheme.blueIcon};
        font-size: 15px;
        line-height: 112.1%;
        margin-bottom: 8px;

        // @media ${deviceQuery.tabletM} {
        //   font-size: 42px;
        // }

        // @media ${deviceQuery.mobileXS} {
        //   font-size: 32px;
        // }
      }

      .unit {
        color: ${(props) => props.theme.palette.newTheme.cardGray};
        font-weight: 300;
        font-size: 14px;
        line-height: 15%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
      }
    }
  }
`;
