import React from 'react';
import PropTypes from 'prop-types';

const ShareIcon = ({ fillColor = '#080b35', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.302"
      height="26.303"
      viewBox="0 0 26.302 26.303"
      {...props}
    >
      <path
        data-name="SHARE ICON"
        d="M614.764 568.537a3.505 3.505 0 0 0-3.357-.929l-18.153 4.538a4.957 4.957 0 0 0 .066 9.634l6.639 1.563 1.563 6.639a4.891 4.891 0 0 0 4.79 3.821h.033a4.888 4.888 0 0 0 4.809-3.754l4.538-18.153a3.5 3.5 0 0 0-.928-3.359zm-.412 3.022-4.538 18.153a3.485 3.485 0 0 1-3.466 2.706h-.025a3.486 3.486 0 0 1-3.453-2.755l-1.573-6.681 6-6a.692.692 0 1 0-.979-.979l-6 6-6.681-1.573a3.572 3.572 0 0 1-.048-6.942l18.153-4.538a2.171 2.171 0 0 1 .529-.065 2.153 2.153 0 0 1 2.081 2.673z"
        transform="translate(-589.5 -567.5)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};

ShareIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default ShareIcon;
