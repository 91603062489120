import React from 'react';
import PropTypes from 'prop-types';

import { Form as FormikForm } from 'formik';
import TextInput from '../TextInput/TextInput';
import Textarea from '../Textarea/Textarea';
import { Form } from 'react-bootstrap';
import Button from '../Button/Button';
// import ProfileImage from '../Profile/ProfileImage';
// import { apiHandler } from '../../services/axios';
// import { UploadAsset } from '../../services/assetServices';
// import { getSupportedFileString } from '../../common/helpers/helper';
// import FormErrorMsg from '../FormErrorMsg/FormErrorMsg';

const ApproveCreateForm = ({ isSubmiting ,status}) => {
  return (
    <FormikForm className="form">
      <Form.Group className="form-group">
        <TextInput
          name="auctionId"
          id="auctionId"
          type="text"
          outline="true"
          color="white"
          readOnly
          label="auctionId"
        />
      </Form.Group>
      <Form.Group className="form-group">
        <TextInput
          name="status"
          id="status"
          type="text"
          outline="true"
          color="white"
          readOnly
          label="status"
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Textarea
          name="reason"
          id="reason"
          label={status===true ?  "Reason(Optional) ":"Reason (Required)"}
          outline="true"
          color="white"
        />
      </Form.Group>

      <Button
        type="submit"
        disabled={isSubmiting }
        className="mt-5 mx-auto"
      >
        Submit
      </Button>
    </FormikForm>
  );
};

ApproveCreateForm.propTypes = {
  isSubmiting: PropTypes.bool,
};

export default ApproveCreateForm;
