import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import { createContractInstance, getChainId } from "../../common/common";
import config from "../../configuration/config";
import {
  UserProfileContainer,
  CardLayout,
  CardText,
  CardTitle,
  CardStyled,
} from "./style";
import { createAndSwitchProvider } from "../../common/helpers/providerHelper";
import {
  updateProvider,
  toggleWalletModal,
  setChainId,
  setError,
} from "../../store/actions/globalActions";
import { WALLET_TYPE } from "../../constants/appConstants";
import {
  getSignInMsg,
  setLoginLoading,
} from "../../store/actions/loginActions";
import {
  SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE,
  WALLET_MESSAGE,
} from "../../constants/messages";
import MainTopSection from "../../Components/MainTopSection";
import LoadingModal from "../../Components/LoadingModal/LoadingModal";
import { isMobile } from "react-device-detect";

const LoginWalletPage = () => {
  // store redux

  const [wallets] = useState([
    {
      walletType: WALLET_TYPE.metamask,
    },
  ]);

  const [modalLoading, setModalLoading] = useState(wallets.map(() => false));
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const userState = useSelector((store) => store.user);
  const globalState = useSelector((store) => store.global);
  const loginState = useSelector((state) => state.login);
  // default methods
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { web3Instance, hasMetamask, account, error, walletModal } =
    globalState;
  // global values
  const { isLogin, requestStatus, loading } = loginState;

  const toggleWalletList = useCallback(
    () => dispatch(toggleWalletModal()),
    [dispatch]
  );
  // use status values

  useEffect(() => {
    if (!isLogin) {
      navigate(`/login`);
    } else {
      navigate(`/`);
    }
  }, [isLogin]);

  const onWalletClickHandler = async (walletType, i) => {
    createAndSwitchProvider({
      walletType,
      web3Instance,
      dispatch,
      updateProvider: updateProvider,
    });
    if (walletType === WALLET_TYPE.metamask) {
      if (hasMetamask) {
        const curChainId = await getChainId(web3Instance);
        dispatch(setChainId(curChainId));
        dispatch(setLoginLoading(true));
        if (curChainId === defaultConfig?.chainInfo.chainId) {
          dispatch(getSignInMsg(walletType));
        } else {
          try {
            await web3Instance.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(defaultConfig?.chainInfo?.chainId),
                },
              ],
            });
            dispatch(getSignInMsg(walletType));
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await web3Instance.currentProvider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: Web3.utils.toHex(
                        defaultConfig?.chainInfo?.chainId
                      ),
                      nativeCurrency: {
                        name: defaultConfig?.chainInfo?.name,
                        decimals: 18,
                        symbol: defaultConfig?.chainInfo?.network_currency,
                      },
                      chainName: defaultConfig?.chainInfo?.name,
                      rpcUrls: [defaultConfig?.chainInfo?.rpc_url],
                    },
                  ],
                });
              } catch (addError) {
                dispatch(setLoginLoading(false));
                console.error("Error", addError);
              }
            }
            dispatch(setLoginLoading(false));
          }
        }
      } else {
        dispatch(
          setError(
            { code: 0 },
            {
              errorTitle: "Install Metamask",
              errorMsg: WALLET_MESSAGE.noWallet.message,
              errorBtnLabel: "Go to MetaMask's website",
            }
          )
        );
      }
    }
  };

  return (
    <>
      <MainTopSection
        backButton={true}
        headingText="Connect Your Wallet"
        descriptionText="By connecting your wallet, you agree to our Terms of Service and our
        Privacy Policy."
        text={`And also Select ${defaultConfig?.chainInfo?.name} network from metamask`}
        routePath="/"
      />
      <UserProfileContainer>
        <CardLayout>
          <CardStyled
            border="primary"
            onClick={() => onWalletClickHandler(WALLET_TYPE.metamask, 0)}
            onKeyPress={() => onWalletClickHandler(WALLET_TYPE.metamask, 0)}
          >
            <Card.Body>
              <img
                className="asset-img-collection"
                src="./metaMask.png"
                alt="Card cap"
              />
              <CardTitle>Meta Mask</CardTitle>
              <CardText>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </CardText>
            </Card.Body>
          </CardStyled>
          {modalLoading[0] && (
            <Spinner animation="border" height={30} width={30} />
          )}
        </CardLayout>
        {loading && !error && (
          <LoadingModal
            show={loading && !error}
            onHide={() => dispatch(setLoginLoading(false))}
            title={SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE?.title?.message}
            body={SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE?.body?.message}
          />
        )}
      </UserProfileContainer>
      ;
    </>
  );
};

export default LoginWalletPage;
