import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useLocation, Routes, Route } from 'react-router-dom';

import MintingStepOne from '../../assets/icons/mintingStepOne';
import MintingStepTwo from '../../assets/icons/mintingStepTwo';

import ItemListingStepOne from './ItemListingStepOne';
import ItemListingStepTwo from './ItemListingStepTwo';
import ItemListingStepThree from './ItemListingStepThree';
import { VerticalLine, RouteStyled } from './style';


function ItemListingRoute() {
  const [step, setSteps] = useState(1);
  const [isMinting, setIsMinted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collectionDetail, setCollectionDetails] = useState();
  const storecollectionId = useSelector((store) => store.assetsCollectionId);
  const { pathname } = useLocation();
  const incrementStep = () => {
    setSteps((prev) => prev + 1);
  };

  useEffect(() => {
    if (pathname.includes('/listing')) {
      setSteps(3);
    } else if (pathname.includes('/listingStepfinal')) {
      setSteps(2);
    } else {
      setSteps(1);
    }
  }, [pathname]);

  return (
    <RouteStyled>
      <Row>
        <Col md={2} sm={12} xs={12} className="MobileView">
          <div className="Horizantalline">
            <MintingStepOne />
            <VerticalLine />
          </div>

          <div className="Horizantalline">
            {step < 2 ? (
              <MintingStepTwo />
            ) : step === 2 ? (
              <MintingStepOne />
            ) : (
              <MintingStepOne />
            )}

            <VerticalLine />
          </div>
          <div>
            {step < 3 ? (
              <MintingStepTwo />
            ) : step === 3 ? (
              <MintingStepOne />
            ) : (
              <MintingStepOne />
            )}
          </div>
        </Col>
        <Col md={10} sm={10} xs={10}>
          <Routes>
            <Route
              path="/:assetId"
              element={
                <ItemListingStepOne
                isMinting={isMinting}
                stateChange={setLoading}
                  setIsMinted={setIsMinted}
                />
              }
            />
            <Route
              path="/:assetId/listing"
              element={
                <ItemListingStepTwo
                  isMinting={isMinting}
                  loadingstate={loading}
                  stateChange={setSteps}
                  collectionStateId={storecollectionId.id}
                  setIsMinted={setIsMinted}
                />
              }
            />
            <Route
              path="/:assetId/listingStepfinal"
              element={
                <ItemListingStepThree
                  isMinting={isMinting}

                  collectionStateId={
                    storecollectionId.id
                      ? storecollectionId.id
                      : collectionDetail?._id
                  }
                />
              }
            />
          </Routes>
        </Col>
      </Row>
    </RouteStyled>
  );
}

export default ItemListingRoute;
