import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  SubHeading,
  FormCheckStyled,
} from "../../Components/UserProfile/style";

import {
  CreateCollectionDiv,
  HeadingEth,
  StyleInput,
  ConnectButton,
  HeadingText,
  Span,
  WalletDiv,
  CardGrid,
} from "./style";
import { Row, Form, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { apiHandler } from "../../services/axios";
import { createAndSwitchProvider } from "../../common/helpers/providerHelper";
import { getChainId } from "../../common/common";

import {
  convertEthToNumber,
  embedMessageString,
} from "../../common/helpers/helper";
import { getAuction } from "../../services/auctionServices";
import { getUser } from "../../services/userServices";

import {
  WALLET_MESSAGE,
  SIGNATURE_REQUEST_LOADING_MODAL_MESSAGE,
} from "../../constants/messages";
import Button from "../../Components/Button/Button";
import { Formik, Form as FormikForm } from "formik";
import TextInput from "../../Components/TextInput/TextInput";
import Checkbox from "../../Components/Checkbox/Checkbox";
import CollectionAuctionCard from "../../Components/CollectionAuctionCard/CollectionAuctionCard";
import { getAllAuctionBids } from "../../services/biddingServices";
import { AUCTION_STATUS, CONTRACT_NAME } from "../../constants/appConstants";
import { WALLET_TYPE } from "../../constants/appConstants";
import {
  getSignInMsg,
  setLoginLoading,
} from "../../store/actions/loginActions";
import {
  toggleWalletModal,
  updateProvider,
  setChainId,
  setError,
} from "../../store/actions/globalActions";
import LoadingModal from "../../Components/LoadingModal/LoadingModal";
import WalletModal from "../../Components/Header/components/WalletModal";
import { convertToEthAmount } from "../../common/helpers/helper";
import { createContractInstance } from "../../common/common";
import * as Yup from "yup";
import Web3 from "web3";
import { setContract } from "../../store/actions/contractActions";

const validationScemaActive = Yup.object().shape({
  bidcheckbox: Yup.boolean().required("Please click on checkbox"),
  amountEth: Yup.mixed().test("isRequred", "Enter valid amount", (value) => {
    if (Number(value)) {
      if (Number(value) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }),
});

function PlaceBidStepOne() {
  const [isSubmiting, setIsSubmiting] = useState({ editUser: false });
  const navigate = useNavigate();
  const [checkBox, setCheckbox] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const [collectionDetail, setCollectionDetails] = useState();
  const [auctionDetails, setAuctionDetails] = useState(null);
  const [assetData, setAssetsData] = useState();
  const [assetDetails, setAssetsDetails] = useState();
  const [ownerdata, setOwnerData] = useState();
  const [bidsData, setBidsData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minimumBid, setMinimumBid] = useState(0);

  const [wallets] = useState([
    {
      walletType: WALLET_TYPE.metamask,
    },
  ]);
  const [modalLoading, setModalLoading] = useState(wallets.map(() => false));
  const loginState = useSelector((state) => state.login);
  const UserState = useSelector((state) => state.user);
  const { isLogin, requestStatus, loading } = loginState;
  const { auctionId } = useParams();
  const globalState = useSelector((state) => state.global);
  const { web3Instance, hasMetamask, error, walletModal } = globalState;
  const { eth } = globalState?.balance;

  const dispatch = useDispatch();

  const oncheckboxClickHandler = () => {
    if (checkBox === false) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  };

  const onSubmitHandler = async (value) => {
    setIsSubmitting(true);

    try {
      if (auctionDetails?.status === AUCTION_STATUS.close) {
        navigate(`/placeBid/${auctionId}/loading?finalise=true`);
      } else {
        const amountEth = convertToEthAmount(value.amountEth);

        navigate(`/placeBid/${auctionId}/loading?amountEth=${amountEth}`);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (auctionId && web3Instance) {
      apiHandler(() => getAuction(auctionId), {
        onSuccess: async (data) => {
          setAuctionDetails(data);
          const auctionContractInstance = await createContractInstance(
            web3Instance,
            defaultConfig?.auctionABI,
            defaultConfig?.auctionContractAddress
          );
          dispatch(setContract(CONTRACT_NAME.auction, auctionContractInstance));
          const minimumBidData = await auctionContractInstance.methods
            .getReserveAuction(data?.auctionId)
            .call();
          setMinimumBid(convertEthToNumber(minimumBidData.amount, 18));
        },
      });
    }
  }, [auctionId, web3Instance, defaultConfig]);

  useEffect(() => {
    if (auctionId) {
      apiHandler(() => getAuction(auctionId), {
        onSuccess: (data) => {
          setAssetsDetails(data.assets[0]);
          setAssetsData(data.assets);
          setCollectionDetails(data);
        },
      });
    }
  }, [auctionId]);

  useEffect(() => {
    if (collectionDetail) {
      apiHandler(() => getUser(collectionDetail?.owner_id?._id), {
        onSuccess: (data) => {
          setOwnerData(data);
        },
        onError: (error) => console.error(error),
        final: setIsSubmiting((prev) => ({ ...prev, editUser: false })),
      });
    }
  }, [collectionDetail]);

  useEffect(() => {
    if (auctionDetails?.status !== AUCTION_STATUS.open) return;

    const intervalId = setInterval(() => {
      apiHandler(() => getAllAuctionBids(auctionId), {
        onSuccess: (data) => {},
      });
    }, 5 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [auctionId, auctionDetails]);

  const buttonLabel = useCallback(
    (formik) => {
      const value = Number(formik?.values?.amountEth);

      if (auctionDetails) {
        if (
          auctionDetails?.status === AUCTION_STATUS.created ||
          auctionDetails?.status === AUCTION_STATUS.open
        ) {
          return Number(minimumBid) > Number(eth) || value > Number(eth)
            ? `You don’t have enough ${
                defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"
              }`
            : (
                auctionDetails?.status === AUCTION_STATUS.open
                  ? value <= Number(minimumBid)
                  : value < Number(minimumBid)
              )
            ? `Amount Should Be Greater than ${
                auctionDetails?.highestBid ? "current Bid" : "reserved Bid"
              }`
            : "Bid";
        } else if (auctionDetails?.status === AUCTION_STATUS.close) {
          return "Finalise";
        }
      }
      return "";
    },
    [eth, auctionDetails, minimumBid]
  );

  const status = auctionDetails?.status;

  const validationSchema = useMemo(() => {
    if (status === AUCTION_STATUS.close) {
    } else {
      return validationScemaActive;
    }
  }, [status]);

  return (
    <CreateCollectionDiv>
      <Row>
        <Col md={6}>
          <CardGrid>
            {auctionDetails && (
              <CollectionAuctionCard
                status={auctionDetails?.status}
                creator_id={auctionDetails?.creator_id?._id}
                collectionImage={
                  auctionDetails?.asset_id?.metadata_fields?.image_url
                }
                followCount={auctionDetails?.asset_id?.followersCount}
                auctionPage={true}
                reservePrice={auctionDetails?.reservePrice}
                contractAuctionId={auctionDetails?.auctionId}
                time={auctionDetails?.endTime}
                title={auctionDetails?.asset_id?.name}
                highestBid={auctionDetails?.highestBid}
              />
            )}
          </CardGrid>
        </Col>
        <Col md={6}>
          {!isLogin ? (
            <WalletDiv>
              <HeadingText>Connect your wallet to continue.</HeadingText>
              <ConnectButton onClick={() => navigate(`/login`)}>
                Connect Wallet
              </ConnectButton>
            </WalletDiv>
          ) : (
            <div className=" mb-5">
              <Formik
                initialValues={{
                  amountEth: "",
                  bidcheckbox: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmitHandler}
              >
                {(formik) => (
                  <FormikForm>
                    {auctionDetails?.status === AUCTION_STATUS.close ? (
                      <div className="mt-5 pt-5">
                        <HeadingText>Finalise The Bid</HeadingText>
                        <SubHeading>
                          You must click on finalise button&nbsp;
                          <br />
                          {minimumBid}
                          {defaultConfig?.chainInfo?.name === "wethio"
                            ? "ZYN"
                            : "ETH"}
                        </SubHeading>

                        <Button
                          color="secondary"
                          className="w-100 mt-5"
                          type="submit"
                          style={{ color: "#fff" }}
                          loading={isSubmitting}
                        >
                          {buttonLabel(formik) || (
                            <div className="w-100 d-flex justify-content-center p-1">
                              <Spinner animation="border" />
                            </div>
                          )}
                        </Button>
                      </div>
                    ) : (
                      <div style={{ marginTop: "60px" }}>
                        <HeadingText>Place a bid</HeadingText>
                        <SubHeading>
                          You must bid at least:&nbsp;
                          {minimumBid}&nbsp;
                          {defaultConfig?.chainInfo?.name === "wethio"
                            ? "ZYN"
                            : "ETH"}
                        </SubHeading>
                        <Col md={8} sm={8}>
                          <Form.Group className="my-3">
                            <div className="d-flex w-100 justify-content-between border p-2">
                              <Span>Your Balance</Span>
                              <Span>{eth}</Span>
                            </div>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <StyleInput>
                              <TextInput
                                name="amountEth"
                                id="amountEth"
                                type="text"
                                placeholder="0.00"
                                outline="true"
                                color="secondary"
                              />
                              <HeadingEth>
                                {defaultConfig?.chainInfo?.name === "wethio"
                                  ? "ZYN"
                                  : "ETH"}
                              </HeadingEth>
                            </StyleInput>
                          </Form.Group>
                        </Col>

                        <Col md={8} sm={8}>
                          <SubHeading>
                            Once a bid is placed, it cannot be withdrawn.
                          </SubHeading>
                          <FormCheckStyled style={{ height: "auto" }}>
                            <Checkbox
                              className="checkBox"
                              id="bidcheckbox"
                              onClick={oncheckboxClickHandler}
                              label="By checking this box, I agree to Wethio NFT Terms of Service"
                              name="bidcheckbox"
                            />
                          </FormCheckStyled>

                          {auctionDetails?.highestBid?.bidder_id !==
                          UserState?.userData?.id ? (
                            <Button
                              color="secondary"
                              className="w-100"
                              type="submit"
                              disabled={
                                !formik.values.bidcheckbox ||
                                !["Finalise", "Bid"].includes(
                                  buttonLabel(formik)
                                )
                              }
                              style={{ color: "#fff" }}
                              loading={isSubmitting}
                            >
                              {buttonLabel(formik) || (
                                <div className="w-100 d-flex justify-content-center p-1">
                                  <Spinner animation="border" />
                                </div>
                              )}
                            </Button>
                          ) : (
                            <>
                              <SubHeading
                                style={{ color: "red", fontSize: "14px" }}
                              >
                                You have already Placed highest bid.
                              </SubHeading>
                              <Button
                                color="secondary"
                                className="w-100 mt-3"
                                type="submit"
                                disabled={true}
                                style={{ color: "#fff" }}
                                loading={isSubmitting}
                              >
                                Bid
                              </Button>
                            </>
                          )}
                        </Col>
                      </div>
                    )}
                  </FormikForm>
                )}
              </Formik>
            </div>
          )}
        </Col>
      </Row>
    </CreateCollectionDiv>
  );
}

PlaceBidStepOne.propTypes = {
  isMinting: PropTypes.bool,
  assetdata: PropTypes.object,
  metadataUrl: PropTypes.string,
  toggleIsMinting: PropTypes.func,
  setAssetData: PropTypes.func,
};

export default PlaceBidStepOne;
