import React, { useState, useEffect, useCallback } from "react";
import {
  CreateCollectionStyled,
  Heading,
  Text,
  HeadingText,
} from "../ApproveAssets/style";
import { getAllAuctions } from "../../services/auctionServices";
import { CardGrid, LoadMoreButton } from "./style";
import { Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { apiHandler } from "../../services/axios";
import CollectionAuctionCard from "../../Components/CollectionAuctionCard/CollectionAuctionCard";
import { ROUTES } from "../../constants/routes";
import MainTopSection from "../../Components/MainTopSection";
import InfiniteScrollWrapper from "../../Components/InfiniteScrollWrapper";

const FullWidthSpinner = () => (
  <div className="d-flex justify-content-center">
    <Spinner variant="light" animation="border" />
  </div>
);
const initialLimit = 5;
const initialPageNumber = 1;

function MarketPlacePage() {
  const [allAuctionsData, setAllAuctionsData] = useState({
    loading: true,
    data: [],
  });

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(initialLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);

  const [commonLoadMore, setCommonLoadMore] = useState(4);
  const navigate = useNavigate();

  const onSuccessHanadler = useCallback(
    (data, limit, pageNumber) => {
      const { offset: offsetData, total } = data.pagination;

      setAllAuctionsData((prevState) => {
        if (pageNumber === 1)
          return { ...prevState, data: data.results, loading: false };
        else
          return {
            ...prevState,
            data: [...prevState.data, ...data.results],
            loading: false,
          };
      });
      setPageNumber((prevState) => {
        return prevState + 1;
      });

      setHasMore(offsetData + limit < total);
    },
    [setPageNumber, setHasMore, setAllAuctionsData]
  );

  const navigateToAuctionDetailsPage = (auctionId) => {
    navigate(`${ROUTES.auctionDetails.replace(":auctionId", auctionId)}`);
  };

  const next = useCallback(() => {
    const api = getAllAuctions;
    setApiLoading(true);
    apiHandler(() => api({ pageNumber, limit }), {
      onSuccess: (data) => onSuccessHanadler(data, limit, pageNumber),
      final: () => setApiLoading(false),
    });
  }, [limit, pageNumber, onSuccessHanadler]);

  const getCollectionDetails = useCallback(
    (pageNumber = initialPageNumber, limit = initialLimit) => {
      setApiLoading(true);
      setAllAuctionsData({ ...allAuctionsData, loading: true });

      apiHandler(() => getAllAuctions({ pageNumber, limit }), {
        onSuccess: (data) => {
          onSuccessHanadler(data, limit, pageNumber);
        },
        onError: (error) => {
          console.error(error);
          setAllAuctionsData({ ...allAuctionsData, loading: false });
        },
        final: () => setApiLoading(false),
      });
    },
    [onSuccessHanadler]
  );
  useEffect(() => {
    getCollectionDetails();
  }, [onSuccessHanadler]);

  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="Explore Marketplace"
        descriptionText={""}
        routePath="/"
      />

      <CreateCollectionStyled style={{ marginTop: "3rem" }}>
        {allAuctionsData.loading ? (
          <FullWidthSpinner />
        ) : (
          <Row
            className="infinite mb-5"
            as={InfiniteScrollWrapper}
            loading={apiLoading}
            dataLength={allAuctionsData.data.length}
            loader={<FullWidthSpinner />}
            next={next}
            hasMore={hasMore}
            endMessage={<p></p>}
          >
            {allAuctionsData.data?.length > 0 ? (
              allAuctionsData.data?.map((item, i) => {
                return (
                  <CollectionAuctionCard
                    key={i + item._id}
                    creator_id={item?.creator_id?._id}
                    collectionImage={item?.asset_id?.metadata_fields?.image_url}
                    status={item?.status}
                    reservePrice={item?.reservePrice}
                    contractAuctionId={item?.auctionId}
                    time={item?.endTime}
                    followCount={item?.asset_id?.followersCount}
                    title={item?.asset_id?.name}
                    onClick={() => navigateToAuctionDetailsPage(item._id)}
                    highestBid={item?.highestBid}
                    approvalStatus={item?.approvalStatus}
                  />
                );
              })
            ) : (
              <div className="text-center mt-3 mb-3">
                <Text>
                  <HeadingText>No items found</HeadingText>
                </Text>
              </div>
            )}
          </Row>
        )}
      </CreateCollectionStyled>
    </>
  );
}

export default MarketPlacePage;
