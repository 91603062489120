import React from "react";

// const style={fill:'#b5b6c7'}

const wethioftLogo = ({ fillColor = "#41416e" }) => {
  return (
    <svg
      width="200"
      height="38"
      viewBox="0 0 200 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152 16.9996V20.9997H148V24.9999H152V29H156V9H152V16.9996Z"
        fill="#ffb11f"
      />
      <path
        d="M160 29H163.666V20.9997H167.333V16.9996H163.666V12.9995H171V9H160V29Z"
        fill="#ffb11f"
      />
      <path
        d="M174 12.9995H178V29H182V12.9995H186V9H174V12.9995Z"
        fill="#ffb11f"
      />
      <path
        d="M144 16.9996V12.9995H140V9H136V29H140V16.9996H144V20.9997H148V16.9996H144Z"
        fill="#ffb11f"
      />
      <path
        d="M27.6231 6L23.1954 21.276L18.9076 6H14.093L9.80527 21.3107L5.34211 6H0L6.74793 29H12.3357L16.5177 14.376L20.6649 29H26.2527L33 6H27.6231Z"
        fill="#ffb11f"
      />
      <path
        d="M41.0697 11C35.7325 11 32 14.9548 32 20C32 24.7731 35.1743 29 41.0351 29C45.1517 29 48.6051 26.8862 49.756 23.1022H44.8374C44.2095 24.1932 42.6399 24.9088 41.0351 24.8406C38.6283 24.738 37.0934 23.2723 36.8143 21.5339H49.9308C50.5587 15.5341 46.8608 11 41.0697 11ZM36.8488 17.955C37.3372 16.2504 38.9766 15.1256 41.0351 15.1256C43.0585 15.1256 44.6281 16.1822 45.1517 17.955H36.8488Z"
        fill="#ffb11f"
      />
      <path
        d="M57.9997 6H53.3902V11.9045H51V16.3672H53.3902V23.9881C53.3902 27.0776 55.4046 29 58.5122 29H65V24.5033H59.3662C58.5467 24.5033 58.0003 24.0227 58.0003 23.1988V16.3665H65V11.9039H57.9997V6Z"
        fill="#ffb11f"
      />
      <path
        d="M78.06 11.2226C75.8867 11.2226 74.0292 12.1949 72.8721 13.7922H72.7319V4H68V29H72.7319V20.0418C72.7319 17.4379 74.5547 15.8055 76.6926 15.8055C78.6556 15.8055 80.2681 17.1945 80.2681 19.6946V29H85V19.0001C85.0006 14.0349 82.3018 11.2226 78.06 11.2226Z"
        fill="#ffb11f"
      />
      <path d="M93 12H89V29H93V12Z" fill="#ffb11f" />
      <path d="M93 4H89V9H93V4Z" fill="#ffb11f" />
      <path
        d="M105.5 11C99.7219 11 96 14.9548 96 20C96 25.0796 99.7577 29 105.5 29C111.242 29 115 25.0796 115 20C115 14.9548 111.278 11 105.5 11ZM105.5 24.5347C102.664 24.5347 100.785 22.5235 100.785 20.0006C100.785 17.4777 102.664 15.4321 105.5 15.4321C108.336 15.4321 110.215 17.4777 110.215 20.0006C110.215 22.5235 108.3 24.5347 105.5 24.5347Z"
        fill="#ffb11f"
      />
      <path d="M130.201 4H192V0H130.201V4H126V8H130.201V4Z" fill="#ffb11f" />
      <path d="M196 4H192V8H196V4Z" fill="#ffb11f" />
      <path
        d="M191.799 34H130V38H191.799V34H196V30H191.799V34Z"
        fill="#ffb11f"
      />
      <path d="M130 30H126V34H130V30Z" fill="#ffb11f" />
      <path d="M126 8H122V30H126V8Z" fill="#ffb11f" />
      <path d="M200 9H196V31H200V9Z" fill="#ffb11f" />
    </svg>
  );
};

export default wethioftLogo;
