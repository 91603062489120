import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { apiHandler } from "../../services/axios";
import {
  getOneAsset,
  updateAssetWithTxData,
} from "../../services/assetServices";
import { useWarnForUnsavedChanges } from "../../hooks/useWarnForUnsavedChanges";
import {
  useLocation,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";

import {
  CreateCollectionDiv,
  Heading,
  CardProfile,
  SubText,
  CardTitle,
  CustomImage,
} from "./style";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import EtherScanLink from "../../Components/EtherScanLink/EtherScanLink";
import AssetComponent from "../../Components/AssetComponent";
import Avatar from "../../Components/Avatar/Avatar";
import store from "../../store/store";


function CollectionStepTwo({ isMinting, toggleIsMinting, metadataUrl }) {
  const [localError, setLocalError] = useState(null);
  const [isMinted, setIsMinted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assetData, setAssetData] = useState(null);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const contractState = useSelector((store) => store.contractState);
  const [finishStatus, setfinishStatus] = useState(false);
  const userState = useSelector((store) => store.user);
  const globalState = useSelector((store) => store.global);
  const { account } = globalState;
  const {
    contract: { minting: mintingContract },
  } = contractState;
  const { userData } = userState;

  useWarnForUnsavedChanges({
    check: loading,
    msg: "Asset Minting Underway",
  });

  const { state } = useLocation();
  const { assetId } = useParams();
  const navigate = useNavigate();

  const getTokenUriPath = (uri) => {

    return uri.replace(defaultConfig?.ipfsBaseUrl, "");
  };

  const mintAndUpdateAsset = useCallback(
    async (assetId) => {
      setLoading(true);
      setIsMinted(true);

      let estimatedGas;
      let reciept;
      try {
        if (state.method === "mint_1") {
          estimatedGas = await mintingContract?.methods
            ?.mintWithoutApproveMarket(
              getTokenUriPath(assetData?.metadata_url || metadataUrl)
            )
            .estimateGas({
              from: account,
              gas: defaultConfig?.maxGasLimit,
            });
          reciept = await mintingContract?.methods
            ?.mintWithoutApproveMarket(
              getTokenUriPath(assetData?.metadata_url || metadataUrl)
            )
            .send({
              from: account,
              gas: estimatedGas,
            });
        } else if (state.method === "mint_2") {
          estimatedGas = await mintingContract?.methods
            ?.mintAndApproveMarket(
              getTokenUriPath(assetData?.metadata_url || metadataUrl)
            )
            .estimateGas({
              from: account,
              gas: defaultConfig?.maxGasLimit,
            });
          reciept = await mintingContract?.methods
            ?.mintAndApproveMarket(
              getTokenUriPath(assetData?.metadata_url || metadataUrl)
            )
            .send({
              from: account,
              gas: estimatedGas,
            });
        } else if (state.method === "mint_3") {
          estimatedGas = await mintingContract?.methods
            ?.mintWithCreatorPaymentAddress(
              getTokenUriPath(assetData?.metadata_url || metadataUrl),
              assetData?.payment_address
            )
            .estimateGas({
              from: account,
              gas: defaultConfig?.maxGasLimit,
            });
          reciept = await mintingContract?.methods
            ?.mintWithCreatorPaymentAddress(
              getTokenUriPath(assetData?.metadata_url || metadataUrl),
              assetData?.payment_address
            )
            .send({
              from: account,
              gas: estimatedGas,
            });
        } else {
          estimatedGas = await mintingContract?.methods
            ?.mintWithCreatorPaymentAddressAndApproveMarket(
              getTokenUriPath(assetData?.metadata_url || metadataUrl),
              assetData?.payment_address
            )
            .estimateGas({
              from: account,
              gas: defaultConfig?.maxGasLimit,
            });
          reciept = await mintingContract?.methods
            ?.mintWithCreatorPaymentAddressAndApproveMarket(
              getTokenUriPath(assetData?.metadata_url || metadataUrl),
              assetData?.payment_address
            )
            .send({
              from: account,
              gas: estimatedGas,
            });
        }
        const updatedAssetData = await apiHandler(() =>
          updateAssetWithTxData(assetData?._id, reciept)
        );
        setIsMinted(true);

        setAssetData(updatedAssetData?.data);
        navigate(`/collectionSteps/collectionStepfinal/${assetData?._id}`);
      } catch (error) {
        const reqDatahash = {};

        const updatedAssetData = await apiHandler(() =>
          updateAssetWithTxData(assetData?._id, reqDatahash)
        );
        navigate(`/collectionSteps?type=image`, {
          state: {
            assetType: "image",
          },
        });

        if (error.code === 4001) {
          setLocalError(true);
        }
      } finally {
        setIsMinted(false);
      }
    },
    [
      account,
      assetData,
      mintingContract,
      toggleIsMinting,
      metadataUrl,
      setAssetData,
    ]
  );

  const onMintClickHandler = () => {
    if (assetData?.mint_tx_hash) {
      navigate("/createCollection");
    } else {
      mintAndUpdateAsset();
    }
  };



  useEffect(() => {
    if (isMinting && assetData && !assetData?.mint_tx_hash) {
      mintAndUpdateAsset(assetData._id);
    }
  }, [isMinting, mintAndUpdateAsset, assetData]);

  useEffect(() => {
    if (!assetData) {
      apiHandler(() => getOneAsset(assetId), {
        onSuccess: (data) => {
          setAssetData(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [assetData, assetId]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
        setfinishStatus(true);
        // your logic
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  if (state?.from === "assetCreate") {
    return (
      <CreateCollectionDiv>
        <Row>
          <Col md={5}>
            <CardProfile>
              {assetData ? (
        
                <AssetComponent
                  assetType="image"
                  className="createNftImage"
                  freeSize
                  src={assetData?.metadata_fields?.image_url}
                />
             
              ) : (
                <Spinner animation="border" />
              )}
              <Card.Body>
                <CardTitle>{assetData?.name}</CardTitle>
                <div className="d-flex pt-3 pb-3">
                  <Avatar
                    $imgURL={userData?.profile_image_url}
                    $height="48px"
                    $width="60px"
                    $size="small"
                  />
                  <SubText className="mb-0 pl-3">
                    {userData?.username ? userData?.username : "@anonymous"}
                  </SubText>
                </div>
              </Card.Body>
            </CardProfile>
          </Col>
          <Col md={5}>
            <Heading>Minting your NFT</Heading>
            <SubText>Confirm with your wallet to continue</SubText>
            <SubText>
              Doing this will sign your wallet as the original owner of the NFT
            </SubText>
            {assetData?.mint_tx_hash &&
              !defaultConfig?.chainInfo?.name === "wethio" && (
                <EtherScanLink
                  mintTxHash={assetData?.mint_tx_hash}
                  className="mt-4 etherscan-link"
                  borderLess
                />
              )}
            {isMinted ? (
              <div className="spinner-wrapper d-flex justify-content-center mt-5">
                <Spinner
                  animation="border"
                  style={{ width: "60px", height: "60px", color: "#080b35" }}
                />
              </div>
            ) : (
              <div className="mt-3 d-flex">
                <Button
                  color="secondary"
                  className="action-btn m-3"
                  onClick={onMintClickHandler}
                >
                  {"Mint"}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </CreateCollectionDiv>
    );
  } else {
    return <Navigate to="/" />;
  }
}

CollectionStepTwo.propTypes = {
  isMinting: PropTypes.bool,
  assetdata: PropTypes.object,
  metadataUrl: PropTypes.string,
  toggleIsMinting: PropTypes.func,
  setAssetData: PropTypes.func,
};

export default CollectionStepTwo;
