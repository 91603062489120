import React, { useState, useEffect } from "react";
import {
  CreateCollectionDiv,
  Heading,
  SubText,
  ListButton,
  Icondiv,
} from "./style";

import { apiHandler } from "../../services/axios";
import { getAuction } from "../../services/auctionServices";
import wethioExplorer from "../../assets/icons/wethio-explorer.png";
import { useParams, useNavigate } from "react-router-dom";
import EtherScanLink from "../../Components/EtherScanLink/EtherScanLink";
import CollectionAuctionCard from "../../Components/CollectionAuctionCard/CollectionAuctionCard";
import TickIcon from "../../assets/icons/tickIcon";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function PlaceBidStepThree({ auctionDetails: _auctionDetails }) {
  const [auctionDetails, setAuctionDetails] = useState(_auctionDetails);
  const [assetData, setAssetData] = useState(null);
  const navigate = useNavigate();
  const { auctionId } = useParams();
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const GoBack = () => {
    navigate("/marketPlacePage");
  };
  useEffect(() => {
    if (auctionId && !auctionDetails) {
      apiHandler(() => getAuction(auctionId), {
        onSuccess: (data) => {
          setAuctionDetails(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [auctionId, auctionDetails]);

  return (
    <CreateCollectionDiv>
      <Row>
        <Col md={5}>
          <Row>
            {auctionDetails && (
              <CollectionAuctionCard
                status={auctionDetails?.status}
                creator_id={auctionDetails?.creator_id?._id}
                collectionImage={
                  auctionDetails?.asset_id?.metadata_fields?.image_url
                }
                auctionPage={true}
                followCount={auctionDetails?.asset_id?.followersCount}
                reservePrice={auctionDetails?.reservePrice}
                contractAuctionId={auctionDetails?.auctionId}
                time={auctionDetails?.endTime}
                title={auctionDetails?.asset_id?.name}
                highestBid={auctionDetails?.highestBid}
              />
            )}
          </Row>
        </Col>
        <Col md={5} className="text-center">
          {auctionDetails?.finalize_by ? (
            <>
              <Heading>Your have finalise the Auction successfully</Heading>
              <SubText>
                Your finalise is being confirmed on the &nbsp;
                {defaultConfig?.chainInfo?.name === "wethio"
                  ? "Wethio"
                  : "Ethereum"}
                &nbsp; blockchain.
              </SubText>
            </>
          ) : (
            <>
              <Heading>Your bid has been submitted</Heading>
              <SubText>
                Your bid is being confirmed on the &nbsp;
                {defaultConfig?.chainInfo?.name === "wethio"
                  ? "Wethio"
                  : "Ethereum"}
                &nbsp; blockchain.
              </SubText>
            </>
          )}
          

          {auctionDetails?.transactionHash &&
          defaultConfig?.chainInfo?.name === "Ethereum" ? (
            <EtherScanLink
              mintTxHash={auctionDetails?.transactionHash}
              className="mt-3 mb-3 d-inline etherscan-link"
              borderLess
              text="View on Etherscan"
            />
          ) : (
            <EtherScanLink
              mintTxHash={auctionDetails?.transactionHash}
              className="mt-3 mb-3 d-inline-block etherscan-link"
              borderLess
              icon={wethioExplorer}
              text="View on Wethio"
            />
          )}
       
          <Icondiv>
            <TickIcon />
          </Icondiv>

          <ListButton variant="primary" type="submit" onClick={GoBack}>
            Go Back
          </ListButton>
        </Col>
      </Row>
    </CreateCollectionDiv>
  );
}

export default PlaceBidStepThree;
