import styled from "styled-components";

export const ApproveButton = styled.button`
  padding: 15px 45px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 20px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const CommonButton = styled.button`
  font-size: 15px;
  padding: 10px 30px;
  margin: 8px;
  border-radius: 14px;
  align-items: center;
  background-color: ${(props) => (props.disabled ? "#73777B" : "#080b35")};
  color: #fff;
  border: none;
`;
export const StyledCard = styled.div`
  max-width: 100%;
  min-width: 300px;
  /* margin: auto; */
  margin-bottom: 5rem;
  .card {
    cursor: pointer;
    margin-top: 22px;
    border-radius: 32px;
    
  }
  .card-title {
    color: ${(props) => props.theme.palette.text.primary} !important;
  }
  border-radius: 2.4rem;

  .card {
    width: 100%;
    text-align: left;
    color: ${(props) => props.theme.palette.text.primary};
    background-color: ${(props) => props.theme.palette.text.white};

    img {
      padding: 0px 2px;
      height: 270px;
      border: none;
      border-radius: 32px;
      width: 100%;
    }

    .body {
      padding: 2rem 1.7rem;
      .card-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 112.1%;
        margin-bottom: 1.5rem;
      }
      .protagonist-wrap {
        .avatar {
          width: 4rem;
          height: 4rem;
        }
        p {
          font-size: 1.4rem;
          margin-bottom: 0.3rem;
          .protagonist-name {
            font-size: 1.6rem;
          }
        }
      }
    }

    .footer {
      color: ${(props) => props.theme.palette.text.light};
      background-color: ${(props) => props.theme.palette.secondary.light};
      padding: 2rem 1.7rem;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 162.1%;
        margin-bottom: 0.5rem;
      }

      .remaining-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 162.1%;
      }

      .value,
      .bid-amount {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const RejectedButton = styled.button`
  font-size: 15px;
  padding: 10px 30px;
  margin: 8px;
  border-radius: 14px;
  align-items: center;
  background-color: ${(props) => {
   
    return props.disabled ? "#73777B" : "#990000";
  }};
  color: #fff;
  border: none;

`;
