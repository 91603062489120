import React, { useState, useEffect, useRef } from 'react';

import defaultConfig from '../../configuration/config';
import { useSelector } from 'react-redux';

import AssetComponent from '../AssetComponent';
import { UserProfileContainer } from './style';
import { Form, Button } from 'react-bootstrap';

const ImgUpload = React.forwardRef(
  ({ onChange, src, id, accept = '', assetComponentProps = {} }, ref) => (
    <label
      htmlFor={id || 'photo-upload'}
      className="custom-file-upload fas w-100"
    >
      <div className="img-wrap img-upload">
        <AssetComponent freeSize src={src} {...assetComponentProps} />
      </div>
      <input
        id={id || 'photo-upload'}
        className="d-none"
        type="file"
        onChange={onChange}
        accept={accept}
        ref={ref}
      />
    </label>
  )
);

const Name = ({ onChange, value }) => (
  <div className="field">
    <label htmlFor="name">name:</label>
    <input
      id="name"
      type="text"
      onChange={onChange}
      maxlength="25"
      value={value}
      placeholder="Alexa"
      required
    />
  </div>
);

const Status = ({ onChange, value }) => (
  <div className="field">
    <label htmlFor="status">status:</label>
    <input
      id="status"
      type="text"
      onChange={onChange}
      maxLength="35"
      value={value}
      placeholder="It's a nice day!"
      required
    />
  </div>
);

const Profile = ({ onSubmit, src, name, status }) => (
  <div className="card border-0">
    <form onSubmit={onSubmit} className="d-flex justify-content-center">
      <h1>Profile Card</h1>
      <label className="custom-file-upload fas">
        <div className="img-wrap">
          {/* <img for="photo-upload" style={{ maxWidth: '100%' }} src={src} /> */}
          <AssetComponent freeSize src={src} />
        </div>
      </label>
      <div className="name">{name}</div>
      <div className="status">{status}</div>
      <button type="submit" className="edit">
        Edit Profile{' '}
      </button>
    </form>
  </div>
);

const Edit = ({ onSubmit, children }) => (
  <div className="card border-0" style={{ overflow: 'hidden' }}>
    <form onSubmit={onSubmit} className="d-flex justify-content-center">
      {/* <h1>Profile Card</h1> */}
      {children}
      {/* <button type="submit" className="save">Save </button> */}
    </form>
  </div>
);

function ProfileImage({
  onImageUploadSuccess,
  onImageUpload,
  accept,
  id,
  ipfs,
  onIpfsUploadSuccess,
  imageUrl,
  resetImage,
  setResetImage,
  assetComponentProps,
}) {
  const [file, setFile] = useState();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    imageUrl || './AvtarImage.png'
  );
  const [name, setName] = useState();
  const [status, setStatus] = useState();
  const [active, setActive] = useState('edit');

  const inputElementRef = useRef(null);

  const photoUpload = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const files = e?.target?.files;

    if (files && files?.length) {
      const formdata = new FormData();
      const file = files[0];
      formdata.append('file', file);
      if (onImageUpload) {
        onImageUpload(formdata);
      }

      reader.onloadend = () => {
        setFile(file);
        setImagePreviewUrl(reader.result);
        onImageUploadSuccess && onImageUploadSuccess(reader.result);
      };
      reader.readAsDataURL(file);
    }
    if (ipfs) {
      if (files && files?.length) {
        const file = files[0];
        onIpfsUploadSuccess && onIpfsUploadSuccess(file);
      }
    }
  };
  const editName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const editStatus = (e) => {
    const status = e.target.value;
    setStatus(status);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let activeP = active === 'edit' ? 'profile' : 'edit';
    setActive(activeP);
  };

  useEffect(() => {
    if (resetImage && inputElementRef.current) {
      inputElementRef.current.value = '';
      setImagePreviewUrl('./AvtarImage.png');
      setResetImage(false);
    }
  }, [resetImage, setResetImage]);

  return (
    <div>
      {active === 'edit' ? (
        <Edit onSubmit={handleSubmit}>
          <ImgUpload
           
            onChange={photoUpload}
            src={imagePreviewUrl}
            accept={accept}
            id={id}
            ref={inputElementRef}
            assetComponentProps={assetComponentProps}
          />
        </Edit>
      ) : (
        <Profile
          onSubmit={handleSubmit}
          src={imagePreviewUrl}
          name={name}
         
          status={status}
          id={id}
        />
      )}
    </div>
  );
}

export default ProfileImage;
