import styled from 'styled-components';
import { flexCenter } from '../../styles/mixins';


export const SubText= styled.p`
color: ${(props) => props.theme.palette.text.primary};
`;
export const ProtagonistDetails = styled.div`
  border: 2px solid ${(props) => props.theme.palette.common.border.light};
  background-color: ${(props) =>
    props.$imgURL ? 'transparent' : props.theme.palette.newTheme.profileGray};
  border-radius: 15rem;
  display: inline-flex;
  align-items: center;
  padding: 0.6rem;


  .avatar {
    border-radius: 24%;
    background-color: ${(props) =>
      props.$imgURL ? 'transparent' : props.theme.palette.newTheme.profileGray};
    height: 4rem;
    width: 4rem;
    margin-right: 0.8rem;
    overflow: hidden;
    ${flexCenter};

    &>div {
      width: 100%;
      height :100%;
      
      svg {
        width :100%;
        height: 100%;
      }
    }
    img{
      height: 4rem !important;
    }
  }

  .details {
    .label {
      font-weight: 400;
      line-height: 1.6rem;
      margin-bottom: 0.5rem;
    }
    .protagonist-name {
      font-weight: 600;
      line-height: 2rem;
    }
  }

  &.expanded {
    border: none;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    display: block;
    padding: 0;
    text-align: center;
    max-width: 60rem;
    margin: auto;

    .avatar {
      border-radius: 50%;
      background-color: ${(props) => props.theme.palette.primary.main};
      height: 9rem;
      width: 9rem;
      margin: 0 auto 0.8rem;
    }

    .details {
      display: flex;
      flex-direction: column-reverse;
      .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 112.1%;
        margin-bottom: 0.8rem;
      }
      .protagonist-name {
        font-weight: 600;
        font-size: 22px;
        line-height: 112.1%;
        margin-bottom: 0.8rem;
      }
    }

    .description {
      font-size: 14px;
      line-height: 24px;
      margin-top: 2rem;
    }
  }

  &.normal {
    border: none;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    text-align: left;
  }
`;
export const RoundImage = styled.div`
  h1{
    margin-left:20px;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  svg {
    /* width: 100%;
    height: 100%; */
  }
`;