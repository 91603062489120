import * as actionTypes from "../../constants/actionTypes";

const initState = {
  id: [],
  imagesUrl: [],
};

export const userAssetsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.ADD_TOKEN_ID:
      return {
        ...state,
        id: [...state.id, payload.id],
        imagesUrl: [...state.imagesUrl, payload.imagesUrl],
      };
    case actionTypes.DELETE_TOKEN_ID:
   
      return {
        id: [
          ...state.id.slice(0, payload?.id),
          ...state.id.slice(payload?.id + 1),
        ],
        imagesUrl: [
          ...state.imagesUrl.slice(0, payload?.id),
          ...state.imagesUrl.slice(payload?.id + 1),
        ],
      };

    default:
      return state;
  }
};


const initAssetsState = {
  contract_address: "",
  description: "",
  metadata_url: "",
  name: "",
  txHashData: "",
  payment_address: "",
  imageUrl:"",
};

export const userAssetsCreatedReducer = (state = initAssetsState, action) => {
  const { type, payload } = action;
  switch (type) {
    // case actionTypes.ADD_METADATA_URL:
    //   return { ...state, userData: payload.userData };
    case actionTypes.ADD_ASSETS_DETAILS:
      return {
        ...state,
        contract_address: payload.contract_address,
        description: payload.description,
        metadata_url: payload.metadata_url,
        txHashData: payload.txHashData,
        name: payload.name,
        payment_address: payload.payment_address,
        imageUrl:payload.imageUrl
      };
    case actionTypes.DELETE_ASSETS_DETAILS:
      return {
        ...state,
        contract_address: "",
        description: "",
        metadata_url: "",
        name: "",
        txHashData: "",
        payment_address: "",
        imageUrl:""
      };

    default:
      return state;
  }
};
