import React from 'react';
import { Modal as RectBootstrapModal, ModalBody } from 'react-bootstrap';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { deviceQuery } from '../../styles/mediaSizes';
import CloseIcon from '../../assets/icons/closeIcon';

const ModalStyled = styled(RectBootstrapModal)`
  a {
    &:hover {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  .modal-content {
    padding: 30px;
    color: ${(props) => props.theme.palette.text.dark};
    border-radius: 50px;

    .modal-body {
      padding: ${(props) => (props.padding ? props.padding : '15px 5px')};
      text-align: center;
      width: ${(props) => (props.width ? props.width : '100%')};
      margin: auto;

      .close-button {
        position: absolute;
        top: 0;
        right: 8px;
        width: 16px;
        text-align: center;
        cursor: pointer;
        flex-shrink: 0;
        img {
          width: 100%;
        }

        @media ${deviceQuery.mobileL} {
          top: -5px;
          right: 10px;
        }
      }
    }
  }
`;

const Modal = ({ children, showCloseButton = false, ...props }) => {
  const { onHide } = props;
  return (
    <ModalStyled {...props}>
      <ModalBody>
        {showCloseButton && (
          <span
            role="button"
            tabIndex={0}
            className="close-button"
            onKeyPress={onHide}
            onClick={onHide}
          >
            <CloseIcon className="close-icon" />
          </span>
        )}
        {children}
      </ModalBody>
    </ModalStyled>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  showCloseButton: PropTypes.bool,
};

export default Modal;
