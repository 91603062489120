import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { createContractInstance } from "../../common/common";
import { toast } from "react-toastify";
import { transferAssetService } from "../../services/assetServices";
import { userAssetsCollectionReducer } from "../../store/reducer/userReducer";
import { useState } from "react";
import MiniLoader from "../MiniLoader";
import Web3 from "web3";
import { getLocalStorageItem } from "../../common/helpers/localStorage";

const TransferAssetModal = ({
  show,
  handleClose,
  transferFrom,
  tokenId,
  assetId,
  loading,
  transferAssetContractAddress,
  setOwnedAssets,
}) => {
  const globalState = useSelector((state) => state.global);
  const { web3Instance } = globalState;
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const formik = useFormik({
    initialValues: {
      transfer_to_wallet_address: "",
    },
    validationSchema: Yup.object({
      transfer_to_wallet_address: Yup.string()
        .nullable()
        .required("Required")
        .test("check", "Address is not correct", (value) => {
          return Web3.utils.isAddress(value);
        }),
    }),
    onSubmit: async (values) => {
      // setLoading(true);
      handleClose();
      loading(true);
      // setTransferButtonLoading(true);
      try {
        const mintingContractInstance = await createContractInstance(
          web3Instance,
          defaultConfig?.mintingABI,
          transferAssetContractAddress
        );

        const currentBlockchain = localStorage.getItem("blockchain");
        const address =
          defaultConfig?.publicMintingContractAddress?.toLowerCase();
        const newAddress = transferAssetContractAddress?.toLowerCase();
        let result;
        if (currentBlockchain === "weth" && newAddress === address) {
          result = await mintingContractInstance.methods
            .safeTransferFrom(
              transferFrom,
              values.transfer_to_wallet_address,
              tokenId
            )
            .send({ from: transferFrom });
        } else {
          result = await mintingContractInstance.methods
            .safeTransferFrom(
              transferFrom,
              values.transfer_to_wallet_address,
              tokenId
            )
            .send({ from: transferFrom });
        }
        console.log(result);

        const response = await transferAssetService(assetId, result);
        setOwnedAssets(response?.data);
        toast.success("Transfer successfully");
        // setLoading(false);

        formik.setFieldValue("transfer_to_wallet_address", "");
        formik.setFieldError("transfer_to_wallet_address", null);
        formik.setFieldTouched("transfer_to_wallet_address", null);
        // setTransferButtonLoading(false);
        loading(false);
      } catch (err) {
        // setLoading(false);
        formik.setFieldValue("transfer_to_wallet_address", "");
        formik.setFieldError("transfer_to_wallet_address", null);
        formik.setFieldTouched("transfer_to_wallet_address", null);
        // setTransferButtonLoading(false);
        loading(false);

        toast.error(err?.response?.data?.message);
      }
    },
  });

  return (
    <Modal backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ fontSize: "18px", color: "black" }}>
          Transfer your asset
        </Modal.Title>
      </Modal.Header>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Modal.Body style={{ margin: "auto", padding: "20px 0" }}>
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            placeholder="Type Wallet Address"
            {...formik.getFieldProps("transfer_to_wallet_address")}
            error={
              formik.errors.transfer_to_wallet_address &&
              formik.touched.transfer_to_wallet_address
            }
            helperText={
              formik.errors.transfer_to_wallet_address &&
              formik.touched.transfer_to_wallet_address
                ? formik.errors.transfer_to_wallet_address
                : null
            }
            variant="outlined"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            // disabled={loading}
            style={{ border: "none" }}
            onClick={() => {
              formik.setFieldValue("transfer_to_wallet_address", "");
              formik.setFieldError("transfer_to_wallet_address", null);
              formik.setFieldTouched("transfer_to_wallet_address", null);
              handleClose();
            }}
          >
            {"Close"}
          </Button>
          <Button
            // disabled={loading}
            style={{ backgroundColor: "#080b35", border: "none" }}
            variant="primary"
            type="submit"
          >
            {"Transfer"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default TransferAssetModal;
