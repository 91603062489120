import { WALLET_TYPE } from '../../constants/appConstants';
import { createProvider, createWeb3Instance } from '../common';

export const initProviderAndWeb3Instance = ({
  walletType,
  dispatch,
  setWeb3Instance,
  updateProvider,
}) => {
  walletType = walletType ? walletType : WALLET_TYPE.metamask;

  const provider = createProvider(walletType);

  dispatch(updateProvider(provider));

  const web3Instance = createWeb3Instance(walletType, provider);
  dispatch(setWeb3Instance(web3Instance));

  return [web3Instance, provider];
};

export const createAndSwitchProvider = ({
  walletType,
  web3Instance,
  dispatch,
  updateProvider,
}) => {
  const provider = createProvider(walletType);

  dispatch(updateProvider(provider));

  provider && web3Instance.setProvider(provider);
  return provider;
};
