import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import {
  StyledCard,
  HeadingText,
  CardSection,
  CustomImage,
  ToolTipValues,
  ProfileUser,
  ProfileText,
  CountDownTimerStyled,
  TimingDiv,
  Footer,
  CardMoreDetails,
  ImageLayout,
} from "./style";
import UserIcon from "../../assets/icons/UserIcon";
import PropTypes from "prop-types";
import ProtagonistChip from "../Protagonist";
import { useNavigate } from "react-router-dom";
import { convertEthToNumber } from "../../common/helpers/helper";
import AssetComponent from "../AssetComponent";
// import defaultConfig from "../../configuration/config";
import { Avatar } from "@mui/material";
import { AUCTION_STATUS } from "../../constants/appConstants";
import Timer from "../Timer/Timer";
import ShareIcon from "../../assets/icons/shareIcon";
import ShareModal from "../ShareModal/ShareModal";
import TransferIcon from "../../assets/icons/transferIcon";
import { useSelector } from "react-redux";
import { AniMationStyled } from "../CollectionAuctionCard/style";

const { Body: CardBody, Title: CardTitle } = Card;

const AssetCardSale = (props) => {
  const {
    status,

    detailsPageType,
  } = props;

  const [show, setShow] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const navigate = useNavigate();

  useEffect(() => {}, [status]);

  const getDetailsPage = (id) => {
    if (id === "") {
      navigate(`/auctionDetails/${id}`);
    } else if (detailsPageType === "auction") {
      navigate(`/auctionDetails/${id}`);
    } else {
      navigate(`/collectionDetails/${id}`);
    }
  };

  const getFixedPriceValue = (amount, decimal) => {
    const result = convertEthToNumber(amount, decimal);
    return Number(result).toFixed(4);
  };
  const handleShareModaClose = () => setShow(false);
  const handleShareModalShow = () => setShow(true);

  return (
    <Row>
      {props.onSaleAssets?.map((items, i) => {
        return (
          <Col key={i} md={4} xs={12} sm={6}>
            <CardSection>
              <CustomImage
                onClick={() =>
                  getDetailsPage(
                    items?.approvalStatus === "PENDING"
                      ? items?.auctions?.[0]?._id
                      : detailsPageType === "auction"
                      ? items?.auctions?.[0]?._id
                      : items?.auctions?.[0]?._id
                  )
                }
              >
                {items?.approvalStatus === "PENDING" ? (
                  <div style={{ opacity: 0.5 }}>
                    <HeadingText
                      style={{
                        zIndex: 1000,
                        textAlign: "center",
                        position: "absolute",
                        top: "21%",
                        width: "100%",
                      }}
                    >
                      <p>Pending for approval</p>
                    </HeadingText>
                    {items?.metadata_fields?.image_url ? (
                      <AssetComponent
                        assetsType="image"
                        src={items?.metadata_fields.image_url}
                      />
                    ) : (
                      <UserIcon />
                    )}
                  </div>
                ) : items?.metadata_fields?.image_url ? (
                  <AssetComponent
                    assetsType="image"
                    src={items?.metadata_fields.image_url}
                  />
                ) : (
                  <UserIcon />
                )}
              </CustomImage>
              {items?.approvalStatus !== "PENDING" ? (
                items?.auctions && items?.auctions?.highestBid ? (
                  <ToolTipValues>
                    <p>Current Bid</p>
                    <div className="d-flex align-items-center">
                      {items?.auctions?.status === AUCTION_STATUS.open ? (
                        <AniMationStyled>
                          <div className="solidDot"></div>
                        </AniMationStyled>
                      ) : null}
                      <h4>
                        {getFixedPriceValue(
                          items?.auctions?.highestBid?.amount,
                          18
                        )}
                        &nbsp;
                        {defaultConfig?.chainInfo?.name === "wethio"
                          ? "ZYN"
                          : "ETH"}
                      </h4>
                    </div>
                  </ToolTipValues>
                ) : null
              ) : null}
              <Card.Body
                className="d-flex flex-column "
                onClick={() =>
                  getDetailsPage(
                    items?.approvalStatus === "PENDING"
                      ? items?.auctions?.[0]?._id
                      : detailsPageType === "auction"
                      ? items?.auctions?.[0]?._id
                      : items?.auctions?.[0]?._id
                  )
                }
              >
                <CardTitle>{items?.name}</CardTitle>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div className="d-flex">
                  {items?.creator_id?.profile_image_url ? <img style={{borderRadius:"20px",height:'40px',width:"40px"}} src={items?.creator_id?.profile_image_url} alt='profile'/>: <Avatar
                $size="small"
                $width="40px"
                $height="40px"
                $imgURL={items?.creator_id?.profile_image_url}
                alt="images"
              />}
                    <div>
                      <ProfileUser>User</ProfileUser>
                      <ProfileText>
                        {items?.creator_id?.username || "anonymous"}
                      </ProfileText>
                    </div>
                  </div>
                  {items?.auctions &&
                  items?.auctions?.status === AUCTION_STATUS.open ? (
                    <CountDownTimerStyled>
                      <h4>Ending in</h4>
                      <TimingDiv>
                        <Timer
                          timeStamp={items?.auctions?.endTime}
                          unitLabels={{
                            hours: "h",
                            minutes: "m",
                            seconds: "s",
                          }}
                        />
                      </TimingDiv>
                    </CountDownTimerStyled>
                  ) : null}
                </div>
              </Card.Body>
              <Footer
                className="d-flex justify-content-between align-items-center"
                approvalStatus={items?.approvalStatus}
                status={items?.status}
              >
                {items?.approvalStatus === "PENDING" ? (
                  <HeadingText style={{ margin: "18px 0px" }}>
                    Coming Soon
                  </HeadingText>
                ) : items?.approvalStatus !== "PENDING" ? (
                  <>
                    <CardMoreDetails>
                      <h4>Followers</h4>
                      <p>{items?.followersCount}</p>
                    </CardMoreDetails>

                    <CardMoreDetails>
                      <h4>Reserved Price</h4>
                      <p>
                        {items?.auctions?.reservePrice
                          ? getFixedPriceValue(
                              items?.auctions?.reservePrice,
                              10
                            )
                          : 0}
                        &nbsp;
                        {defaultConfig?.chainInfo?.name === "wethio"
                          ? "ZYN"
                          : "ETH"}
                      </p>
                    </CardMoreDetails>
                    <CardMoreDetails
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShareModalShow()}
                      className="d-flex align-items-center"
                    >
                      <ImageLayout>
                        <TransferIcon />
                        {/* <img src="./transferIcon.svg" alt="images" /> */}
                      </ImageLayout>
                      {/* <ShareIcon className="me-2" /> */}
                    </CardMoreDetails>
                    <ShareModal
                      image={items?.metadata_fields?.image_url}
                      show={show}
                      handleClose={handleShareModaClose}
                    />
                  </>
                ) : items?.status === AUCTION_STATUS.close ||
                  items?.status === AUCTION_STATUS.completed ? (
                  <div>
                    <HeadingText>Highest Bid</HeadingText>
                    <p className="mt-2">
                      {getFixedPriceValue(items?.highestBid?.amount, 18)}&nbsp;
                      {defaultConfig?.chainInfo?.name === "wethio"
                        ? "ZYN"
                        : "ETH"}
                    </p>
                  </div>
                ) : (
                  <div className="w-100 d-flex justify-content-center p2">
                    <Spinner animation="border" />
                  </div>
                )}
              </Footer>
            </CardSection>
          </Col>
        );
      })}
    </Row>
  );
};

export default AssetCardSale;

AssetCardSale.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  soldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  remainingTime: PropTypes.string,
  detailsPageType: PropTypes.oneOf(["auction"]),
};
