export const sizeNumbers = {
  mobileXS: 320,
  mobileS: 380,
  mobileM: 480,
  mobileSM: 575,
  mobileL: 600,
  tabletS: 650,
  tabletM: 768,
  tabletL: 900,
  laptopS: 991,
  laptopM: 1024,
  laptopL: 1200,
  desktopS: 1260,
  desktop: 1440,
  desktopL: 1600,
};

export const size = {
  mobileXS: `${sizeNumbers.mobileXS}px`,
  mobileS: `${sizeNumbers.mobileS}px`,
  mobileM: `${sizeNumbers.mobileM}px`,
  mobileSM: `${sizeNumbers.mobileSM}px`,
  mobileL: `${sizeNumbers.mobileL}px`,
  tabletS: `${sizeNumbers.tabletS}px`,
  tabletM: `${sizeNumbers.tabletM}px`,
  tabletL: `${sizeNumbers.tabletL}px`,
  laptopS: `${sizeNumbers.laptopS}px`,
  laptopM: `${sizeNumbers.laptopM}px`,
  laptopL: `${sizeNumbers.laptopL}px`,
  desktopS: `${sizeNumbers.desktopS}px`,
  desktop: `${sizeNumbers.desktop}px`,
  desktopL: `${sizeNumbers.desktopL}px`,
};

export const deviceQuery = {
  mobileXS: `(max-width: ${size.mobileXS})`,
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileSM: `(max-width: ${size.mobileSM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tabletM: `(max-width: ${size.tabletM})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktopL})`,
};
