import styled, { css, keyframes } from 'styled-components';

const wandererAnimation = ({ parentWidth, width }) => keyframes`
  0% {
    width: ${width}px;
    transform: translateX(0);
  }
  50%{
    width:${2 * width}px;
  }
  100% {
    width:${width}px;
    transform: translateX(${parentWidth - width}px);
  }
`;

export const StyledLoader = styled.div`
  display: flex;
  width: 100%;
  background-color: transparent;
  height: 0.5rem;

  .wanderer {
    width: ${({ width }) => `${width}px`};
    background-color: ${({ theme, color = null, $active }) =>
      $active ? color ?? theme.palette.primary.main : 'transparent'};
    height: 100%;
    max-width: 50%;
    opacity: 0.8;

    ${({ $active }) =>
      $active &&
      css`
        animation: ${(props) => wandererAnimation(props)} 2s infinite alternate
          ease-in-out;
      `}
  }
`;
