import React, { useState, useCallback, useEffect } from "react";
import {
  CreateCollectionStyled,
  HeadingText,
  Heading,
  Text,
  StyledCard,
  LoadMoreButton,
  LoaderArea,
  CustomCarouselStyled,
  CarouselItem,
  CarouselCaption,
  ImageSection,
} from "./style";
import { Row, Col, Spinner } from "react-bootstrap";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; 
// import { Carousel } from "react-responsive-carousel";
import getAxiosInst, { apiHandler } from "../../services/axios";

import FullSizeLoader from "../../Components/FullSizeLoader";
import CommonAssetCard from "../../Components/CommonAssetCard/CommonAssetCard";
import { getAllAssets } from "../../services/assetServices";
import InfiniteScrollWrapper from "../../Components/InfiniteScrollWrapper";
import { getAllCreators } from "../../services/userServices";
import { toast } from "react-toastify";
import CommonCreatorCard from "../../Components/CommonCreatorCard/CommonCreatorCard";
import MainTopSection from "../../Components/MainTopSection";
import { useSelector } from "react-redux";

const initialLimit = 5;
const initialPageNumber = 1;

function AllCreators() {
  const [allCollection, setAllCollection] = useState();
  const [loading, setLoading] = useState();
  const [commonLoadMore, setCommonLoadMore] = useState(9);
const userData = useSelector(state=>state.user.userData);
  useEffect(() => {
    getAllCreators(commonLoadMore)
      .then((data) => {
      
        setAllCollection(data?.data?.results);
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, [commonLoadMore]);

  const onClickLoadMore = (value) => {
    setCommonLoadMore(value + 3);
  };

  const FullWidthSpinner = () => (
    <div className="d-flex justify-content-center">
      <Spinner variant="light" animation="border" />
    </div>
  );


  return (
    <>
      <MainTopSection
        backButton={false}
        headingText="Our Creator"
        descriptionText={""}
        routePath="/"
      />

      <CreateCollectionStyled>
        {loading ? (
          <LoaderArea>
            <FullSizeLoader />
          </LoaderArea>
        ) : (
          <>
            {/* <CustomCarouselStyled>
              <Carousel
                labels={false}
                showStatus={false}
                showThumbs={false}
                interval={1000}
                
              >
                <div className="d-flex">
                  <div>
                    <ImageSection>
                      <img
                        className="d-block "
                        src="./user1.webp"
                        alt="First slide"
                      />
                    </ImageSection>
                    <CarouselCaption>
                      <img
                        className="d-block verifiedIcon "
                        src="./verifiedIcon.png"
                        alt="First slide"
                      />
                      <h3>Name Prenom</h3>
                      <p>214.2 ETH</p>
                    </CarouselCaption>
                  </div>
                  <div>
                    <ImageSection>
                      <img
                        className="d-block "
                        src="./user1.webp"
                        alt="First slide"
                      />
                    </ImageSection>
                    <CarouselCaption>
                      <img
                        className="d-block verifiedIcon "
                        src="./verifiedIcon.png"
                        alt="First slide"
                      />
                      <h3>Name Prenom</h3>
                      <p>214.2 ETH</p>
                    </CarouselCaption>
                  </div>
                  <div>
                    <ImageSection>
                      <img
                        className="d-block "
                        src="./user1.webp"
                        alt="First slide"
                      />
                    </ImageSection>
                    <CarouselCaption>
                      <img
                        className="d-block verifiedIcon "
                        src="./verifiedIcon.png"
                        alt="First slide"
                      />
                      <h3>Name Prenom</h3>
                      <p>214.2 ETH</p>
                    </CarouselCaption>
                  </div>
                </div>

                <div className="d-flex">
                  <div>
                    <ImageSection>
                      <img
                        className="d-block "
                        src="./user1.webp"
                        alt="First slide"
                      />
                    </ImageSection>
                    <CarouselCaption>
                      <img
                        className="d-block verifiedIcon "
                        src="./verifiedIcon.png"
                        alt="First slide"
                      />
                      <h3>Name Prenom</h3>
                      <p>214.2 ETH</p>
                    </CarouselCaption>
                  </div>
                  <div>
                    <ImageSection>
                      <img
                        className="d-block "
                        src="./user1.webp"
                        alt="First slide"
                      />
                    </ImageSection>
                    <CarouselCaption>
                      <img
                        className="d-block verifiedIcon "
                        src="./verifiedIcon.png"
                        alt="First slide"
                      />
                      <h3>Name Prenom</h3>
                      <p>214.2 ETH</p>
                    </CarouselCaption>
                  </div>
                  <div>
                    <ImageSection>
                      <img
                        className="d-block "
                        src="./user1.webp"
                        alt="First slide"
                      />
                    </ImageSection>
                    <CarouselCaption>
                      <img
                        className="d-block verifiedIcon "
                        src="./verifiedIcon.png"
                        alt="First slide"
                      />
                      <h3>Name Prenom</h3>
                      <p>214.2 ETH</p>
                    </CarouselCaption>
                  </div>
                </div>
              </Carousel>
            </CustomCarouselStyled> */}
            {/* <div className="text-center" style={{ marginTop: "8rem" }}>
              <HeadingText>Our Creator</HeadingText>
            </div> */}
            <StyledCard className="asset-card">
              <Row>
                {allCollection?.length > 0 ? (
                  allCollection?.slice(0, commonLoadMore)?.map((items, i) => {
                    return (
                      <CommonCreatorCard
                        key={i}
                        loginUserId={userData?._id}
                        assets={items}
                        creatorId={items?.user_id?._id}
                        coverImage={items?.user_id?.profile_image_url}
                        username={items?.user_id?.username}
                        allAssets={items?.created_assets}
                        twitterUsername={items?.user_id?.twitterUsername}
                        instagramUsername={items?.user_id?.instagramUsername}
                        isAdminUserTrue={true}
                        totalFollowCount={items?.followersCount}
                      />
                    );
                  })
                ) : (
                  <div className="text-center mt-3 mb-3">
                    <Text>
                      <HeadingText>No items found</HeadingText>
                    </Text>
                  </div>
                )}
            </Row>
            </StyledCard>
            {allCollection && allCollection?.length > commonLoadMore ? (
              <div className="mt-3">
                <LoadMoreButton
                  type="button"
                  onClick={() => onClickLoadMore(commonLoadMore)}
                  className=" d-block mx-auto"
                  color="primary"
                >
                  Load More
                </LoadMoreButton>
              </div>
            ) : null}
          </>
        )}
      </CreateCollectionStyled>
    </>
  );
}

export default AllCreators;
