import getAxiosInst from "./axios";

export const uploadAsset = (reqDatqa) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/ipfs/image?network=${blockchain}`,
    reqDatqa
  );
};

export const uploadMetaData = (metadata, path) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/ipfs/metadata?network=${blockchain}`,
    {
      metadata,
      path,
    }
  );
};
