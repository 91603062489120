import * as actionTypes from '../../constants/actionTypes';
// import defaultConfig from "../../configuration/config";
const initiContractState = {
  contract: {
    minting: null,
    auction: null,

  },
};

export const contractReducer = (state = initiContractState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case actionTypes.SET_CONTRACT:
      return {
        ...state,
        contract: {
          ...state.contract,
          [payload.contractName]: payload.contract,
        },
      };
    default:
      return state;
  }
};
