import styled from 'styled-components';

export const StyledTitle = styled.h4`
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const AnimationWrapper = styled.div`
  svg {
    & * {
      fill: ${(props) => props.theme.palette.common.animationBg};
    }
  }
`;
