import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes } from "react-router";
import { logout } from "../../../store/actions/loginActions";
import defaultConfig from "../../../configuration/config";
import { CURRENCY_SYMBOLS } from "../../../constants/appConstants";
import {
  DropdownMenuStyled,
  DropdownToggleStyled,
  UserName,
  Option,
  MenuLogo,
  DropdownItem,
  AddressInDropdown,
  Balance,
} from "../style";
import Dropdown from "react-bootstrap/Dropdown";
import Avatar from "../../Avatar/Avatar";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import CopyAddress from "../../../assets/icons/copyAddress";
import { toast } from "react-toastify";
import store from "../../../store/store";
import { setWeb3Instance } from "../../../store/actions/globalActions";
import { createContractInstance } from "../../../common/common";
import getAxiosInst from "../../../services/axios.js";

const popperConfig = {
  modifiers: [
    {
      name: "offset",
      enabled: true,
      options: {
        offset: [0, 10],
      },
    },
  ],
};

function ConnectButton({ currentChainId }) {
  const dispatch = useDispatch();

  useEffect(() => { }, [dispatch]);
  return <ConnectedButton currentChainId={currentChainId} wrapped />;
}

export default ConnectButton;

ConnectButton.propTypes = {
  isLogin: PropTypes.bool,
  toggleWalletList: PropTypes.func,
  currentChainId: PropTypes.number,
  chainInfo: PropTypes.shape({
    chainId: PropTypes.number,
    network: PropTypes.string,
  }),
};

const ConnectedButton = ({ currentChainId }) => {
  const globalState = useSelector((state) => state.global);
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isBlocked = useSelector((state) => state.global.isBlocked);
  
  const { userData } = userState;
  const loginState = useSelector((state) => state.login);

  const { isLogin, requestStatus, loading } = loginState;

  const defaultConfig = useSelector((state) => state.global.activeConfig);
  const history = useNavigate();
  const isCreator = useSelector((state) => state.global.isCreator);
  const [isAdminUser, setIsAdminUser] = useState();
  const navigate = useNavigate();
  const [creatorApprovalStatus, setCreatorApprovalStatus] = useState('');
  const { web3Instance, hasMetamask, account, error, walletModal } =
    globalState;
  useEffect(() => { }, [userData]);
  const disconnectHandler = () =>
    dispatch(logout(history, userData?.wallet_type));

  const menuOptions = [
    {
      label: `${userData?.username || "@anonymous"}`,
      Icon: null,
      onClickHandler: () => { },
      arrow: false,
    },
    {
      Icon: null,
      arrow: false,
      onClickHandler: () => { },
      Component: (
        <UserInfo
          currentChainId={currentChainId}
          // balance={globalState["balance"]}
          // balance={globalState["balance"]?.eth}
          balance={globalState["balance"].eth}
          account={globalState.account}
          chainInfo={defaultConfig?.chainInfo}
          className="user-info-mobile"
        />
      ),
      itemClass: "d-block  user-info-item",
    },
    {
      label: "notifications",
      itemClass: "notification-item",
      iconProps: { fill: "black" },
      onClickHandler: () => history(Routes.Notification),
    },
    {
      label: "My profile",
      onClickHandler: () => history(`/userDetails`),
    },

    {
      label: "Log Out",
      onClickHandler: disconnectHandler,
    },
  ];
  const checkUserIsAdmin = useCallback(async () => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    const isAdmin = await ContractInstance.methods.isAdmin(account).call();
    setIsAdminUser(isAdmin);
  }, [account]);
  useEffect(() => {
    if (isLogin && account) {
      checkUserIsAdmin();
    }
  }, [account]);

  const id = useSelector(state=>state?.user?.userData?._id);

  useEffect(() => {
    const blockchain = localStorage.getItem("blockchain");
    if (id)
 {
      getAxiosInst({ withAuth: true })
        .get(
          `/users/creators/${id}?network=${blockchain}`
        )
        .then((data) => {
       console.log(data,"data")
          setCreatorApprovalStatus(data?.data?.creatorApprovalStatus)
        })
        .catch((err) => toast.error(err?.response?.data?.message));
    }
  }, [id]);
  const OpenAssetPage = () => {
    if (isAdminUser) {
      navigate("/collectionSteps?type=image");
    } else if (isBlocked) {
      navigate("/userProfile", {
        state: { msg: "You are blocked by admin" },
      });
    } else if (isCreator) {
      navigate("/collectionSteps?type=image");
    } else if(creatorApprovalStatus ==='REJECTED'){
      navigate("/userProfile", {
        state: { msg: "You are rejected by admin" },
      });

    }else if (
      (!isCreator && userData?.instagramUsername) ||
      userData?.twitterUsername
    ) {
      navigate("/userProfile", {
        state: { msg: "Please wait for approval from admin" },
      });
    } else if (
      !isCreator &&
      (!userData?.instagramUsername || !userData?.twitterUsername)
    ) {
      navigate("/userProfile", {
        state: { msg: "Please complete your profile" },
      });
    }
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={DropdownToggleStyled}
        id="dropdown-custom-components"
      >
        <Avatar
          $width="42px"
          $height="42px"
          className="user-profile"
          fill={theme.palette.common.white.main}
          $imgURL={userData?.profile_image_url}
          $connectBtn
        />
      </Dropdown.Toggle>
      <DropdownMenuStyled popperConfig={popperConfig}>
        <Dropdown.Item>
          <UserName>{`${userData?.username || "@anonymous"}`}</UserName>
        </Dropdown.Item>
        <Dropdown.Item className="d-block  user-info-item">
          <AddressInDropdown>
            <div className="d-flex justify-content-between align-items-center">
              <p className="walletAddress">{userData?.wallet_address}</p>

              <CopyAddress
                onClick={() => {
                  navigator.clipboard.writeText(userData?.wallet_address);
                  toast("Account Address Copied successfully");
                }}
              />
            </div>
            <Balance style={{ color: "#080b35" }}>
              {currentChainId === defaultConfig?.chainInfo?.chainId ||
                !defaultConfig?.chainInfo
                ? `${globalState["balance"].eth} ${defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"
                }`
                : "wrong-network"}
            </Balance>
          </AddressInDropdown>
        </Dropdown.Item>
        <DropdownItem
          onClick={OpenAssetPage}
          className="d-flex justify-content-between align-items-center"
        >
          <Option>Create NFT</Option>
        </DropdownItem>
        <DropdownItem
          onClick={() => history(`/userDetails`)}
          className="d-flex justify-content-between align-items-center"
        >
          <Option>My Profile</Option>
          <MenuLogo src="/img/user.png" />
        </DropdownItem>
        <DropdownItem
          onClick={disconnectHandler}
          className="d-flex justify-content-between align-items-center"
        >
          <Option>Log out</Option>
          <MenuLogo src="/img/log-out.png" />
        </DropdownItem>
      </DropdownMenuStyled>
    </Dropdown>
  );
};

ConnectedButton.propTypes = {
  currentChainId: PropTypes.number,
  wrapped: PropTypes.bool,
};

const UserInfo = ({
  currentChainId,
  chainInfo,
  balance,
  account,
  wrapped,
  className = "",
}) => {
  return (
    <div className={`user-info ${className}`}>
      <div
        className={`balance m-3 ${currentChainId === chainInfo?.chainId ? "" : "error-net"
          }`}
      >
        {currentChainId === chainInfo?.chainId || !chainInfo
          ? `${balance.eth} ${CURRENCY_SYMBOLS.ether}`
          : "wrong-network"}
      </div>
      <div className="account-number pb-3">{false ? account : ""}</div>
    </div>
  );
};

UserInfo.propTypes = {
  currentChainId: PropTypes.number,
  chainInfo: PropTypes.shape({
    id: PropTypes.number,
    network: PropTypes.string,
  }),
  balance: PropTypes.number,
  account: PropTypes.string,
  className: PropTypes.string,
  wrapped: PropTypes.bool,
};
