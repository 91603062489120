import React from "react";
import { SearchField } from "./style";
import SearchIcon from "../../assets/icons/searchIcon";
import { Form } from "react-bootstrap";

const Index = () => {
  return (
    <SearchField>
      <SearchIcon />
      <Form.Control placeholder="Search" className="searchfield"></Form.Control>
    </SearchField>
  );
};

export default Index;
