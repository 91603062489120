import styled from "styled-components";
import {
  Container,
  Popover,
  FormControl,
  FormLabel,
} from "react-bootstrap";

import { Button } from "react-bootstrap";

export const UserProfileStyled = styled.div`
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    color: #b5b6c7;
  }
`;

export const CloseIconDiv = styled.div`
  margin-left: 10px;
  align-items: center;
  display: flex;
`;

export const AddButton = styled.div`
  button {
   
    color: ${(props) => props.theme.palette.text.light};
  }
`;
export const Text1 = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  margin: 0px 0px 0px 8px;
`;
export const Image = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  img {
    padding: 0px 10px;
  }
`;
export const StyledPopper = styled(Popover)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.text.primary};
  font-size: ${(props) => props.theme.typography.subText4};
  font-weight: 500;
  line-height: 1.6rem;
  min-width: 7.3rem;
  min-height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    display: none;
  }
  .popover-body {
    color: inherit;
  }
`;
export const AddressText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AvtarImg = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const AddressBtn = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.text.gray};
  margin: 0px 0px 0px 8px;
  display: flex;

  padding: 10px 20px;
  border-radius: 40px;
  height: 40px;
  align-items: center;
  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px 10px 0px 0px;
  }
  img {
    width: 16px;
  }
`;

export const UserProfileContainer = styled(Container)`
  max-width: 700px !important;
  img {
    width: 20px;
    height: 20px;
  }

  .backbutton {
    cursor: grabbing;
  }
`;
export const StyleInput = styled.div`
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: none !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  color: ${(props) => props.theme.palette.text.light};
  align-items: center;
  display: flex;
  input,
  input:focus {
    border: none;
    box-shadow: none !important;
    margin: 1rem 0rem;
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
`;
export const CreateButton = styled.button`
  padding: 8px 32px;
  background-color: #feefa4;
  border: 0px;
  border-radius: 10px;
  color: #000000;
`;
export const EditProfile = styled.button`
  height: 40px;
  margin-left: 100px;
  width: 205px;
  border: none;
  border-radius: 20px;
  padding: 8px 37px;
  background-color: #feefa4;
  color: #000;
`;
export const ProfileDiv = styled.div`
  padding: 4px 37px;
  margin: 0px 18px 40px 0px;
  color: #000;
  .card {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
  }
  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
  }
`;

export const LabelStyled = styled(FormLabel)`
  font-size: 16px;

  color: ${(props) => props.theme.palette.newTheme.blueText};
`;
export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
  width:48%;
`;
export const FormCheckStyled = styled.div`
  height: 40px;
  input {
    width: 37px;
    height: 35px;
  }

  label {
    margin: 10px;
    background-color: ${(props) => props.theme.palette.text.light} !important;
  }

  color: ${(props) => props.theme.palette.text.secondary};

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const InputStyled = styled(FormControl)`
  padding: 12px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none !important;
`;
export const ContainerDiv = styled(Container)`
  .nav-tabs .nav-link.active {
    color: #080b35;
    border: none !important;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    outline: none;
    color: #b5b6c7;
  }
  .asset-img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
  }
  .profileImage {
    cursor: pointer;
    height: 30px;
    margin: 0px 21px;
  }
`;

export const ButtonList = styled.button`
  padding: 8px 45px;
  border-radius: 30px;
  margin-right: 20px;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const StyledButton = styled(Button)`
  padding: 8px 35px;
  border-radius: 14px;
  align-items: center;
  border: none;
  color:${(props) => props.theme.palette.newTheme.white};
  background-color: ${(props) => props.theme.palette.text.primary};
  &:disabled {
    cursor: not-allowed;
    color:${(props) => props.theme.palette.newTheme.darkBlueButton};
    background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  }
  &:hover{

    background-color: ${(props) => props.theme.palette.text.primary} !important;
  }
`;
export const SaveButton = styled.button`
  padding: 12px 45px;
  border-radius: 25px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10rem 0px;
  color:${(props) => props.theme.palette.newTheme.buttonText};
  background-color: ${(props) => props.theme.palette.newTheme.lightGray};
  border: none;
`;
export const CommonButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const Text = styled.div`
  color: #080b35;
`;
export const ProfileCard = styled.div`
  height: 229px;
  margin: 50px 0 84px 0px;
  object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #b5b6c7;
  .cutomizebutton {
    text-align: end;
    width: 100%;
    display: inline-block;
    position: relative;
  }
  .profileImage {
    padding-top: 172px;
  }
`;
