import styled from "styled-components";

export const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh);
`;

export const StyledComp = styled.div`
  flex-grow: 1;
  .container {
   
  }
`;
