import getAxiosInst from "./axios";

export const getAllCollection = () => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: false }).get(
    `/collections?limit=100&network=${blockchain}`
  );
};

export const getCollection = (collectionId) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).get(
    `/collections/${collectionId}?network=${blockchain}`
  );
};

// not a get api but post to freeze a collection
export const getfreezeCollections = (collectionId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/collections/${collectionId}/freeze-collections?network=${blockchain}`,
    reqData
  );
};

export const getAuctionfinalDeatils = (collectionId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/auctions/${collectionId}?network=${blockchain}`,
    reqData
  );
};
export const getAuctionData = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/auctions?network=${blockchain}`,
    reqData
  );
};
export const deleteCollectionAssets = (collectionId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).delete(
    `/collections/${collectionId}?network=${blockchain}`,
    reqData
  );
};
export const getCollectionApproval = (collectionId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/collections/${collectionId}/approval?network=${blockchain}`,
    reqData
  );
};
export const createCollection = (reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).post(
    `/collections?limit=100&network=${blockchain}`,
    reqData
  );
};

export const updateCollection = (collectionId, reqData) => {
  const blockchain = localStorage.getItem("blockchain");

  return getAxiosInst({ withAuth: true }).put(
    `/collections/${collectionId}?network=${blockchain}`,
    reqData
  );
};
