import styled, { css } from "styled-components";

import {
  Container,
  Card,
  Button,
  FormControl,
  FormLabel,
  FormCheck,
} from "react-bootstrap";
import { deviceQuery, size } from "../../styles/mediaSizes";

export const CreateCollectionStyled = styled(Container)`
  .card:hover {
    background-color: #fff;
  }
  .nav-tabs .nav-link.active {
    color: #080b35;
    border-bottom: 2px solid #080b35 !important;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-link {
    color: #b5b6c7;
  }
  .silderImage {
    text-align: center;
    height: 400px;
    width: 400px;
    display: inline-block;
  }
`;

export const SubHeading = styled.h5`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const FormCheckStyled = styled.div`
  height: 40px;
  input {
    width: 37px;
    height: 35px;
  }

  label {
    margin: 10px;
    background-color: ${(props) => props.theme.palette.text.light} !important;
  }

  color: ${(props) => props.theme.palette.text.secondary};

  color: ${(props) => props.theme.palette.text.secondary};
`;
export const TextHeading = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const AuctionButton = styled.div`
  margin-top: 3rem;
  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.palette.text.gray};
  }
  button {
    border-radius: 12px;
    background-color: #b5b6c7;
    color: ${(props) => props.theme.palette.text.light};
  }
`;
export const StyleInput = styled.div`
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: none !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  color: ${(props) => props.theme.palette.text.light};
  align-items: center;
  display: flex;
  input,
  input:focus {
    border: none;
    box-shadow: none !important;
    margin: 1rem 0rem;
    background-color: ${(props) => props.theme.palette.text.secondary};
  }
`;
export const HeadingItem = styled.h1`
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const HeadingEth = styled.h1`
  position: absolute;
  text-align: right;
  margin-left: 277px;
  margin-bottom: 0px;
  display: inline-block;
  color: ${(props) => props.theme.palette.text.light};
`;
export const PreviewDiv = styled.div`
  padding: 40px 0px 15rem 0px;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
`;
export const RouteStyled = styled(Container)`
  padding: 40px 0px 100px 0px;
  @media ${deviceQuery.laptopM} {
    .MobileView {
      width: 100%;
      display: flex;
    }
    .MobileView div {
      width: 100%;
      text-align: center;
    }
    .MobileView .Horizantalline::after {
      content: "";
      position: absolute;
      width: 16.4%;
      height: 4px;
      background-color: #dcdcdc;
      margin-top: 52px;
    }
  }
`;

export const BackButton = styled(Container)``;

export const VerticalLine = styled.div`
  border-left: 2px solid #b5b6c7;
  height: 100px;
  margin: 20px 0px 20px 46px;
  @media ${deviceQuery.laptopM} {
    display: none;
  }
`;

export const AddressBtn = styled.div`
  color: ${(props) => props.theme.palette.text.secondary};
  background-color: ${(props) => props.theme.palette.text.light};
  margin: 0px 0px 0px 8px;
  display: flex;

  padding: 10px 20px;
  border-radius: 40px;
  height: 40px;
  align-items: center;
  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px 10px 0px 0px;
  }
  img {
    width: 16px;
  }
`;

export const TextContent = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const HeadingText = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
 
`;
export const CreateButton = styled(Button)`
  padding: 8px 37px;
  background-color: #feefa4;
  color: #000;
`;
export const SubTextGray = styled.p`
  color: ${(props) => props.theme.palette.text.gray};
`;
export const Heading = styled.h1`
  margin-top: 4rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const MyAccount = styled.h4`
  margin-top: 1rem;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const LabelStyled = styled(FormLabel)`
  font-size: 14px;
  margin-left: 50px;
  color: ${(props) => props.theme.palette.text.secondary};
`;
export const SaveButton = styled.button`
  padding: 15px 45px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10rem 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;

export const MintButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 100%;
  margin: 20px 0px 10px 0px;
  background-color: #080b35 !important;

  border: none;
`;

export const ListButton = styled.button`
  padding: 15px 4px;
  border-radius: 10px;
  align-items: center;
  color: #fff;
  width: 50%;
  margin: 20px 0px 10px 0px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
`;
export const InputStyled = styled(FormControl)`
  padding: 12px;
  object-fit: contain;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border: none !important;
`;

export const CustomCardStyled = styled(Card)`
  width: 18rem;
  text-align: center;
  color: #000000;
  margin: 20px;
  background-color: #edfbff;
`;
export const CustomCard = styled(Card)`
  padding: 30px 30px 50px;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  .card-title {
    color: ${(props) => props.theme.palette.text.primary};
  }
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      pointer-events: none;
      background-color: #eaeaea !important;
    `}
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  img {
    height: 140px;
  }
  @media ${deviceQuery.laptopM} {
    img {
      height: 100%;
    }
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }
`;
export const CreateCollectionDiv = styled(Container)`
  .action-btn {
    border-radius: 14px;
    color: #fff;
  }
`;

export const ContractAddess = styled.p`
  color: ${(props) => props.theme.palette.text.primary};
`;

export const RoundImage = styled.div`
  h1 {
    margin-left: 20px;
  }
  img {
    border-radius: 50%;
    color: #fff;
    width: 70px;
    height: 70px;
  }
`;
export const CoverImage = styled.div`
  text-align: center;
  img {
    width: 386px;
    height: 342px;
    border-radius: 32px;
    color: #fff;
  }
`;
export const Text1 = styled.h1`
  margin-left: 20px;
  color: ${(props) => props.theme.palette.text.primary};
`;
export const Text2 = styled.h1`
  color: ${(props) => props.theme.palette.text.primary};
`;
export const CommonButton = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #080b35;
  color: #fff;
  border: none;
`;
export const CommonReject = styled.button`
  padding: 16px 50px;
  border-radius: 14px;
  align-items: center;
  background-color: #080b35;
  background-color: ${(props) => props.theme.palette.text.error} !important;
  color: #fff;
  border: none;
`;

export const SubText = styled.p`
  color: ${(props) => props.theme.palette.text.secondary};
  font-family: ${(p) => p.theme.ff.poppins};
  margin-top: 5px;
  &.asset-description{
    line-height: 1.6rem;
overflow: hidden;
display: -webkit-box; 
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
max-height: 3.2rem;
  }
`;
export const Text = styled.div`
  color: #080b35;
  margin-bottom: 90px;
`;
export const CardProfile = styled(Card)`
  overflow: hidden;
  object-fit: contain;
  border-radius: 37px;
  border: none;
  margin: 42px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.palette.text.pink} !important;
  .card-title {
    text-align: left;
  }
  .card-body {
    text-align: center;
    margin-top: 22px;
  }
`;
