import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { apiHandler } from "../../services/axios";
import { useParams, useNavigate } from "react-router-dom";
import { createBid } from "../../services/biddingServices";
import {
  updateAuctionWithFinalizeTxData,
  getAuction,
} from "../../services/auctionServices";
import { toast } from "react-toastify";
import { CreateCollectionDiv, Heading, SubText } from "./style";
import { Col, Row, Spinner } from "react-bootstrap";
import Button from "../../Components/Button/Button";
import CollectionAuctionCard from "../../Components/CollectionAuctionCard/CollectionAuctionCard";
import useQuery from "../../hooks/useQuery";

function PlaceBidStepTwo({ auctionDetails: _auctionDetails }) {
  const [loading, setLoading] = useState(false);
  const [auctionDetails, setAuctionDetails] = useState(_auctionDetails);
  const [finishStatus, setfinishStatus] = useState(false);
  const contractState = useSelector((store) => store.contractState);
  const [confirmationRejected, setConfirmationRejected] = useState({
    code: "",
    message: "",
    typeMethod:"",
  });
  const globalState = useSelector((store) => store.global);
  const { account } = globalState;
  const {
    contract: { auction: auctionContractInstance },
  } = contractState;
  const query = useQuery();
  const amountEth = query.get("amountEth");
  const finalise = query.get("finalise");
  const navigate = useNavigate();
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const { auctionId } = useParams();

  const placeBid = useCallback(async () => {
    if (auctionContractInstance && account && auctionDetails && amountEth) {
      setLoading(true);
      try {
        const tx_data = await auctionContractInstance.methods
          .placeBid(auctionDetails?.auctionId)
          .send({
            from: account,
            value: amountEth,
            to: defaultConfig?.auctionContractAddress,
          });
        const reqData = {
          auction_id: auctionDetails?._id,
          tx_data,
          amount: amountEth,
        };
        await apiHandler(() => createBid(reqData), {
          onSuccess: (data) => {
            navigate(`/placeBid/${auctionDetails?._id}/success`);
          },
          onError: (error) => console.error(error),
        });
      } catch (error) {
    
        toast.error(error.message);
        setConfirmationRejected({ code: error.code, message: error.message,typeAction:amountEth});
        setLoading(false);
      }
    }
  }, [
    auctionContractInstance,
    amountEth,
    auctionDetails,
    account,
    navigate,
    defaultConfig,
  ]);

  const finalizeHandler = useCallback(async () => {
    if (auctionContractInstance && account && auctionDetails && finalise) {
      setLoading(true);
      try {
        const tx_data = await auctionContractInstance.methods
          .finalizeReserveAuction(auctionDetails?.auctionId)
          .send({
            from: account,
          });
        apiHandler(
          () => updateAuctionWithFinalizeTxData(auctionDetails?._id, tx_data),
          {
            onSuccess: (data) => {
              navigate(`/placeBid/${auctionDetails?._id}/success`);
            },
            onError: (error) => {
             
              setLoading(false);
            },
          }
        );
      } catch (error) {
       
        toast.error(error.message);
        setConfirmationRejected({ code: error.code, message: error.message,typeAction:finalise });
        setLoading(false);
      }
    }
  }, [auctionContractInstance, finalise, auctionDetails, account, navigate]);

  const onClickHandler = () => {
    if (finalise) {
      finalizeHandler();
    } else {
      placeBid();
    }
  };

  useEffect(() => {
    if (finalise) {
      finalizeHandler();
    } else {
      placeBid();
    }
  }, [placeBid, finalizeHandler, finalise]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
        setfinishStatus(true);
        // your logic
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (auctionId && !auctionDetails) {
      apiHandler(() => getAuction(auctionId), {
        onSuccess: (data) => {
          setAuctionDetails(data);
        },
        onError: (error) => console.error(error),
      });
    }
  }, [auctionId, auctionDetails]);



  return (
    <CreateCollectionDiv>
      <Row>
        <Col md={5}>
          <Row>
            {auctionDetails && (
              <CollectionAuctionCard
                status={auctionDetails?.status}
                creator_id={auctionDetails?.creator_id?._id}
                collectionImage={
                  auctionDetails?.asset_id?.metadata_fields.image_url
                }
                auctionPage={true}
                followCount={auctionDetails?.asset_id?.followersCount}
                reservePrice={auctionDetails?.reservePrice}
                contractAuctionId={auctionDetails?.auctionId}
                time={auctionDetails?.endTime}
                title={auctionDetails?.asset_id?.name}
                highestBid={auctionDetails?.highestBid}
              />
            )}
          </Row>
        </Col>
        <Col md={5} className="text-center">
          {confirmationRejected?.code===4001 && confirmationRejected?.typeAction===finalise ? (
            <>
              <Heading>Please try to finalise the auction again</Heading>
              <SubText>Confirm with your wallet to continue.</SubText>
            </>
          ) : (
            <>
              <Heading>{confirmationRejected?.code===4001 && confirmationRejected?.typeAction !==finalise  ? "Please try to Place Bid again":"Waiting for confirmation" }</Heading>
              <SubText>Confirm with your wallet to continue.</SubText>
            </>
          )}

          {loading ? (
            <div className="spinner-wrapper d-flex justify-content-center mt-5">
              <Spinner
                animation="border"
                style={{ width: "60px", height: "60px", color: "#080b35" }}
              />
            </div>
          ) : (
            <div className="w-100 text-center mt-5">
              <Button
                color="secondary"
                className="w-100"
                style={{ color: "#fff" }}
                onClick={onClickHandler}
              >
                {finalise ? "Finalise" : "Bid"}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </CreateCollectionDiv>
  );
}

PlaceBidStepTwo.propTypes = {};

export default PlaceBidStepTwo;
