import { useCallback } from "react";
import Web3 from "web3";
import defaultConfig from "../configuration/config";
import { WALLET_TYPE } from "../constants/appConstants";
import store from "../store/store";
import { parseBalance } from "./helpers/helper";

export const createWeb3Instance = (walletType, provider) => {
  const state = store.getState();
  const defaultConfig = state.global.activeConfig;

  if (walletType === WALLET_TYPE.metamask)
    return new Web3(
      Web3.givenProvider || defaultConfig?.web3ProviderFallbackUrl
    );
  else {
    throw new Error("Wallet Type Not Supported");
  }
};

export const createProvider = (walletType) => {
  if (walletType === WALLET_TYPE.metamask) return Web3.givenProvider;
  else return null;
};

export const createContractInstance = (web3, abi, address) => {
  const contract = new web3.eth.Contract(abi, address);
  return contract;
};

export const reqAccountAndSignature = async (web3, msg, walletType) => {
  let accounts, signature;

  if (window.ethereum && walletType === WALLET_TYPE.metamask) {
    accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    signature = await window.ethereum.request({
      // method: "eth_personalSign",
      method: "personal_sign",
      params: [web3.utils.utf8ToHex(msg), accounts[0]],
    });
  } else {
    accounts = await web3.eth.getAccounts();

    signature = await web3.eth.personal.sign(
      web3.utils.utf8ToHex(msg),
      accounts[0]
    );
  }
  return [accounts[0], signature];
};

export const getChainId = async (web3) => await web3.eth.net.getId();

export const getAccount = async (web3) => {
  try {
    const account = await web3.eth.getAccounts();
    return account[0];
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getBalance = async (web3, account) => {
  const balances = {};

  const ethBalance = await web3.eth.getBalance(account);
  balances.eth = parseBalance(ethBalance);

  return balances;
};

export const isConnected = async (web3) => {
  const accounts = await web3.eth.getAccounts();

  return !!accounts.length;
};

export const hexToNumber = (web3, hex) => web3.utils.hexToNumber(hex);
