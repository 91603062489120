import * as actionTypes from '../../constants/actionTypes';

const initLoginState = {
  token: null,
  isLogin: false,
  msg: '',
  loading: false,
  requestStatus: false,
};

export const loginReducer = (state = initLoginState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOGIN:
      return {
        ...state,
        isLogin: action.payload.isLogin,
        token: action.payload.token,
        msg: action.payload.msg,
      };
    case actionTypes.UPDATE_MSG:
      return { ...state, msg: action.payload.msg };
    case actionTypes.SET_LOGIN_LOADING:
      return { ...state, loading: action.payload.loading };
    case actionTypes.SET_REQUEST_STATUS:
      return { ...state, requestStatus: action.payload.requestStatus };
    default:
      return state;
  }
};
