import React, { useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

const InfiniteScrollWrapper = ({
  children,
  scrollableTarget,
  hasMore,
  next,
  loading,
  ...restProps
}) => {
  useEffect(() => {
    const root = scrollableTarget || document.documentElement;
    if (!loading && hasMore && next && root.scrollHeight <= root.clientHeight) {

      next();
    }
  }, [loading, next, scrollableTarget, hasMore]);

  return (
    <InfiniteScroll
      next={next}
      hasMore={hasMore}
      scrollableTarget={scrollableTarget}
      scrollThreshold={0.3}
      style={{overflow: 'hidden'}}
      {...restProps}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollWrapper;
