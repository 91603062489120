import React, { useState, useEffect } from "react";

import { Card, Col, Spinner } from "react-bootstrap";

// import EthAmount from '../EthAmount';
import UserIcon from "../../assets/icons/UserIcon";
import PropTypes from "prop-types";
import ProtagonistChip from "../Protagonist";
// import { useLocaleNumber } from "../../hooks/useLocaleNumber";
import { useNavigate } from "react-router-dom";

import { apiHandler } from "../../services/axios";
// import THREASURY_ABI from "../../abi/THREASURY_ABI.json";

import {
  // createWeb3Instance,
  createContractInstance,
  // createProvider,
} from "../../common/common";
import { useSelector } from "react-redux";
// import defaultConfig from "../../configuration/config";
import { getUser } from "../../services/userServices";
import store from "../../store/store";
import {
  CardMoreDetails,
  CardSection,
  CountDownTimerStyled,
  CustomImage,
  Footer,
  HeadingText,
  ImageLayout,
  ProfileText,
  ProfileUser,
  TimingDiv,
  ToolTipValues,
} from "./style";
import AssetComponent from "../AssetComponent";
import { convertEthToNumber } from "../../common/helpers/helper";
import Avatar from "../Avatar/Avatar";
import { AUCTION_STATUS } from "../../constants/appConstants";
import Timer from "../Timer/Timer";
import ShareModal from "../ShareModal/ShareModal";
import ShareIcon from "../../assets/icons/shareIcon";
import TransferIcon from "../../assets/icons/transferIcon";
const { Body: CardBody, Title: CardTitle } = Card;

const CommonAssetCard = ({
  collectionId,
  createrId,
  isAdminUserTrue,
  collectionImage,
  status,
  reservePrice,
  time,
  followCount,
  title,
  highestBid,
  approvalStatus,
}) => {
  // const formatNumber = useLocaleNumber();
  // const bidValue = 0.917;
  // const formatedBidValue = formatNumber(bidValue);
  const navigate = useNavigate();
  const globalState = useSelector((store) => store.global);
  const [isAdminUser, setIsAdminUser] = useState();
  // const [createrdata, setCreaterData] = useState();
  const { web3Instance, error } = globalState;
  const [creatorData, setCreatorData] = useState(null);
  const [show, setShow] = useState(false);
  const defaultConfig = useSelector((state) => state.global.activeConfig);

  const IsAdmin = async (address) => {
    const ContractInstance = await createContractInstance(
      web3Instance,
      defaultConfig?.treasuryABI,
      defaultConfig?.treasuryContractAddress
    );

    if (address) {
      const isAdmin = await ContractInstance.methods.isAdmin(address).call();
      if (isAdminUserTrue) {
        setIsAdminUser(isAdminUserTrue);
      } else {
        setIsAdminUser(isAdmin);
      }
    }
  };

  const getFixedPriceValue = (amount, decimal) => {
    const result = convertEthToNumber(amount, decimal);
    return Number(result).toFixed(4);
  };

  const getCreaterDetails = async () => {
    if (createrId) {
      apiHandler(() => getUser(createrId), {
        onSuccess: (data) => {
          IsAdmin(data?.wallet_address);
          setCreatorData(data);
        },
        onError: () => {
          console.error(error);
        },
        final: () => {},
      });
    }
  };
  const getDetailsPage = (collectionId) => {
    navigate(`/collectionDetails/${collectionId}`);
  };
  useEffect(() => {
    getCreaterDetails();
  }, [createrId]);

  const handleShareModaClose = () => setShow(false);
  const handleShareModalShow = () => setShow(true);



  return (
    <Col
      md={4}
      style={{
        display:
        isAdminUserTrue === true //&& status !== "APPROVED"
            ? "block"
            : "none",
      }}
    >
      <CardSection>
        <CustomImage onClick={() => getDetailsPage(collectionId)}>
          <AssetComponent assetsType="image" src={collectionImage} />
        </CustomImage>
       
        {approvalStatus !== "PENDING" ? (
          reservePrice ? (
            <ToolTipValues>
              <p>Current Bid</p>
              <h4>
                {highestBid
                  ? getFixedPriceValue(highestBid?.amount, 18)
                  : reservePrice
                  ? getFixedPriceValue(reservePrice, 10)
                  : 0}&nbsp;

                {defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"}
              </h4>
            </ToolTipValues>
          ) : null
        ) : null}

        <Card.Body
          className="d-flex flex-column "
          onClick={() => getDetailsPage(collectionId)}
        >
          <CardTitle>{title}</CardTitle>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex">
              <Avatar
                $size="small"
                $width="40px"
                $height="40px"
                $imgURL={creatorData?.profile_image_url}
                alt="images"
              />
              <div>
                <ProfileUser>User</ProfileUser>
                <ProfileText>
                  {creatorData?.username || "anonymous"}
                </ProfileText>
              </div>
            </div>
            {status === AUCTION_STATUS.open ? (
              <CountDownTimerStyled>
                <h4>Ending in</h4>
                <TimingDiv>
                  <Timer
                    timeStamp={time}
                    unitLabels={{ hours: "h", minutes: "m", seconds: "s" }}
                  />
                </TimingDiv>
              </CountDownTimerStyled>
            ) : null}
          </div>
        </Card.Body>
        <Footer
          className="d-flex justify-content-between align-items-center"
          approvalStatus={approvalStatus}
          status={status}
        >
          {approvalStatus === "PENDING" ? (
            <HeadingText style={{ margin: "18px 0px" }}>
              Coming Soon
            </HeadingText>
          ) : approvalStatus !== "PENDING" ? (
            <>
              <CardMoreDetails>
                <h4>Followers</h4>
                <p>{followCount}</p>
              </CardMoreDetails>

              <CardMoreDetails>
                <h4>Reserved Price</h4>
                <p>
                  {reservePrice ? getFixedPriceValue(reservePrice, 10) : 0}&nbsp;
                  {defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"}
                </p>
              </CardMoreDetails>
              <CardMoreDetails
                style={{ cursor: "pointer" }}
                onClick={() => handleShareModalShow()}
                className="d-flex align-items-center"
              >
                <ImageLayout>
                  <TransferIcon />
                  {/* <img src="./transferIcon.svg" alt="images" />  */}
                </ImageLayout>
                {/* <ShareIcon className="me-2" /> */}
              </CardMoreDetails>
              <ShareModal
                image={collectionImage}
                show={show}
                handleClose={handleShareModaClose}
              />
            </>
          ) : status === AUCTION_STATUS.close ||
            status === AUCTION_STATUS.completed ? (
            <div>
              <HeadingText>Highest Bid</HeadingText>
              <p className="mt-2">
                {getFixedPriceValue(highestBid?.amount, 18)}&nbsp;
                {defaultConfig?.chainInfo?.name === "wethio" ? "ZYN" : "ETH"}
              </p>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center p2">
              <Spinner animation="border" />
            </div>
          )}
        </Footer>
      </CardSection>
    </Col>
  );
};

export default CommonAssetCard;

CommonAssetCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  soldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  remainingTime: PropTypes.string,
  detailsPageType: PropTypes.oneOf(["auction"]),
};
