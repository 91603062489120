import React from "react";
import { NavLink } from "react-router-dom";
import {
  Content,
  FooterSectionHeading,
  IconList,
  FooterC,
  Heading,
} from "./Style";
import WethioLogo from "../../assets/icons/wethioftLogo";

function Footer() {
  return (
    <FooterC className="wethio-Footer footer">
      <div className="container">
        <div className="row footer-main pt-5">
          <div className="col-md-3 mb-5 mb-md-0 footer-social-media">
            <Heading className="footer-logo-text">
            
            <WethioLogo />
            </Heading>
            <Content>
              <p>
                The original digital marketplace for crypto collectibles,
                non-fungible tokens (NFTs) and Gold Coins. Buy, Sell, and
                discover your talent.
              </p>
            </Content>
            
          </div>
          <div className="col-md-3 mb-3 mb-md-0 footer-space justify-content-center">
            <FooterSectionHeading>Explore</FooterSectionHeading>
            <div>
              <ul className="footerWethio pl-0">
              <li>
                  <NavLink to="/wethioGold/zynecoin">Wethio Gold ZYN</NavLink>
                </li>
                <li>
                  <NavLink to="/wethioGold/zynecoin18k">Wethio Gold 18K</NavLink>
                </li>
                <li>
                  <NavLink to="/wethioGold/bullishBTC">Wethio Gold BTC</NavLink>
                </li>
                <li>
                  <NavLink to="/allAssets">Assets</NavLink>
                </li>
                <li>
                  <NavLink to="/marketPlacePage">Marketplace</NavLink>
                </li>
                <li>
                  <NavLink to="/creators">Creators</NavLink>
                </li>
                {/* <li>
                  <a target="_blank" href="/">
                    Gold Coins
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <div className="col-md-2 mb-3 mb-md-0 footer-space justify-content-center">
            <FooterSectionHeading>My account</FooterSectionHeading>
            <div>
              <ul className="footerWethio">
                <li>
                  <a target="_blank" href="/userDetails">
                    My profile
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/userDetails">
                    My assets
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    My favorites
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/userDetails">
                    My account Settings
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          {/* <div className="col-md-2 mb-3 mb-md-0 footer-space justify-content-center">
            <FooterSectionHeading>Resources</FooterSectionHeading>
            <div>
              <ul className="footerWethio">
                <li>
                  <a target="_blank" href="/">
                    Help Center
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/">
                    Partners
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/">
                    Suggestions
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/">
                    Newsletter
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-md-2 mb-3 mb-md-0 footer-space justify-content-center">
            <FooterSectionHeading>Company</FooterSectionHeading>
            <div >
              <ul className="footerWethio">
                <li>
                  <a target="_blank" href="https://www.wethioproject.com">
                    Wethio Project
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wethiox.io/landing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wethio eXchange
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.wethiox.io/terms-and-services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wethiox.io/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
        <div className="wethio-social d-flex mt-3">
              <IconList className="d-flex">
                <li>
                  <a
                    href="https://twitter.com/Wethio_NFT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src="./twitter.png" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/wethio-project"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src="./linkedin.png" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/wethio_nft" target="_blank" rel="noreferrer">
                    <img alt="" src="./insta.png" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img alt="" src="./fb.png" />
                  </a>
                </li>
              </IconList>
            </div>
        <div className="row copy-right cursor-pointer d-block text-center  pb-5 pt-3">
          Wethio NFT &copy; {new Date().getFullYear()}- v1.0
        </div>
        </div>
      </div>
    </FooterC>
  );
}

export default Footer;
