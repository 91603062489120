import React from 'react';
import PropTypes from 'prop-types';

const ActivityListLinkIcon = ({ fillColor = '#41416e', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.967"
      height="25.967"
      viewBox="0 0 25.967 25.967"
      {...props}
    >
      <path
        data-name="Tracé 57"
        d="M-1786.116-138.033a8.894 8.894 0 0 1-8.883-8.883v-8.2a8.894 8.894 0 0 1 8.883-8.883h8.2a8.894 8.894 0 0 1 8.884 8.883v8.2a8.894 8.894 0 0 1-8.884 8.883zm-7.517-17.084v8.2a7.525 7.525 0 0 0 7.517 7.517h8.2a7.525 7.525 0 0 0 7.517-7.517v-8.2a7.525 7.525 0 0 0-7.517-7.517h-8.2a7.525 7.525 0 0 0-7.517 7.517zm5.5 10.22a.456.456 0 0 1 0-.644l10.818-10.818h-7.919a.456.456 0 0 1-.455-.456.456.456 0 0 1 .456-.455h9.019a.456.456 0 0 1 .456.455v9.019a.456.456 0 0 1-.456.455.455.455 0 0 1-.322-.134.459.459 0 0 1-.134-.322v-7.919l-10.818 10.818a.454.454 0 0 1-.322.133.454.454 0 0 1-.327-.135z"
        transform="translate(1795 164)"
        style={{ fill: fillColor }}
      />
    </svg>
  );
};

ActivityListLinkIcon.propTypes = {
  fillColor: PropTypes.string,
};

export default ActivityListLinkIcon;
