import React from 'react';
import PropTypes from 'prop-types';

import MetamaskIcon from '../../../assets/icons/wallets/metaMaskIcon';
import { WALLET_TYPE } from '../../../constants/appConstants';

import Modal from '../../Modal/Modal';
import { WalletListStyled ,HeadingSubText} from '../style';
import { Spinner } from 'react-bootstrap';

const WalletModal = ({
  show,
  onHide,
  onWalletClickHandler,
  modalLoading,
  onExited,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      onExited={onExited}
      showCloseButton
    >
      <HeadingSubText className="title1">Connect Your Wallet</HeadingSubText>
      <p className="my-5 text1">
        <span className="content">
          By connecting your wallet, you agree to our Terms of Service and our
          Privacy Policy. And also Select The Rinkeby Test Network
        </span>
      </p>
      <WalletListStyled modalLoading>
        <li>
          <div
            tabIndex={0}
            role="button"
            className="wallet-list-item"
            onClick={() => onWalletClickHandler(WALLET_TYPE.metamask, 0)}
            onKeyPress={() => onWalletClickHandler(WALLET_TYPE.metamask, 0)}
          >
            <div className="iconWrap">
              <MetamaskIcon className="wallet-img" />
              <div className="wallet-name">
                <span>MetaMask</span>
              </div>
            </div>
            {modalLoading[0] && (
              <Spinner animation="border" height={30} width={30} />
            )}
          </div>
        </li>
      </WalletListStyled>
      <p className="text-weak">Learn more about wallets and Wethio</p>
    </Modal>
  );
};

WalletModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onWalletClickHandler: PropTypes.func,
  modalLoading: PropTypes.arrayOf(PropTypes.bool),
  onExited: PropTypes.func,
  isBrave: PropTypes.bool,
};

export default WalletModal;
