import styled, { css } from 'styled-components';

export const CustomMenuStyled = styled.div`
  width: 100%;
  min-width: 200px;
  background-color: ${(props) => props.theme.palette.selectMenu.bg};

  .menu-wrapper {
    width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    padding: 0px;
    border: none;
    // min-height: 100px;
    max-height: 320px;
    overflow-y: auto;

    ${(props) =>
      !props.$data &&
      css`
        display: grid;
        place-items: center;
      `}
  
    .dropdown-item {
      align-items: center;
      padding: 10px 20px;
      margin: 5px 0;

      &:active,
      &:focus,
      &:hover {
        background-color: ${(props) => props.theme.palette.selectMenu.hover};
        color: ${(props) => props.theme.palette.text.dark};
      }

      p {
        margin: 0;
      }
    }
  }
`;
export const MenuItemList= styled.div`
.disabled{

cursor:not-allowed !important;
pointer-events:none;
background-color: #eaeaea !important;
}
   ${props=>props.disabled && css`


cursor:not-allowed !important;
pointer-events:none;
background-color: #eaeaea !important;

`}
`;

export const SubText= styled.p`
color: ${(props) => props.theme.palette.text.primary};
`;