import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlayIcon from '../../assets/icons/playIcon';
import { PlayButtonWrap, PlayButtonWrapper } from './style';
import { ButtonSizes } from '../../constants/appConstants';

const PlayButton = ({
  size,
  className: _className = '',
  label,
  wrapperClass = '',
  ...restProps
}) => {
  const buttonClass = size ? `${size}-size` : '';
  const [className, setClassName] = useState(buttonClass);

  useEffect(() => {
    let classText = [buttonClass, ..._className.split(' ')].join(' ');
    setClassName(classText);
  }, [_className, buttonClass]);

  return (
    <PlayButtonWrapper className={wrapperClass}>
      <PlayButtonWrap className={className} {...restProps}>
        <PlayIcon className="icon" />
      </PlayButtonWrap>
      {label && <span className="play-button-label text1">{label}</span>}
    </PlayButtonWrapper>
  );
};

export default PlayButton;

PlayButton.propTypes = {
  size: PropTypes.oneOf(ButtonSizes),
  className: PropTypes.string,
  label: PropTypes.string,
  wrapperClass: PropTypes.string,
};
