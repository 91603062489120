import React from 'react';

const style={fill:'#b5b6c7'}
const mintingStepOne = ({ fillColor = '#080b35' }) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="95.12" height="104.642" viewBox="0 0 95.12 104.642">
    <defs>

    </defs>
    <g id="Groupe_6671" data-name="Groupe 6671" transform="translate(-215.441 -515.347)">
        <path id="Tracé_18495" data-name="Tracé 18495" className="cls-1" style={style} d="M0 54.792 47.7 27.25l47.42 27.376-47.793 27.157z" transform="translate(215.441 538.207)"/>
        <path id="Tracé_18496" data-name="Tracé 18496" className="cls-1" style={style} d="m0 82.025 47.7-27.541v50.109L0 132.134z" transform="translate(215.441 460.864)"/>
        <path id="Tracé_18497" data-name="Tracé 18497" d="M48.133 104.761V54.652l47.415 27.375v50.109z" transform="translate(215.014 460.696)" style={{fill:fillColor}}/>
    </g>
</svg>
  );
};

export default mintingStepOne;
