import styled from "styled-components";

export const SingleNotificationContainer = styled.div`
  transition: background-color 300ms ease-in;
  cursor: pointer;
  background-color: white;
  /* background-color: ${(props) => (props.read ? "white" : "#f0f0f0")}; */
  padding: 5px;
  margin: 5px;
  /* border-radius: 5px; */
  &:hover {
    background-color: #f0f0f0;
  }
  .title {
    font-weight: 600;
    color: #404040;
    font-size: 16px;
  }

  .description {
    font-weight: 500;
    color: #585858;
    margin-top: 5px;
    font-size: 14px;
  }
  .date {
    font-weight: 500;
    color: #808080;
    margin-top: 2px;
    font-size: 10px;
  }
`;
